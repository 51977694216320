
function dispatchEvent(name, val) {

    window.dispatchEvent(new CustomEvent(name, {
        detail: {
            storage: val
        }
    }));

}

/**
 *  Types 
 */
export function addStorageEventListener(type,callback = (event)=>{}) {
    if (['get','set','remove'].indexOf(type) > 0) {
        type = type+'-localstorage';
        window.addEventListener(type, callback);
    } else {
        console.error(`type ${type} invalid for storage event listener`);
    }
   
}

export function setLocalStorage(key, val) {
    localStorage.setItem(key,val);
    dispatchEvent('set-localstorage', {key, val});
}

export function getLocalStorage(key) {
    let val = localStorage.getItem(key);
    dispatchEvent('get-localstorage', {key,val});
    return val;
}

export function removeLocalStorage(key) {
    dispatchEvent('remove-localstorage', {key});
    localStorage.removeItem(key);
}