<template>
<div class="vue">
  <div class="container overflow">
    <div v-if="errorMessage" class="error">{{errorMessage}}</div>
    <template v-else>
      <!-- Grant a user access -->
      <div v-if="projectName || organizationName" class="row grant-access-wrapper boxed">
        <div class="col s3"></div>
        <div class="col s6 center">
          <template v-if="!userAlreadyPermitted">
          <div class="row">
            <span>
              <template v-if="projectId">{{$t('grantAccess-requestingProject', {userName: userName, projectName: projectName})}}</template>
              <template v-else>{{$t('grantAccess-requestingOrganization', {userName: userName, organizationName: organizationName})}}</template>
              </span>
            </div>
            <template v-if="!decided">
            <div class="row">

          <label>{{$t('grantAccess-includeComment')}}</label>
          <textarea v-model="comment" class="border border-gray-300"></textarea>
            </div>
          <div class="row btn-wrapper">
              <button class="btn btn-success" @click="doAccept(true)"> <span style="font-size: 20px;"></span>{{$t('grantAccess-approve')}}</button>
              <button class="btn btn-border" @click="doAccept(false)"> <span style="font-size: 20px;"></span>{{$t('grantAccess-reject')}}</button>
          </div>
          </template>
          <div v-else>
            <pulse-loader v-if="!message" :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            <span class="message">{{message}}</span>
            </div>
          </template>
          <template v-else>
            <div class="row">
              <span>
              <template v-if="projectId">{{$t('grantAccess-alreadyHasAccessProject', {userName: userName, projectName: projectName})}}</template>
              <template v-else>{{$t('grantAccess-alreadyHasAccessOrganization', {userName: userName, organizationName: organizationName})}}</template>
              </span>
            </div>
          </template>
          </div>
          <div class="col s3"></div>
        </div>
    </template>
  </div>
</div>
</template>

<script>

import {API_URL, getParameterByName, attachAuthorizaitonHeader} from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

export default {
  name: 'grant-access',
  components: {'pulse-loader': PulseLoader},
  created () {
    //attachAuthorizaitonHeader();
    this.userId = parseInt(getParameterByName('userId'));
    this.projectId = parseInt(getParameterByName('projectId'));
    this.organizationId = parseInt(getParameterByName('organizationId'));
    this.userName = getParameterByName('userName');
    if (!((!isNaN(this.userId) && !isNaN(this.projectId) && (this.userName != null)) ^ (!isNaN(this.userId) && !isNaN(this.organizationId) && (this.userName != null)))) {
      this.errorMessage = this.$t('grantAccess-missingIdParameters');
    }

    if (!this.errorMessage) {
      this.getNames();
    }
  },
  data () {
    return {
      addUserToProjectUrl: API_URL + 'add-user-to-project',
      addUserToOrganizationUrl: API_URL + 'add-user-to-organization',
      getProjectDetailsUrl: API_URL + 'get-all-users-in-and-out-of-project',
      // getUsersInProjectUrl: API_URL + "project-basic-details",
      getOrganizationDetailsUrl: API_URL + 'get-organization-details-by-id',
      errorMessage: '',
      message: '',
      userId: null,
      projectId: null,
      organizationId: null,
      projectName: null,
      organizationName: null,
      userName: null,
      decided: false,
      comment: null,
      userAlreadyPermitted: false,
      headers: {
        'Content-Type': 'application/json'
      }
    }
  },
  methods: {
    doAccept (acceptance) {
      this.decided = true;
      if (this.projectId && this.userId && !this.organizationId) {
        this.addUserToProject(acceptance);
      } else {
        if (this.organizationId && this.userId && !this.projectId) {
          this.addUserToOrganization(acceptance);
        }
      }
    },
    addUserToProject (acceptance) {
      this.$http.post(this.addUserToProjectUrl, null, {params: {userId: this.userId, projectId: this.projectId, isAccept: acceptance, comment: this.comment}, headers: this.headers}).then(function (response) {
        if (response.body.error) {
          this.errorMessage = response.body.error;
        } else {
          if (response.body.success) {
            this.message = this.$t('grantAccess-responseSent');
          }
          if (response.body.message) {
            this.message = response.body.message;
          }
        }
      });
    },
    addUserToOrganization (acceptance) {
      this.$http.post(this.addUserToOrganizationUrl, null, {params: {userId: this.userId, organizationId: this.organizationId, isAccept: acceptance, comment: this.comment}, headers: this.headers}).then(function (response) {
        if (response.body) {
          if (response.body.error) {
            this.errorMessage = response.body.error;
          } else {
            if (response.body.message) {
              // successfully rejected
              this.message = this.$t('grantAccess-responseSent');
            } else {
              this.message = this.$t('grantAccess-projectAddError');
            }
          }
        } else {
          // successfully added
          this.message = response.bodyText;
        }
      });
    },
    getNames () {
      if (this.projectId && this.userId && !this.organizationId) {
        this.getProjectName();
      } else {
        if (this.organizationId && this.userId && !this.projectId) {
          this.getOrganizationName();
        }
      }
    },
    getProjectName () {
      this.$http.get(this.getProjectDetailsUrl, {params: {projectId: this.projectId}}).then(function (response) {
        if (response.body.error) {
          this.errorMessage = response.body.error;
        } else {
          // check if the requester is already a member of the proejct
          for (let i = 0; i < response.data.usersInProject.length; i++) {
            if (response.data.usersInProject[i].user.id === this.userId) {
              this.userAlreadyPermitted = true;
              break;
            }
          }
          this.projectName = response.body.project.fullNm;
        }
      });
    },
    getOrganizationName () {
      this.$http.get(this.getOrganizationDetailsUrl, {params: {id: this.organizationId}}).then(function (response) {
        if (response.body.error) {
          this.errorMessage = response.body.error;
        } else {
          // determine if user is already a member of the organization
          for (let i = 0; i < response.data.organizationUserRoles.length; i++) {
            if (response.data.organizationUserRoles[i].userId === this.userId) {
              this.userAlreadyPermitted = true;
              break;
            }
          }
          this.organizationName = response.body.organization.name;
        }
      });
    }
  }
}

</script>
<style scoped>
.message {
  color: rgb(255, 123, 0);
}

.grant-access-wrapper{
  padding: 30px 0;
}

.btn-wrapper{
  height:35px;
}
textarea{
resize: none;

}

</style>
