<template>
  <div class="container overflow vue" id="task-page" >
    <div class="container error padded60" v-if="error != null">
        <span v-if="error.statusText != null">
          {{error.statusText}}
        </span>
        <span v-else>
          {{error}}
        </span>
      </div>
    <div class="row" v-if="error==null">
      <div class="col s12 m12" :class="{l8: showStats, xl9: showStats, l12: !showStats}" id="listeningPanel">
        <auto-complete class="!w-1/2 float-right z-10 pr-[29px] pl-[12px]"
                  :placeholder="$t('tasks-jump')"
                  :source= "getOtherProjectUrl"
                  initialValue=" "
                  initialDisplay=""
                  clearButtonIcon="ion-close"
                  name="id"
                  results-display="fullNm"
                  :request-headers="authHeaders"
                  @selected="redirectPage"
                >
                </auto-complete>
        <!-- top panel -->
        <div class="padded60 projectHeader !pb-0 !px-8 !pb-4 !pt-[30px]" :class="{narrowPanel: showStats}">
          <div v-if="project.isUserProjectMember" class="toggleWrap">
            <div id="toggleInfo">
                <span class="ion-eye-disabled" v-if="showStats" @click="showStats=false">{{$t('common-hideStats')}}</span>
                <span v-if="!showStats" class="ion-eye" @click="showStats=true">{{$t('common-showStats')}}</span>
            </div>
          </div>
          <p class="pb-3"><a :href="breadcrumbHomeLink()">{{$t('common-home')}}</a> / <a :href="breadcrumbBaseLink() + 'PC'">{{$t('common-pointCount')}}</a> /</p>
          <div class="row">
              <div class="col s12 m12 l12 xl6">
                  <a  class="link" @click="updateProject()" v-if="project.isAdmin" :title="$t('tasks-edit')">
                    <h1 style="display:inline">{{project.fullNm}}</h1>
                  <span  class="ion-edit edit-project-icon"></span></a>
                  <h1 v-else>{{project.fullNm}}</h1>
                  <p class="pt-3"> 
                    <span v-if="project.isAdmin">
                      <span class="font-bold text-2xl">{{$t('tasks-projectStatus', {status: getProjectStatus()})}}</span>
                      <template v-if="isPublished">
                        <span class="ion-android-lock red-font"></span>
                        <span class="info-icon ion-information-circled" v-tooltip="publishedTooltip" />
                      </template>
                    </span>
                  </p>

                  <p class="font-bold pt-3">{{$t('Purpose and Methods')}}</p>
                  <p v-if="project.description && project.description.length>=descLimit" class="">
                    {{project.description.substring(0,descLength)}}<span v-show="descLength==descLimit">...</span>
                    <a @click="showMore" class="text-xs cursor-pointer">{{$t('common-showMoreLess', {amount: (descLength==descLimit?$t('common-more'):$t('common-less'))})}}</a>
                  </p>
                  <p v-else-if="project.description && project.description.length<descLimit" class="">{{project.description}}</p>
                  <p v-else class="text-red-400">{{$t('Please add a Purpose and Methods to help inform users about your project')}}</p>
                  <p class="font-bold pt-3">{{$t('Results Summary')}}</p>
                  <p v-if="project.results && project.results.length>=descLimit" class="">
                    {{project.results.substring(0,descLength)}}<span v-show="descLength==descLimit">...</span>
                    <a @click="showMore" class="text-xs cursor-pointer">{{$t('common-showMoreLess', {amount: (descLength==descLimit?$t('common-more'):$t('common-less'))})}}</a>
                  </p>
                  <p v-else-if="project.results && project.results.length<descLimit" class="">{{project.results}}</p>
                  <p v-else class="text-red-400">{{$t('Please add a Results Summary to help inform users about your project')}}</p>
                  
              </div>
              <div class="col s12 m12 l12 xl6" v-if="getOtherProjectUrl!=null">
                <div class="p-5 bg-white relative">
                  <location-map sensor="PC" ref="map" v-bind:map-type="'project'" v-if="projectId" v-bind:category-id="projectId" :mapStyle="{height: '400px'}" /> 
                  <div class="absolute top-5 left-5 w-24 text-left z-30">
                    <a @click="setStyle('light-v10')"  class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block text-left !rounded-t !rounded-b-none"><i class="far  !text-xs" :class="{'fa-check-square': mapStyle=='light-v10', 'fa-square': mapStyle!='light-v10'}"></i> {{$t('common-light')}}</a>
                    <a @click="setStyle('satellite-streets-v11')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='satellite-streets-v11','fa-square': mapStyle!='satellite-streets-v11'}"></i> {{$t('common-satellite')}}</a> 
                    <a @click="setStyle('streets-v11')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='streets-v11','fa-square': mapStyle!='streets-v11'}"></i> {{$tc('common-street', 2)}}</a>
                    <a @click="setStyle('dark-v10')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-b !rounded-t-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='dark-v10','fa-square': mapStyle!='dark-v10'}"></i> {{$t('common-dark')}}</a>
                    <!-- <a @click="setStyle('outdoors-v11')" class="btn !text-xs !p-3 !py-1"><i class="far fa-square !text-xs" :class="{'fa-check-square': getStyle()=='outdoors-v11'}"></i> Outdoors</a>  -->
                  </div>
                </div>
              </div>
          </div>
       
        </div>
        <!-- tab area -->
        <section class="tabbed  !pt-0">
          <ul class="tabs">
            <li class="tab" @click="clickTab('task')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'task', disabledTab: !project.hasTaskAccess}"><span class=""></span><span class="ion-android-clipboard clipboard"><span class="ion-checkmark checkmark"></span></span> {{$tc('common-survey', 2)}}</a></li>
            <!-- <li class="tab" @click="clickTab('map')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'map', disabledTab: !project.hasMapAccess}"><span class="ion-map"></span> {{$t('common-map')}}</a></li> -->
            <li class="tab" @click="clickTab('files')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'files', disabledTab: project.hasFileAccess }"><i class="fa fa-file"></i> {{$tc('common-file',2)}}</a></li>

          </ul>
          <div class="padded60 boxed dataGroup border-gray-400 border-2">
            <task-table ref="task" v-if="project.isAdmin != null && options != null && userList != null" v-show="activeTab=='task'" v-bind:project-id="projectId" v-bind:sensor-id="project.sensorId"
              v-bind:is-manager="project.isAdmin" v-bind:has-project-write-access="project.hasProjectWriteAccess" v-bind:isUserProjectMember="project.isUserProjectMember" v-bind:filter-options="options"  v-bind:user-list="userList" v-bind:is-published="isPublished"
              v-bind:project-name="project.fullNm"v-bind:project="project" />
            <files v-show="activeTab=='files'" :projectId="projectId" :project="project" :user-list="userList"></files>
        <!-- map has it own borader -->
          </div>
        </section>
      </div>
      <div class="col s12 m12 l4 xl3" v-if="showStats">
        <label>{{$t('tasks-stats')}}</label>
        <stats :url="statUrl" customClass="row" customDivClass="col s6 m4 l4" :is-wrapped="true"/>
        <div class="note warning panel"  v-show="false"> <span class="ion-ios-close-empty dismiss"></span></div>
      </div>
    </div>
   <modal 
      name="project"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      :delay="100"
      width = "99%"
      height="808"
      :minWidth="400"
      :clickToClose = "false"
      :shiftY="0.25"
    >
      <project-form v-if="projectId != null" v-bind:project-id="projectId" v-bind:active-sensor="project.sensorId" v-bind:options="projectFormOptions"
        />
    </modal>
    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/

import TaskTable from './PCTaskTable';
import TaskPageMixin from './TaskPageMixin';
import Autocomplete from 'vuejs-auto-complete';
import StatsBox from '@/components/common/StatsBox';
import ProjectAgreementTab from './ProjectAgreementTab';
import ProjectForm from '@/components/project/ProjectForm';
import {getAuthorizaitonHeader} from '@/lib/common';
import CommonPageMixin from '@/components/common/CommonPageMixin';
import FilesTab from './FilesTab.vue';

export default {
  name: 'task-page',
  mixins: [TaskPageMixin, CommonPageMixin],
  components: {
    'task-table': TaskTable,
    'auto-complete': Autocomplete,
    'stats': StatsBox,
    'agreement': ProjectAgreementTab,
    'project-form': ProjectForm,
    'files': FilesTab

  },
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    }
  },
  methods: {
    displayDescription() {
      return project.description + 'asdfasdfsadf';
    },
    showMore () {
      if (this.descLength === this.descLimit) {
        this.descLength = 10000;
      } else {
        this.descLength = this.descLimit;
      }
    },
    /*
    Handle clicking on a tab
    only allows switching if user has permission
    */
    clickTab (tabName) {
      switch (tabName) {
        case 'task':
          if (this.project.hasTaskAccess) {
            this.activeTab = 'task';
          }
          break;
        case 'agreement':
          if (this.project.isAdmin || this.project.hasTaskAccess) {
            this.activeTab = 'agreement';
          }
          break;
        case 'map':
          if (this.project.hasMapAccess) {
            this.activeTab = 'map';
          }
          break;
          default: this.activeTab = tabName;
      };
    }
  },
  created () {
  },
  data () {
    return {
      expectedSensorType: 'PC',
      descLimit: 50,
      descLength: 50,
    }
  }
}
</script>
<style scoped>

.edit-project-icon {
  display: inline-block;
  vertical-align: top;
  padding: 1rem;
}

.disabledTab{
  pointer-events: none;
  cursor: default !important;
  color: #7D7D7D !important;

}

.clipboard{
  display: inline-block;
  font-size: 28px !important;
}

.checkmark{
  position: absolute !important;
  top: 2px !important;
  font-size: 10px !important;
  left: 7px;
}

</style>
