<template>
  <div id="project-table">
    <div class="col s12 panel boxed padded60 dataGroup tab-content !border-gray-400" :class="{ [sensor.id]: true }">
      <div class="button-section-wrapper">
        <span id="people" vcloak v-if="canCreateProject">
          <button v-tooltip="$t('project-newProjectButton-tooltip')" class="btn btn-success  projectAdd"
            @click="createProject"> <span style="font-size: 20px;" :class="{
              'ion-ios-mic': sensor.id == 'ARU',
              'ion-ios-camera': sensor.id == 'CAM'
            }"></span>
            {{ $t(`project-new-${sensor.id.toLowerCase()}-button`) }}
          </button>
        </span>

        <span
          v-tooltip="$t('Toggle on to see all of the projects you have access to. Toggle off to see a list of all projects. Click the dropdown beside the project to request access.')"
          v-if="toggleInitSetting !== null" class="project-toggle"> {{ $t('project-onlyMine') }} <ToggleButton
            :value="toggleInitSetting" :sync="true" color="#C94412" @change="switchProjectView"></ToggleButton></span>
        <span v-if="tableOptions.activeRowId.length > 0" class="ml-3">
          <dropdown-button>
            <template slot="menuTitle">{{ $t('common-manage') }} ({{ tableOptions.activeRowId.length }})</template>
            <template slot="dropdownButtons">

              <!-- Upload media to project -->
              <a><span class="btn btn-success popover-menu-item" @click="deleteProjects" v-close-popover>
                  {{ $t('Delete Projects') }}
                </span></a>

              <!-- Download locations -->
              <!-- <a class="group-top btn btn-success popover-menu-item">
                <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('locationTable-downloadCSV')}}
              </a>
               -->

            </template>
          </dropdown-button>
        </span>

      </div>
      <v-server-table ref="projectTable" v-if="optionLoaded" :url="url" :columns="columns" :options="tableOptions"
        class="table vue-bordered"  @loaded="tableLoaded()" @limit="resetCheckBoxes"
        @pagination="resetCheckBoxes">
        <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
        <template slot="shortNm" slot-scope="props">
          <a v-if="isSensorAdmin || props.row.requestingUserAdmin"
            class="editProject modal-trigger projectAdd cursor-pointer" @click="updateProject(props.row)"><span
              class="ion-edit"></span></a>
        </template>
        <template slot="h__tasksCount">
          {{ $t('project-tableHeadings.tasksCount') }}
          <span v-tooltip="$t('project-tableHeadings.tasksCount-tooltip')"> <i
              class="fas fa-info-circle text-yellow-400"></i></span>
        </template>
        <template slot="h__tasksCompletedCount">
          {{ $t('project-tableHeadings.tasksCompleted') }}
          <span v-tooltip="$t('project-tableHeadings.tasksCompleted-tooltip')"> <i
              class="fas fa-info-circle text-yellow-400"></i></span>
        </template>
        <template slot="h__check">
          <span @click="onCheckAllClick">
            <i class="fa fa-check text-2xl cursor-pointer"
              v-if="tableOptions.activeRowId.length == tableOptions.perPage"></i>
            <i class="fa-regular fa-square-full text-xl cursor-pointer"
              v-if="tableOptions.activeRowId.length < tableOptions.perPage"></i>
          </span>
        </template>
        <template slot="check" slot-scope="props">
          <label>
            <input type="checkbox" :data-id="props.row.id" @click="onCheckClick(props.row.id)" />
            <span></span>
          </label>
        </template>
        <template slot="fullNm" slot-scope="props">
          <a :href="taskRowUrl(props.row.id)" v-if="props.row.hasProjectAccess">{{ props.row.fullNm }}</a>
          <span v-if="!props.row.hasProjectAccess">{{ props.row.fullNm }}</span>
        </template>
        <template slot="statusId" slot-scope="props">
          {{ options.status.find(item => item.id == props.row.statusId).type }}
        </template>
        <div class="row" slot="child_row" slot-scope="props">
          <div class="col s12 m12 l12">
            <p class="left-align">{{ props.row.description }}</p>
            <button v-if="props.row.canRequestAccess" class="float-right btn btn-border"
              @click="requestAccess(props.row)">{{ $t('common-requestAccess') }}</button>
            <div v-if="props.row.requestingUserAdmin" class="right">
              <div class="merge-wrapper"
                v-tooltip="{ content: !(props.row.requestingUserAdmin && props.row.statusId === 2) ? $t('project-tooltips.mergeButton') : null }">
                <button :disabled="!(props.row.requestingUserAdmin && props.row.statusId === 2)" class="btn btn-success"
                  @click="openMergeModal(props.row)">{{ $t('project-mergeButton') }}</button>
              </div>
              <div class="single-btn-wrapper"
                v-tooltip="{ content: props.row.tasksCount > 0 ? $t('project-tooltips.deleteButton') : null }">
                <button class="btn" @click="deleteProject(props.row)" :disabled="props.row.tasksCount > 0"> <span
                    style="font-size: 20px;"></span>{{ $t('project-deleteButton') }}</button>
              </div>
            </div>
          </div>
          <!--div class="col s12 m6 l3 subrow-info stats">
            <p><label>Due Date:</label> {{props.row.dueDate}}</p>
            <p><label>Species Identified:</label> {{props.row.speciesCount}}</p>
          </div>
          <div class="col s12 m6 l3 subrow-info stats">
            <p><label>Total Tasks:</label> {{props.row.tasksCount}}</p>
            <p><label>Completed Tasks:</label> {{props.row.tasksCompletedCount}}</p>
          </div-->
        </div>
      </v-server-table>
    </div>
    <modal name="project" :scrollable="true" :adaptive="true" transition="nice-modal-fade" classes="modal-form scroll"
      :delay="100" width="99%" :height="'99%'" :minWidth="400" :clickToClose="false" :shiftY="0.25">
      <project-form ref="projectForm" v-if="projectId != -1" v-bind:project-id="projectId"
        v-bind:active-sensor="sensor.id" v-bind:options="options" />
    </modal>

    <modal name="requestAccess" :scrollable="true" :adaptive="true" transition="nice-modal-fade"
      classes="modal-form scroll" draggable=".drag-handle" :delay="100" width="75%" height="620" :minWidth="400"
      :minHeight="540" :clickToClose="false">
      <project-access-request-form ref="projectAccessRequestForm" v-if="projectId != -1" v-bind:project-id="projectId"
        v-bind:projectName="projectName" v-bind:organizationId="organizationId"
        v-bind:organizationName="organizationName" />
    </modal>
    <modal name="merge-form" :scrollable="true" :adaptive="true" transition="nice-modal-fade" classes="modal-form scroll"
      draggable=".drag-handle" :delay="100" width="55%" height="auto" :minWidth="400" :minHeight="500"
      :clickToClose="false">
      <merge-locations-form ref="mergeLocationForm" v-if="mergeSourceInfo" v-bind:mergeSourceInfo="mergeSourceInfo"
        v-bind:merge-type="'project'" />
    </modal>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
import ProjectForm from './ProjectForm';
import ProjectAccessRequestForm from './ProjectAccessRequestForm';
import { eventBus } from '@/lib/eventbus';
import { API_URL, PROJECT_URLS, MY_PROJECTS_TOGGLE_SELECTION } from '@/lib/common';
import { createLink } from '@/lib/language.js';
import { getLanguageSession } from '@/lib/user-auth0';
import FormMixin from '@/components/common/FormMixin.js';
import { ToggleButton } from 'vue-js-toggle-button';
import MergeFormVue from '../organization/MergeForm.vue';
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';
import { setLocalStorage } from '@/lib/localStorage';
import { getLocalStorage } from '../../lib/localStorage';

export default {
  name: 'ProjectTable',
  props: ['sensor', 'showAdminOnlyProjects'],
  components: {
    'project-form': ProjectForm,
    'project-access-request-form': ProjectAccessRequestForm,
    'ToggleButton': ToggleButton,
    'merge-locations-form': MergeFormVue,
    'dropdown-button': DropdownButtonWithOptionsVue
  },
  mixins: [FormMixin],
  async created() {
    const self = this;
    this.getPermissions();
    await this.$http.get(API_URL + 'project-options').then(
      function (response) {
        this.options = response.data;
        let status = [];
        this.options.status.forEach(function (e) {
          status.push({ id: e.id, text: e.type });
        });
        this.$set(this.tableOptions.listColumns, 'statusId', status);

      });

    await this.getOrgs();
    this.setupTableHeadings(this.sensor.id);
    this.optionLoaded = true;

    eventBus.$on('reload-table', function () {
      self.$refs.projectTable.refresh();
    });
    eventBus.$on('reload-project-form', function (projectId) {
      self.projectId = projectId;
    });
    eventBus.$on('close-project-form', function (projectId) {
      self.projectId = -1;
    });
    eventBus.$on('close-merge-form', function () {
      self.mergeSourceInfo = null;
    });

    eventBus.$on('tab-switch', () => {
      this.tableOptions.activeRowId = [];
    });

    window.ref = this;

  },
  methods: {
    getURL() {
      return this.url;
    },
    getPermissions() {
      this.isProjectAdmin = this.sensor.isManagerOfAnyProject;
      this.isSensorAdmin = this.sensor.isSensorAdmin;
      this.canCreateProject = this.sensor.isSensorAdmin || this.sensor.canUserCreateProjects;
    },
    showHideStatusColumn(isProjectAdmin) {
      // if (isAdmin) {
      //   if (!this.columns.includes('status')) {
      //     this.columns.splice(this.columns.indexOf('tasksCount'), 0, 'status');
      //   }
      // }
      // else {
      //    if (this.columns.includes('status')) {
      //     this.columns.splice(this.columns.indexOf('status'), 1);
      //   }
      // }
    },
    updateProject(row) {
      if (row.requestingUserAdmin) {
        this.projectId = row.id;
        this.showProjectForm();
      }
    },
    createProject() {
      this.projectId = null;
      this.showProjectForm();
    },
    deleteProject(row) {
      this.commonDelete(this.projectRemoveUrl, { projectIds: [row.id] }, () => {
        this.$refs.projectTable.refresh();
      });
    },
    deleteProjects() {
      this.commonDeleteBatch(this.projectRemoveUrl, { projectIds: this.tableOptions.activeRowId }, () => {
        this.$refs.projectTable.refresh();

        //Reset active rows
        this.tableOptions.activeRowId = [];
      }, '', 'delete', this.tableOptions.activeRowId.length);
    },
    showProjectForm() {
      //  console.log('projectId =', this.projectId)
      // this.$refs.projectForm.switchProject();
      this.$modal.show('project');
    },
    initTable() {
      this.tableData = [];
      // console.log(this === this.$refs['table']);
      // this.$refs['table'].refresh();
    },
    taskRowUrl: function (id) {
      return createLink(PROJECT_URLS[this.sensor.id] + id);
    },
    onRowClick(event) {
      let rowId = parseInt(event.row.id);
      event.event.target.closest('tr').querySelectorAll('input[data-id]')[0].click();

    },
    resetCheckBoxes() {
      //this.$set(this.tableOptions, 'activeRowId', []);
      this.$refs.projectTable.$el.querySelectorAll('input[data-id]:checked').forEach((n) => {
        n.click();
        //this.onCheckClick(n.getAttribute('data-id'))          
      });
    },
    onCheckAllClick(event) {
      if (this.tableOptions.activeRowId.length == 0) {
        this.$refs.projectTable.$el.querySelectorAll('input[data-id]').forEach((n) => {
          n.click();
          // this.onCheckClick(n.getAttribute('data-id'))          
        });
      } else {
        this.$refs.projectTable.$el.querySelectorAll('input[data-id]:checked').forEach((n) => {
          n.click();
          // this.onCheckClick(n.getAttribute('data-id'))          
        });
      }
    },
    onCheckClick(rowId) {
      rowId = parseInt(rowId);
      let activeRows = [...this.tableOptions.activeRowId];
      let exists = activeRows.indexOf(rowId);
      window.activeRows = activeRows;

      if (exists > -1) { // Remove
        //this.tableOptions.activeRowId.splice(exists,1);
        activeRows.splice(exists, 1);
        this.$set(this.tableOptions, 'activeRowId', activeRows);
      } else { // Add
        this.$set(this.tableOptions, 'activeRowId', [...this.tableOptions.activeRowId, rowId]);
        //this.tableOptions.activeRowId.push(rowId);
      }
    },
    tableLoaded() {

      /*
      Only do this the first time table is loaded
      if user has at least 1 project, can set toggle to view only theirs
      If not, see all projects
          */
      if (!this.tableLoadedOnce) {
        this.tableLoadedOnce = true;
        if (this.$refs.projectTable.data.length > 0) {
          // user has at least one project
          this.tableOptions.isProjectMember = true;
          //this.toggleInitSetting = true;
        } else {
          //this.toggleInitSetting = false;
          this.tableOptions.isProjectMember = false;
        }
        //  this.$refs.projectTable.refresh();
        // this.$refs['projectTable'].resetQuery();
      }
      //console.trace();
      this.$emit('loaded', true);
    },
    async getOrgs() {

      await this.commonGet(API_URL + 'get-all-readable-organizations', {}, false, (response) => {
        this.$set(this.tableOptions.listColumns, 'organizationId', response.body.map(x => { return { id: x.id, text: x.name }; }));
      });
    },
    requestAccess(row) {
      this.organizationName = row.organizationId;
      this.organizationId = this.getOrganizationIdNumber(row.organizationId);
      this.projectName = row.fullNm;
      this.projectId = row.id;
      this.$modal.show('requestAccess');
    },
    getOrganizationIdNumber(organizationText) {
      var tmp = this.tableOptions.listColumns['organizationId'].filter(org => {
        if (org.text === organizationText) {
          return org;
        }
      });
      return tmp[0].id;
    },
    switchProjectView() {
      // switch between showing all projects and only the projects where the user is a member
      this.toggleInitSetting = !this.toggleInitSetting;
      setLocalStorage(MY_PROJECTS_TOGGLE_SELECTION, this.toggleInitSetting);
      this.tableOptions.isProjectMember = !this.tableOptions.isProjectMember;
      this.$refs.projectTable.refresh();
    },
    setupTableHeadings(sensorId) {
      if (sensorId === 'PC') {
        this.columns = ['check', 'organizationId', 'fullNm', 'statusId', 'tasksCompletedCount', 'shortNm'];
        this.tableOptions.filterable = ['fullNm', 'statusId', 'organizationId'];
        this.tableOptions.headings.tasksCompletedCount = this.$t('project-tableHeadings.totalSurveys');
      } else {
        this.columns = ['check', 'organizationId', 'fullNm', 'statusId', 'dueDate', 'tasksCount', 'tasksCompletedCount', 'shortNm'];
        this.tableOptions.filterable = ['fullNm', 'statusId', 'dueDate', 'organizationId'];
        this.tableOptions.headings.tasksCompletedCount = this.$t('project-tableHeadings.tasksCompleted');
      }
    },
    openMergeModal(row) {
      this.mergeSourceInfo = row;
      this.$modal.show('merge-form');
    }
  },
  computed: {
    checkAllBoxActive() {
      return this.tableOptions.activeRowId.length == this.tableOptions.perPage;
    }
  },
  watch: {

    sensor: async function (newVal, oldVal) {
      this.loaded = false;
      this.url = API_URL + 'get-project-summary?sensorId=' + newVal.id;
      this.getPermissions();
      for (var key in this.$refs['projectTable'].query) {
        this.$refs['projectTable'].query[key] = '';
      }
      await this.getOrgs();
      this.setupTableHeadings(newVal.id);
      this.tableOptions.headings.fullNm = this.$t('project-tableHeadings.fullNmSensor', { label: this.sensor.label });

      //this.$refs['table'].refresh();
      if (newVal !== oldVal) {

      }

    }
  },
  data() {
    const pageSize = 100;
    return {
      loaded: false,
      url: API_URL + 'get-project-summary?sensorId=' + this.sensor.id + '&adminOnlyProjects=' + this.showAdminOnlyProjects,
      projectUpdateUrl: API_URL + 'insert-update-project',
      projectRemoveUrl: API_URL + 'delete-project',
      recordName: this.$tc('common-project', 1).toLowerCase(),
      toggleInitSetting: getLocalStorage(MY_PROJECTS_TOGGLE_SELECTION)=='true'?true:false,
      tableLoadedOnce: false,
      projectId: null,
      projectName: null,
      organizationId: null,
      organizationName: null,
      mergeSourceInfo: null,
      columns: ['check', 'organizationId', 'fullNm', 'statusId', 'dueDate', 'tasksCount', 'tasksCompletedCount', 'shortNm'],
      customFilters: ['projectType'],
      isProjectAdmin: null,
      isSensorAdmin: null,
      canCreateProject: false,
      options: {},
      optionLoaded: false,
      tableOptions: {
        isProjectMember: true, // show if the user is a project member or not
        activeRowId: [],
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        filterable: ['fullNm', 'statusId', 'dueDate', 'organizationId'], // 'dueDate' can't sort or filter dueDate as
        sortable: ['fullNm', 'statusId', 'tasksCount', 'tasksCompletedCount', 'dueDate', 'organizationId'],
        listColumns: {},
        headings: {
          check: 'x',
          organizationId: this.$tc('common-organization', 1),
          fullNm: this.$t('project-tableHeadings.fullNmSensor', { label: this.sensor.label }),
          statusId: this.$t('common-status'),
          dueDate: this.$t('project-tableHeadings.dueDate'),
          tasksCount: this.$t('project-tableHeadings.tasksCount'),
          tasksCompletedCount: this.$t('project-tableHeadings.tasksCompleted'),
          shortNm: this.$t('project-tableHeadings.details')
        },
        columnsClasses: {
          dueDate: 'dueDate',
          statusId: 'status',
          fullNm: 'name',
          tasksCount: 'tasks',
          tasksCompletedCount: 'completed',
          shortNm: 'details'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true
        },
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: { column: 'id', ascending: false },
        rowClassCallback(row) {
          let rowId = parseInt(row.id);
          return (this.activeRowId, this.activeRowId.indexOf(rowId) >= 0 ? 'activeTableRow' : '');
        },
        requestAdapter(data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({ column: key, value: query[key] });
            });
          }
          if (this.isProjectMember) {
            filter.push({ column: 'isProjectMember', value: this.isProjectMember }); // set if it  member or not
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter(data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.subrow-info p {
  color: #C94412;
}

th.name {
  width: 30%;
}

th.details {
  width: 3%;
}

th.year,
th.tasks {
  width: 10%;
}

th.completed {
  width: 12%;
}

th.dueDate {
  width: 10%
}

th.status {
  width: 15%;
}

.VueTables__table td,
.VueTables__table th {
  text-align: center;
}

.VueTables__table td.name,
.VueTables__table th.name {
  text-align: left;
}

.float-right {
  float: right;
}

.vue-js-switch {
  padding-left: 5px;
}

.button-section-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.project-toggle {
  margin-bottom: 10px;
  /* To match the margin on the 2 buttons */
  margin-left: auto;
  font-weight: 600;
  /*border-radius: 2px;
  border: solid #d6a491 1px;*/
}

.single-btn-wrapper {
  /* this element and css needed in order to show tooltip on disabled btn */
  display: inline-block;
}

.merge-wrapper {
  display: inline;
}
</style>
