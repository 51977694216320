var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "600px" } },
    [
      _c(
        "div",
        { staticClass: "row", staticStyle: { position: "relative" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ui.loading,
                  expression: "(ui.loading)"
                }
              ],
              staticClass: "overaly",
              staticStyle: {
                opacity: "0.6",
                position: "absolute",
                width: "100%",
                height: "100%",
                "z-index": "100",
                background: "white"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "250px" }
                },
                [
                  _c("pulse-loader", {
                    attrs: {
                      loading: true,
                      color: "#C94412",
                      size: "20px",
                      width: 400,
                      height: 400
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("aruSpeciesVerification-loadingTags")) +
                        " "
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          !_vm.ui.loading && (!_vm.recordings || _vm.recordings.length === 0)
            ? [
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("aruSpeciesVerification-noTagsFound")))
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.recordings && _vm.recordings.length > 0
            ? _c(
                "div",
                {
                  ref: "scrollBounds",
                  staticClass: "col s12 panel",
                  attrs: { id: "scrollBounds" }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col s12",
                        class: { fullWidth: !_vm.ui.infoPanelVisible },
                        attrs: { id: "mainPanel" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("div", { staticClass: "flex-initial" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.queryParam.limit,
                                      expression: "queryParam.limit"
                                    }
                                  ],
                                  ref: "limit",
                                  staticClass: "h-auto",
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.queryParam,
                                          "limit",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.changeNumberPerPage
                                    ]
                                  }
                                },
                                _vm._l(_vm.ImagePerPageOption, function(
                                  count,
                                  index
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: "countPerPage" + index,
                                      domProps: { value: count }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("common-perPage", {
                                            count: count
                                          })
                                        )
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "grow" }),
                            _vm._v(" "),
                            _vm.totalPageNum >= 0 || _vm.pagination !== null
                              ? _c("pagination", {
                                  ref: "paginationTop",
                                  staticClass: "flex-initial",
                                  attrs: {
                                    "active-page-number": _vm.queryParam.page,
                                    pagination: _vm.pagination,
                                    "total-page-num": _vm.totalPageNum,
                                    "time-stamp": _vm.timeStamp,
                                    "status-field": _vm.paginationStatusField,
                                    "order-display": _vm.orderDisplay,
                                    "is-series": _vm.filterMode === "series"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "overflow-x-scroll" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.recordings && _vm.recordings.length > 0,
                                  expression:
                                    "recordings && recordings.length> 0"
                                }
                              ],
                              key: _vm.queryParam.limit,
                              staticClass: "min-w-280"
                            },
                            [
                              _c("drag-select-container", {
                                ref: "imageSelectArea",
                                staticClass: "row less-margin pt-10",
                                attrs: {
                                  "input-selection": _vm.selectedImgId,
                                  selectorClass: "drag-select",
                                  excludeSelectorClass: "exclude",
                                  "disable-drag-selection":
                                    _vm.filterMode === "fov"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ selectedItems }) {
                                        return [
                                          _vm._l(_vm.recordings, function(
                                            img,
                                            index
                                          ) {
                                            return [
                                              index < _vm.queryParam.limit
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: img.tagId,
                                                      staticClass:
                                                        "drag-select mx-auto w-auto float-left min-w-max mr-3 ml-0 mb-2 bg-opacity-25 relative",
                                                      class: {
                                                        "bg-green-500":
                                                          img.isVerified,
                                                        "bg-yellow-500":
                                                          img.needsReview,
                                                        hidden: !_vm.tagVisible(
                                                          img
                                                        )
                                                      },
                                                      attrs: {
                                                        id: index,
                                                        "data-item": img.tagId
                                                      }
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "w-full h-full absolute left-0 top-0 bg-blue-800 z-10 bg-opacity-25 pointer-events-none",
                                                        class: _vm.getClasses(
                                                          img,
                                                          selectedItems
                                                        )
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "min-w-60 flex border border-gray-500 items-center border-solid flex p-4 relative match-height pb-12"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "relative border-4 box-content mt-10 border-gray-500 border-solid bg-white",
                                                              style: {
                                                                width:
                                                                  img.clipSpectrogramWidth +
                                                                  "px",
                                                                height:
                                                                  img.clipSpectrogramHeight +
                                                                  "px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "absolute top-0 z-10 left-1 text-xs text-black"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      parseFloat(
                                                                        img.maxFreq /
                                                                          1000
                                                                      ).toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("img", {
                                                                staticClass:
                                                                  "absolute",
                                                                style: {
                                                                  width:
                                                                    img.clipSpectrogramWidth +
                                                                    "px",
                                                                  height:
                                                                    img.clipSpectrogramHeight +
                                                                    "px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    img.tagSpectrogramURL
                                                                },
                                                                on: {
                                                                  load: () =>
                                                                    _vm.onImageLoad(
                                                                      img
                                                                    )
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "absolute bottom-0 z-20 left-1 text-xs text-black"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      parseFloat(
                                                                        img.minFreq /
                                                                          1000
                                                                      ).toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "absolute w-px h-full bg-gray-600 top-0",
                                                                style: {
                                                                  left:
                                                                    (img.clipStartTime /
                                                                      img.clipLength) *
                                                                      img.clipSpectrogramWidth +
                                                                    "px"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "absolute w-px h-full bg-gray-600 top-0",
                                                                style: {
                                                                  left:
                                                                    (img.clipEndTime /
                                                                      img.clipLength) *
                                                                      img.clipSpectrogramWidth +
                                                                    "px"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("div", {
                                                                staticClass:
                                                                  "absolute w-px h-full bg-red-600 top-0",
                                                                style: {
                                                                  left:
                                                                    (_vm.getAudioRef(
                                                                      img
                                                                    )
                                                                      .currentTime /
                                                                      img.clipLength) *
                                                                      img.clipSpectrogramWidth +
                                                                    "px"
                                                                }
                                                              })
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.showPlayPauseButton
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer text-white absolute left-0 top-0 bg-gray-500 text-sm py-2 px-4 exclude",
                                                                  attrs: {
                                                                    "data-progress":
                                                                      img.animationProgress
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.playPause(
                                                                        img
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    class: {
                                                                      "fas fa-play exclude":
                                                                        !_vm.getAudioRef(
                                                                          img
                                                                        ) ||
                                                                        _vm.getAudioRef(
                                                                          img
                                                                        )
                                                                          .paused,
                                                                      "fas fa-pause exclude": !_vm.getAudioRef(
                                                                        img
                                                                      ).paused
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cursor-pointer text-white absolute right-0 top-0 bg-gray-500 text-sm py-2 px-4 exclude",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openModal(
                                                                    img
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "far fa-window-maximize exclude"
                                                              })
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "absolute bottom-0 left-1 text-sm"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (
                                                                    _vm.staticOptions.vocalization.find(
                                                                      v =>
                                                                        v.id ==
                                                                        img.vocalizationId
                                                                    ) || {
                                                                      type: _vm.t(
                                                                        "Unknown"
                                                                      )
                                                                    }
                                                                  ).type
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex items-center p-3 text-center border-l border-r border-b border-gray-500 bg-white"
                                                        },
                                                        [
                                                          _c("i", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip",
                                                                value: _vm.$t(
                                                                  "aruSpeciesVerification-tooltips.verified"
                                                                ),
                                                                expression:
                                                                  "$t('aruSpeciesVerification-tooltips.verified')"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "flex-1 exclude",
                                                            class: {
                                                              "!cursor-pointer":
                                                                _vm.userCanModify,
                                                              "far fa-check-circle ": !img.isVerified,
                                                              "fas fa-check-circle text-green-500":
                                                                img.isVerified
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.markVerified(
                                                                  img
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "i",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "tooltip",
                                                                  rawName:
                                                                    "v-tooltip",
                                                                  value: _vm.$t(
                                                                    "aruSpeciesVerification-tooltips.rating"
                                                                  ),
                                                                  expression:
                                                                    "$t('aruSpeciesVerification-tooltips.rating')"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "flex-1 exclude relative",
                                                              class: {
                                                                "far fa-star": !img.rating,
                                                                "fas fa-star text-yellow-500":
                                                                  img.rating
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.$modal.show(
                                                                    "rating-modal"
                                                                  )
                                                                  _vm.selectedImgId = [
                                                                    img.tagId
                                                                  ]
                                                                }
                                                              }
                                                            },
                                                            [
                                                              img.rating
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "fa-layers-text fa-inverse font-light font-sans text-xs z-10 absolute inset-x-1/3 inset-y-1",
                                                                      attrs: {
                                                                        "data-fa-transform":
                                                                          "shrink-6 down-2"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          img.rating
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip",
                                                                value: _vm.$tc(
                                                                  "common-task",
                                                                  1
                                                                ),
                                                                expression:
                                                                  "$tc('common-task', 1)"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "flex-1 fas fa-tasks !cursor-pointer py-1 exclude",
                                                            on: {
                                                              click: () => {
                                                                _vm.window.open(
                                                                  _vm.recordingRowUrl(
                                                                    img
                                                                  ),
                                                                  "_blank"
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip",
                                                                value: _vm.birdNetTooltip(
                                                                  img
                                                                ),
                                                                expression:
                                                                  "birdNetTooltip(img)"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "flex-1 fas fa-brain"
                                                          }),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip",
                                                                value: _vm.loudnessTooltip(
                                                                  img
                                                                ),
                                                                expression:
                                                                  "loudnessTooltip(img)"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "flex-1 fas fa-volume-up"
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "tooltip",
                                                                  rawName:
                                                                    "v-tooltip",
                                                                  value: _vm.$t(
                                                                    "aruSpeciesVerification-tooltips.abundance"
                                                                  ),
                                                                  expression:
                                                                    "$t('aruSpeciesVerification-tooltips.abundance')"
                                                                },
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    img.abundanceType !=
                                                                    "TMTT",
                                                                  expression:
                                                                    "img.abundanceType!='TMTT'"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "flex-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  img.abundanceType
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  img.abundanceType ==
                                                                  "TMTT",
                                                                expression:
                                                                  "img.abundanceType=='TMTT'"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "flex-1 fas fa-infinity exclude"
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "audio",
                                                            {
                                                              ref: img.tagId,
                                                              refInFor: true,
                                                              attrs: {
                                                                id:
                                                                  img.tagId +
                                                                  "-audio"
                                                              }
                                                            },
                                                            [
                                                              _c("source", {
                                                                attrs: {
                                                                  src: img.tagClipURL
                                                                    ? img.tagClipURL
                                                                    : img.recordingURL,
                                                                  type:
                                                                    "audio/mpeg"
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("tag-modal", {
                                                key: index + "-modal",
                                                attrs: {
                                                  name: "modal-" + img.tagId,
                                                  tag: img,
                                                  ratingModal: () => {
                                                    _vm.$modal.show(
                                                      "rating-modal"
                                                    )
                                                  },
                                                  markVerified:
                                                    _vm.markVerified,
                                                  deleteModal: _vm.deleteModal,
                                                  userCanModify:
                                                    _vm.userCanModify,
                                                  opened: () => {
                                                    _vm.setSelectedImgId(
                                                      img.tagId
                                                    )
                                                  },
                                                  "static-options":
                                                    _vm.staticOptions
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "modal",
                                                {
                                                  key:
                                                    "delete-modal-" + img.tagId,
                                                  attrs: {
                                                    height: "auto",
                                                    name:
                                                      "delete-modal-" +
                                                      img.tagId
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "p-5" },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "aruVerificationModal-deleteTag"
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "aruVerificationModal-deleteWarning"
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grid grid-cols-2 gap-4 mt-5"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "btn",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteTag(
                                                                    img
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-check"
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common-yes"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "btn",
                                                              on: {
                                                                click: () => {
                                                                  _vm.$modal.hide(
                                                                    "delete-modal-" +
                                                                      img.tagId
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-times"
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "common-no"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1170656757
                                )
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.recordings && _vm.recordings.length > 0
                          ? _c("div", {}, [
                              _c(
                                "div",
                                { staticClass: "w-full flex my-10" },
                                [
                                  _c("div", { staticClass: "grow" }),
                                  _vm._v(" "),
                                  _vm.totalPageNum >= 0 ||
                                  _vm.pagination !== null
                                    ? _c("pagination", {
                                        ref: "paginationBottom",
                                        staticClass: "text-right flex-initial",
                                        attrs: {
                                          "active-page-number":
                                            _vm.queryParam.page,
                                          pagination: _vm.pagination,
                                          "time-stamp": _vm.timeStamp,
                                          "total-page-num": _vm.totalPageNum,
                                          "status-field":
                                            _vm.paginationStatusField,
                                          "order-display": _vm.orderDisplay,
                                          "is-series":
                                            _vm.filterMode === "series"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.speciesId !== null &&
                        _vm.pagination !== null &&
                        _vm.userCanModify
                          ? _c(
                              "div",
                              { staticClass: "col s12 m12 right-align" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col s12 m9 right-align" },
                                  [_vm._v(" " + _vm._s(_vm.messages))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col s12 m3 right-align" },
                                  [
                                    _vm.pagination[_vm.queryParam.page - 1][
                                      _vm.paginationStatusField
                                    ] === "full"
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cameraTagging-pageNumVerified",
                                                { num: _vm.queryParam.page }
                                              )
                                            )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.currentImgId != null &&
      _vm.staticOptions &&
      _vm.staticOptions.age &&
      _vm.staticOptions.age.length > 0
        ? _c("tag-form", {
            attrs: {
              id: _vm.currentImgId,
              "form-name": _vm.ui.tagFormName,
              options: _vm.staticOptions,
              "for-untagged": _vm.forUntagged,
              "is-editable": _vm.userCanModify
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("img-info", {
        attrs: { img: _vm.currentExifImgObj, "form-name": _vm.ui.exifFormName }
      }),
      _vm._v(" "),
      _c("modals-container"),
      _vm._v(" "),
      _c("modal", { attrs: { name: "error-modal" } }, [
        _c("h5", { staticClass: "px-3" }, [_vm._v("Errors")]),
        _vm._v(" "),
        _c("div", { staticClass: "p-3 overflow-y-scroll h-48" }, [
          _c(
            "ul",
            _vm._l(_vm.error, function(err) {
              return _c("li", { staticClass: "text-sm" }, [_vm._v(_vm._s(err))])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "p-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default float-right",
              on: {
                click: () => {
                  _vm.$modal.hide("error-modal")
                  _vm.error = []
                }
              }
            },
            [_vm._v("Close")]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }