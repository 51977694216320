<template>
  <div class="remote-camera camera-tagging no-top-sticky-menu">
    <div class="container error padded60" v-if="error != null">
      <span v-if="error.statusText != null">
        {{error.statusText}}
      </span>
      <span v-else>
        {{error}}
      </span>
    </div>
    <div class="warning" v-if="warning != null">
        {{warning}}
    </div>
    <div v-if="error == null">
      <div class="container large ">
          <div class="row breadCrumb">
              <div class="col s12">
                  <p><a :href="breadcrumbHomeLink()">{{ $t('common-home') }}</a> / <a :href="breadcrumbBaseLink() + 'ARU'">{{ $t('common-aru') }}</a> /
                  <a :href="breadcrumbARUSPPProjectLink()">{{project && project.fullNm}}</a> / {{$t('aruSpeciesVerification-breadcrumb')}}</p>
              </div>
          </div>
      </div>
      <div class="stickyWrap"  id="topStickyBar">
        <div id="topStickyBarContent" class="sticky" style="position:static; "
          v-sticky="{zIndex: 21, stickyTop: 0, stickTopOnly: true, positionType: 'fixed', stickyContainerId: 'topStickyBar'}"
          >
          <div class="container large taskHeader">
              <div class="row"  >
                  <div class="col s12 m12 l8 title">
                    <div>
                      <h1>
                        <img 
                        v-if="speciesObj && !imageNotFound"
                        class="rounded-full w-12 inline" 
                        :src="`https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${speciesObj.scientificName}`"
                        @error="imageNotFound=true" 
                        />
                        <i v-if="imageNotFound" 
                        :data-src="`https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${speciesObj.scientificName}`"
                        class="fas fa-question-circle text-5xl"></i>
                        {{speciesObj && speciesObj.commonName}} 
                        <!-- {{vocalizationId&&staticOptions?'- '+staticOptions.vocalization.find(x=> x.id == vocalizationId).type:''}} -->
                      </h1>
                      <div class="actionGroup">
                         
                          <div class="editLink" @click="toggleDialog(ui.helpFormName, true)">
                              <span class="ion-help-circled"></span>{{ $t('common-help') }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col s12 m12 l4 filters" v-if="projectSppInfo.canUserModify">
                    <div class="row">
                        <div class="col m12">
                             
                          <div v-if="selectedImageCount > 0" class="mt-3 grid grid-cols-3 gap-4">
                                  <a @click="() => { toggleDropdown(); $modal.show('edit-modal'); }" class="btn" role="menuitem" tabindex="-1" id="menu-item-0">
                                    <span class="fas fa-pencil"></span>  {{ $t('Edit') }} ({{selectedImageCount}})</a>
                                  <a @click="() => {tagSelected(); toggleDropdown(); }" class="btn" role="menuitem" tabindex="-1" id="menu-item-0">
                                    <span class="fas fa-check"></span>  {{ $t('common-verifySelected') }} ({{selectedImageCount}})</a>
                                  <a @click="() => { toggleDropdown(); $modal.show('rating-modal'); }" class="btn" role="menuitem" tabindex="-1" id="menu-item-1">
                                    <i class="fas fa-star"></i> {{$t('common-rate')}} ({{selectedImageCount}})</a>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <span v-show="!projectSppInfo.canUserModify"><i class="fas fa-lock"></i> {{$t('common-readOnly')}}</span>
          </div>
     
        </div>
      </div>
    </div>
    <!-- tab area -->
    <div class="container overflow" style="margin-top: 20px;" v-if="error == null">
    
        <div class="padded boxed dataGroup">
          <verify-filter v-if="staticOptions!=null && project.id !=null && speciesCode !=null"
                v-bind:static-options="staticOptions"
                v-bind:species-code="speciesCode"
              
            />
          <recording-verify-list ref="tag"
          v-if="project && project.id && speciesCode && speciesObj"
            @imageCountChange="imageCountChange"
            v-bind:project-id="project.id"
            v-bind:speciesObj ="speciesObj"
            v-bind:static-options="staticOptions"
            v-bind:userCanModify="projectSppInfo.canUserModify"
            :vocalizationId="vocalizationId"
            />
        </div>
    </div>
    <tagging-help v-bind:form-name="ui.helpFormName" />
    <v-dialog/>
    <modal name="rating-modal" height="auto" class="relative" :width="400">
      <div class="text-center p-3 w-full h-full ">
        <h2 class="mt-4">{{$t('common-rate')}}</h2>
        <div class="text-3xl">
          <i v-for="i in 5" :key="i" class="cursor-pointer far fa-star" @mouseover="mouseOverStar(i)" @mouseleave="mouseLeaveStar" :class="{'fas fa-star text-yellow-400': starRating>(i-1)&&starRating<=5}" @click="clickStar(i)"></i>
        </div>
        <div >
          <div class="text-sm h-auto -mx-3 fixed bg-white z-20 w-[400px] p-5" v-for="i in 5" :key="i" v-show="ratingModal.hoverValue===i">
            {{ratingModal.examples[i-1]}}
            <div class="flex">
            <div :class="`star${i}_example1 w-24 h-24 flex-1 bg-contain`"></div>
            <div class="flex-1"></div>
            <div :class="`star${i}_example2 w-24 h-24 flex-1 bg-contain`"></div>
            </div>
          </div>
        </div>
        <div class="btn mt-6" @click="() => {  rateSelected(); $modal.hide('rating-modal'); starRating = null; }">{{$t('common-submit')}}</div>
      </div>
    </modal>
    <modal name="edit-modal" height="auto" width="75%" @closed="editModalClose">
      <div class="p-3 w-full h-full">
        <h5 class="mt-4 ">{{$t('Batch Edit Tags')}}</h5>
        <div class="grid grid-cols-1 mb-3">
           <div> 
              <label>{{$t('Target Species')}}</label>
               <auto-complete ref="speciesInput" v-if="speciesObj"
                  :placeholder="$t('recordingForms-spaceListsAllSpecies')"
                  :source= "getSpeciesAPIUrl"
                  :initialDisplay="formattedSpeciesDisplay(this.speciesObj)"
                  :initialValue="this.speciesObj.id"
                  name="code"
                  resultsValue="code"
                  :results-display="formattedSpeciesDisplay"
                  excludeCharacter ='|'
                  :request-headers="authHeaders"
                  @selected="setSpeciesId"
                  @clear="clearSpeciesInput"
                >
                </auto-complete>
             </div>
        </div>
        <div class="grid grid-cols-2 gap-4" v-show="!editModal.loadingTagOptions">
             <div> 
              <label>{{$t('Vocalization Type')}}</label>
              <select v-model="editModal.vocalizationId">
                <option :value="null">-- {{$t('Unchanged')}} --</option>
                <option v-for="value in editModal.tagOptions.vocalization" :value="value.id">{{value.type}}</option>
              </select> 
             </div>
             <div> 
              <label>{{$t('Abundance')}}</label>
              <select v-model="editModal.abundanceId">
                <option :value="null">-- {{$t('Unchanged')}} --</option>
                <option v-for="value in editModal.tagOptions.abundance" :value="value.id">{{value.type}}</option>
              </select> 
             </div>
        </div>
        <div class="grid grid-cols-1 gap4" v-show="!editModal.loadingTagOptions">
                <div> 
                  <label for="verify">
                    <input type="checkbox" id="verify"  name="verify" v-model="editModal.verify">
                    <span>{{ $t('Verify') }}</span>
                  </label>
                   <label for="notify">
                    <input type="checkbox" id="notify"  name="notify" v-model="editModal.notify">
                    <span>{{ $t('Notify Tag Creator') }}</span>
                  </label>
                </div>
        </div>
        <div  v-show="editModal.loadingTagOptions"  class="text-center mt-3">
          <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="50"></pulse-loader>
          <div> {{ $t('Loading Tag Options') }} </div>
        </div>
        
        <div :disabled="!editModal.specieId && !editModal.vocalizationId && !editModal.abundanceId && !editModal.verify" class="btn mt-6" @click="() => {  editSelected(); $modal.hide('edit-modal'); }">{{$t('common-submit')}}</div>
      </div>
    </modal>
  </div>
</template>

<script>
/* this component show is the camera tagging page.
*/
import Autocomplete from 'vuejs-auto-complete';
import {API_URL, CAM_PROJECT_URL, getParameterByName, getAuthorizaitonHeader} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';

import RecordingVerifyList from '@/components/ARU/RecordingVerifyList';
import VerifyFilter from '@/components/ARU/SpeciesVerifyFilter';
import TaggingMixin from './TaggingMixin.js';
import TaggingHelp from './TaggingHelp';
import DropdownButton from '@/components/common/DropdownButtonWithOptions';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

import CommonPageMixin from '@/components/common/CommonPageMixin';

import example1 from '../../assets/1star_example1.png';

export default {
  name: 'aru-species-verification-page',
  mixins: [TaggingMixin, CommonPageMixin],
  components: { 'pulse-loader': PulseLoader, 'dropdown': DropdownButton, 'auto-complete': Autocomplete, 'recording-verify-list': RecordingVerifyList, 'verify-filter': VerifyFilter, 'tagging-help': TaggingHelp},
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    },
    getSpeciesAPIUrl() {
      return API_URL + 'get-species?projectId=' + this.projectId + '&filter=';
    }
  },
  methods: {
    mouseOverStar(value) {
      this.ratingModal.hoverValue = value;
    },  
    mouseLeaveStar() {
      this.ratingModal.hoverValue = null;
    },
    imageCountChange (count) {
      this.selectedImageCount = count;
    },
    toggleDropdown () {
      this.ui.dropdownhide = !this.ui.dropdownhide;
    },
    clickStar (num) {
      if (this.starRating == num) this.starRating = null;
      else this.starRating = num;
    },
    rateSelected () {
      this.$refs.tag.batchRateTags(this.starRating);
    },
    editSelected() {
      this.$refs.tag.batchEditTags({
        newSpeciesId: this.editModal.specieId,
        newAbundanceId: this.editModal.abundanceId,
        newVocalizationId: this.editModal.vocalizationId,
        isVerified: this.editModal.verify,
        emailUser: this.editModal.notify
      });
    },
    formattedSpeciesDisplay (result) {
      if (!result) return;
      return result.code + ' | ' + result.commonName + ' | ' + (result.prediction ? ' ' + Math.floor(result.prediction * 100) + '%' : '');
    },
    setSpeciesId (speciesRow) {
      this.editModal.specieId = parseInt(speciesRow.value) || parseInt(speciesRow.selectedObject.id);
      this.getTagOptions(this.editModal.specieId).then(()=> {
        this.editModal.abundanceId = null;
        this.editModal.vocalizationId = null;
      });
    },
    getTagOptions (speciesId) {

      // first reset tagOptions
      this.editModal.tagOptions = {
        abundance: [],
        vocalization: []
      };

      this.editModal.tagOptionsError = false;
      this.editModal.loadingTagOptions = true;

      let promise = new Promise((resolve,reject) => {

      
        this.$http.get(this.editModal.getTagOptionsUrl + speciesId).then((response) => {
          if (response.body.hasOwnProperty('error')) {
            this.editModal.tagOptionsError = true;
            reject();
          } else {
            this.editModal.tagOptionsError = false;
            this.editModal.tagOptions = response.body;
            resolve(true);
          }
          this.editModal.loadingTagOptions = false;
        });
      });

      return promise;
    },
    editModalClose() {
      //reassign original tag options
      this.editModal.tagOptions = this.current.tagOptions;
      this.editModal.vocalizationId = this.current.vocalizationId;
      this.editModal.abundanceId = this.current.abundanceId;
      this.editModal.verify = false;
      this.editModal.notify = false;
    },
    clearSpeciesInput() {
      this.editModal.specieId = null;
    }

  },
  created () {
    console.log(example1);
    this.projectId = parseInt(getParameterByName('projectId'));
    if (!this.projectId) {
      this.error = this.$t('tasks-idError');
      return;
    }

    this.speciesCode = getParameterByName('speciesId');
    if (!this.speciesCode) {
      this.error = this.$t('common-missingSpecies');
      return;
    }
    // this.vocalizationId = getParameterByName('vocalizationId');
    // if (!this.vocalizationId) {
    //   this.error = this.$t('aruSpeciesVerification-missingVocalizationId');
    //   return;
    // }

    this.loadStaticOption(false);
    this.loadSpeciesObj();

    this.editModal.vocalizationId = this.vocalizationId;
    //Store original options in base data prop. To restore later.
    this.getTagOptions(this.speciesCode).then(()=>{
      this.current.tagOptions = this.editModal.tagOptions;
      this.current.abundanceId = this.editModal.abundanceId;
      this.current.vocalizationId = this.editModal.vocalizationId;
    });

    /* get project details: name, year, description */
    this.$http.get(this.getProjectDescriptionUrl, {params: {projectId: this.projectId}}).then((response) => {
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        this.project = response.data;
      }
      // this.deployment = response.data.projectUserDeploymentOverview;
    }, (err) => {
    // error callback
      this.error = err;
    });

    this.$http.get(this.getProjectSpeciesInfoUrl, {params: {projectId: this.projectId, speciesId: this.speciesCode, vocalizationId: this.vocalizationId}}).then((response) => {
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        this.projectSppInfo = response.data;
      }
      // this.deployment = response.data.projectUserDeploymentOverview;
    }, (err) => {
    // error callback
      this.error = err;
    });

    const self = this;
    /* always have specie filtered */
    eventBus.$on('update-filter-flag', function (queryParam) {
      self.hasFilter = (Object.keys(queryParam).length > 1);
    });
  },
  data () {
    return {
      ratingModal: {
        hoverValue: null,
        examples: [
          this.$t("1 Star: Very weak target sound that is barely audible due to high background or handling noise or many overlapping sounds."),
          this.$t("2 Stars: Weak target sound with significant background or handling noise or many overlapping sounds."),
          this.$t("3 Stars: Good target sound with moderate background noise or some overlap with other sounds."),
          this.$t("4 Stars: Strong target sound with limited background noise or some overlap with other sounds."),
          this.$t("5 Stars: Very strong target sound with little or no background noise or overlap with other sounds."),
        ]
      },
      editModal: {
          getTagOptionsUrl: API_URL + 'aru-tag-options?speciesId=',
          getSpeciesAPIUrl: API_URL + 'get-species?projectId=' + this.currentProjectId + '&filter=',
          loadingTagOptions: false,
          tagOptions: {
                  abundance: [],
                  vocalization: []
          },
          specieId: null,
          vocalizationId: null,
          abundanceId: null,
          verify: false,
          notify: false
      },
      current: {
        tagOptions: {
                    abundance: [],
                    vocalization: []
        },
        vocalizationId: null,
        abundanceId: null,
      },
      
      autoIdSpecies: [],
      getTaskOverviewUrl: API_URL + 'get-task-overview?',
      
      projectPageUrl: CAM_PROJECT_URL,
      imageNotFound: false,
      projectId: -1, // current deployment + user assignment id
      deploymentOptions: null,
      getProjectDescriptionUrl: API_URL + 'get-project',
      // getDeploymentOverviewUrl: API_URL + 'get-camera-project-user-deployment-overview?id=', // basic information about this deployment
      project: {},
      getProjectSpeciesInfoUrl: API_URL + 'aru-get-project-species-overview',
      projectSppInfo: {},
      // deployment: {},
      speciesCode: [],
      vocalizationId: null,
      hasFilter: false, // for UI, show whether filter has values;
      selectedImageCount: 0,
      starRating: null,
      ui: {
        activeTab: 'tag',
        showFilter: false,
        helpFormName: 'help-form',
        showSelectAll: true,
        dropdownhide: true
      
      },
      error: null

    }
  }
}
</script>
<style>
  .remote-camera .actionGroup .editLink.has-filter {
    color: #308233;
  }
  .padded.boxed.dataGroup {
    padding: 40px 20px;
  }
  .autocomplete .autocomplete__box .autocomplete__inputs input[type="text"] {
    margin: 0 !important;
  }
  .autocomplete__inputs {
    border: 0 !important;
  }
  .star1_example1 {
    background-image: url('../../assets/1star_example1.png');
  }
  .star1_example2 {
    background-image: url('../../assets/1star_example2.png');
  }
  .star2_example1 {
    background-image: url('../../assets/2star_example1.png');
  }
  .star2_example2 {
    background-image: url('../../assets/2star_example2.png');
  }
  .star3_example1 {
    background-image: url('../../assets/3star_example1.png');
  }
  .star3_example2 {
    background-image: url('../../assets/3star_example2.png');
  }
  .star4_example1 {
    background-image: url('../../assets/4star_example1.png');
  }
  .star4_example2 {
    background-image: url('../../assets/4star_example2.png');
  }
  .star5_example1 {
    background-image: url('../../assets/5star_example1.png');
  }
  .star5_example2 {
    background-image: url('../../assets/5star_example2.png');
  }
</style>
