var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "deployment-table tasks", attrs: { id: "PCTask-table" } },
    [
      _vm.message != null
        ? _c(
            "div",
            {
              staticClass: "row col s12 error no-bottom-margin",
              class: { "error-hide": !_vm.message }
            },
            [_vm._v(_vm._s(_vm.message))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isPublished != null && _vm.hasProjectWriteAccess != null
        ? _c(
            "div",
            { staticClass: "right row col s12" },
            [
              _c(
                "dropdown-button",
                { staticClass: "float-right" },
                [
                  _c("template", { slot: "menuTitle" }, [
                    _vm._v(_vm._s(_vm.$t("common-manage")))
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "dropdownButtons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-success popover-menu-item",
                        class: { downloading: _vm.downloadingTags },
                        attrs: { download: "" },
                        on: { click: _vm.downloadTags }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _c("span", { staticClass: "spin-icon" }),
                        _vm.tableLoadedOnce && _vm.$refs.table.data.length === 0
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("taskTable-downloadSurveyTemplate")
                                )
                              )
                            ]
                          : [
                              _vm._v(
                                _vm._s(_vm.$t("taskTable-downloadSurveys"))
                              )
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    !_vm.canUpload
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: _vm.tooltips.disabledUpload,
                                  show: _vm.canUpload,
                                  classes: "front"
                                },
                                expression:
                                  "{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}"
                              }
                            ]
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "close-popover",
                                    rawName: "v-close-popover"
                                  }
                                ],
                                staticClass:
                                  "btn btn-success popover-menu-item",
                                attrs: {
                                  disabled:
                                    !_vm.hasProjectWriteAccess ||
                                    _vm.isPublished
                                },
                                on: { click: _vm.uploadSurveysCSV }
                              },
                              [
                                _c("span", {
                                  staticStyle: { "font-size": "20px" }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("common-uploadItem", {
                                        item: _vm.$tc("common-survey", 2)
                                      })
                                    )
                                )
                              ]
                            )
                          ]
                        )
                      : _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "close-popover",
                                rawName: "v-close-popover"
                              }
                            ],
                            staticClass: "btn btn-success popover-menu-item",
                            on: { click: _vm.uploadSurveysCSV }
                          },
                          [
                            _c("span", {
                              staticStyle: { "font-size": "20px" }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common-uploadItem", {
                                    item: _vm.$tc("common-survey", 2)
                                  })
                                )
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "group-top btn btn-success popover-menu-item",
                        class: { downloading: _vm.downloading },
                        attrs: { download: "" },
                        on: { click: _vm.downloadLocationCSV }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _c("span", { staticClass: "spin-icon" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("locationTable-downloadCSV"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.canUpload
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: {
                                  content: _vm.tooltips.disabledUpload,
                                  show: _vm.canUpload,
                                  classes: "front"
                                },
                                expression:
                                  "{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}"
                              }
                            ]
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "close-popover",
                                    rawName: "v-close-popover"
                                  }
                                ],
                                staticClass:
                                  "btn btn-success popover-menu-item",
                                attrs: {
                                  disabled:
                                    !_vm.hasProjectWriteAccess ||
                                    _vm.isPublished
                                },
                                on: { click: _vm.uploadLocationCSV }
                              },
                              [
                                _c("span", {
                                  staticStyle: { "font-size": "20px" }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("locationTable-uploadCSV"))
                                )
                              ]
                            )
                          ]
                        )
                      : _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "close-popover",
                                rawName: "v-close-popover"
                              }
                            ],
                            staticClass: "btn btn-success popover-menu-item",
                            on: { click: _vm.uploadLocationCSV }
                          },
                          [
                            _c("span", {
                              staticStyle: { "font-size": "20px" }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("locationTable-uploadCSV"))
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "group-top btn btn-success popover-menu-item",
                        class: { downloading: _vm.downloadingCodes },
                        attrs: { download: "" },
                        on: { click: _vm.downloadCodes }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _c("span", { staticClass: "spin-icon" }),
                        _vm._v(" " + _vm._s(_vm.$t("common-downloadCodes")))
                      ]
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.optionsLoaded
        ? _c("v-server-table", {
            ref: "table",
            staticClass: "table vue-bordered taskTable",
            attrs: {
              url: _vm.apiUrls.list,
              columns: _vm.columns,
              options: _vm.tableOptions
            },
            on: {
              loaded: function($event) {
                return _vm.tableLoaded()
              },
              "row-click": _vm.onRowClick
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "locationName",
                  fn: function(props) {
                    return [
                      _c(
                        "a",
                        { attrs: { href: _vm.recordingRowUrl(props.row.id) } },
                        [_vm._v(_vm._s(props.row.locationName))]
                      )
                    ]
                  }
                },
                {
                  key: "distanceMethodId",
                  fn: function(props) {
                    return [
                      [
                        _vm._v(
                          _vm._s(
                            _vm.distanceMethods.get(props.row.distanceMethodId)
                          )
                        )
                      ]
                    ]
                  }
                },
                {
                  key: "durationMethodId",
                  fn: function(props) {
                    return [
                      [
                        _vm._v(
                          _vm._s(
                            _vm.durationMethods.get(props.row.durationMethodId)
                          )
                        )
                      ]
                    ]
                  }
                }
              ],
              null,
              false,
              1269583788
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "delete_task",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "55%",
            height: "420",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _vm.showDeleteForm && _vm.taskId > 0
            ? _c("task-delete-form", {
                attrs: {
                  "task-row": _vm.taskRow,
                  "task-id": _vm.taskId,
                  headings: _vm.tableOptions.headings,
                  "active-sensor": "PC"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "project-upload-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: 620,
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("upload-pc-project-csv", {
            attrs: {
              "project-name": _vm.projectName,
              projectId: _vm.projectId,
              sensor: "PC",
              "upload-url": _vm.apiUrls.uploadTagsCSVUrl
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "location-upload-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: 620,
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _vm.project.organizationId && _vm.options
            ? _c("upload-location-csv", {
                attrs: {
                  "organization-id": _vm.project.organizationId,
                  "organization-name": _vm.project.fullNm,
                  "visibility-options": _vm.options.visibility,
                  "project-id": _vm.projectId,
                  project: _vm.project,
                  type: "project-location"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }