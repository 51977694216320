<template>
    <div class="container-wrapper ">
        
    <div class="container overflow col s12 panel boxed padded60 dataGroup tab-content ">
        <h1 class="">UUID Entry</h1>
        <div class="flex justify-center items-center gap-1 w-1/2">
            <input ref="box1" type="text" class="numBox !w-[110px]" id="0" maxlength="8" @focus="focus(0)" @keyup="keyupEvent">-
            <input ref="box2" type="text" class="numBox !w-[67px]" id="1" maxlength="4" @focus="focus(1)" @keyup="keyupEvent">-
            <input ref="box3" type="text" class="numBox !w-[67px] small" id="2" @focus="focus(2)" maxlength="4" @keyup="keyupEvent">-
            <input ref="box4" type="text" class="numBox !w-[67px] small" id="3" @focus="focus(3)" maxlength="4" @keyup="keyupEvent">-
            <input ref="box5" type="text" class="numBox large" id="4" @focus="focus(4)" maxlength="12" @keyup="keyupEvent">
        </div>
        <div class="flex justify-center items-center gap-1 w-1/2">
            <button :disabled="disableGo" id="goBtn" class="btn flex-1" type="button" @click="openUrl">Go</button>
            <button class="btn flex-1" type="button" @click="clear">Clear</button>
        </div>
    </div>
</div>
</template>
<script>
import { logout } from '@/lib/user-auth0';
export default {
    name: 'id-entry',
    data() {
        return {
            disableGo: true,
            focusedIndex: null,
            boxes: []
        }
    },
    created() {

    },
    mounted() {
        this.boxes = [this.$refs.box1, this.$refs.box2, this.$refs.box3, this.$refs.box4, this.$refs.box5];
    },
    methods: {
        focus(index) {
            this.focusedIndex = index;
        },
        keyupEvent(event) {
            if (event.keyCode === 13 || event.target.value.length === event.target.maxLength) {
                console.log(this.focusedIndex, this.boxes.length);
                if (this.focusedIndex !== this.boxes.length-1) {
                    this.boxes[this.focusedIndex+1].focus();
                }
            }
            this.checkIfReady();
        },
        checkIfReady() {
            let count = 0;
            this.boxes.forEach(box=> {
                if (box.value.length == box.maxLength) count++;
            });

            this.disableGo = !(count == this.boxes.length)
        },  
        openUrl() {
            let uuid = [];
            this.boxes.forEach(box=> {
                uuid.push(box.value);
            });
            let url = `https://${window.location.hostname}/home/landowners/reports.html?UUID=${uuid.join("-")}`;
            window.open(url);
        },
        clear() {
            this.boxes.forEach(box=> {
                box.value = '';
            }); 
            this.disableGo = true;
        }
        

    }
    
}
</script>