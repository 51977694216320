import {alertDialog, confirmDialog} from '@/lib/common';
import {isLoggedIn, login} from '@/lib/user-auth0.js';
// shared messages
export default {
  data () {
    return {
      commonMsg: null
    }
  },
  created () {
    this.commonMsg = this.confirmMsgGenerator(this.recordName || '');
  },
  methods: {
    confirmMsgGenerator (name) {
      return {
        deny: {
          view: this.$t('commonForm-deny.view', {name: name}),
          delete: this.$t('commonForm-deny.delete', {name: name}),
          add: this.$t('commonForm-deny.add', {name: name}),
          update: this.$t('commonForm-deny.update', {name: name})
        },
        done: {
          add: this.$t('commonForm-done.add', {name: name}),
          delete: this.$t('commonForm-done.delete', {name: name}),
          update: this.$t('commonForm-done.update', {name: name}),
          batchDelete: this.$t('commonForm-done.batchDelete', {name: name})
        },
        fail: {
          view: this.$t('commonForm-fail.view', {name: name}),
          add: this.$t('commonForm-fail.add', {name: name}),
          delete: this.$t('commonForm-fail.delete', {name: name}),
          update: this.$t('commonForm-fail.update', {name: name}),
          batchDelete: this.$t('commonForm-fail.batchDelete', {name: name})
        },
        confirm: {
          delete: this.$t('commonForm-confirm.delete', {name: name})
        },
        selectionRequired: this.$t('commonForm-selectionRequired', {name: name}),
        loading: this.$t('common-loading')
      }
    },
    commonInsertUpdate (url, postObj, actionKey, successAction, failedAction, noteMsg = '') {
      this.errors = [];
      return new Promise((resolve,reject) => {
        this.$http.post(url, postObj).then(function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.errors.push(this.commonMsg.fail[actionKey] + noteMsg + response.data.error);
            reject(Error(response.data.error));
          } else {
            this.errors = [];
            this.errors.push(this.commonMsg.done[actionKey]);
            successAction && successAction(response);
            resolve(response);
          }
        }, (response) => {
          this.errors.push(this.commonMsg.fail[actionKey] + noteMsg + response.data.error);
          failedAction && failedAction(response);
          reject(Error(response.data.error));
        }
        );
      });
    },

    commonBatchDelete (url, postObj, count, successAction, noteMsg = '', actionKey = 'batchDelete') {
      confirmDialog(this.$modal, this.$t('common-delete'), this.$t('commonForm-batchDeleteMsg', {count: count, recordName: this.recordName}),
        'Yes', () => { this._commonDelete(url, postObj, successAction, noteMsg, actionKey); },
        'Cancel'
      );
    },
    commonDelete (url, postObj, successAction, noteMsg = '', actionKey = 'delete') {
      confirmDialog(this.$modal, this.$t('common-delete'), this.commonMsg.confirm.delete,
        'Yes', () => { this._commonDelete(url, postObj, successAction, noteMsg, actionKey); },
        'Cancel'
      );
    },
    commonDeleteBatch (url, postObj, successAction, noteMsg = '', actionKey = 'delete', number = 0) {
      confirmDialog(this.$modal, this.$t('common-delete'), this.$t('commonForm-confirm.batchDelete', {name: this.recordName, number: number}),
        'Yes', () => { this._commonDelete(url, postObj, successAction, noteMsg, actionKey); },
        'Cancel'
      );
    },
    _commonDelete (url, postObj, successAction, noteMsg = '', actionKey) {
      this.$http.post(url, postObj).then(
        response => {
          if (response.data.hasOwnProperty('error')) {
            alertDialog(this.$modal, this.$tc('common-error', 1), `<span class="error">${this.commonMsg.fail[actionKey]} ${noteMsg} </span> <br>${response.data.error}`);
          } else {
            alertDialog(this.$modal, this.$t('common-done'), this.commonMsg.done[actionKey]);
            successAction(response);
          }
        }, (response) => {
          console.error(response);
          alertDialog(this.$modal, this.$tc('common-error', 1), `<span class="error">${this.commonMsg.fail[actionKey]} ${noteMsg} </span> <br> ${response.data}`); 
        });
    },
    async commonGet (url, params, showAlert, successAction, failedAction) {
      this.errors = [];
      try {
        const response = await this.$http.get(url, params);
        if (response.data.hasOwnProperty('error')) {
          if (showAlert) {
            console.error(response.data.error)
            this.errors.push(this.commonMsg.fail['view']);
          }
          if (failedAction) {
            failedAction(response);
          }
        } else {
          if (showAlert) {
            this.errors = [];
            this.errors.push(this.commonMsg.done['view']);
          }
          successAction(response);
        }
      } catch (e) {
        console.log('failed ,..', e);
        if (e.status === 401 || e.status === 401) {
          if (!isLoggedIn()) {
            //login();
          }
        }
        if (showAlert) {
          console.error(e);
          this.errors.push(this.commonMsg.fail['view'] + e);
        }
      }
    },

    async commonPost (url, params, showAlert, successAction, failedAction, actionKey = 'update') {
      this.errors = [];
      try {
        const response = await this.$http.post(url, params);
        if (response.data.hasOwnProperty('error')) {
          if (showAlert) {
            console.error(response.data.error);
            this.errors.push(this.commonMsg.fail[actionKey] + response.data.error);
            if (failedAction) {
              failedAction(response);
            }
          }
        } else {
          if (showAlert) {
            this.errors = [];
            this.errors.push(this.commonMsg.done[actionKey]);
          }
          successAction(response);
        }
      } catch (e) {
        console.log('failed ,..', e);
        if (e.status === 401 || e.status === 401) {
          if (!isLoggedIn()) {
            //login();
          }
        }
        if (showAlert) {
          console.error(e);
          this.errors.push(this.commonMsg.fail[actionKey] + e);
          if (failedAction) {
            failedAction(e);
          }
        }
      }
    },
    async postAndPassBack (url, params, successAction, failedAction) {
      try {
        const response = await this.$http.post(url, params);
        if (response.data.hasOwnProperty('error')) {
          if (failedAction) {
            failedAction(response.data);
          }
        } else {
          successAction(response);
        }
      } catch (e) {
        console.log('failed ,..', e);
        if (e.status === 401 || e.status === 401) {
          if (!isLoggedIn()) {
            //login();
          }
        }
        failedAction(e);
      }
    },
    async getAndPassBack (url, params, successAction, failedAction) {
      try {
        const response = await this.$http.get(url, params);
        if (response.data.hasOwnProperty('error')) {
          if (failedAction) {
            failedAction(response.data);
          }
        } else {
          successAction(response);
        }
      } catch (e) {
        console.log('failed ,..', e);
        if (e.status === 401 || e.status === 401) {
          if (!isLoggedIn()) {
            //login();
          }
        }
        failedAction(e);
      }
    }
  }
}
