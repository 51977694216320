<template>
  <div id="ARUTask-table" class="deployment-table tasks">
    <div class="grid-cols-3 grid gap-2">
      
      <div v-if="isManager && !isPublished" class=" no-bottom-margin col-span-2"><!-- right">-->
        <multiselect :selectAllLabel="$t('common-selectAll')" v-model="randomAssignUsers" :options="userList"
          :multiple="true" :close-on-select="false" :placeholder="$t('taskTable-selectObserversToAssign')" label="text"
          track-by="id" selectLabel="" deselectLabel="" :showSelectAll="true" class="tagger-select">
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{
              $t('taskTable-validatorsSelected', {
                num:
                  values.length
              }) }}</span>
            <span class="multiselect__single" v-if="values.length && values.length == 1 && !isOpen">{{
              $t('taskTable-singleValidator') }}</span>
          </template>
        </multiselect>
        <a class='btn btn-success btnimportant right assign-btn' :class="{ 'downloading': randomAssignLoading }"
          @click="randomAssign"><span class="spin-icon"></span>{{ $t('taskTable-randomAssign') }}</a>
      </div>
      <div class="">
        <span v-if="toggleInitSetting !== null && isUserProjectMember" class="task-toggle-manage">
          {{ $t('taskTable-viewOnlyMine') }} <ToggleButton :value="toggleInitSetting" :sync="true" color="#C94412"
            @change="switchTaskView"></ToggleButton></span>
        <div v-if="isPublished != null && hasProjectWriteAccess != null" class="right">
          <dropdown-button >
            <template slot="menuTitle">{{ $t('common-manage') }} <span class="!text-sm"
                v-show="tableOptions.activeRowId.length">({{ tableOptions.activeRowId.length }})</span></template>
            <template slot="dropdownButtons">

              <!-- Upload media to project -->
              <a v-if="project.hasProjectWriteAccess" @click="openBatchUpload()"><span
                  class="btn btn-success popover-menu-item" v-close-popover>{{ $t('taskTable-aruUpload') }}</span></a>

              <!-- Download locations -->
              <a download class="group-top btn btn-success popover-menu-item" :class="{ 'downloading': downloading }"
                @click="downloadLocationCSV"> <span style="font-size: 20px;"></span><span class="spin-icon"></span>
                {{ $t('locationTable-downloadCSV') }}</a>

              <!-- Upload Locations -->
              <span v-if="!canUpload" v-tooltip="{ content: tooltips.disabledUpload, show: canUpload, classes: 'front' }">
                <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover
                  class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span
                    style="font-size: 20px;"></span> {{ $t('locationTable-uploadCSV') }}</button>
              </span>
              <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span
                  style="font-size: 20px;"></span> {{ $t('locationTable-uploadCSV') }}</button>



              <!-- Download tasks -->
              <a download class="group-top btn btn-success popover-menu-item" :class="{ 'downloading': downloadingTasks }"
                @click="downloadTasks">
                <span style="font-size: 20px;"></span><span class="spin-icon"></span><template
                  v-if="tableLoadedOnce && $refs.table.data.length === 0">{{ $t('taskTable-downloadTaskTemplate')
                  }}</template><template v-else>{{ $t('taskTable-downloadTasks') }}</template>
              </a>

              <!-- Upload Tasks -->
              <span v-if="!canUpload" v-tooltip="{ content: tooltips.disabledUpload, show: canUpload, classes: 'front' }">
                <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover
                  class="btn btn-success popover-menu-item" @click="uploadTasksARU"> <span style="font-size: 20px;"></span>
                  {{ $t('common-uploadItem', { item: $tc('common-task', 2) }) }}</button>
              </span>
              <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadTasksARU"> <span
                  style="font-size: 20px;"></span> {{ $t('common-uploadItem', { item: $tc('common-task', 2) }) }}</button>

              <!-- Download Tags -->
              <a download class="group-top btn btn-success popover-menu-item" :class="{ 'downloading': downloadingTags }"
                @click="downloadTags">
                <span style="font-size: 20px;"></span><span class="spin-icon"></span><template
                  v-if="tableLoadedOnce && $refs.table.data.length === 0">{{ $t('taskTable-downloadTagTemplate')
                  }}</template><template v-else>{{ $t('taskTable-downloadTags') }}</template>
              </a>

              <!-- Upload Tags -->
              <span v-if="!canUpload" v-tooltip="{ content: tooltips.disabledUpload, show: canUpload, classes: 'front' }">
                <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover
                  class="btn btn-success popover-menu-item" @click="uploadTagsARU"> <span style="font-size: 20px;"></span>
                  {{ $t('common-uploadItem', { item: $tc('common-tag', 2) }) }}</button>
              </span>
              <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadTagsARU"> <span
                  style="font-size: 20px;"></span> {{ $t('common-uploadItem', { item: $tc('common-tag', 2) }) }}</button>

              <!-- Download codes -->
              <a download class="group-top btn btn-success popover-menu-item" :class="{ 'downloading': downloadingCodes }"
                @click="downloadCodes"> <span style="font-size: 20px;"></span><span class="spin-icon"></span>
                {{ $t('common-downloadCodes') }}</a>

              <a v-show="tableOptions.activeRowId.length > 0" @click="showDeleteTaskForm(activeRows)"><span
                  class="group-top btn btn-success popover-menu-item" v-close-popover>
                  {{ $t('Delete Tasks') }}
                </span></a>

            </template>
          </dropdown-button>
        </div>
      </div>
 
    </div>
  <div class="row col s12 error no-bottom-margin" :class="{ 'error-hide': !message }" v-if="message != null">{{ message
    }}
    </div>
    <div class="relative">
      <div class="absolute top-0 left-0 right-0 bottom-0 z-20" v-if="preventClicking"></div>
      <v-server-table ref="table" :url="apiUrls.list" :columns="columns" :options="tableOptions" v-if="optionsLoaded"
        @loaded="tableLoaded()" class="table w-full vue-bordered taskTable" @limit="resetCheckBoxes"
        @pagination="resetCheckBoxes"><template slot="h__check">
          <span @click="onCheckAllClick">
            <i class="fa fa-check text-2xl cursor-pointer"
              v-if="tableOptions.activeRowId.length == tableOptions.perPage"></i>
            <i class="fa-regular fa-square-full text-xl cursor-pointer"
              v-if="tableOptions.activeRowId.length < tableOptions.perPage"></i>
          </span>
        </template>
        <template slot="check" slot-scope="props">
          <label>
            <input type="checkbox" :data-id="props.row.id" @click="onCheckClick(props.row)" />
            <span></span>
          </label>
        </template>
        <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->

        <template slot="h__tagCount">
          {{ $t('taskTable-ARUheadings.tagCount') }}
          <span v-tooltip="$t('taskTable-ARUheadings-tooltips.tagCount')"> <i
              class="fas fa-info-circle text-yellow-400"></i></span>
        </template>
        <template slot="locationName" slot-scope="props">
          <a :href="recordingRowUrl(props.row.id)" class="whitespace-nowrap">
            <span class="indicator" :class="getClass(props.row.statusId)"></span>{{ props.row.locationName }}</a>
        </template>
        <template slot="recordingDateStr" slot-scope="props">
          <div class="whitespace-nowrap">
            {{ props.row.recordingDateStr }}
          </div>
        </template>
        <template slot="method" slot-scope="props">
          <div class="whitespace-nowrap">
            {{ props.row.method }}
          </div>
        </template>
        <template slot="statusId" slot-scope="props">
          {{ getStatus(props.row.statusId) }}
        </template>
        <!-- <template slot="transcriberUserId" slot-scope="props">{{props.row.transcriberName}}</template> -->
        <template slot="transcriberUserId" slot-scope="props">
          <template v-if="isManager && editUserId == props.row.id && !isPublished">
            <div class="flex justify-center">
              <div class="flex-initial flex-grow">
                <select style="height: 2em" v-model="props.row.transcriberUserId" @focus="clearMessage"
                  @change="updateUser(props.row, $event)">
                  <option value="-1"> -- {{ $t('taskTable-notAssigned') }} -- </option>
                  <option v-for="(user, index) in userList" v-bind:key="'user' + index" :value="user.id"> {{ user.text }}
                  </option>
                </select>
              </div>
              <div class="flex-1 flex justify-center items-center link" @click="editUserId = -1">
                <span class="ion-checkmark p-2  text-burntOrange" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="whitespace-nowrap	">
              {{ props.row.transcriberName }} <span v-if="isManager && editUserId !== props.row.id && !isPublished"
                @click="editUserId = props.row.id" class="ion-edit cursor-pointer text-burntOrange"></span>
            </div>
          </template>
        </template>
        <div class="row no-bottom-margin taskTable" slot="child_row" slot-scope="props" v-if="toLoadSubRow">
          <div class="row s12">
            <div class="col center s4">{{ $t('taskTable-individualCount') }}</div>
            <div class="col center s4">{{ $t('taskTable-tagCount') }}</div>
            <div class="col center s4">{{ $tc('common-tag', 2) }}</div>
          </div>
          <div class="subtable">
            <template v-if="props.row.subDetails != null && props.row.subDetails.length > 0">
              <div class="row s12 brown-color">
                <div class="col center s4">{{ props.row.subDetails[0].individualCount }} </div>
                <div class="col center s4">{{ props.row.subDetails[0].tagCount }} </div>
                <div class="col center s4">{{ props.row.subDetails[0].uniqueTagTypes }} </div>
              </div>
            </template>
          </div>
          <div v-if="!props.row.childRowLoading" class="row col s12 no-bottom-margin">
            <div class="btns-wrapper right">
              <button class="btn" :class="{ 'btn-border': !isManager }" :disabled="!isManager"
                @click="showDeleteTaskForm([props.row])"> <span style="font-size: 20px;"></span>{{
                  $t('taskTable-deleteTask') }}</button>
            </div>
          </div>
        </div>
      </v-server-table>
    </div>

    <modal name="delete_task" :scrollable="true" :adaptive="true" transition="nice-modal-fade" classes="modal-form scroll"
      draggable=".drag-handle" :delay="100" width="75%" height="420" :minWidth="400" :minHeight="540"
      :clickToClose="false">
      <task-delete-form v-if="showDeleteForm && taskRows.length > 0" v-bind:task-rows="taskRows" v-bind:task-ids="taskIds"
        v-bind:headings="tableOptions.headings" v-bind:active-sensor="'ARU'" />
    </modal>
    <modal name="project-upload-form" :scrollable="true" :adaptive="true" transition="nice-modal-fade"
      classes="modal-form scroll" :delay="100" width="90%" :height="620" :minWidth="400" :minHeight="540"
      :clickToClose="false">
      <upload-csv-form v-bind:project-name='projectName' v-bind:projectId='projectId'
        v-bind:uploading-tags='uploadingTags' v-bind:upload-url="uploadUrl" sensor="ARU" />
    </modal>
    <modal name="location-upload-form" :scrollable="true" :adaptive="true" transition="nice-modal-fade"
      classes="modal-form scroll" draggable=".drag-handle" :delay="100" width="90%" :height="620" :minWidth="400"
      :minHeight="540" :clickToClose="false">
      <upload-location-csv v-if="project.organizationId && options" v-bind:organization-id="project.organizationId"
        v-bind:organization-name="project.fullNm" v-bind:visibility-options="options.visibility"
        v-bind:project-id="projectId" v-bind:project="project" v-bind:type="'project-location'" />
    </modal>
    <v-dialog />
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
// TODO: create mixin for aru and camera task table

import { API_URL, ARU_RECORDING_URL } from '@/lib/common';
import { createLink } from '@/lib/language.js';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import { ToggleButton } from 'vue-js-toggle-button';
import TaskDeleteForm from './TaskDeleteForm';
import TaskTableMixin from './TaskTableMixin';
import VueTableMixin from '@/components/common/VueTableMixin';
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';
import TaskTableUploadCSVForm from './TaskTableUploadCSVForm.vue';
import UploadCSVForm from '@/components/common/UploadCSVForm';
import { eventBus } from '@/lib/eventbus';

export default {
  name: 'TaskTable',
  components: { 'upload-location-csv': UploadCSVForm, 'multiselect': Multiselect, 'ToggleButton': ToggleButton, 'task-delete-form': TaskDeleteForm, 'dropdown-button': DropdownButtonWithOptionsVue, 'upload-csv-form': TaskTableUploadCSVForm },
  mixins: [TaskTableMixin, VueTableMixin],
  props: ['isManager', 'isUserProjectMember', 'isPublished', 'hasProjectWriteAccess', 'projectName', 'sensorId', 'project', 'taskOptions'],
  methods: {
    getClass(statusId) {
      let className = '';
      let status = '';
      switch (statusId) {
        case 1:
          className = 'ion-ios-pricetag';
          break;
        case 3:
          className = 'ion-ios-pulse';
          break;
        case 4:
          className = 'ion-checkmark'; break;
        case 5:
          className = 'ion-ios-thunderstorm';
          break;
        case 7:
          className = 'ion-bug';
          break;
      }

      return { [className]: true, [status.replace(/\s/g, '-')]: true };
    },
    /* call get pud detail, and change user object, and send to update */
    updateUser(row, $event) {
      const transcriberUserId = row.transcriberUserId;
      const newUsername = $event.target.selectedOptions[0].text; // find names for display updated result
      const id = row.id;
      this.message = '';
      /* ---------------- step 1, get user project deployment details --------- */
      /* -------------step 2, update user id ----------- */
      this.$http.post(this.apiUrls.updateUser, { id, transcriberUserId }).then((response) => {
        if (response.body.hasOwnProperty('error')) {
          this.message = this.$tc('common-error', 1) + ':' + response.body.error;
        } else {
          if ($event.target.selectedOptions[0].value === '-1') {
            this.message = this.$t('taskTable-unassigned', { task: row.locationName });
          } else {
            this.message = this.$t('taskTable-assigned', { task: row.locationName, name: newUsername });
          }
          row.transcriberName = newUsername;
        }
      }, () => {
        // error callback
        this.message = this.$t('taskTable-messages.updateUserError');
      });
    },
    uploadTagsARU() {
      // need to specify which url since aru uploads both tasks and tags seperately
      this.uploadUrl = this.apiUrls.uploadTagsCSVUrl;
      this.uploadTagsCSV()
    },
    uploadTasksARU() {
      this.uploadUrl = this.apiUrls.uploadTasksUrl;
      this.uploadTasks()
    },
    getFileName(label = 'location') {
      let type = this.$tc(`common-${label}`, 2);
      let now = new Date();
      let filename = this.projectName + '_' + type + '_' + now.getFullYear();
      if ((now.getMonth() + 1) < 10) {
        filename += '0' + (now.getMonth() + 1) + now.getDate();
      } else {
        filename += (now.getMonth() + 1) + now.getDate();
      }
      return filename;
    },
    loadOrganization() {
      this.$http.get(this.listOrgUrl).then(
        function (response) {
          if (response.data && !response.data.hasOwnProperty('error')) {
            this.organizationList = response.data;
            this.organizationCount = response.data.length;
            if (!this.editOrganziationId) {
              this.organizationObj = this.organizationList[0]; // when no id specified, default select first one
            } else {
              this.organizationObj = this.organizationList.find(x => x.id === this.project.organizationId);
            }
            if (!this.organizationObj) {
              this.error = this.$t('orgAdmin-permissionError');
            }
          }
        });
    },
    getMethod(name) {
      console.log(name, this.filterOptions.method);
      let method = this.filterOptions.method.find(method => method.id === name);
      return method && method.type;
    },
    getStatus(id) {
      return this.filterOptions.taskStatus && this.filterOptions.taskStatus.find(status => status.id === id).type;
    }
  },
  created() {
    // this.columns = this.isManager ? this.initColumns : this.initUserColumns;
    // console.log('this.filterOptionst', this.filterOptions)
    this.$set(this.tableOptions.listColumns, 'methodId', (this.filterOptions.method || []).map(x => { return { id: x.id, text: x.type }; }));
    this.$set(this.tableOptions.listColumns, 'transcriberUserId', [{ id: -1, text: this.$t('Not Assigned') }].concat((this.userList || []).map(x => ({ id: x.id, text: x.text }))));
    this.$set(this.tableOptions.listColumns, 'statusId', (this.filterOptions.taskStatus || []).map((x, index) => { return { id: x.id, text: x.type }; }));
    this.optionsLoaded = true;
    this.loadOrganization();


  },
  watch: {
    userList(newVal, oldVal) {
      this.$set(this.tableOptions.listColumns, 'transcriberUserId', (newVal || []).map(x => ({ id: x.id, text: x.text })));
    }
  },

  data() {
    return {
      organizationObj: null,
      listOrgUrl: API_URL + 'get-all-readable-organizations',
      organizationList: null,
      options: null,

      apiUrls: {
        list: API_URL + 'get-all-tasks?projectId=' + this.projectId,
        updateUser: API_URL + 'update-task-transcriber-only',
        idUrl: createLink(ARU_RECORDING_URL),
        assignUser: API_URL + 'randomly-assign-users-not-assigned-aru-tasks',
        taskDetailsUrl: API_URL + 'get-aru-task-details?taskId=',
        uploadTagsCSVUrl: API_URL + 'upload-recording-tags-csv-for-sync?projectId=',
        downloadTagsCSVUrl: API_URL + 'download-tags-by-project-id?projectId=',
        downloadCodesURL: API_URL + `download-recording-tag-codes?projectId=${this.projectId}`,
        downloadTasksUrl: API_URL + 'download-tasks-by-project-id?projectId=',
        uploadTasksUrl: API_URL + 'upload-recording-tasks-csv-for-sync?projectId='
      },
      uploadUrl: null,
      columns: ['check', 'locationName', 'recordingDateStr', 'method', 'statusId', 'recordingSampleFreq', 'tagCount', 'transcriberUserId'],
      tableOptions: {
        rowClassCallback(row) {
          let rowId = parseInt(row.id);
          return (this.activeRowId, this.activeRowId.indexOf(rowId) >= 0 ? 'activeTableRow' : '');
        },
        statusRow: 'statusId',
        showOwnOnly: false,
        filterByColumn: true,
        filterable: ['locationName', 'recordingDateStr', 'method', 'statusId', 'recordingSampleFreq', 'tagCount', 'transcriberUserId'],
        sortable: ['locationName', 'recordingDateStr', 'method', 'statusId', 'recordingSampleFreq', 'tagCount', 'transcriberUserId'],
        serverMultiSorting: true,
        // cellClasses: {
        //   transcriberUserId: [
        //     {
        //       class:'text-ellipsis overflow-hidden',
        //       condition: row=>true
        //     }
        //   ]
        // },
        headings: {
          check: '',
          locationName: this.$tc('common-location', 1),
          recordingDateStr: this.$t('taskTable-ARUheadings.recordingDateStr'),
          method: this.$t('taskTable-ARUheadings.methodType'),
          statusId: this.$t('common-status'),
          recordingSampleFreq: this.$t('taskTable-ARUheadings.sampleFrequency'),
          tagCount: this.$t('taskTable-ARUheadings.tagCount'),
          transcriberUserId: this.$t('taskTable-ARUheadings.transcriberName')
        },
        columnsClasses: {
          locationName: 'site',
          recordingDateStr: 'time',
          method: 'method',
          statusId: 'status',
          tagCount: '',
          transcriberUserId: 'transcribers'
        },
        listColumns: {},
        customFilters: [{
          name: 'status',
          callback: function (row, query) {
            return query.code.includes(row.code);
          }
        }]
      }
    }
  }
}
</script>

<style>
@import './task-table.css';
</style>
