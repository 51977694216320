<!-- list all organization belong to you, and then select them -->
<template>
  <div class="vue">
    <!-- <common-check /> -->
    <div class="container error padded60" v-if="error != null">
      <span v-if="error.statusText != null">
        {{error.statusText}}
      </span>
      <span v-else>
        {{error}}
      </span>
    </div>
    <div v-if="error==null">
      <div class="container overflow boxed homeHead !mb-5" style="padding-top: 4%; padding-bottom: 4%;">
          <div class="headImg image-container" id="org-page">
              <span class="photo-credit white small">Brandon Smith</span>
          </div>

            <h2 class="grey-font"><span style="color:#FFFFF"> My Organizations </span>    </h2>
      </div>
      
 
      <div class="container overflow tabbed">
        <org-table v-bind:edit-organziation-id = "editOrganziationId" />
      </div>
    </div>
    <v-dialog/>
  </div>
</template>

<script>
import { API_URL, attachAuthorizaitonHeader, getParameterByName } from '@/lib/common';
import OrganizationTable from './OrganizationTable';
import StatsBox from '@/components/common/StatsBox';
import { eventBus } from '@/lib/eventbus';

export default {
  name: 'organization-list-page',
  props: {
    isAdmin: {
      default: null,
      type: Boolean
    }
  },
  components: { 'org-table': OrganizationTable, 'stats': StatsBox },
  data () {
    return {
      error: null,
      statUrl: API_URL + 'get-all-organization-stats',
      hideStats: true
    }
  },
  created () {
    //attachAuthorizaitonHeader();
    this.editOrganziationId = parseInt(getParameterByName('id'));

    const self = this;
    eventBus.$on('show-stats', function () {
      self.hideStats = false;
    });
  },
  methods: {
  }
}
</script>
<style scoped>
.statWrap {
  display: flex!important;
}
.userPanel .userWelcome :deep( .statWrap .stat) {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: unset!important;
}

.hide{
  display: none;
}

</style>
