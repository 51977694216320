<!-- list all organization belong to you, and then select them -->
<template>
  <div class="vue org-admin">
    <!-- <common-check /> -->
    <div class="container error padded60" v-if="error != null">
      <span v-if="error.statusText != null">
        {{ error.statusText }}
      </span>
      <span v-else>
        {{ error }}
      </span>
    </div>
    <div class="row" v-if="error == null">
      <div class="mainPanel col l12">
        <div class="container large userPanel">
          <div class="row">
          
            <div class="col s12 m12">
              <p><a :href="breadcrumbHomeLink()">{{ $t('common-home') }}</a> / <a
                  :href="organziationListPageUrl">{{ $t('org-myOrganizations') }}</a> /</p>
              <div class="col s12 m6 l7">
                <a class="link" v-if="showOrganizationEditButton" @click="updateOrganization()">
                  <h4 style="display:inline;">{{ selectedOrganization && selectedOrganization.name }}</h4>
                  <span class="link ion-edit edit-project-icon"></span>
                </a>
                <h4 v-else>{{ selectedOrganization && selectedOrganization.name }}</h4>
              </div>
              <div class="col s12 m6 l5 pull-right">
                <!-- <button v-if="organizationCount > 1" @click="showOrganizationDropdown = !showOrganizationDropdown" class="btn btn-success link"><span class="ion-eject edit-project-icon"/>Switch</button> -->
                <multiselect :selectAllLabel="$t('common-selectAll')"
                  v-if="organizationCount > 1 && organizationList != null" v-model="selectedOrganization"
                  :close-on-select="true" :allowEmpty="false" :placeholder="$t('orgAdmin-typeToSearchOrganizations')"
                  label="name" track-by="id" :options="organizationList" :multiple="false" @select="switchOrganization">
                  <template slot="singleLabel">
                    {{ $t('org-switchOrganization') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="container overflow tabbed">
          <ul class="tabs">
            <template v-cloak v-for="(tab, index) in tabs">
              <li v-if="checkTabExists(tab)" @click="switchTab(tab)" :key="'tab' + index" class="tab"
                :class="{ [tab.class]: true }">
                <a class="hyperlink" v-bind:class="{ active: (selectedTab == tab.id), [tab.id]: true }">
                  {{ tab && tab.id && $tc(`common-${tab.id}`, 2) }} <span class="info-icon ion-information-circled"
                    style="font-size:12px;"
                    v-tooltip.left-start="{ content: $tc(`orgAdmin-tooltips.${tab.id}`), hideOnTargetClick: true, popperOptions: { flip: { enabled: false } } }" />
                </a>
              </li>
            </template>
          </ul>
          <equipment-grid v-if="selectedTab == 'equipment' && selectedOrganization"
            v-bind:organization-id="selectedOrganization.id" v-bind:organization-name="selectedOrganization.name"
            v-bind:is-editable="showOrganizationEditButton" />
          <location-table v-if="selectedTab == 'location' && selectedOrganization"
            v-bind:missing-count="missingCorrdinateCount" v-bind:organization-obj="selectedOrganization"
            v-bind:organization-id="selectedOrganization.id" v-bind:is-editable="showOrganizationEditButton" />
          <location-visit v-if="selectedTab == 'visit' && selectedOrganization"
            v-bind:organization-id="selectedOrganization.id" v-bind:organization-name="selectedOrganization.name"
            v-bind:is-editable="showOrganizationEditButton" />
          <recording-table v-if="selectedTab == 'recording' && showRecordings && selectedOrganization"
            v-bind:organization-id="selectedOrganization.id" v-bind:organization-name="selectedOrganization.name"
            v-bind:is-editable="showOrganizationEditButton" v-bind:show-delete-media-button="showDeleteMediaButton" />
          <image-table v-if="selectedTab == 'cameraImageSet' && showImageDeployments && selectedOrganization"
            v-bind:organization-id="selectedOrganization.id" v-bind:is-editable="showOrganizationEditButton"
            v-bind:show-delete-media-button="showDeleteMediaButton" />
          <div v-if="selectedTab == 'map' && selectedOrganization" class="panel padded20 boxed">
            <location-map v-bind:category-id="selectedOrganization.id" />
          </div>
        </div>
      </div>

    </div>

    <organization-form ref="organizationForm" v-bind:organization-id="editOrganziationId"
      v-bind:is-editable="showOrganizationEditButton" v-bind:options="options"
      v-if="showForm && showOrganizationEditButton && options != null" v-bind:is-global-admin="isGlobalAdmin" />
    <v-dialog />
  </div>

</template>

<script>
// import Datepicker from 'vue2-datepicker';
import { eventBus } from '@/lib/eventbus';
import { API_URL, ORGANIZATION_LIST_URL, getParameterByName, isLocal } from '@/lib/common';
import { createLink } from '@/lib/language';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import EquipmentGrid from './equipment/EquipmentGrid';
import OrganizationForm from './OrganizationForm';
import LocationTable from './LocationTable';
import StatsBox from '@/components/common/StatsBox';
import RecordingTable from './OrganizationRecordingTable';
import CameraImageTable from './CameraImageTable';
import ClusterPointMap from '../mapGL/ClusterPointMap';
import LocationVisitTableVue from './location-visit/LocationVisitSummaryTable';
import CommonPageMixin from '@/components/common/CommonPageMixin';
// import 'vue2-datepicker/index.css';

export default {
  name: 'organization-admin-page',
  props: {
    isAdmin: {
      default: null,
      type: Boolean
    }
  },
  mixins: [CommonPageMixin],
  components: {
    //  'date-picker': Datepicker,
    'stats': StatsBox,
    'multiselect': Multiselect,
    'equipment-grid': EquipmentGrid,
    'organization-form': OrganizationForm,
    'location-table': LocationTable,
    'recording-table': RecordingTable,
    'image-table': CameraImageTable,
    'location-map': ClusterPointMap,
    'location-visit': LocationVisitTableVue
  },
  data() {
    return {
      organziationListPageUrl: createLink(ORGANIZATION_LIST_URL),
      listOrgUrl: API_URL + 'get-all-readable-organizations',
      statUrl: null,
      selectedOrganization: null,
      editOrganziationId: null,
      isGlobalAdmin: false,
      organizationList: null,
      organizationCount: 0,
      selectedTab: 'location',
      options: null,
      error: null,
      showImageDeployments: false,
      showRecordings: true,
      showOrganizationEditButton: false,
      showDeleteMediaButton: false,
      missingCorrdinateCount: null,
      showForm: false,
      tabs: [
        {
          id: 'location',
          label: this.$tc('common-location', 2),
          //        class: 'pink',
          tooltip: this.$t('orgAdmin-tooltips.location')
        },
        {
          id: 'visit',
          label: this.$tc('common-visit', 2),
          //      class: 'blue',
          tooltip: this.$t('orgAdmin-tooltips.visit')
        },
        {
          id: 'equipment',
          label: this.$t('common-equipment'),
          //      class: 'yellow',
          tooltip: this.$t('orgAdmin-tooltips.equipment')
        },
        {
          id: 'recording',
          label: this.$tc('common-recording', 2),
          exists: 'showRecordings',
          class: 'ARU',
          tooltip: this.$t('orgAdmin-tooltips.recording')
        },
        {
          id: 'cameraImageSet',
          label: this.$tc('common-cameraImageSet', 2),
          exists: 'showImageDeployments',
          class: 'CAM',
          tooltip: this.$t('orgAdmin-tooltips.cameraImageSet')
        },
        {
          id: 'map',
          label: this.$t('common-map'),
          //    class: 'green',
          tooltip: this.$t('orgAdmin-tooltips.map')
        }
      ],
      showOrganizationDropdown: false
    }
  },
  created() {
    const self = this;
    if (getParameterByName('id') != null) {
      this.editOrganziationId = parseInt(getParameterByName('id'));
    } else {
      this.editOrganziationId = null;
    }
    // get options
    this.$http.get(API_URL + 'organization-details-static-options').then(
      function (response) {
        this.options = response.data;
      });
    this.loadOrganization();
    // get global admin options from sensor option is sensorAdmin, both ARU and camera isSensorAdmin are the same
    if (this.isAdmin === null) {
      this.$http.get(API_URL + 'sensor-options').then(
        (response) => {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          }
          this.sensors = response.data;
          if (this.sensors != null) {
            this.sensors.forEach(x => { this.isGlobalAdmin = this.isGlobalAdmin || x.isSensorAdmin; });
          }
        }, (error) => {
          // error callback
          this.error = this.$tc('common-error', 1) + ': ' + (error.data ? error.data.error : '') + ' ' + error.status;
        }
      );
    } else {
      this.isGlobalAdmin = this.isAdmin;
    }
    eventBus.$on('reload-table', function () {
      self.loadOrganization();
    });
    eventBus.$on('close-organization-form', function () {
      self.showForm = false;
    });
    eventBus.$on('Missing %s Location Coords', function (count) {
      self.missingCorrdinateCount = count;
    });

   

  },
  watch: {
    selectedOrganization(newVal, oldVal) {

      if (newVal !== oldVal) {
        // window.history.pushState(null, this.selectedOrganization.name, '?id=' + newVal.id);
        this.statUrl = API_URL + 'get-organization-stats?organizationId=' + newVal.id;
        // console.log(window.location.href, this.selectedOrganization.id);
        this.showOrganizationEditButton = false;
        this.editOrganziationId = newVal.id;
        this.checkOrganizationSensors();
      }
    }
  },
  methods: {
    checkTabExists(tab) {
      if (tab.exists) {
        if (!this[tab.exists] && this.selectedTab === tab.id) { // when hide, but shows
          this.selectedTab = this.tabs[0].id;
        }
        return this[tab.exists];
      }
      return true;
    },
    switchTab(tab) {
      this.selectedTab = tab.id;
    },
    switchOrganization() { // hide drop down menu, and let watch handle the changes.
      this.showOrganizationDropdown = false;
    },
    loadOrganization() {
      this.$http.get(this.listOrgUrl).then(
        function (response) {
          if (response.data && !response.data.hasOwnProperty('error')) {
            this.organizationList = response.data;
            this.organizationCount = response.data.length;
            if (!this.editOrganziationId) {
              this.selectedOrganization = this.organizationList[0]; // when no id specified, default select first one
            } else {
              this.selectedOrganization = this.organizationList.find(x => x.id === this.editOrganziationId);
            }

            if (!this.selectedOrganization) {
              this.error = this.$t('orgAdmin-permissionError');
            }
          }
        });
    },
    checkOrganizationSensors() {
      this.$http.get(API_URL + 'organization-get-visible-tabs?organizationId=' + this.selectedOrganization.id).then(
        (response) => {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.showImageDeployments = response.data.showImageDeployments;
            this.showRecordings = response.data.showRecordings;
            this.showOrganizationEditButton = response.data.showOrganizationEditButton;
            this.showDeleteMediaButton = response.data.showDeleteMediaButton;
          }
        }, (error) => {
          // error callback
          this.error = this.$tc('common-error', 1) + ': ' + (error.data ? error.data.error : '') + ' ' + error.status;
        }
      );
    },
    updateOrganization() {
      this.editOrganziationId = this.selectedOrganization.id;
      this.showForm = true;
      const self = this;
      this.$nextTick(() => {
        self.$modal.show('organization');
      })
    }
  }
}
</script>
<style scoped>
.organization-select {
  position: absolute;
  padding: 30px 34px;
  background-color: #fff;
  border: #ccc 1px solid;
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 9;
}

.org-admin .tabs li.tab a {
  background: #d5d5d5;
}

.org-admin .tabs li.tab a.active {
  background: #ffffff;
}

@media only screen and (max-width: 1240px) {
  .tabs li.tab a {
    padding: 0 10px;
    font-size: 12px;
  }
}

/*        */
.statRow {
  margin: auto;
}

.statColumn {
  padding: 30px 20px 0 0 !important;
  /*  position: absolute;
    top: 0;
    left: 20px !important;*/
}

.statColumn .statRow .stat {
  height: unset;
  margin-bottom: 20px;
}

/*
.no-float{
  float: none !important;
}*/
</style>
<style scoped>
.tabs li.tab {
  -webkit-box-shadow: 1px 0px 5px rgba(19, 17, 17, 0.52);
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.56);
}
</style>
