<template>

    <div style=" min-height: 600px;">
      <div class="row" style="position: relative;">
        <div v-show="ui.loading" class="overaly" style="opacity: 0.6; position: absolute; width: 100%; height: 100%; z-index: 100; background: white;">
          <div style="text-align: center; margin-top:250px;">
            <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            <div> {{$t('common-loadingImages')}} </div>
          </div>
        </div>

        <template v-if="!ui.loading&& (!imageList || imageList.length === 0)" >
            <h5>{{$t('cameraTagging-noImagesFound')}}</h5>
        </template>
        <div class="col s12 panel" ref="scrollBounds" id='scrollBounds'  v-if="imageList && imageList.length> 0"> <!-- TP: removed class boxed. TODO: check effects. -->
          <div class="row" >
            <!-- -FORM PANEL- -->

              <div class="col s12 m2 custom" v-show="ui.infoPanelVisible" style="position: relative;padding: 20px;">
                <div id="infoPanel" style="width:100%;">
                  <div class="row numberRow" v-sticky="{zIndex: 9, stickyTop: 10, stickTopOnly: false, stickyTopElementId: 'topStickyBarContent', positionType: 'fixed', className: 'sticky-buttons', stickyContainerId: 'scrollBounds'}">
                    <button v-if="filterMode !== 'fov' && isTagger" class="btn fullWidth " @click="selectAll" :disabled="!ui.showSelectAll" :title="$t('cameraTagging-ctrlASelectAll')">{{$t('common-selectAll')}}</button>
                    <button v-if="filterMode !== 'fov' && isTagger && deploymentId" class="btn fullWidth " @click="selectAllUntagged" :disabled="untaggedImageCount === 0" :title="$t('cameraTagging-ctrlQSelectAllUntagged')">{{$t('cameraTagging-selectUntagged')}}</button>
                    <button v-if="filterMode !== 'fov' && isTagger" class="btn fullWidth" @click="unselectAll" :disabled="selectedImageCount === 0" :title="$t('cameraTagging-escToDeselectAll')">{{$t('cameraTagging-deselectAll')}}</button>
                    <!-- don't use any padding, margin inside sticky area, otherwise, the width won't be accurrate, and may not fully visible -->
                    <drag-select-container ref="numberSelectArea" class="row1 numberSelectArea" :class="{scroller: imageList.length > 50 }" v-bind:input-selection ="selectedImgId" :selectorClass="ui.numberPanelClass"
                    name="numberArea" :disable-drag-selection="filterMode === 'fov'">
                      <template slot-scope="{ selectedItems }">
                        <!-- Your items here -->
                          <div ref="numberPanel" v-for="(img, index) in imageList" :id="index" :key="img.task.id"
                            class="col numberCol"
                            :class="getClasses(img, selectedItems, true)"
                            :data-item="img.task.id"
                            style="cursor: pointer;">
                            <div class="number">
                              <div class="multipleSpecies" v-if="img.species && img.species.length > 1"></div>
                              <div class="multipleIndividual" v-if="img.hasMultipleIndividuals"></div>
                              <div class="outOfRange" v-if="img.task.fovId!==0"></div>
                              {{index +1}}</div>
                          </div>
                      </template>
                    </drag-select-container>
                  </div>
                </div>
              </div>
            <!-- -END FORM PANEL- -->

            <div class="col s12 m10 custom" :class="{fullWidth: ! ui.infoPanelVisible}" id="mainPanel">
              <div class="grid grid-cols-4 top-item-wrapper mb-3" >
                  
                  <div class="toggleWrap">
                    <div style="cursor: pointer;">
                      <span class="ion-eye-disabled" v-show="ui.infoPanelVisible" @click="ui.infoPanelVisible = ! ui.infoPanelVisible"> {{$t('cameraTagging-hideNumberPanel')}}</span>
                      <span class="ion-eye" v-show="! ui.infoPanelVisible" @click="ui.infoPanelVisible = ! ui.infoPanelVisible"> {{$t('cameraTagging-showNumberPanel')}}</span>
                      <div class="red-orange-colored" v-show="ui.showImageType === 'untagged'"> {{$t('cameraTagging-untaggedView')}}</div>
                      <div class="red-orange-colored" v-show="ui.showImageType === 'tagged'"> {{$t('cameraTagging-taggedView')}}</div>
                      <div class="red-orange-colored" v-show="ui.showImageType === 'partial'"> {{$t('cameraTagging-partialTaggedView')}}</div>
                    </div>
                  </div>

                  <div class="user-series-gap  " v-if="filterMode === 'series'">
                    {{$t('cameraTagging-seriesGap')}}
                    <select @change="applyUserSeriesGap()" class="series-gap-select" v-model="userSeriesGap">
                      <template v-for="option in userSeriesOptions">
                        <option :key="option.id" :value="option">{{option.text}}</option>
                      </template>
                    </select>
                  </div>
                  
                  <div class="toggleWrap items-center" v-if="filterMode !== 'series'">
                    <select ref="limit" class="record-per-page !flex-initial" v-model="imageQueryParam.limit" @change="changeNumberPerPage">
                      <option v-for="(count, index) in ImagePerPageOption" :key="'countPerPage' + index" :value="count">{{$t('common-perPage', {count: count})}}</option>
                    </select>
                    <span class="total-image !flex-initial mr-20" v-if="totalImageCount > 0"> [ {{$tc('common-image', totalImageCount, {num: totalImageCount})}}] </span>
                  </div>

                  <!-- MD CONFIDENCE SLIDER -->
                  <div class="">
                        <label class="whitespace-nowrap">{{$t('Bounding box display threashold')}}</label>
                        <div class="flex items-center">
                        <span>0</span>
                          <input class="flex-grow !m-0" type="range" min="0" max="1" step="0.01"  v-model="ui.filter.MDConfidence" />
                        <span>1</span>
                        </div>
                        <div class="w-full text-center -mt-[5px]">{{ui.filter.MDConfidence}}</div>
                  </div>

                  <div class="toggleWrap  right-align" :class="{'l6':filterMode === 'series', 'l4': filterMode !== 'series'}">
                    <!-- check this: https://materializecss.com/pagination.html#! -->
                    <pagination class="right" ref="paginationTop" v-if="totalPageNum >= 0 || pagination !== null" v-bind:active-page-number="imageQueryParam.page" v-bind:pagination="pagination" v-bind:total-page-num="totalPageNum"
                        v-bind:time-stamp="timeStamp" v-bind:status-field="paginationStatusField" v-bind:order-display="orderDisplay"
                        v-bind:is-series="filterMode === 'series'"
                        />
                  </div>

                  <div class="toggleWrap col-span-4 " v-if="filterMode === 'series'">
                      <span class="total-image warning" style="display:block;" v-if="imageList.length > 0"> {{$tc('cameraTagging-imagesInSeries', imageList.length, {num: imageList.length})}}</span>
                      <span class="total-image warning" v-if="totalImageCount > 0"> {{$tc('cameraTagging-numImagesTotal', totalImageCount, {num: totalImageCount})}}</span>
                      <span  class="total-image warning" v-if="userSeriesGap || seriesGap">
                        {{$t('cameraTagging-timeBetweenImages')}} &#x2264; <template v-if="appliedUserSeriesGap && appliedUserSeriesGap.value != null">{{appliedUserSeriesGap.value}}</template><template v-else>{{seriesGap}}</template> {{$t('commonUnits-secondsFull')}}</span>
                  </div>

              </div>

              <div class="row selectable" id="selectable-wrapper" v-show="imageList && imageList.length> 0">
               
                <drag-select-container ref="imageSelectArea" v-bind:input-selection ="selectedImgId" class="row less-margin"
                  :selectorClass="ui.imagePanelClass" excludeSelectorClass ="exclude" :disable-drag-selection="filterMode === 'fov'">
                  }}
                  <template slot-scope="{ selectedItems }">
                  <template v-for="(img, index) in imageList">
                   
                    <div ref="imagePanel" :key="'image' + img.task.id" class="col capture img-selectable" :id="index"
                      :data-item="img.task.id" :class="getClasses(img, selectedItems, true)">
                      <div :class="{'imgWrap relative': true, 'border border-black': typeof img.provided != 'undefined' }"  :data-item="img.task.id" :id="index">
                        <span v-if="img.megaDetector">
                          <template  v-for="(MDResult,index) in img.megaDetector">
                            <div v-if="( MDResult.confidence > ui.filter.MDConfidence)" :key="index" class="absolute border border-red-500" :style="{ left:`${MDResult.box1*100}%`, top: `${MDResult.box2*100}%`, width: `${MDResult.box3*100}%`, height: `${MDResult.box4*100}%`}">
                             
                            </div>
                          </template>
                        </span>
                        <img v-if="typeof img.provided == 'undefined'"  v-lazy="img.fileThumbURL" :id="'img' + img.task.id" @error="imgUrlAlt($event, img.fileURL)"/>
                        <img v-else src="../../assets/image-not-found.jpg" class="border border-black"  />

                        <div class="imgOverlay">
                          <div class="magnify" >
                            <span class="ion-android-search exclude" @click.stop.prevent="showLightbox(img.fileURL)"></span>
                            <span v-if="isTagger" :title="$t('cameraTagging-clickToggleNice')" :class="{'ion-ios-star-outline': !img.task.nice, 'ion-star': img.task.nice}" class="tag-isNice exclude" @click.stop.prevent="markNice(img)" />
                          </div>
                        </div>
                      </div>

                      <div class="vidData" style="position: relative;">

                        <div class="number">
                          <div class="multipleSpecies" v-if="img.species && img.species.length > 1"></div>
                          <div class="multipleIndividual" v-if="img.hasMultipleIndividuals"></div>
                          <div class="outOfRange" v-if="img.task.fovId!=0"></div>
                          {{index+1}}
                        </div>
                          <template v-if="img.speciesTags && img.speciesTags.length > 0">
                            <div v-for="(tag, index) in img.speciesTags" :key="'species' + tag.id" class="info exclude">
                              <div class="tag-details link exclude" :name="'species'+tag.id">
                                <span  class="link exclude tag-text break-words" @click.stop="editImageTag(img.task.id)">
                                  <span class="tagSpp">{{getTagSpp(tag)}}</span> {{getTagDisplay(tag)}}
                                  <span v-if="index === 0 && img.task.fovId!==0"> {{getFOVType(img.task.fovId)}} </span></span>
                                <div v-if="isTagger" class="delete">
                                  <span class="ion-trash-b exclude" @click.stop="confirmDeleteSpecies(tag.id)"></span>
                                </div>
                                <div class="moreInfo" v-if="index ==0">
                                  <span class="ion-ios-information exclude" @click="showImageInfoForm(img)"></span>
                                </div>
                              </div>
                            </div>
                            <!-- Location info, in spp verification only -->
                            <div v-if="speciesId !== null" class="location">{{getLocation(index)}}</div>
                          </template>
                          <template v-else>

                            <div class="info link exclude" >
                              <div v-if="isTagger" class="tag-details link exclude" @click="editImageTag(img.task.id)"><span>{{$t('cameraTagging-newTag')}}
                              <span v-if="img.task.fovid"> | {{staticOptions.fieldOfView.find(fov=>fov.id==img.task.fovid).type}} </span></span>
                              </div>
                              <div v-else class="tag-details">
                              </div>
                              <div class="moreInfo exclude">
                                <span class="ion-ios-information exclude" @click="showImageInfoForm(img)"></span>
                              </div>
                            </div>
                          </template>
                      </div>


                    </div>
                  </template>
                  </template>
                </drag-select-container>
                </div>

              <div class="" v-if="imageList && imageList.length> 0">
                <div class="toggleWrap col s12 m12 l6">
                  <div style="cursor: pointer;">
                    <span class="ion-eye-disabled" v-show="ui.infoPanelVisible" @click="ui.infoPanelVisible = ! ui.infoPanelVisible"> {{$t('cameraTagging-hideNumberPanel')}}</span>
                    <span class="ion-eye" v-show="! ui.infoPanelVisible" @click="ui.infoPanelVisible = ! ui.infoPanelVisible"> {{$t('cameraTagging-showNumberPanel')}}</span>
                  </div>
                </div>
                <div class="toggleWrap col s12 m12 l6 right-align">
                  <!-- check this: https://materializecss.com/pagination.html#! -->
                  <pagination class="right" ref="paginationBottom" v-if="totalPageNum >= 0 || pagination !== null"
                    v-bind:active-page-number="imageQueryParam.page"
                    v-bind:pagination="pagination"
                    v-bind:time-stamp="timeStamp"
                    v-bind:total-page-num="totalPageNum"
                    v-bind:status-field="paginationStatusField"
                    v-bind:order-display="orderDisplay"
                    v-bind:is-series="filterMode === 'series'"/>
                </div>
              </div>

              <div class="col s12 m12 right-align" v-if="speciesId !== null && pagination!== null && isTagger">
                  <div  class="col s12 m9 right-align" > {{messages}}</div>
                  <div  class="col s12 m3 right-align">
                    <button class="btn btnImportant" :disabled="ui.workingOnVerifySpp" @click="verifySpecies()" v-if="pagination[imageQueryParam.page-1][paginationStatusField] !== 'full'">
                      <span class="ion-checkmark"></span> {{$t('cameraTagging-verifySpecies', {name: speciesObj.commonName})}}
                    </button>
                    <div v-if="pagination[imageQueryParam.page-1][paginationStatusField] === 'full'">{{$t('cameraTagging-pageNumVerified', {num: imageQueryParam.page})}}</div>
                  </div>
              </div>
              <!-- previous
              FOV button <div class="col s12 m12 right-align" v-if="filterMode== 'fov' && isTagger">
                  <div  class="col s12 m9 right-align" > {{messages}}</div>
                  <div  class="col s12 m3 right-align">
                    <button class="btn btnImportant" @click="verifyFOV()" v-if="deploymentDetails.fovVerified !== true">
                      <span class="ion-checkmark"></span> Verify Field of View
                    </button>
                    <h6 v-if="deploymentDetails.fovVerified === true">FOV verified</h6>
                  </div>
              </div> -->

            </div>
            <!-- -END Image PANEL- -->

          </div>
        </div>

      </div>
      <!-- popup and modal boxes -->
      <lightbox v-if="imageList" 
      id="mylightbox" 
      ref="lightbox"
      v-bind:images="lightboxImages"
      v-bind:mega-detector-categories="megaDetectorCategories"
      :timeoutDuration="10000" 
      v-bind:can-download="isTagger"
      :project="project">
      </lightbox>

      <tag-form v-if="currentImgId !=null && staticOptions && staticOptions.age && staticOptions.age.length > 0"
          v-bind:id = "currentImgId"
          v-bind:form-name = "ui.tagFormName"
          v-bind:options = "staticOptions"
          v-bind:for-untagged = "forUntagged"
          v-bind:is-editable = "isTagger" 
          v-bind:project="project"
          :image-list="imageList"
          :mega-detector-categories="megaDetectorCategories"
          />
      <img-info v-bind:img="currentExifImgObj" v-bind:form-name="ui.exifFormName" />

    </div>
</template>

<script>
/* this component show is the camera tagging page.
*********************************** NOTE: images will not show up in localhost because of the proxy. ********************************************************
We did this so the image urls could be served from the same origin and thus we could rename image downloads appropriately
 */
// import Autocomplete from 'vuejs-auto-complete';
import {API_URL, confirmDialog, alertDialog, copyObject, createLightboxImageObject} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import TaggingGalleryPagination from '@/components/camera/TaggingGalleryPagination';
import TaggingForm from '@/components/camera/TaggingForm';
import TaggingImageInfo from '@/components/camera/TaggingImageInfo';
import DragSelect from '@/components/utils/DragSelect.vue';
import Lightbox from '@/components/utils/lightbox.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
export default {
  name: 'tagging-main-page',
  components: {
    'drag-select-container': DragSelect,
    'lightbox': Lightbox,
    'tag-form': TaggingForm,
    'pagination': TaggingGalleryPagination,
    'img-info': TaggingImageInfo,
    'pulse-loader': PulseLoader
  },
  props: ['deploymentId', 'staticOptions', 'deploymentOptions', 'speciesObj', 'projectId', 'filterMode', 'project', 'isTagger', 'seriesGap','megaDetectorCategories'],
  computed: {
   
  },
  methods: {
    getFOVType(id) {
      if (typeof id == 'undefined')
        return this.$t('Unknown');
      if (id == 0)
        return '';

      return (this.staticOptions.fieldOfView.find(fov=>fov.id==id)||{type:this.$t('Unknown')}).type;
    },
    /* when image load error use place holder image */
    imgUrlAlt (event, url) {
      const placeHolderImageUrl = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      if (placeHolderImageUrl !== event.target.src) {
        event.target.src = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      }
    },
    markNice (img) {
      const params = {cloneToIds: [img.task.id], task: {id: img.task.id, nice: !img.task.nice}, updateNice: true }; // toggle isNice
      const batchUpdateTaskAPI = API_URL + 'update-camera-tasks'; // ? taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'
      this.$http.put(batchUpdateTaskAPI, params).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('cameraTagging-failedNice') + ' ' + response.data.data);
        } else {
          img.task.nice = !img.task.nice;
        }
      }, (err) => {
        this.error.push(this.$t('cameraTagging-failedNice') + ' ' + err.data);
      });
    },
    /* drag selected item style, number panel and image panel classes.
    can control how many images per row */
    getClasses (image, selectedItems) {
      const self = this;
      const imgId = image.task.id;
      const isActive = !!(selectedItems.find((selectedItem) => {
        return parseInt(selectedItem.dataset.item, 10) === imgId
      }));

      let tagClass = {
        selected: isActive,
        active: isActive,
        's6 xl6': this.imageQueryParam.limit === this.ImagePerPageOption[0],
        's4 xl4': this.imageQueryParam.limit === this.ImagePerPageOption[1] || this.imageQueryParam.limit === this.ImagePerPageOption[2],
        's3 xl3': this.imageQueryParam.limit === this.ImagePerPageOption[3] || this.imageQueryParam.limit === this.ImagePerPageOption[4],
        's3 xl3 e': this.imageQueryParam.limit > this.ImagePerPageOption[4]
      };
      if (image.speciesTags && image.speciesTags.find(spp => { return spp.needsReview === true })) {
        tagClass['need-review'] = true;
      }
     
      if (!this.speciesId) { // if it is for tagging, not species verification
        if (image.speciesTags && image.speciesTags.length > 0) {
          // loop through each spp tag row, and check if species name, sex, and age exist, if any is missing make it as untagged.
          if (image.speciesTags.find(tag=> !tag.sexId || !tag.ageId || !tag.species)) {
            tagClass.partial = true;
            // tagClass.tagged = false;
            tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'partial';
          } else {
            tagClass.tagged = true;
            tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'tagged';
          }
        } else {
           
          tagClass.untagged = true;
          tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'untagged';
        }
      } else { // species verificaiton view
        if (image.speciesTags && image.speciesTags.find(spp => { return spp.speciesId == self.speciesObj.id && spp.verified === true })) {
          tagClass.tagged = true;
          tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'tagged';
        } else {
          tagClass.untagged = true;
          tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'untagged';
        }
      }
      return tagClass;
    },
    getTagSpp(tag) {
      let species = (this.staticOptions?
        this.staticOptions.allSpecies.find(
          (v)=>v.id === tag.speciesId
        ) : 
        this.staticOptions.species.concat(this.staticOptions.none).concat(this.staticOptions.bird).concat(this.staticOptions.domestic).find(v=>v.id===tag.speciesId));
      return (species||{commonName: "NONE"}).commonName
    },

    formatTooltip(speciesRow) {
      let labels = this.getTagDisplay(speciesRow,true);
      let labelMap = [ this.$t('Sex'), this.$t('Age'), this.$t('Coat Colour'),this.$t('Coat Attributes'),this.$t('Behaviours'),this.$t('Conditions'), this.$t('Direction'), this.$t('Has Collar'), this.$t('Has Tag'), this.$t('Interacting with human feature'), this.$t('Tines'), this.$t('Count')];
      
      let str = '';

      labels.map((l,i)=>{
        l = `${l}`;

        if (l&&l.length>0) { //tis a string
          switch(i){
            case 7: case 8: case 9: //collar, tag or interacting with human feature.
              str += `${labelMap[i]} <br />`;
              break;
            case 6: //direction
              str += `Direction Facing: ${l} ${this.$t('-o- Clock')} <br />`;
              break;
            case 10: //tines description
              let tines = [];
              speciesRow.tines.map(tine=> {
                tines.push(`${this.formatTineLabel(tine)}`);
              });
              str += `Tines: ${tines.join('+')} <br />`;
              break;
            default: 
             str+=`${labelMap[i]}: ${l} <br />`;
          }
         
        }
      });

      return str;

    },
    formatTineLabel(tine) {
      let tineCountAttribute = '';
      let tineProperty = '';
      
        switch(tine.tinePropertyId) {
          case 1: case 4: tineProperty = tine.tineProperty.type; break;
          case 2: tineProperty = this.$t('Left'); break;
          case 3: tineProperty = this.$t('Right'); break;

        } 

        switch(tine.countAttributeId) {
          case 1: tineCountAttribute = '='; break;
          case 2: tineCountAttribute = '>='; break;
          case 3: tineCountAttribute = '~='; break;
          case 4: tineCountAttribute = '?='; break;
        }

      let count = tine.tineCount?tine.tineCount:this.$t('Unknown');
      return `${tineProperty} ${tineCountAttribute} ${count}`;
    },

    /* used for tag display under each image */
    getTagDisplay (speciesRow, rtnArray = false) {
      const sexValue = this.staticOptions.sex.find(item => { return item.id === parseInt(speciesRow.sexId) });
      const ageValue = this.staticOptions.age.find(item => { return item.id === parseInt(speciesRow.ageId) });

      // sexTypeID = 4 VNA ageValueID = 0 VNA
      let labels = [];
      labels.push((sexValue ? (sexValue.id === 4 ? '' : sexValue.type) : ''));
      labels.push((ageValue ? (ageValue.id === 0 ? '' : ageValue.type) : ''));
      labels.push(speciesRow.coatColors.map(o=>this.staticOptions.coatColor.find(coatColor=>coatColor.id==o.coatColorId).type).join('+'));
      labels.push(speciesRow.coatAttributes.map(o=>this.staticOptions.coatAttribute.find(coatAttribute=>coatAttribute.id==o.coatAttributeId).type).join('+'));
      labels.push(speciesRow.behaviours.map(o=>this.staticOptions.speciesBehaviour.find(sb=>sb.id==o.behaviourId).type).join('+'));
      labels.push(speciesRow.healthConditions.map(o=>this.staticOptions.health.find(h=>h.id==o.healthId).type).join('+'));

      labels.push(speciesRow.direction);
      labels.push(speciesRow.hasCollar?this.$t("Collar"):"");
      labels.push(speciesRow.hasTag?this.$t("Tag"):"");
      labels.push(speciesRow.interactingWithHumanFeature?this.$t("Interacting with human feature"):"");
      labels.push(speciesRow.tines.length>0?this.$t('Tines'):"");


      labels.push((['VNA',''].includes(speciesRow.individualCount.trim()) ? '' : speciesRow.individualCount));
      if (rtnArray) return labels;
      let display = labels.map(l=>l&&`${l}`.length>0?l+' | ':'').join('');
      return  (display.length > 1 ? ' | ' + display : '');
    },
    /* Show location underneath image but only for species verification */
    getLocation (index) {
      return this.imageList[index].locationName;
    },
    changeNumberPerPage () {
      this.imageQueryParam.page = 1;
      // this.reloadImage(true); this call was causing errors
      if (this.$refs.limit) {
        this.$refs.limit.blur();
      }
      this.loadImages();
      this.loadPagination();
    },
    /* load image by given page number *
    // reloadImageByPage (pageIndex) {
    //   this.imageQueryParam.page = pageIndex;
    //   this.reloadImage();
    // },
    /* call api to get a new image set,
    when it is only navigation no update, don' t call pagination APIs
    */
    reloadImage (bLoadPagination, bKeepScreenPosition) {
      if (!this.deploymentId && !this.projectId) {
        this.error = this.$t('cameraTagging-missingId');
        return;
      }
      /* ------------------------------------------------------------------
          first reset all
      -------------------------------------------------------------------- */
      this.ui.loading = true;
      /* ------------------------------------------------------------------
        then reload images
      -------------------------------------------------------------------- */
      this.imageQueryParam.sortAsc = (this.filterMode !== 'fov');
      if (this.appliedUserSeriesGap && this.filterMode === 'series') {
        this.imageQueryParam.seriesGap = this.appliedUserSeriesGap.value;
      }
    
      this.loadImages();
      
      if (bLoadPagination) { // page query updates, load pagination information. don't call it when it is only navigating
        // get pagination
        this.ui.paginationLoading = true;
        // this.pagination = null;
        // this.totalPageNum = 0;
        this.loadPagination();
        eventBus.$emit('reload-filter-options'); // when updated tag, reload options
      }
      /* clear current user selection */
      this.unselectAll();
      // eventBus.$emit('manual-selection-change', this.selectedImgId); // update parent page button
      if (!bKeepScreenPosition) { // when reload whole page (navigation), scroll to top, don't scroll when editing tag refreshing
        window.scrollTo(0, 0); // scroll to top
      }
    },
    loadPagination () {
      this.$http.get(this.getPaginationUrl, {params: this.imageQueryParam}).then(
        function (response) {
          this.ui.paginationLoading = false;
          if (response.body.error) {
            this.error = response.body.error;
            this.pagination = null;
            this.totalPageNum = 0;
          } else {
            let pageList = response.data;
            const total = pageList.pop();
            this.totalImageCount = total.count;
            this.pagination = pageList;
            this.totalPageNum = this.pagination.length;
            this.timeStamp = new Date().getTime(); // a time stamp to trigger pagination changes
            // if (this.deploymentId && this.pagination && this.pagination.length > 0) { // when used for deployment tagging, check if any untagged image existing
            //   const allTagged = this.pagination.every(item => item.status == 'full')
            //   eventBus.$emit('toggle-form', 'showUntaggedButton', !allTagged);
            // } else {
            //   eventBus.$emit('toggle-form', 'showUntaggedButton', false);
            // }
          }
        },
        function () {
          this.ui.paginationLoading = false;
          this.pagination = null;
          this.timeStamp = new Date().getTime(); // a time stamp to trigger pagination changes
        }
      );
    },
    loadImages () {
      this.ui.loading = true;
      this.$http.get(this.getImageListUrl, {params: this.imageQueryParam}).then(
        function (response) {
          this.ui.loading = false;
          if (response.body.hasOwnProperty('error')) {
            this.error = response.body.error;
            this.imageList = [];
          } else {
            // depends on whether untagged image exists, toggle update all untagged button
            this.imageList = response.data;



            //populate hasMultipleIndividuals
            this.imageList.forEach((img,index) => {
              if (img && Array.isArray(img.megaDetector) && img.megaDetector.length > 0) {
                img.megaDetector.map((md,i)=> {
                  img.megaDetector[i].area = md.box3*md.box4;
                });
                this.imageList[index].megaDetector = img.megaDetector.sort((a,b) => a.area < b.area);
              }
              this.imageList[index].hasMultipleIndividuals = false;
              this.imageList[index].hasMultipleSpecies = false;

              img.speciesTags.forEach((tag,index2) => {
                      img.speciesTags[index2].species = this.staticOptions.species.find(v=>v.id===tag.speciesId);
                      if (!this.imageList[index].hasMultipleIndividuals) //only set to true if false. So we dont set it back again.
                        this.imageList[index].hasMultipleIndividuals = parseInt(img.speciesTags[index2].individualCount)>1;
              });

              if (img.speciesTags && img.speciesTags.length > 1) {
                  let sIds = {};
                  img.speciesTags.every((s,i) => {
                    if (sIds[s.speciesId]) {
                      this.imageList[index].hasMultipleIndividuals = true;
                      this.imageList[index].hasMultipleSpecies = true;
                      return false;
                    }
                    else sIds[s.speciesId] = 1;
                    return true;
                  }); 

              }

            });

            // if (!bImageOnly) { // when not only update tags, update pagination and selections.
            // alwasy reload light box and number selection, just in case when user filtered on some critiera, and the updates made
            // may yield different images sets
            this.populateLightBoxImages(this.imageList);
            if (this.imageList.length > 0) {
              try {
                this.untaggedImageCount = this.imageList.filter(item => !item.species || item.species.length === 0).length;
              } catch (e) {}
              if (this.$refs.numberSelectArea) {
                this.$nextTick(() => {
                  this.$refs.numberSelectArea.contentUpdated();
                });
              }
              if (this.$refs.imageSelectArea) {
                this.$nextTick(() => {
                  this.$refs.imageSelectArea.contentUpdated();
                });
              }
            }
            // }
          }
        },
        function () {
          this.ui.loading = false;
          this.imageList = [];
        }
      );
    },
    // select all at current page
    selectAll () {
      this.selectedImgId = this.imageList.map(item => item.task.id);
      this.ui.showSelectAll = false;
      this.selectedImageCount = this.selectedImgId.length;
    },
    // select untagged for current page
    selectAllUntagged () {
      // console.log(this.imageList.filter(item => !item.species || item.species.length === 0));
      this.selectedImgId = this.imageList.filter(item => !item.speciesTags || item.speciesTags.length === 0).map(item => item.task.id);
      this.selectedImageCount = this.selectedImgId.length;
      this.ui.showSelectAll = (this.selectedImgId.length < this.imageList.length)
      return this.selectedImgId.length;
    },
    unselectAll () {
      this.selectedImgId = [];
      this.selectedImageCount = 0;
      this.ui.showSelectAll = true;
    },
    populateLightBoxImages (imgs) {
      let lbImages = [];
      this.lightboxImages = [];
      if (!imgs || imgs.length === 0) {
        return;
      }
      imgs.forEach((img, index) => {
        lbImages.push({
          ...createLightboxImageObject(img),
           'alt': this.$t('common-imgNumber', {num: (index + 1), total: imgs.length}),
           'id': 'image' + index,
        });
      });

      this.lightboxImages = lbImages;
    },
    showLightbox (imgUrl) {
      this.$refs.lightbox.show(imgUrl);
    },
    editImageTag (id) {
      this.currentImgId = null;
      this.currentImgId = id;
      this.forUntagged = false;
      eventBus.$emit('toggle-modal-form', this.ui.tagFormName, true);
    },
    // update all for the whole deployment task: deployment only
    updateAllUntaggedImages () {
      if (!this.isTagger) {
        return;
      }
      this.currentImgId = null;
      this.$http.get(this.getAllUntaggedIdUrl, {params: {pudId: this.deploymentId}}).then(
        (response) => {
          this.currentImgId = response.data;

          if (this.currentImgId == null || this.currentImgId.length === 0) { // when no tagging can't found
            this.currentImgId = null;
            alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('cameraTagging-cantFindUntagged'));
          } else {
            if (this.currentImgId.length === 1) { // if only one image untagged, treated it as single image update
              this.editImageTag(this.currentImgId[0])
            } else {
              this.forUntagged = true;
              eventBus.$emit('toggle-modal-form', this.ui.tagFormName, true);
            }
          }
        },
        (error) => {
          alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('cameraTagging-errorsLoadingUntagged') + ' ' + error);
        });
    },
    batchEditImageTags () {
      if (!this.isTagger) {
        return;
      }
      this.currentImgId = null;
      this.forUntagged = false;
      if (!this.selectedImgId) {
        return;
      }
      if (this.selectedImgId.length === 1) { // single image selected, call single image editing form
        this.editImageTag(this.selectedImgId[0])
      } else {
        this.currentImgId = this.selectedImgId.concat([]);
        eventBus.$emit('toggle-modal-form', this.ui.tagFormName, true);
      }
    },
    deleteSpecies (tagId) { // click the delete icon next to image tag
      if (!this.isTagger) {
        return;
      }
      this.$http.delete(this.deleteSpeciesTagsUrl, {body: [tagId]}).then(
        function (data) {
          if (data.error) {
            alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('cameraTagging-failedDeleteTag') + data.error);
            return false;
          } else {
            eventBus.$emit('reload-static-option');
            eventBus.$emit('update-tagging-status'); // after batch update, check if all fully tagged.
            this.reloadImage(true, true); // when delete species still need to reload pagination
            return true;
          }
        });
    },
    confirmDeleteSpecies (tagId) {
      const self = this;
      confirmDialog(this.$modal, this.$t('cameraTagging-deleteATag'), this.$t('cameraTagging-deleteTagWarning'), this.$t('common-delete'),
        function () { self.deleteSpecies(tagId); },
        this.$t('common-cancel'));
    },
    showImageInfoForm (img) {
      this.currentExifImgObj = img;
      eventBus.$emit('toggle-modal-form', this.ui.exifFormName, true);
    },
    /* shortcut keys, disabled when loading images */
    keyPressed (e) { //
      if (e.target.nodeName === 'TEXTAREA' ||
        e.target.nodeName === 'INPUT' ||
        e.target.nodeName === 'SELECT' ||
        e.target.nodeName === 'CHECKBOX' ||
        e.target.nodeName === 'DIV') { /* may need to remove div if other divs become selectable */
        return;
      }
      if (this.ui.loading) {
        return;
      }

      if (e.code === 'Escape' || e.code === 'ArrowRight' || e.code === 'ArrowLeft') { /* these 3 keys are used for image navigation as well, don't use it when image lightbox is showing */
        let lightBox = document.getElementById('mylightbox');
        // console.log(e.target, lightBox, document.body.style.overflow)
        if (lightBox && lightBox.childNodes.length > 0 && lightBox.childNodes[0] && lightBox.childNodes[0].classList && lightBox.childNodes[0].classList.contains('lightbox')) { // no use when in lightbox view
          return;
        }
      }

      if (e.code === 'KeyA' && e.ctrlKey) { /* for control A or ctrl a select all */
        e.preventDefault();
        this.selectAll();
      } else if (e.code === 'KeyQ' && e.ctrlKey) { /* for control q or ctrl Q select all untagged images */
        e.preventDefault();
        this.selectAllUntagged();
      } else if (e.code === 'KeyS' && e.ctrlKey) { /* for control T toggle tagged only */
        e.preventDefault();
        this.ui.showImageType = this.ui.showImageType === 'all' ? 'tagged' : 'all';
      } else if (e.code === 'KeyE' && e.ctrlKey) { /* for control E toggle untagged only */
        e.preventDefault();
        this.ui.showImageType = this.ui.showImageType === 'all' ? 'untagged' : 'all';
      } else if (e.code === 'KeyB' && e.ctrlKey) { /* for control B toggle partial only */
        e.preventDefault();
        this.ui.showImageType = this.ui.showImageType === 'all' ? 'partial' : 'all';
      } else if (e.code === 'Escape') { // escape, clear selection
        e.preventDefault();
        this.unselectAll();
      } else if (e.code === 'ArrowRight' || e.code === 'KeyD') { // right arrow 39, next page or D
        e.preventDefault();
        if (this.imageQueryParam.page >= this.pagination.length) { // if it is already on first page do nothing.
          return;
        }
        this.ui.loading = true;
        this.imageQueryParam.page++;
        this.reloadImage();
      } else if (e.code === 'ArrowLeft' || e.code === 'KeyA') { // left arrow 37, previous page
        e.preventDefault();
        if (this.imageQueryParam.page <= 1) { // if it is already on first page do nothing.
          return;
        }
        this.imageQueryParam.page--;
        this.ui.loading = true;
        this.reloadImage();
      } else if (e.code === 'KeyS') { // w scroll down
        const firstImage = document.querySelector('.img-selectable');
        if (firstImage) {
          window.scrollBy(0, firstImage.clientHeight);
        }
      } else if (e.code === 'KeyW') { // w scroll up
        const firstImage = document.querySelector('.img-selectable');
        if (firstImage) {
          window.scrollBy(0, -1 * firstImage.clientHeight);
        }
      }
    },
    /* click button to verify all species at current page */
    verifySpecies () {
      if (!this.isTagger) {
        return;
      }
      this.messages = '';
      const params = {
        speciesId: this.speciesId,
        taskIds: this.imageList.map(item => item.task.id)
      };
      this.ui.workingOnVerifySpp = true;
      /* species verify and then move to next page */
      this.$http.post(this.verifySpeicesUrl, params).then(
        (response) => {
          this.ui.workingOnVerifySpp = false;
          if (response.data.hasOwnProperty('error')) {
            this.messages = response.data.error;
          } else {
            this.messages = this.$t('cameraTagging-verifiedPageNum', this.imageQueryParam.page, {num: this.imageQueryParam.page});

            if (this.imageQueryParam.page >= this.totalPageNum) { // if it is already on first page do nothing.
              this.messages += ' (' + this.$t('cameraTagging-lastPage') + ')';
            }
            // move to next page and reload images
            if (this.imageQueryParam.isVerified !== false) { // when not query not-verified image, move to next page.
              try {
                this.pagination[this.imageQueryParam.page - 1][this.paginationStatusField] = 'full'; // single paginationStatusField
                this.timeStamp = new Date().getTime();
              } catch (e) {}
              if (this.imageQueryParam.page < this.totalPageNum) {
                this.imageQueryParam.page++;
              }
              this.reloadImage();
            } else {
              this.reloadImage(true); // need to reload pagination
            }
          }
        },
        (error) => {
          this.ui.workingOnVerifySpp = false;
          alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('cameraTagging-errorsLoadingUntagged') + error);
        });
    },
    applyUserSeriesGap () {
      this.imageQueryParam.page = 1;
      this.appliedUserSeriesGap = this.userSeriesGap;
      this.reloadImage(true, true);
    }
  },
  created () {
    const self = this;
    if (this.project) { 
      this.ui.filter.MDConfidence = this.project.defaultMinMegaDetectorConfidence?this.project.defaultMinMegaDetectorConfidence:0;
        setTimeout(() =>{eventBus.$emit('MDConfidence-change',this.ui.filter.MDConfidence)}, 500);
    }
    this.imageQueryParam.projectId = this.projectId;
    this.imageQueryParam.pudId = this.deploymentId;
    if (this.speciesObj) {
      this.speciesId = this.speciesObj.id;
      this.paginationStatusField = 'verified';
      this.orderDisplay = ['', ''];
    } else {
      this.paginationStatusField = 'status';
    }

    this.userSeriesGap = this.userSeriesOptions[0];

    

    /* mouse drag and draw selection on left number and right panel image change selection
    use this the synchronize them, and move the firs image into view */
    eventBus.$on('selection-change', function (selection, name) {
      // console.log('parent selection changed')
      self.selectedImageCount = selection.length;
      self.ui.showSelectAll = (self.selectedImageCount < self.imageList.length); // when selected < total record

      self.selectedImgIndex = selection;
      self.selectedImgId = selection.map(item => parseInt(item.dataset.item, 10));
      /* make the first selection to scroll into view when user uses left side panel to select */
      const newVal = self.selectedImgId;
      // try {
      if (newVal && newVal.length > 0 && name === 'numberArea') {
        const dragArea = self.$refs.numberPanel;
        let imgIndex = -1;
        for (let i = 0; i < dragArea.length; i++) {
          // console.log(dragArea[i].classList);
          if (dragArea[i].classList.contains('selected')) {
            imgIndex = i;
            break;
          }
        }

        if (this.currentInViewImageIndex !== imgIndex && imgIndex > -1 && imgIndex < self.$refs.imagePanel.length) {
          this.currentInViewImageIndex = imgIndex;
          self.$refs.imagePanel[imgIndex].scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        }
      }
      // } catch (e) {console.error(e); }
    });
    /* when user click a page in pagination */
    eventBus.$on('pick-page', function (pageNumber) {
      if (self.imageQueryParam.page !== pageNumber) {
        self.imageQueryParam.page = pageNumber;
        self.reloadImage();
      }
    });

    /* when user click apply filter,
      assign filter paramter to query params, they are in different format
      remove empty filters, and then send a flag to top menu to show filtered icon
 */

    eventBus.$on('apply-filter', function (queryParam) {
     
      //Preserve limit
      queryParam.limit = self.imageQueryParam.limit;

      // clear parameters that is not in initial list, otherwise, if previous set as nice
      // after removed from filter ui, you still have the nice flag here.
      Object.keys(self.imageQueryParam).forEach(key => {
        if (!self.initalParamNames.includes(key)) {
          this.$delete(self.imageQueryParam, key);
        }
      });
      self.imageQueryParam.page = 1; // reset page to the 1
      self.imageQueryParam = Object.assign(self.imageQueryParam, queryParam); // (target, source)
      // console.log(Object.keys(queryParam));
      const queryForFalseFields = ['isVerified'];
      const alwaysPassFields = ['excludeAutoTag', 'isVerified'];
      Object.keys(queryParam).forEach(key => {
        if (!alwaysPassFields.includes(key)) { // for some fields, we want to pass true or false all the time.
          const nonAcceptedValue = queryForFalseFields.includes(key); // default: remove false, for query false field, remove true values
          const value = queryParam[key];
          if (value == null || value === nonAcceptedValue || value === '' || value.length === 0 || key.startsWith('tmp')) { // remove empty or false values
            if (self.imageQueryParam.hasOwnProperty(key)) {
              this.$delete(self.imageQueryParam, key);
            }
            this.$delete(queryParam, key);
          }
        }
      });
      /* don't load total count for series view */
      self.reloadImage(true);
      // console.log(queryParam);
      let alwaysRemoveFields = ['page', 'useSeriesView'];
      if (self.filterMode === 'series') { /* hide auto tagger and show motion only */
        alwaysRemoveFields.push('triggerModeId');
      }
      alwaysRemoveFields.forEach(key => { this.$delete(queryParam, key); });

      //apply md display threashold to the bound box filter 
      if (queryParam.megaDetectorThreshold) {
        self.ui.filter.MDConfidence = queryParam.megaDetectorThreshold;
      }
      console.log(copyObject(self.imageQueryParam), copyObject(queryParam));
      eventBus.$emit('update-filter-flag', queryParam); // let main page know if there is filter or not
    });
    /* when user updated tag information */
    eventBus.$on('refresh-tag', function (queryParam) {
      self.reloadImage(true, true);
    });

    eventBus.$on('close-tagging-form', function (formName, toClose) {
      self.currentImgId = null; // remove selected ID
      eventBus.$emit('toggle-modal-form', formName, false);
    });
  },
  mounted () {
    const that = this;
    window.addEventListener('keydown', function (event) { /* don't use keyup, otherwise the default action will not be prevented */
      that.keyPressed(event);
    });
  },
  watch: {
  
    selectedImageCount: (newVal, oldVal) => {
      if (newVal != oldVal)
      eventBus.$emit('selectionImageCount-change', newVal);
    },

    "ui.filter.MDConfidence": (newVal, oldVal) =>{
        if (newVal != oldVal) {
          eventBus.$emit('MDConfidence-change',newVal);
        }
    },
    project(newval) {
      if (newval) {
        this.ui.filter.MDConfidence = newval.defaultMinMegaDetectorConfidence?newval.defaultMinMegaDetectorConfidence:0; 
        setTimeout(() =>{eventBus.$emit('MDConfidence-change',this.ui.filter.MDConfidence)}, 500);
      }
    },
    filterMode (newVal, oldVal) { // when user switch tabs, use different field for pagination status
      if (newVal !== oldVal) {
        this.orderDisplay = [this.$t('common-oldest'), this.$t('common-newest')];
        if (newVal === 'fov') {
          this.paginationStatusField = 'is_fov status';
          this.orderDisplay = [this.$t('common-newest'), this.$t('common-oldest')];
        } else if (this.filterMode === 'tag') {
          this.paginationStatusField = 'status';
        }

        if (newVal === 'series') {
          this.orderDisplay = ['', ''];
        }
      }
    }
  },
  data () {
    return {
      getImageListUrl: API_URL + 'get-camera-image-view', // 'get-camera-task-for-proj-user-deploy', // static options such as sex age
      // no longer used getImageCountUrl: API_URL + 'get-camera-image-count', // 'get-camera-task-for-proj-user-deploy', // static options such as sex age
      getPaginationUrl: API_URL + 'get-camera-image-pagination', // get pagination
      
      deleteSingeTagUrl: API_URL + 'delete-camera-species-tag',
      deleteSpeciesTagsUrl: API_URL+'delete-camera-species-tags',

      getAllUntaggedIdUrl: API_URL + 'get-untagged-tasks', // get all untagged id and pass them to form for updating
      verifySpeicesUrl: API_URL + 'camera-verify-species',
      imageQueryParam: {
        sortAsc: true,
        limit: 60,
        page: 1,
        sort: 'date',
        pudId: this.deploymentId,
        projectId: this.projectId
        // entityIdCSV: '',
        // speciesIdCSV: '',
        // sexIdCSV: '',
        // individualCount: '',
        // triggerModeCSV: '',
        // tagToolCSV: '',
        // startTimeStr: '',
        // endTimeStr: '',
        // ageIdCSV: ''
      },
      initalParamNames: ['sortAsc', 'limit', 'sort', 'pudId', 'projectId'],
      currentImgId: null,
      forUntagged: false,
      appliedUserSeriesGap: null,
      userSeriesOptions: [
        {
          id: 0,
          value: null,
          text: this.$t('cameraTagging-seriesOptions[0]')
        },
        {
          id: 1,
          value: 60,
          text: this.$t('cameraTagging-seriesOptions[1]')
        },
        {
          id: 2,
          value: 120,
          text: this.$t('cameraTagging-seriesOptions[2]')
        },
        {
          id: 3,
          value: 300,
          text: this.$t('cameraTagging-seriesOptions[3]')
        },
        {
          id: 4,
          value: 600,
          text: this.$t('cameraTagging-seriesOptions[4]')
        },
        {
          id: 5,
          value: 1800,
          text: this.$t('cameraTagging-seriesOptions[5]')
        },
        {
          id: 6,
          value: 3600,
          text: this.$t('cameraTagging-seriesOptions[6]')
        },
        {
          id: 7,
          value: 18000,
          text: this.$t('cameraTagging-seriesOptions[7]')
        },
        {
          id: 8,
          value: 36000,
          text: this.$t('cameraTagging-seriesOptions[8]')
        }
      ],
      userSeriesGap: null,
      selectedImgId: [],
      imageList: null,
      lightboxImages: [],
      ImagePerPageOption: [12, 24, 48, 60, 80, 200, 500],
      pagination: null,
      totalPageNum: 0,
      totalImageCount: 1000,
      selectedImageCount: 0,
      ui: {
        infoPanelVisible: true,
        numberPanelClass: 'numberCol',
        imagePanelClass: 'imgWrap',
        /* form name */
        tagFormName: 'tagging-form',
        infoFormName: 'image-info-form',
        exifFormName: 'exif-form-name',
        loading: true,
        showImageType: 'all',
        showSelectAll: true,
        workingOnVerifySpp: false,
        filter: {
          MDConfidence: 0
        }

      },
      currentInViewImageIndex: -1,
      currentExifImgObj: null,

      speciesId: null,
      messages: null,
      paginationStatusField: null,
      timeStamp: null,
      untaggedImageCount: 0,
      orderDisplay: [this.$t('common-oldest'), this.$t('common-newest')]
    }
  }
}
</script>
<style scoped>
/* Custom styling */
.numberCol.selected {
  border:#91efef solid 1px;
}

.selected.active {
    background-color: #91efef;
}

#infoPanel .vue-drag-select {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}
.lightbox-fade-enter-active,
    .lightbox-fade-leave-active {
        transition: none!important;
    }

.lightbox-fade-enter,
    .lightbox-fade-leave-to {
        opacity: 1!important;
    }

.lightbox-slide-next-enter-active,
    .lightbox-slide-next-leave-active,
    .lightbox-slide-prev-enter-active,
    .lightbox-slide-prev-leave-active{
        transition: all 0s ease;
}

.multipleSpecies { /* top right red triangle */
      width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #b73333;
    border-top: 5px solid #b73333;
    border-left: 5px solid transparent;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
 .multipleCount, .multipleIndividual{/* top left blue triangle */
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #337ab7;
    border-left: 5px solid #337ab7;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}
/* .outOfRange {/* top left blue triangle *
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-left: 22px solid transparent;
    border-bottom: 22px solid #e6e92e87;
    border-right: 22px solid #e6e92e8f;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
} */

  .outOfRange:before {
    content: "";
    position: absolute;
    top: 0%;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left top, rgba(224, 226, 97, 0.6) 50%, transparent 50%)
}

.remote-camera #infoPanel .numberRow .numberCol.untagged .number{
    border:1px solid #ccc;
}
.remote-camera #infoPanel .numberRow .numberCol .number{
  position: relative;
}

#selectable-wrapper .delete {
  opacity: 1!important;
  -webkit-transform: scale(1)!important;
  -ms-transform: scale(1)!important;
  transform: scale(1)!important;
}
.tag-details {
    height: 1.8em;
}

.remote-camera #mainPanel .row .capture .vidData .number {
    border: #c3c3bf solid 1px;
}
.remote-camera #mainPanel .row .capture.partial .number,
.remote-camera #infoPanel .numberRow .numberCol.partial .number {

    background: rgb(203, 221, 206);
    color: #666;
}

.hidden {
  display: none;
}
.remote-camera #infoPanel .numberRow .numberCol.hidden {
  display: none;
}
.red-orange-colored {
  color: #C94412;
}

.remote-camera #mainPanel .row .capture .imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    /* -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
     transform: scale(0.1); */
     transition-delay: 0.8s;

}
.remote-camera #mainPanel .row .capture .imgWrap:hover .imgOverlay {
    opacity: 1;
    display:flex;
    /* -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); */
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    /* transition-delay: 0.5s!important; */
}
.remote-camera #mainPanel .row .capture .imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    /* -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
     transform: scale(0.1); */
     /* transition-delay: 0.8s; */

}
.remote-camera #mainPanel .row .capture .imgWrap .imgOverlay .magnify{
    font-size: 1px;
   
}
.remote-camera #mainPanel .row .capture .imgWrap:hover .imgOverlay span{
    font-size: 2.3rem;
}

.imgWrap {
  width: 100%;
}
.lightbox {
  z-index: 20000!important;
}

.remote-camera #infoPanel .numberRow .numberCol.selected.untagged .number {
   color: #999;
}
.remote-camera #infoPanel .numberRow .numberCol.need-review .number {
 border: #FF9800 4px solid;
}

.less-margin {
  margin: 0!important;
}

.remote-camera #mainPanel { /* reduce padding on left side */
    padding-left: 2%!important;
}

.vue-drag-select {
  width: 100%;
}

@media only screen and (min-width: 601px) {
 #scrollBounds .row .col.m2.custom {
    width: 12%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
 #scrollBounds .row .col.m10.custom {
    width: 88%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

.tagSpp {
  font-weight: 600;
  color: #227cc3;
}

.remote-camera #mainPanel .row .capture .vidData .info .tag-text {
  font-size: 15px;
}

.numberSelectArea {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.vue-drag-select.numberSelectArea.scroller {
  overflow-y: scroll!important;
}

select.record-per-page {
  display: inline;
  width: unset;
}
.remote-camera #mainPanel .row .capture .vidData .moreInfo {
  top: 2px;
  right: -5px;
}
.remote-camera #mainPanel .row .capture .vidData .delete {
    right: 10px;
    top: 2px;
}
select:focus {
    box-shadow: 0 0 3pt 2pt #FFEB3B;
}

.multiselect--disabled {
    background: transparent;
}
.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
    background: #eae7e7;
    display: none;
    margin-top: 10px;
}

.multiselect--disabled .multiselect__tags {
  cursor: not-allowed;
}
.tag-isNice {
  z-index: 5;
  color: #fbc218;
  font-size: 1.2em;
}

.location{
  color: #FFA622;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  padding: 0 32px;
}

.user-series-gap{
  display: inline;
  color: #C94412;
  padding-right: 12px;
}

.user-series-gap.select{
  display: inline;
}

.user-series-gap input{
  width: 90px !important;
  height: auto !important;
  padding-right: 0px !important;
}

#series-apply-btn {
  padding: 5px 9px !important;
}

.series-gap-select{
  display: inline !important;
  width: 55%;
}

.top-item-wrapper{
  align-items: center;
}
.imageNotFound {
  background-size: cover;
  width: 100px;
  height: 100px;
  background-image: url('../../assets/image-not-found.jpg');
}
</style>
