<template>
  <div id="equipment-grid" class="col s12 panel boxed padded60 dataGroup dhtmlx-abmi">
    <template v-if="!loading && equipmentGrid != null">
      <dropdown-button class="right">
        <template slot="menuTitle">{{$t('common-manage')}}</template>
        <template slot="dropdownButtons">
          <a download class="btn btn-success popover-menu-item" :class="{'downloading': downloading }" @click="downloadEquipmentCSV"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{equipmentGrid.getRowsNum() > 0 ? $t('equipmentGrid-downloadCSV') : $t('common-downloadCsvTemplate')}}</a>
          <a download class="btn btn-success popover-menu-item" :class="{'downloading': downloadingCodes }" @click="downloadCodes"> <span style="font-size: 20px;"></span><span class="spin-icon"></span>{{$t('common-downloadCodes')}}</a>
          <!-- popover + tooltip has been buggy so this v-if part is necessary to avoid bug -->
            <span v-if="!isEditable" v-tooltip="{content: tooltips.disabledUpload, show: isEditable, classes: 'front'}">
              <button :disabled="!isEditable" v-close-popover class="btn btn-success popover-menu-item" @click="uploadEquipmentCSV"> <span style="font-size: 20px;"></span> {{$t('equipmentGrid-uploadEquipmentCSV')}}</button>
            </span>
          <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadEquipmentCSV"> <span style="font-size: 20px;"></span> {{$t('equipmentGrid-uploadEquipmentCSV')}}</button>
        </template>
      </dropdown-button>
    </template>
    <div class="loader" v-if="loading">
      <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
      <div class="white">{{$t('equipmentGrid-updating')}}</div>
    </div>
    <div class="dhtmlx-abmi" ref="container"></div>
    <modal name="equipment-upload-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <upload-equipment-csv
      v-bind:organization-id="organizationId"
      v-bind:organization-name="organizationName"
      v-bind:type="'equipment'"/>
    </modal>
    <v-dialog/>
  </div>
</template>

<script>
import fromCDN from 'from-cdn';
import { API_URL, DHTMLX_LIB_PATH, capitalizeString } from '@/lib/common';
import mixin from './dhtmlx-mixin.js';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import {createDhtmlxLayout} from '@/components/utils/external-lib-wrapper';
import UploadCSVForm from '@/components/common/UploadCSVForm';
import { eventBus } from '@/lib/eventbus';
import DropdownButtonWithOptionsVue from '@/components/common/DropdownButtonWithOptions.vue';


export default {
  mixins: [mixin],
  props: ['organizationId', 'organizationName', 'isEditable'],
  components: {'pulse-loader': PulseLoader, 'upload-equipment-csv': UploadCSVForm, 'dropdown-button': DropdownButtonWithOptionsVue},
  watch: {
    organizationId () {
      // if (this.equipmentLayout) {
      //   this.equipmentLayout.unload();
      //   this.equipmentLayout = null;
      // }
      this.getGridBaseUrl = API_URL + 'get-equipment?organization=' + this.organizationId;
     // this.initLayout();
      this.loadEquipmentDataInGrid(this.equipmentGrid, this.organizationId.toString());
    }
  },
  created: function () {
    // this.ready = fromCDN([
    //   './static/dhtmlx/sources/dhtmlxCommon/codebase/dhtmlxcommon.js',
    //   './static/dhtmlx/sources/dhtmlxGrid/codebase/dhtmlxgrid.js',
    //   './static/dhtmlx/sources/abmiExtension.js',
    //   // '//cdn.dhtmlx.com/5.1/dhtmlx.js',
    //   '//cdn.dhtmlx.com/5.1/dhtmlx.css'
    // ]);
    //  .fromCDN([
    //   './static/dhtmlx/sources/dhtmlxCommon/codebase/dhtmlxcommon.js'
    // // ])
    // attachAuthorizaitonHeader();
    this.ready = fromCDN([
      // '//cdn.dhtmlx.com/5.1/dhtmlx.js',
      //  './static/dhtmlx/sources/dhtmlxCommon/codebase/dhtmlxcommon.js',
      DHTMLX_LIB_PATH + 'dhtmlx/codebase/dhtmlx.css', // version5.1 profesional
      DHTMLX_LIB_PATH + 'dhtmlx/codebase/dhtmlx.js'
    ]).then(_ => fromCDN([DHTMLX_LIB_PATH + 'dhtmlx/abmiExtension.js']));
    eventBus.$on('hide-csv-upload-form', () => {
      this.$modal.hide('equipment-upload-form');
    });
  },
  mounted: function () {
    const self = this;
    this.ready.then(() => {
      this.$http.get(this.getGridOption).then(
        response => {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          }
          this.options = response.data;
          self.initLayout();
        },
        errResponse => {
          // error callback
          this.error =
            this.$tc('common-error', 1) + ': ' +
            (errResponse.data ? errResponse.data.error : '') +
            ' ' +
            errResponse.status;
        }
      );
    });
  },
  methods: {
    initLayout () {
      // this.equipmentLayout = new dhtmlXLayoutObject(this.$refs.container, '2U');
      this.equipmentLayout = createDhtmlxLayout(this.$refs.container, '2U');
      let layout = this.equipmentLayout;
      layout.cells('a').setWidth(250);
      layout.cells('a').setMinWidth(20);
      layout.cells('a').setText(this.$t('equipmentGrid-type'));
      layout.cells('b').setText(this.$t('common-equipment'));
      layout.cells('a').setMinWidth(100);

      this.statusBar = layout.cells('b').attachStatusBar();
      let grid = this.initEquipmentGrid(layout, this.statusBar); /* initial lab sample grid */

      this.initOrganization(layout, grid);
      // this.addLeftToolBar(layout); // call inside toolbar initialzation
      //                     // loadSpecimenDataInGrid($.cookie(g_treeGridCurrentIdCookie));
      this.addRightToolBar(layout, grid, this.statusBar);
      let self = this;
      window.onresize = function () { self.setLayout(layout); };
    },
    setLayout (layout) {
      layout.cells('a').collapse();
      layout.cells('a').expand();
    },
    initOrganization (layout, grid) {
      let tree = layout.cells('a').attachTree('0');
      const self = this;
      tree.setImagePath(DHTMLX_LIB_PATH + 'dhtmlx/codebase/imgs/dhxtree_material/');
      // tree.setIconSize('16px', '16px');

      tree.setOnClickHandler(function (id) {
        // tree.saveOpenStates(self.cookieName, self.getCookieExpireDate(30));
        self.loadEquipmentDataInGrid(grid, id);
      });

      tree.deleteChildItems(0);
      tree.insertNewChild(0, this.organizationId.toString(), this.organizationName);
      self.options.EquipmentType.forEach(function (type) {
        tree.insertNewChild(self.organizationId.toString(), self.organizationId + '_' + type.id, type.type);
      });
      tree.openAllItems(this.organizationId.toString());
    },
    initEquipmentGrid (layout, statusBar) {
      this.equipmentGrid = layout.cells('b').attachGrid();
      

      let mygrid = this.equipmentGrid;
      const self = this;
      this.equipmentGrid.$t = key => {
        return self.$t(key);
      };
      mygrid.setImagesPath('//cdn.dhtmlx.com/5.1/imgs/');
      this.initGrid(mygrid, statusBar, this.configArray, function () { // after configuration
        const typeOpt = self.options.EquipmentType.map(x => { return { value: x.id, text: x.type }; });
        mygrid.getColumnComboById('type').addOption(typeOpt);
        const statusOpt = self.options.EquipmentStatus.map(x => { return { value: x.id, text: x.type }; });
        mygrid.getColumnComboById('status').addOption(statusOpt);
        mygrid.attachEvent('onEditCell', function (stage, rId, cInd, nValue, oValue) {
          return cInd !== mygrid.getColIndexById('is_in_field');
        });
        self.loadEquipmentDataInGrid(mygrid, self.organizationId + ''); // load grid after initialization
      },
      function () { // after update
        self.addBlankRow(mygrid);
      });
      return mygrid;
    },
    addLeftToolBar (layout) {
      let toolbar = layout.cells('a').attachToolbar();
      // toolbar
      // .setIconsPath('/resources/dhtmlx/dhtmlxToolbar/codebase/icons/');
      toolbar.loadStruct(DHTMLX_LIB_PATH + 'dhtmlx/toolbar/equipment.xml');
      toolbar.attachEvent('onClick', function (id) {
        switch (id) {
          case 'filter':
            // showTreeFilterUI();
            break;
          default:
            break;
        }
      });
    },
    addRightToolBar (layout, grid, statusBar) {
      /* don't load the toolbar, when it is read only mode */
      /* default the grid is read only */
      this.isGridEditable = false;
      grid.setEditable(this.isGridEditable);
      if (!this.isEditable) {
        return;
      }
      const self = this;
      const editButtonIds = ['undo', 'redo', 'insert', 'sep3']; // 'delete'
      const buttonIds = ['refresh','redo','undo','insert','setEditable','delete'];
      let toolbar = layout.cells('b').attachToolbar();
      toolbar.setIconsPath(DHTMLX_LIB_PATH + 'dhtmlx/codebase/imgs/dhxtoolbar_material/');

      toolbar.loadStruct(DHTMLX_LIB_PATH + 'dhtmlx/toolbar/equipment.xml', function (e) {
        /* show hide buttons for read and edit */
        editButtonIds.forEach(x => {
          if (!self.isGridEditable) {
            toolbar.hideItem(x);
          } else {
            toolbar.showItem(x)
          }
        });
          buttonIds.forEach( id => {
            //toolbar.hideItem(id);
            toolbar.setItemText(id, self.$t(`equipmentGridButtons.${id}`));
        });
      });

   

      toolbar.attachEvent('onClick', function (id) {
        switch (id) {
          case ('delete'):
            self.deleteSelectedRow(grid);
            self.dp.sendData(); // since the editing trigger at row level, so need to manually send this to dp
            break;
          case ('refresh'):
            self.reloadGrid(grid, statusBar);
            break;
          case ('save_full'):
            self.exportToExcel();
            break;
          case ('undo'):
            grid.doUndo();
            break;
          case ('redo'):
            grid.doRedo();
            break;
          case ('insert'):
            self.addBlankRow(grid);
            break;
          case ('setEditable') :
            // toggle grid editable
            self.isGridEditable = !self.isGridEditable;
            grid.setEditable(self.isGridEditable);
            toolbar.setItemText(id, self.isGridEditable ? self.$t('equipmentGrid-returnToReadOnly') : self.$t('equipmentGrid-editTable'));
            toolbar.setItemImage(id, self.isGridEditable ? 'cantedit.gif' : 'edit.gif');
            editButtonIds.forEach(x => {
              if (!self.isGridEditable) {
                toolbar.hideItem(x);
              } else {
                toolbar.showItem(x)
              }
            });
            if (self.isGridEditable) { // insert a blank row when switch to editable
              self.addBlankRow(grid);
            }
            break;
          default:
            break;
        }
      });
    },
    /* id, at organization level used for id
      at device level id = orgId_typeId
      */
    loadEquipmentDataInGrid (grid, id) {
      this.deviceTypeId = null;
      this.equipmentGrid.clearAll();
      const ids = id.split('_');
      this.getGridUrl = this.getGridBaseUrl; // + 'organization=' + this.organizationId; // ids[0];
      let currentType = '';
      if (ids[1]) {
        this.deviceTypeId = parseInt(ids[1]);
        currentType = (this.options.EquipmentType.find(x => x.id === this.deviceTypeId) || {}).type;
        this.getGridUrl += '&type=' + ids[1];
        // have device type provided
        grid.setColumnHidden(grid.getColIndexById('type'), true);
      } else {
        grid.setColumnHidden(grid.getColIndexById('type'), false);
      }
      this.equipmentLayout.cells('b').setText(this.$t('common-equipment') + ' ' + currentType);
      this.loadGrid(this.equipmentGrid, this.statusBar, this.getGridUrl)
    },
    addBlankRow (grid) {
      if (!this.isGridEditable) { // don't add blank row when it is not editable
        return;
      }
      const self = this;
      if (this.dp) {
        this.dp.ignore(function () {
          const rowId = (new Date()).valueOf();
          const lastRowIndex = grid.getRowsNum();

          try {
            const lastRowID = grid.getRowId(lastRowIndex - 1);
            Object.keys(self.copiedField).forEach(x => {
              self.copiedField[x] = grid.cellByIdStr(lastRowID, x).getValue();
            });
          } catch (e) {
            // empty grid
          }
          self.copiedField.type = (self.deviceTypeId ? self.deviceTypeId : self.copiedField.type); // overwrite previous value with selected device value
          grid.addRow(rowId, '', lastRowIndex);
          Object.keys(self.copiedField).forEach(x => {
            self.setCellValue(grid, rowId, x, self.copiedField[x]);
          });
          self.setCellValue(grid, rowId, 'organization', self.organizationId);

          grid.setRowTextStyle(rowId, 'background-color:#fbe1adf2; font-style: italic;');
          grid.showRow(rowId);
          // console.log(self.copiedField);
          // console.log(grid.cellById(rowId, grid.getColIndexById('type')).getValue(), ' and combo=', grid.cellById(rowId, grid.getColIndexById('type')));
        });
      }
    },
    downloadEquipmentCSV () {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.$http.get(this.downloadEquipmentUrl + this.organizationId, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.getFileName();
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloading = false;
      },
      (err) => {
        this.downloading = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    getFileName () {
      let now = new Date();
      let filename = this.organizationName + '_' + this.$t('common-equipment').toLowerCase() + '_' + now.getFullYear();
      if ((now.getMonth() + 1) < 10) {
        filename += '0' + (now.getMonth() + 1) + now.getDate() + '.csv';
      } else {
        filename += (now.getMonth() + 1) + now.getDate() + '.csv';
      }
      return filename;
    },
    downloadCodes () {
      if (this.downloadingCodes) {
        return;
      }
      this.downloadingCodes = true;
      this.$http.get(this.downloadCodesUrl, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.$t('common-equipment') + '_' + capitalizeString(this.$tc('common-code', 2)) + '.zip';
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloadingCodes = false;
      },
      (err) => {
        this.downloadingCodes = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    uploadEquipmentCSV () {
      this.$modal.show('equipment-upload-form');
    }
  },
  data () {
    return {
      equipmentGrid: null,
      equipmentLayout: null,
      statusBar: null,
      deviceTypeId: null,
      getGridBaseUrl: API_URL + 'get-equipment?organization=' + this.organizationId, // base url with org id
      getGridUrl: null, // used url including filter, sorting etc.
      downloadEquipmentUrl: API_URL + 'download-equipment-by-org-id?orgId=',
      downloadCodesUrl: API_URL + 'download-equipment-codes',
      tooltips: {
        disabledUpload: this.$t('common-disabledUpload')
      },
      downloading: false,
      downloadingCodes: false,
      getGridOption: API_URL + 'get-equipment-options',
      updateRowUrl: API_URL + 'insert-update-delete-equipment',
      cookieName: 'wildtrax-equipment',
      dateFormat: '%Y-%m-%d',
      isGridEditable: false,
      options: {},
      configArray: [
        [ 'id', 'Id', '0', 'ro', '&nbsp;', 'str', 'true', 'left' ], // unique
        [ 'organization', 'Organization', '0', 'ro', '', 'str', 'true', 'left' ],
        [ 'code', this.$t('equipmentGrid.code'), '10', 'ed', '#text_filter', 'str', 'true', 'left' ],
        [ 'make', this.$t('equipmentGrid.make'), '8', 'ed', '#text_filter', 'str', 'true', 'left' ],
        [ 'model', this.$t('equipmentGrid.model'), '12', 'ed', '#text_filter', 'str', 'true', 'left' ],
        [ 'serialNumber', this.$t('equipmentGrid.serial'), '10', 'ed', '#text_filter', 'str', 'true', 'left' ],
        [ 'type', this.$t('equipmentGrid.type'), '10', 'combo', '#select_filter', 'str', 'true', 'left' ],
        [ 'status',this.$t('equipmentGrid.status'), '10', 'combo', '#select_filter', 'str', 'true', 'left' ],
        [ 'purchaseDate', this.$t('equipmentGrid.purchaseDate'), '10', 'dhxCalendarA', '#text_filter', 'str', 'false', '' ],
        [ 'is_in_field', this.$t('equipmentGrid.inuse'), '5', 'ch', '#select_filter', 'str', 'true', 'left' ],
        [ 'comments', this.$t('equipmentGrid.comments'), '25', 'txt', '#text_filter', 'str', 'false', '' ]
      ],
      copiedField: { model: '', make: '', type: '', status: 1, is_in_field: 1, purchaseDate: '' }
    };
  }
};
</script>
<style lang="scss">
#equipment-grid{
.dhtmlx-abmi {
  height: 100%;
  min-height: 800px;
  width: 100%;
  position: relative;
  clear: both;

  .loader {
    position: absolute;
    z-index: 10;
    height: 20%;
    width: 100%;
    padding: 25% 30%;
    background: #f9f9f9ad;
    margin-left: -60px;
    text-align: center;
    clear:both;
  }

  table{
    width: unset;
  }

  table td, th {
    padding: 3px 0px;
  }
  table tr {
      border-bottom: 0px solid rgba(0, 0, 0, 0.12);
  }

  div.dhx_cell_layout div.dhx_cell_hdr {
    background-color: #C94412!important;
  }
  .xhdr input, .xhdr select {
    height: 2.5rem;
  }
  .gridbox {
    padding-left: 10px;
  }
  div.gridbox_material.gridbox .xhdr {
    border-bottom: 2px solid #C94412;
  }
}
.dhxwins_vp_auto textarea {
      background-color: white!important;
}
textarea.dhx_tab_ignore {
min-height:1px;
padding: 0px;
}

.front{
  z-index: 1000000;
}
}
</style>
