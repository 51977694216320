var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !(_vm.project && _vm.tableData),
            expression: "!(project && tableData)"
          }
        ],
        staticClass: "overaly",
        staticStyle: {
          opacity: "0.6",
          width: "100%",
          height: "100%",
          "z-index": "100"
        }
      },
      [
        _c(
          "div",
          { staticStyle: { "text-align": "center", margin: "250px 0px" } },
          [
            _c("pulse-loader", {
              attrs: {
                loading: true,
                color: "#C94412",
                size: "20px",
                width: 400,
                height: 200
              }
            }),
            _vm._v(" "),
            _c("div", [
              _vm._v(" " + _vm._s(_vm.$t("common-tableTexts.loading")) + " ")
            ])
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _vm.project && _vm.tableData
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "flex gap-1" },
              [
                _c("div", { staticClass: "grow" }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.project.isAdmin
                          ? ""
                          : _vm.$t("Upload disabled for standard users."),
                        expression:
                          "project.isAdmin ? '' : $t('Upload disabled for standard users.')"
                      }
                    ],
                    class: {
                      "!cursor-auto": !_vm.project.isAdmin,
                      "!cursor-pointer": _vm.project.isAdmin
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        style: { height: "42px" },
                        attrs: { disabled: !_vm.project.isAdmin },
                        on: { click: _vm.showModal }
                      },
                      [
                        _c("i", { staticClass: "fa fa-upload" }),
                        _vm._v(" " + _vm._s(_vm.$t("common-upload")))
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "dropdown-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedIds.length > 0,
                        expression: "selectedIds.length > 0"
                      }
                    ]
                  },
                  [
                    _c("template", { slot: "menuTitle" }, [
                      _vm._v(
                        _vm._s(_vm.$t("common-manage")) +
                          " (" +
                          _vm._s(_vm.selectedIds.length) +
                          ")"
                      )
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "dropdownButtons" }, [
                      _c("a", [
                        _vm.project.isAdmin
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "close-popover",
                                    rawName: "v-close-popover"
                                  }
                                ],
                                staticClass:
                                  "btn btn-success popover-menu-item",
                                on: { click: _vm.includeInDownloadBatch }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("Include in download")) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("a", [
                        _vm.project.isAdmin
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "close-popover",
                                    rawName: "v-close-popover"
                                  }
                                ],
                                staticClass:
                                  "btn btn-success popover-menu-item",
                                on: { click: _vm.hideBatch }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("Hide files")) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("a", [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "close-popover",
                                rawName: "v-close-popover"
                              }
                            ],
                            class: {
                              "group-top": _vm.project.isAdmin,
                              "btn btn-success popover-menu-item": true
                            },
                            on: {
                              click: () => {
                                if (!_vm.downloading) _vm.downloadBatch()
                              }
                            }
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.downloading,
                                  expression: "downloading"
                                }
                              ],
                              staticClass: "fa fa-spinner fa-spin"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Download files")) +
                                "\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.project.isAdmin
                        ? _c("a", [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "close-popover",
                                    rawName: "v-close-popover"
                                  }
                                ],
                                staticClass:
                                  "btn btn-success popover-menu-item",
                                on: { click: _vm.removeBatch }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("Delete files")) +
                                    "\n                        "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  2
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.tableData
              ? _c(
                  "v-client-table",
                  {
                    ref: "filesTable",
                    attrs: {
                      columns: _vm.columns,
                      options: _vm.tableOptions,
                      data: _vm.tableData
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "check",
                          fn: function(props) {
                            return [
                              _c("label", [
                                _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    "data-id": props.row.id
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onCheckClick(props.row.id)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span")
                              ])
                            ]
                          }
                        },
                        {
                          key: "uploadDate",
                          fn: function(props) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.date(props.row.uploadDate)) +
                                  "\n            "
                              )
                            ]
                          }
                        },
                        {
                          key: "fileName",
                          fn: function(props) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.download(props.row)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(props.row.fileName))]
                              )
                            ]
                          }
                        },
                        {
                          key: "description",
                          fn: function({ row, update }) {
                            return [
                              _vm.project.isAdmin
                                ? _c("input", {
                                    staticClass: "!border-0 !mb-0 !h-10",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "Click to edit description"
                                      ),
                                      type: "text"
                                    },
                                    domProps: { value: row.description },
                                    on: {
                                      keypress: e => _vm.onInput(e, row),
                                      blur: e =>
                                        _vm.updateDescription(
                                          row,
                                          e.target.value
                                        )
                                    }
                                  })
                                : _c("div", [_vm._v(_vm._s(row.description))])
                            ]
                          }
                        },
                        {
                          key: "hidden",
                          fn: function(props) {
                            return [
                              _c("div", { staticClass: "text-center" }, [
                                _c("i", {
                                  class: {
                                    "text-gray-300": !_vm.project.isAdmin,
                                    "cursor-pointer": _vm.project.isAdmin,
                                    "fa fa-check": props.row.hidden,
                                    "fa-regular fa-square": !props.row.hidden
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggle(props.row, "hidden")
                                    }
                                  }
                                })
                              ])
                            ]
                          }
                        },
                        {
                          key: "includeInDownload",
                          fn: function(props) {
                            return [
                              _c("div", { staticClass: "text-center" }, [
                                _c("i", {
                                  class: {
                                    "text-gray-300": !_vm.project.isAdmin,
                                    "cursor-pointer": _vm.project.isAdmin,
                                    "fa fa-check": props.row.includeInDownload,
                                    "fa-regular fa-square": !props.row
                                      .includeInDownload
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggle(
                                        props.row,
                                        "includeInDownload"
                                      )
                                    }
                                  }
                                })
                              ])
                            ]
                          }
                        },
                        {
                          key: "actions",
                          fn: function(props) {
                            return [
                              _c("div", { staticClass: "text-center" }, [
                                _c("i", {
                                  staticClass: "fa fa-download cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.download(props.row)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.project.isAdmin
                                  ? _c("i", {
                                      staticClass: "fa fa-trash cursor-pointer",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeClick(props.row)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1784277966
                    )
                  },
                  [
                    _c("template", { slot: "h__check" }, [
                      _c("span", { on: { click: _vm.onCheckAllClick } }, [
                        _vm.allChecked
                          ? _c("i", {
                              staticClass: "fa fa-check text-2xl cursor-pointer"
                            })
                          : _c("i", {
                              staticClass:
                                "fa-regular fa-square-full text-xl cursor-pointer"
                            })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "h__hidden" }, [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$t("Hidden")) + " "),
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.$t(
                                "Your files will not be accessible to users who are not admins."
                              ),
                              expression:
                                "$t('Your files will not be accessible to users who are not admins.')"
                            }
                          ],
                          staticClass: "fa-solid fa-circle-exclamation"
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "h__includeInDownload" }, [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$t("Inc. D/l")) + " "),
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.$t(
                                "Your files will be included in the download of your project if the user has access to them. Only admins can download hidden files."
                              ),
                              expression:
                                "$t('Your files will be included in the download of your project if the user has access to them. Only admins can download hidden files.')"
                            }
                          ],
                          staticClass: "fa-solid fa-circle-exclamation"
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "h__actions" }, [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$tc("common-action", 2)))
                      ])
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("file-upload", {
              attrs: {
                multiple: true,
                validMimeTypes: _vm.validMimeTypes,
                fileNameExcludes: _vm.tableData.map(r => r.fileName)
              },
              on: { getID: _vm.onGetModalID, filesUploaded: _vm.onUploadFiles }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }