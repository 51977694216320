<template>
  <modal name="organization"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "70%"
      height = "80%"
      :minWidth="400"
      :minHeight="560"
      :clickToClose = "false"
  >
    <div id="organizationModal">
      <ul class="tabs drag-handle">
        <li class="tab" @click="showTab='organization'">
          <a :class="{active: showTab == 'organization'}">{{organizationId ==null? $t('orgForm-create') : $t('orgForm-update')}} {{ organization && organization.sensorId}}</a></li>
        <li  v-if="organization && organization.id != null && isEditable"  @click="showTab='user'" class="tab"><a :class="{active: showTab =='user'}">{{$t('projectForm-users')}}</a></li>
        <div @click="close" class="iconButton link">
          <span class="ion-close"></span>
        </div>
      </ul>
      <div v-if="organization != null">
        <div class="modal-content padded40" v-show="showTab ==='organization'">
          <h4></h4>
          <div class="row">
            <div class="col s12 m6 l5">
              <label for="form_organization_name">{{$t('orgForm-acronym')}}
                <span class="info-icon ion-information-circled" v-tooltip="tooltips.name"></span>
              </label>
              <input id="form_organization_name" v-model="organization.name" type="text" class="validate" required :placeholder="$t('orgForm-orgName')">
            </div>
            <div class="col s12 m6 l7">
              <label for="formshortCode">{{$t('orgForm-fullName')}}
                <span  class="info-icon ion-information-circled" v-tooltip="tooltips.fullName"></span>
              </label>
              <input id="formshortCode" v-model="organization.fullName" type="text" class="validate" required :placeholder="$t('orgForm-fullName')">
            </div>
            <div class="col s12 m6">
              <label for="form_organization_institution">{{$t('orgForm-institution')}}
                <span class="info-icon ion-information-circled"
                  v-tooltip="tooltips.institution"/>
              </label>
              <input id="form_organization_institution" v-model="organization.institution" type="text" class="validate" required :placeholder="$t('orgForm-institutionName')">
            </div>
            <div v-if="organizationId != null" class="col s12 m6">
              <label class="investigator-text" for="form_organization_person1">{{$t('orgForm-primaryPI')}}</label>
              <span class="info-icon ion-information-circled" v-tooltip="tooltips.primary"></span>
              <select :disabled="organizationId == null" id="form_organization_person1" v-model="organization.principalInvestigatorUserId">
                  <option v-for="(admin, index) in adminUserList" :key="'primary'+index" :value="admin.id">{{admin.name}}</option>
                </select>
              </div>
              <div v-if="organizationId != null" class="col s12 m7">
              <label class="investigator-text" for="form_organization_person2">{{$t('orgForm-secondaryPI')}}</label>
              <span class="info-icon ion-information-circled" v-tooltip="tooltips.secondary"></span>
              <select :disabled="organizationId ==null" id="form_organization_person2" v-model="organization.alternateInvestigatorUserId">
                  <option v-for="(admin, index) in adminUserList" :key="'primary'+index" :value="admin.id">{{admin.name}}</option>
                </select>
              </div>
            <div class="col s12 m5">
              <label>{{$t('orgForm-defaultStorage')}}<span class="info-icon ion-information-circled" v-tooltip="tooltips.storage" /></label>
              <div class="vue-select-wrapper">
                <select v-model="organization.defaultStorageId">
                  <option v-for="(option, index) in options.storage" :key="index" :value="option.id">{{option.displayName}}</option>
                </select>
              </div>
            </div>
            <!--  <div class="col s12 m12 l7 xl6"> -->
                <div class="col s12 m5 clear-left">
              <label>{{$t('orgForm-defaultLocationBuffering')}}<span class="info-icon ion-information-circled" v-tooltip="tooltips.trueLocation" /></label>
              <label class="form-option">
                <input type=radio id="trueLocation" name="bufferLocation" :disabled="!isEditable" :value="true" v-model="organization.defaultIsTrueCoordinates"><span>{{$t('orgForm-trueLocations')}}</span>
              </label>
              <label class="form-option">
                <input type="radio" id="bufferedLocation" name="bufferLocation" :disabled="!isEditable" :value="false" v-model="organization.defaultIsTrueCoordinates"><span>{{$t('orgForm-bufferedLocations')}}</span>
              </label>
              </div>
              <div class="col s12 m7">
                <label><template v-if="organization.defaultIsTrueCoordinates">{{$t('orgForm-addDefaultBufferRadius')}}</template><template v-else>{{$t('orgForm-bufferRadiusUsed')}}</template>
                  <span v-if="organization.defaultIsTrueCoordinates" class="info-icon ion-information-circled" v-tooltip="tooltips.wildtraxBuffer" />
                  <span v-else class="info-icon ion-information-circled" v-tooltip="tooltips.userBuffer" />
                </label>
                <input id="org-buffer-radius" type="number" v-model="organization.defaultBufferRadius">
              </div>
              <div class="col s12 m6 l5">
              <label>{{$t('orgForm-defaultVisitImgAccess')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.visitImageAccess" /></label>
              <div class="vue-select-wrapper">
                <select v-model="organization.defaultVisitImageAccess">
                  <option v-for="(option, index) in options.visitImageAccess" :key="index" :value="option.id">{{option.type}}</option>
                </select>
              </div>
            </div>
            <div class="col s12 m5">
              <label>{{$t('orgForm-allowLocationReports')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.locationReports" /></label>
              <label class="form-option">
                <input type=radio id="enableLocationReports" name="enableLocationReports" :disabled="!isEditable" :value="true" v-model="organization.allowLocationReports"><span>{{$t('orgForm-allow')}}</span>
              </label>
              <label class="form-option">
                <input type="radio" id="disableLocationReports" name="disableLocationReports" :disabled="!isEditable" :value="false" v-model="organization.allowLocationReports"><span>{{$t('orgForm-dontAllow')}}</span>
              </label>
              </div>
            <!-- <div class="col s12">
              <label>{{$t('orgForm-facialBlurringTitle')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.facialBlurring" /></label>
              <label class="form-option">
                <input type=radio id="enableFacialBlurring" name="enableFacialBlurring" :disabled="!isEditable" :value="true" v-model="organization.blurHumanImages"><span>{{$t('orgForm-facialBlurringOptIn')}}</span>
              </label>
              <label class="form-option">
                <input type="radio" id="disableFacialBlurring" name="disableFacialBlurring" :disabled="!isEditable" :value="false" v-model="organization.blurHumanImages"><span>{{$t('orgForm-facialBlurringOptOut')}}</span>
              </label>
              <div v-if="organization.blurHumanImages === false" class="disclaimer">{{facialBlurringText}}</div>
            </div> -->
            <div class="col s12">
              <label>{{$t('Blurring')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.facialBlurring" /></label>
              <select v-model="organization.humanBlurringId">
                  <option v-for="(option, index) in options.blurring" :key="index" :value="option.id">{{option.type}}</option>
                </select>
            </div>

            <!--</div> -->
            <div class="col s12 ">
              <label for="form_organization_description" >{{$t('common-description')}}</label>
              <textarea id="form_organization_description" class="materialize-textarea" data-length="120" v-model="organization.description"></textarea>
              <span class="character-counter" style="float: right; font-size: 12px; height: 1px;"></span>
            </div>
          </div>
          <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
          <div class="modal-footer">
            <template v-if="isEditable" >
              <button class="btn btn-success" @click="saveOrganization($event, null)">
                <template v-if="!organizationId && !isGlobalAdmin">{{$t('orgForm-submitForApproval')}}</template>
                <template v-else>{{$t('common-save')}}</template>
              </button>
              <button v-if="organization.id > 0" @click="deleteOrganization" class="btn">
                {{$t('common-delete')}}
              </button>
              <button v-if="organization.id > 0 && organization.isPendingStatus && isGlobalAdmin" class="btn btn-success" @click="saveOrganization($event, false)">
                {{$t('orgForm-approve')}}
              </button>
              <!-- <button v-if="organization.id > 0 && !organization.isPendingStatus && isGlobalAdmin" class="btn" @click="saveOrganization($event, true)">
                {{$t('orgForm-changeToPending')}}
              </button> -->
            </template>
            <button class="btn btn-border modal-action modal-close" id="formcancelAdd" @click="close">{{$t('common-close')}}</button>
          </div>
        </div>
        <!-- add users -->



        <div class="modal-content padded20" v-show="organization.id != null && showTab =='user' && isEditable">

        <organization-user-assignment-table
          :users="formatUsersForTable(options.users)"
          :parentId="organizationId"
          parentIdLabel="organizationId"
          :roles="options.roles"
        />
        </div>
      
      
      </div>
    </div>
  </modal>
</template>

<script>
import {eventBus} from '@/lib/eventbus';
import {API_URL, alertDialog} from '@/lib/common';
import FormMixin from '@/components/common/FormMixin.js';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import OrganizationUserAssignmentTable from './OrganizationUserAssignmentTable.vue';

const ROLE_ADMIN = 1;
const ROLE_READ = 4;

export default {
  name: 'organization-form',
  props: ['organizationId', 'options', 'isEditable', 'isGlobalAdmin'],
  components: { 'multiselect': Multiselect, 'organization-user-assignment-table': OrganizationUserAssignmentTable },
  mixins: [FormMixin],
  data () {
    return {
      url: API_URL + 'get-organization-details-by-id',
      organizationUpdateUrl: API_URL + 'insert-update-organization',
      getOrgAdminUrl: API_URL + 'get-organization-admin-users',
      formName: 'organization',
      organization: null,
      organizationUserRoles: null,
      usersNotInOrganization: [],
      usersInOrganization: [],
      message: '',
      selectedUser: null,
      showTab: 'organization',
      errors: [],
      userOptionList: [],
      userGrantedList: [],
      adminUserList: [],
      state: {
        disabledDates: {
        }
      },
      recordName: this.$tc('common-organization', 1).toLowerCase(),
      tooltips: {
        /* -- user tab */
        'Admin': this.$t('orgForm-tooltips.admin'),
        'Read Access': this.$t('orgForm-tooltips.read'),
        /* organization tab */
        'institution': this.$t('orgForm-tooltips.institution'),
        'name': this.$t('orgForm-tooltips.name'),
        'fullName': this.$t('orgForm-tooltips.fullName'),
        'storage': this.$t('orgForm-tooltips.storage'),
        'visitImageAccess': this.$t('orgForm-tooltips.visitImageAccess'),
        'userBuffer': this.$t('orgForm-tooltips.userBuffer'),
        'wildtraxBuffer': this.$t('orgForm-tooltips.wildtraxBuffer'),
        'primary': this.$t('orgForm-tooltips.primary'),
        'secondary': this.$t('orgForm-tooltips.secondary'),
        'trueLocation': this.$t('orgForm-tooltips.trueLocation'),
        'locationReports': this.$t('orgForm-tooltips.locationReports'),
        'facialBlurring': this.$t('orgForm-tooltips.facialBlurring')
      },
      msg: {
        waitForApproval: this.$t('orgForm-messages.waitForApproval'),
        approved: this.$t('orgForm-messages.approved')
      },
      facialBlurringText: this.$t('orgForm-facialBlurringText')
    }
  },
  created () {
    this.loadOrganization(this.organizationId);
  },
  watch: {
    organizationId: function (newVal, oldVal) {
      if (newVal !== -1) {
        this.switchOrganization();
      }
    },
    usersNotInOrganization: function () {
      this.userOptionList = this.usersNotInOrganization.slice(0);
      // this.userOptionList.sort(function (a, b) {
      //   return (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);
      // });
    }
  },
  methods: {
    formatUsersForTable(users) {
      
      if (!users)
        return;

      return users.map(user=> {

        let inProject = (this.organizationUserRoles && this.organizationUserRoles.find(userrole => userrole.userId === user.id)) || false;
        return { 
            ...user,
          inProject: !!inProject,
          roles: inProject?inProject.roles:[],
          readonly: !!inProject && (inProject.roles.includes(ROLE_READ)||inProject.roles.includes(ROLE_ADMIN)),
          admin: !!inProject && inProject.roles.includes(ROLE_ADMIN)
        };

      });
    },
    switchOrganization () {
      this.showTab = 'organization';
      // console.log('organizationId =', this.organizationId, this.organization.id)
      if (this.organizationId !== null && this.organizationId > 0) {
        if (this.organizationId !== this.organization.id) { // only clear messages when organization id are different.
          this.errors = [];
        }
        this.loadOrganization(this.organizationId); // need to reload to get user lists
      } else {
        this.errors = [];
        this.initOrganization();
      }
    },
    initOrganization () {
      this.organization = {defaultStorageId: 2, defaultIsTrueCoordinates: true, allowLocationReports: false, blurHumanImages: true};
    },
    loadOrganization (organizationId) {
      if (organizationId) { // get organizationId from database if id is provided.
        this.$http.get(this.url, {params: {id: organizationId}}).then(function (response) {
          if (response.body.error || response.data.error) {
            alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view);
            this.close();
          } else {
            this.organization = response.data.organization;
            this.organizationUserRoles = response.data.organizationUserRoles;
            const self = this;
            this.usersNotInOrganization = this.options.users.filter(x => { return self.organizationUserRoles.find(y => y.userId === x.id) == null; });
            this.getAdminUsers(organizationId);
          }
        },
        function (error) {
          alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view + error.data);
          this.close();
        });
      } else {
        this.initOrganization();
      }
    },
    close () {
      this.errors = [];
      this.message = '';
      this.showTab = 'organization';
      eventBus.$emit('close-organization-form');
      this.$modal.hide('organization');
    },
    async saveOrganization (e, status) {
      /* validation */
      this.errors = [];
      if (!this.organization.name) {
        this.errors.push(this.$t('orgForm-errors.nameRequired'));
      }

      if (!this.organization.name.match(/^[\w\@\_\&\#\-\:\ \(\)]+$/)){
        this.errors.push(this.$t("Organization Acronym has an invalid character"));
      }

      if (!this.organization.fullName) {
        this.errors.push(this.$t('orgForm-errors.fullNameRequired'));
      }

      if (!this.organization.defaultIsTrueCoordinates && (this.organization.defaultBufferRadius < 25 || this.organization.defaultBufferRadius > 1000000)) {
        this.errors.push(this.$t('orgForm-errors.defaultBufferLimits'));
      }

      if (!this.organization.defaultIsTrueCoordinates && !this.organization.defaultBufferRadius) {
        this.errors.push(this.$t('orgForm-errors.bufferRadiusRequired'));
      }

      if (!this.organization.defaultIsTrueCoordinates && this.organization.defaultBufferRadius < 25) {
        this.errors.push(this.$t('orgForm-errors.bufferRadiusMin'));
      }

      if (!this.organization.defaultVisitImageAccess) {
        this.errors.push(this.$t('orgForm-errors.selectDefaultVisitImgAccess'));
      }
      if (!this.organization.humanBlurringId) {
        this.errors.push(this.$t('Image blurring option is required.'));
      }
      // no longer uses short code
      // if (!this.organization.shortCode) {
      //   this.errors.push('Short Code required.');
      // } else if (this.organization.shortCode.length > 10) {
      //   this.errors.push('Short Code need to be 1 - 10 letters.');
      // }

      if (this.errors.length > 0) {
        return;
      }
      if (status !== null) {
        this.organization.isPendingStatus = status;
      }
      // there are waiting for approval messages after creating.
      this.$http.post(this.organizationUpdateUrl,
        this.organization).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.errors.push(this.commonMsg.fail.update + response.data.error);
        } else {
          this.errors = [];
          if (this.organizationId > 0) {
            if (status === null) {
              this.errors.push(this.commonMsg.done.update);
            } else if (status === false) {
              this.errors.push(this.$t('orgForm-orgApproved'));
            } else if (status === true) {
              this.errors.push(this.$t('orgForm-orgRejected'));
            }
            eventBus.$emit('reload-table');
          } else {
            this.organization = response.data;
            if (this.isGlobalAdmin) {
              eventBus.$emit('organization-created');
              this.close();
            } else {
              eventBus.$emit('organization-created');
              this.close();
            }
          }
          // this.loadOrganization(response.data.id);
        }
      });
    },
    deleteOrganization () {
      const self = this;
      this.commonDelete(API_URL + 'delete-organization', this.organization, () => {
        eventBus.$emit('reload-table');
        self.close();
      }, ' ' + this.$t('orgForm-deleteMsg'));
    },
    switchRole (userId, roleId, organizationId, event) {
      let url = API_URL;
      if (event.target.checked) { // to add
        url += 'insert-organization-user-role';
      } else { // to remove
        url += 'delete-organization-user-role';
      }
      this.message = '';
      const params = { organizationId, userId, roleId };
      this.$http.post(url, params).then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.message = this.commonMsg.fail.update + response.data.error;
          } else {
            this.message = this.commonMsg.done.update;
          }
        });
    },
    addUser () {
      // step 1 add user to the list
      this.message = '';
      this.selectedUser.forEach(user => {
        this.organizationUserRoles.push({
          userId: user.id,
          userName: user.name,
          roles: [4] // 4 is id for readonly access
        });
        // step 2 add user with readonly role
        const params = { organizationId: this.organizationId, userId: user.id, roleId: 4 };
        this.$http.post(API_URL + 'insert-organization-user-role', params).then(response => {
          if (response.data.hasOwnProperty('error')) {
            this.message = this.commonMsg.fail.update + response.data.error;
          }
        }).catch(err => {
          console.error(err);
          this.message = err;
        });

        const index = this.usersNotInOrganization.find(x => x.id === user.id);
        if (index !== undefined) {
          this.usersNotInOrganization.splice(index, 1); // remove
        }
      });
      this.selectedUser = [];
    },
    // populate dropdown list
    async getAdminUsers (organizationId) {
      await this.$http.get(this.getOrgAdminUrl, {params: {orgId: organizationId}}).then(
        function (response) {
          if (!response.data || response.data.hasOwnProperty('error')) {
            this.adminUserList = [];
          } else {
            this.adminUserList = response.body;
          }
        }
      );
    }
  }
}
</script>
<style scoped>
input[type="radio"]+span{
  font-size: 0.8rem !important;
  padding-left: 26px !important;
}

.form-option{
  display:block;
}

select:disabled{
  background-color: #E2E2E2;
}

@media only screen and (max-width: 1340px) {
  .investigator-text{
    font-size: 0.7rem;
  }
}

.clear-left{
  clear: left;
}

#org-buffer-radius::-webkit-outer-spin-button,
#org-buffer-radius::-webkit-inner-spin-button{
  /* remove arrows */
  -webkit-appearance: none;
  margin: 0;
}

#org-buffer-radius{
  -moz-appearance: textfield;
}

.disclaimer{
  font-size: 0.8rem !important;
}

</style>
