<template>
<div class="container overflow vue" id="task-page" >
  <div class="container error padded60" v-if="error != null">
    <span v-if="error.statusText != null">
      {{error.statusText}}
    </span>
    <span v-else>
      {{error}}
    </span>
  </div>
  <div class="row" v-if="error==null && project!=null">
    <div class="col s12 m12" :class="{l8: showStats, xl9: showStats, l12: !showStats}" id="listeningPanel">
      <auto-complete class="!w-1/2 float-right z-10 pr-[29px] pl-[12px]" v-if="getOtherProjectUrl !=null"
                  :placeholder="$t('tasks-jump')"
                  :source= "getOtherProjectUrl"
                  initialValue=" "
                  initialDisplay=""
                  clearButtonIcon="ion-close"
                  name="id"
                  results-display="fullNm"
                  :request-headers="authHeaders"
                  @selected="redirectPage"
                >
                </auto-complete>
      <!-- top panel -->
      <div class="padded60 !pt-5 projectHeader !px-8 !pb-4 !pt-[30px]" :class="{narrowPanel: showStats}">
        <!-- <div v-if="project.isUserProjectMember" class="toggleWrap">
          <div id="toggleInfo">
              <span class="ion-eye-disabled" v-if="showStats" @click="showStats=false">{{$t('common-hideStats')}}</span>
              <span v-if="!showStats" class="ion-eye" @click="showStats=true">{{$t('common-showStats')}}</span>
          </div>
        </div> -->
        <p><a :href="breadcrumbHomeLink()">{{$t('common-home')}}</a> / <a :href="breadcrumbBaseLink() + 'CAM'">{{$t('common-camera')}}</a> /</p>
        <div class="row">
            <div class="col s12 m12 l12 xl6">
              <a  class="link" @click="updateProject()" v-if="project.isAdmin" :title="$t('tasks-edit')">
                    <h1 style="display:inline">{{project.fullNm}}</h1>
                  <span  class="ion-edit edit-project-icon"></span></a>
                <h1 v-else>{{project.fullNm}}</h1>

                <p class="pt-3"> 
                    <span v-if="project.isAdmin">
                      <span class="font-bold text-2xl">{{$t('tasks-projectStatus', {status: getProjectStatus()})}}</span>
                      <template v-if="isPublished">
                        <span class="ion-android-lock red-font"></span>
                        <span class="info-icon ion-information-circled" v-tooltip="publishedTooltip" />
                      </template>
                    </span>
                  </p>

                  <p class="font-bold pt-3">{{$t('Purpose and Methods')}}</p>
                  <p v-if="project.description && project.description.length>=descLimit" class="">
                    {{project.description.substring(0,descLength)}}<span v-show="descLength==descLimit">...</span>
                    <a @click="showMore" class="text-xs cursor-pointer">{{$t('common-showMoreLess', {amount: (descLength==descLimit?$t('common-more'):$t('common-less'))})}}</a>
                  </p>
                  <p v-else-if="project.description && project.description.length<descLimit" class="">{{project.description}}</p>
                  <p v-else class="text-red-400">{{$t('Please add a Purpose and Methods to help inform users about your project')}}</p>
                  <p class="font-bold pt-3">{{$t('Results Summary')}}</p>
                  <p v-if="project.results && project.results.length>=descLimit" class="">
                    {{project.results.substring(0,descLength)}}<span v-show="descLength==descLimit">...</span>
                    <a @click="showMore" class="text-xs cursor-pointer">{{$t('common-showMoreLess', {amount: (descLength==descLimit?$t('common-more'):$t('common-less'))})}}</a>
                  </p>
                  <p v-else-if="project.results && project.results.length<descLimit" class="">{{project.results}}</p>
                  <p v-else class="text-red-400">{{$t('Please add a Results Summary to help inform users about your project')}}</p>
                  

            </div>
            <div class="col s12 m12 l12 xl6" v-if="getOtherProjectUrl !=null">
              <div class="p-5 bg-white relative">
                  <location-map sensor="CAM" ref="map" v-bind:map-type="'project'" v-if="projectId" v-bind:category-id="projectId" :mapStyle="{height: '400px'}" /> 
                  <div class="absolute top-5 left-5 w-24 text-left z-30">
                    <a @click="setStyle('light-v10')"  class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block text-left !rounded-t !rounded-b-none"><i class="far  !text-xs" :class="{'fa-check-square': mapStyle=='light-v10', 'fa-square': mapStyle!='light-v10'}"></i> {{$t('common-light')}}</a>
                    <a @click="setStyle('satellite-streets-v11')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='satellite-streets-v11','fa-square': mapStyle!='satellite-streets-v11'}"></i> {{$t('common-satellite')}}</a> 
                    <a @click="setStyle('streets-v11')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='streets-v11','fa-square': mapStyle!='streets-v11'}"></i> {{$tc('common-street', 2)}}</a>
                    <a @click="setStyle('dark-v10')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-b !rounded-t-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='dark-v10','fa-square': mapStyle!='dark-v10'}"></i> {{$t('common-dark')}}</a>
                    <!-- <a @click="setStyle('outdoors-v11')" class="btn !text-xs !p-3 !py-1"><i class="far fa-square !text-xs" :class="{'fa-check-square': getStyle()=='outdoors-v11'}"></i> Outdoors</a>  -->
                  </div>
                </div>

            </div>
        </div>
       
      </div>
      <div class="row" v-if="warning!=null">
        {{warning}}
      </div>
      <!-- upload form -->
      <batch-upload-form ref="uploadForm" :organization-id="project.organizationId" v-bind:is-abmi="isAbmi" v-bind:project-id="projectId" v-bind:aws-param="AWSParam" v-if="showUploadForm && AWSParam.bucket != null"/>
      <!-- tab area -->
      <section class="tabbed !pt-0">
        <ul class="tabs">
          <li class="tab" @click="clickTab('task')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'task', disabledTab: !project.hasTaskAccess}"><span class="ion-ios-camera"></span>{{$tc('common-imageSet', 2)}}</a></li>
          <li class="tab" @click="clickTab('species')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'species', disabledTab: !project.hasTaskAccess}"><span class="ion-ios-paw"></span><template v-if="project.isUserProjectMember">{{$t('tasks-verifySpecies')}}</template><template v-else>{{$tc('common-species', 2)}}</template></a></li>
          <!-- <li class="tab" @click="clickTab('agreement')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'agreement', disabledTab: !project.hasTaskAccess}"><span class="ion-ios-copy-outline"></span> {{$t('tasks-userAgreement')}}</a></li> -->
          <!-- <li class="tab" @click="clickTab('map')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'map', disabledTab: !project.hasMapAccess}"><span class="ion-map"></span> {{$t('common-map')}}</a></li> -->
          <li class="tab" @click="clickTab('files')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'files', disabledTab: project.hasFileAccess }"><i class="fa fa-file"></i> {{$tc('common-file',2)}}</a></li>
        </ul>
        <div class="padded60 boxed dataGroupv border-gray-400 border-2">
          <task-table ref="task" v-if="project.isAdmin != null && options != null && userList != null" v-show="activeTab=='task'"
            v-bind:project="project"
            v-bind:project-id="projectId" v-bind:sensor-id="project.sensorId" v-bind:has-project-write-access="project.hasProjectWriteAccess"
            v-bind:is-manager="project.isAdmin" v-bind:isUserProjectMember="project.isUserProjectMember" v-bind:filter-options="options"  v-bind:user-list="userList"
            v-bind:show-auto-tag-button="project.cameraAutoReadyExists" v-bind:is-published="isPublished" v-bind:project-name="project.fullNm"
            :has-assigned-tasks="project.isAssignedTask"
            />
          <species-table ref="species-table" v-if="project.isAdmin != null && speciesOptions != null && userList != null" v-show="activeTab=='species'"
            v-bind:project-id="projectId"
            v-bind:is-manager="project.isAdmin" v-bind:filter-options="speciesOptions" v-bind:user-list="userList" v-bind:statusId="project.status"/>

          <!-- <agreement v-if="project.isAdmin == true" v-show="activeTab=='agreement'" v-bind:project-id="projectId" v-bind:user-profile="userProfile"
            v-bind:statusId="project.status" /> -->
          <files v-show="activeTab=='files'" :projectId="projectId" :project="project" :user-list="userList"></files>
        </div>
      </section>
    </div>
    <div class="col s12 m12 l4 xl3" v-if="showStats">
      <label>{{$t('tasks-stats')}}</label>
      <stats :url="statUrl" customClass="row" customDivClass="col s6 m4 l4" :is-wrapped="true"/>
    <!--  <div class="row">
        <div class="col s12 m6 l12">
          <div class="boxed padded40 panel">
            <h5>My Most Recent Tags</h5>
            <ul class="linkList">
            </ul>
          </div>
        </div>
        <div class="col s12 m6 l12">
          <div class="boxed padded40 panel">
            <h5>High Profile Tags</h5>
            <ul class="linkList">
            </ul>
          </div>
        </div>
      </div>-->
      <div class="note warning panel"> <span class="ion-ios-close-empty dismiss"></span></div>
    </div>
  </div>
  <v-dialog/>
  <modal name="project"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "98%"
      height = "99%"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
    <project-form v-if="projectId != null" v-bind:project-id="projectId" v-bind:active-sensor="project.sensorId" v-bind:options="projectFormOptions"
      />
  </modal>
</div>
</template>

<script>
/* this component show two tabs + stats + notification for all camera projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
import TaskTable from './CameraTaskTable';
import TaskSpecies from './CameraTaskSpecies';
import BatchUploadForm from './imageUpload/CameraTaskUploadForm';
import Autocomplete from 'vuejs-auto-complete';
import StatsBox from '@/components/common/StatsBox';
import ProjectAgreementTab from './ProjectAgreementTab';
import ProjectForm from '@/components/project/ProjectForm';
import TaskPageMixin from './TaskPageMixin';
import {API_URL, getAuthorizaitonHeader} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import CommonPageMixin from '@/components/common/CommonPageMixin';
import FilesTab from './FilesTab.vue';

export default {
  name: 'task-page',
  mixins: [TaskPageMixin, CommonPageMixin],
  components: {
    'task-table': TaskTable,
    'species-table': TaskSpecies,
    'auto-complete': Autocomplete,
    'stats': StatsBox,
    'agreement': ProjectAgreementTab,
    'batch-upload-form': BatchUploadForm,
    'project-form': ProjectForm,
    'files': FilesTab
  },
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    }
  },
  methods: {
     displayDescription() {
      return this.project.description.replaceAll('\n', '<br/>');
    },

    showMore () {
      if (this.descLength === this.descLimit) {
        this.descLength = 10000;
      } else {
        this.descLength = this.descLimit;
      }
    },
    
    /*
    Handle clicking on a tab
    only allows switching if user has permission
    */
    clickTab (tabName) {
      switch (tabName) {
        case 'task':
          if (this.project.hasTaskAccess) {
            this.activeTab = 'task';
          }
          break;
        case 'species':
          if (this.project.isAdmin || this.project.hasTaskAccess) {
            this.activeTab = 'species';
          }
          break;
        case 'agreement':
          if (this.project.isAdmin || this.project.hasTaskAccess) {
            this.activeTab = 'agreement';
          }
          break;
        case 'map':
          if (this.project.hasMapAccess) {
            this.activeTab = 'map';
          }
          break;
        case 'files':
          //if (this.project.hasFileAccess) {
            this.activeTab = 'files';
          //}
          break;
      };
    },
    loadSpeciesOption () {
      this.$http.get(this.getSpeciesOptionUrl).then((response) => {
        let sppOption = response.data;
        this.speciesOptions = {};
        if (sppOption.ProjectSpeciesStatus) {
          this.speciesOptions.ProjectSpeciesStatus = sppOption.ProjectSpeciesStatus.map(x => { return {id: x.text, text: x.text}; });
        } else {
          this.speciesOptions.ProjectSpeciesStatus = [];
        }
      }, (err) => {
        // error callback
        this.warning = this.$t('tasks-filterWarning') + err;
      });
    }
  },
  watch: {
    projectId (newVal, oldVal) {
      if (newVal !== oldVal && newVal != null && oldVal != null) {
        this.loadSpeciesOption();
        this.$refs.uploadForm && this.$refs.uploadForm.resetBatchUpload();
        this.$refs.uploadForm && this.$refs.uploadForm.resetBatchUploadSetting();
      }
    }
  },
  created () {
    this.loadSpeciesOption();
    const self = this;

    eventBus.$on('show-upload-form', function (show) {
      self.toggleUploadForm(show);
    });

    eventBus.$on('reload-task-table-option', function () {
      self.loadTaskOption();
    });

    eventBus.$on('open-batch-upload', function () {
      self.toggleUploadForm(true);
    });

    // inclucde exif script
    // let recaptchaScript = document.createElement('script');
    // recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/exif-js');
    // document.head.appendChild(recaptchaScript);
    
  },
  mounted() {
    this.clickTab('files');
  },
  data () {
    return {
      // getTaskOptionUrl: API_URL + 'get-task-options?projectId=',
      // options: null, // {transcriber: [], taskStatus: [] },

      // getProjectBasicInfoUrl: API_URL + 'project-basic-details',
      // project: { isAdmin: null },
      getSpeciesOptionUrl: API_URL + 'get-project-species-options',
      speciesOptions: null,
      expectedSensorType: 'CAM',
      uploadFormName: 'upload-camera-form',
      descLimit: 50,
      descLength: 50
    }
  }
}
</script>
<style scoped>
#task-page .autocomplete__icon {
    height: 0px;
    width: 0px;
}
#task-page .autocomplete__box {
  border: 0px solid #ccc;
}

.edit-project-icon {
  display: inline-block;
  vertical-align: top;
  padding: 1rem;
}

.disabledTab{
  pointer-events: none;
  cursor: default !important;
  color: #7D7D7D !important;

}
</style>
