import { render, staticRenderFns } from "./BatchTableUploadPreview.vue?vue&type=template&id=56b21d81&scoped=true"
import script from "./BatchTableUploadPreview.vue?vue&type=script&lang=js"
export * from "./BatchTableUploadPreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b21d81",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/exile/Extra/Work/Repos/wildtrax/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56b21d81')) {
      api.createRecord('56b21d81', component.options)
    } else {
      api.reload('56b21d81', component.options)
    }
    module.hot.accept("./BatchTableUploadPreview.vue?vue&type=template&id=56b21d81&scoped=true", function () {
      api.rerender('56b21d81', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/task/audioUpload/BatchTableUploadPreview.vue"
export default component.exports