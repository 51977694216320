<template>
    <div class="grid grid-cols-5 gap-2 mx-5">
      <div class="input" v-if="speciesCode == null && options.species">
        <multiselect :selectAllLabel="$t('common-selectAll')" v-model="filter.speciesCode" :options="options.species"
          :multiple="true" :close-on-select="false" @input="updateSpeciesFilter()" :placeholder="$tc('common-species', 2)"
          label="commonName" track-by="id" selectLabel="" deselectLabel=" " class="input">
          <template slot="selection" slot-scope="{ values, search, isOpen }">
            <span class="multiselect__single" v-if="initSpeciesSelected">{{ initSpeciesName }}</span>
            <template v-else>
              <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{
                $tc('common-numSpecies', values.length, { num: values.length }) }}</span>
              <span class="multiselect__single" v-if="values.length == 1 && !isOpen">{{ values[0].commonName }}</span>
            </template>
          </template>
        </multiselect>
      </div>

      <!-- second row -->

 

      <div class="input" v-if="isNotGapMode">
        <select v-model="filter.rating" id="searchRating">
          <option value="">-- {{ $t('taggingFilter-selectRating') }} --</option>
          <option v-for="(v, i) in [1, 2, 3, 4, 5]" :key="i">{{ v }}</option>
        </select>
      </div>

      <div class="input" v-if="isNotGapMode">
        <VueCtkDateTimePicker :locale="$root.$i18n.locale" id="startTime2" :overlay="true" :class="{ dtpicker: true }"
          v-model="startTimeStr" format="YYYY-MM-DD HH:mm" formatted="llll" :hint="$t('taggingFilter-selectStartTime')"
          :label="$t('taggingFilter-selectStartTime')"
          :minDate="filterMode === 'tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.startDate ? deploymentInfo.imageSetSummary.startDate.substring(0, 10) : null"
          :maxDate="filterMode === 'tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.endDate ? deploymentInfo.imageSetSummary.endDate.substring(0, 10) : null">
        </VueCtkDateTimePicker>

      </div>

      <div class="input" v-if="isNotGapMode">
        <VueCtkDateTimePicker :locale="$root.$i18n.locale" id="endTime" :class="{ dtpicker: true }" v-model="endTimeStr"
          format="YYYY-MM-DD HH:mm" :hint="$t('taggingFilter-selectEndTime')" :label="$t('taggingFilter-selectEndTime')"
          :minDate="filterMode === 'tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.startDate ? deploymentInfo.imageSetSummary.startDate.substring(0, 10) : null"
          :maxDate="filterMode === 'tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.endDate ? deploymentInfo.imageSetSummary.endDate.substring(0, 10) : null">
        </VueCtkDateTimePicker>



      </div>
      <div class="input">
        <select v-model="filter.abundanceId" id="abundanceId">
          <option value="">-- {{ $t('Select Abundance') }} --</option>
          <option v-for="(v, i) in Object.values(staticOptions.abundance)" :value="v.id" :key="i">{{ v.type }}</option>
        </select>
      </div>
      <div class="input">
        <select v-model="filter.vocalizationId" id="vocalizationId">
          <option value="">-- {{ $t('Select Vocalization Type') }} --</option>
          <option v-for="(v, i) in Object.values(staticOptions.vocalization)" :value="v.id" :key="i">{{ v.type }}</option>
        </select>
      </div>
      <div class="input" v-if="isNotGapMode">
        <label for="searchReview">
          <input type="checkbox" id="searchReview" v-model="filter.needsReview" />
          <span>{{ $t('common-needsReview') }}</span>
        </label>
      </div>
      <div class="input" v-if="speciesCode != null && isNotGapMode">
        <label for="searchVerification">
          <input type="checkbox" id="searchVerification" v-model="filter.tmpNotIsVerified" />
          <span>{{ $t('taggingFilter-needsVerification') }}</span>
        </label>
      </div>


      
      <div class="input" v-if="filterMode == 'pole'">
        <button type="button" id="applyFilters" class="btn" @click="filterImages()">
          <span class="ion-android-funnel"></span> {{ $t('taggingFilter-getRandom') }}
        </button>
      </div>
      <div class="input" v-if="filterMode == 'gap'">
        <button type="button" id="applyFilters" class="btn" @click="filterImages()">
          <span class="ion-android-funnel"></span> {{ $t('taggingFilter-assessGaps') }}
        </button>
      </div>
      <div class="input grid grid-cols-2 gap-2" v-if="isNotGapMode">
        <button type="button" id="applyFilters" class="btn" @click="filterImages()">
          <span class="ion-android-funnel"></span> {{ $t('common-update') }}
        </button>
          <button type="button" id="applyFilters" class="btn" @click="clearFilter()">
            <span class="ion-android-refresh"></span> {{ $t('common-clear') }}
          </button>
      </div>
   
    </div>
</template>

<script>
/* this component is the camera tagging Filter page.
it takes user into and converted them to query parameters and then pass back
*/
import { eventBus } from '@/lib/eventbus';
// import VueCtkDateTimePicker from '@/components/Test/test'; //
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
//  import VueCtkDateTimePicker from '@/components/Test//VueCtkDateTimePicker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import moment from 'moment';

export default {
  name: 'aru-species-verify-filter',
  props: ['options', 'staticOptions', 'deploymentInfo', 'speciesCode', 'vocalizationId', 'filterMode', 'useSeriesView', 'initspeciesCode'],
  components: { 'VueCtkDateTimePicker': VueCtkDateTimePicker, 'multiselect': Multiselect },
  computed: {
    isNotGapMode() {
      return (this.filterMode !== 'pole' && this.filterMode !== 'gap')
    }
  },
  created() {
    window.staticOptions = this.staticOptions;
  },
  methods: {
    filterImages() {
      /* convert selections object into arraies of ids */
      this.queryParam = {};
      if (this.filterMode !== 'fov') {
        // dont apply the filter to fov
        this.queryParam = Object.assign(this.queryParam, this.filter);
        /* when it is at species view */
        if (this.speciesCode) {
          this.queryParam.speciesCode = this.speciesCode;
          // this.queryParam.speciesCode.push(this.speciesCode);
        }
        if (this.vocalizationId) {
          this.queryParam.vocalizationId = this.vocalizationId;
          // this.queryParam.speciesCode.push(this.speciesCode);
        }
        this.queryParam.sexId = this.filter.sexId ? this.filter.sexId.map(opt => opt.id) : null;

        this.queryParam.ageId = this.filter.ageId ? this.filter.ageId.map(opt => opt.id) : null;
        this.queryParam.triggerModeId = this.filter.triggerModeId ? this.filter.triggerModeId.map(opt => opt.id) : null;
        this.queryParam.fovId = this.filter.fovId ? this.filter.fovId.map(opt => opt.id) : null;
        /* filter needs verified on UI, but filter isVerified on backend, use opposite value */
        if (this.filter.tmpNotIsVerified) {
          this.queryParam.isVerified = !this.filter.tmpNotIsVerified;
        }

        if (this.deploymentInfo && this.deploymentInfo.imageSetSummary) {
          // don't put start in query considtion when it is same a the time range.
          if (this.deploymentInfo.imageSetSummary.startDate && this.deploymentInfo.imageSetSummary.startDate.substring(0, this.deploymentInfo.imageSetSummary.startDate.length - 3) !== this.startTimeStr) {
            this.queryParam.dateGT = moment(this.startTimeStr).format('YYYY-MM-DD 00:00:00');
          }
          if (this.deploymentInfo.imageSetSummary.endDate && this.deploymentInfo.imageSetSummary.endDate.substring(0, this.deploymentInfo.imageSetSummary.endDate.length - 3) !== this.endTimeStr) {
            this.queryParam.dateLT = moment(this.endTimeStr).format('YYYY-MM-DD 23:59:59');
          }
        } else {
          if (this.startTimeStr) { this.queryParam.dateGT = moment(this.startTimeStr).startOf('day').format('YYYY-MM-DD HH:mm:ss'); }
          if (this.endTimeStr) { this.queryParam.dateLT = moment(this.endTimeStr).endOf('day').format('YYYY-MM-DD HH:mm:ss'); }
        }

        if (this.useSeriesView) {
          this.queryParam.useSeriesView = true;
          this.queryParam.limit = 999;
        } else {
          this.queryParam.limit = 80;
        }
      }
      // const self = this;

      // this.queryParam.individualCount = this.filter.individual;
      // this.queryParam.isNice = this.filter.isFeatured;
      // this.queryParam.isFire = this.filter.isFire;
      // this.queryParam.needReview = this.filter.needReview;
      // this.queryParam.startTimeStr = this.filter.startTime;
      // this.queryParam.endTimeStr = this.filter.endTime;

      eventBus.$emit('apply-filter', this.queryParam);
      this.closeForm();
    },
    getDefaultTriggerMode() {
      return this.staticOptions.triggerMode.filter(x => { return x.id === 2 || x.id < 0; });
    },
    clearFilter() {
      this.filter = { triggerModeId: [], speciesCode: [], rating: "", abundanceId: "", vocalizationId: "" };
      // if (this.speciesCode) {
      //  try {
      //     this.filter.speciesCode.push(this.staticOptions.allSpecies.find(x => x.id == this.speciesCode));
      //   } catch (e) { console.log(e)}
      // }
      /* no filter for FOV mode */
      if (this.filterMode === 'series') {
        /* hide auto tagger and show motion only,
        since series view don't show motion trigger input,
        so hard code it when clear filter. */
        this.filter.triggerModeId = this.getDefaultTriggerMode();
      }
      this.startTimeStr = undefined;
      this.endTimeStr = undefined;
      if (this.filterMode !== 'tag' && this.filterMode !== 'fov') {
        return;
      }

      //    console.log('species obj', this.speciesCode, ' matched ' this.staticOptions.allSpecies.find(x => x.id == this.speciesCode));
      let clearBtns = document.getElementsByClassName('field-clear-button');
      console.log(clearBtns);
    },
    /*
    Views:
      Tag (Tagging): exclude auto tagged, trigger mode default to motion trigger and unknown.
      subsample: exclude auto tagged, trigger mode default to motion trigger and unknown.
      series view: exclude auto tagged, trigger mode default to motion trigger and unknown.
      field of view: exclude auto tagged.
    */
    resetFilter() {
      this.clearFilter();
      /* no filter for FOV mode */
      if (this.speciesCode || this.filterMode === 'fov') { /* fov show all images */
        this.filter.excludeAutoTag = false;
      } else if (this.filterMode === 'tag' || this.filterMode === 'series') { /* hide auto tagger and show motion only */
        this.filter.excludeAutoTag = true;
        this.filter.triggerModeId = this.getDefaultTriggerMode();
      }
    },
    closeForm() {
      eventBus.$emit('toggle-form', 'showFilter', false);
    },
    getInitSpeciesName() {
      for (let i = 0; this.options.species.length; i++) {
        if (this.options.species[i].id === this.initspeciesCode) {
          return this.options.species[i].commonName;
        }
      }
    },
    updateSpeciesFilter() {
      this.initSpeciesSelected = false;
      //  let params = new URLSearchParams(window.location.search.slice(1));
      // remove all params except the id
      //  window.history.pushState({}, document.title, window.location.href.split('?')[0] + '?id=' + params.get('id'));
    }
  },

  mounted() { // set default filter
    this.resetFilter();
    /* for initial query parameters */
    if (this.initspeciesCode) {
      this.filter.speciesCode = [{ id: this.initspeciesCode }];
    }
    if (this.initspeciesCode) {
      // set the name in the filter
      this.initSpeciesName = this.getInitSpeciesName();
      this.initSpeciesSelected = true;
    }
    // this.filterImages();
  },
  data() {
    return {
      filter: { triggerModeId: [], speciesCode: [], rating: "", abundanceId: "", vocalizationId: "" },
      queryParam: {},
      /* need this two parameters to track start end time from calendar component
        if use filter.startTime the components won't update the display. although values were
        set but on page, still shows old time.
      */
      startTimeStr: null,
      endTimeStr: null,
      initSpeciesName: null,
      initSpeciesSelected: false
    }
  }
}
</script>
<style scoped>
.remote-camera .filterDrop .container .filterWrap .input label {
  display: block;
}

.remote-camera .filterDrop,
.remote-camera .searchDrop {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.dtpicker input {
  font-size: 0.9em !important;
}

.dtpicker label.field-label {
  color: #C94412 !important;
  font-size: 0.9em !important;
  top: 0px !important;
}

input.individualCount {
  width: 150px !important;
  height: 45px !important;
  margin-top: 10px !important;
}

.input {
  height: 60px;
}

.filter-link {
  color: #C94412;
}
</style>
