var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue remote-camera camera-tagging no-top-sticky-menu" },
    [
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _vm.error.statusText != null
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.error.statusText) + "\n    ")
                ])
              : _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _vm.message
          ? _c(
              "span",
              { staticClass: "error", staticStyle: { "pading-right": "20px" } },
              [
                _vm._v(_vm._s(_vm.message) + " "),
                _c("span", {
                  staticClass: "ion-android-close link",
                  on: {
                    click: function($event) {
                      _vm.message = null
                    }
                  }
                })
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.error == null
        ? [
            _vm.deploymentDetails != null
              ? _c("div", [
                  _c("div", { staticClass: "container large" }, [
                    _c("div", { staticClass: "row abmi-breadcrumb" }, [
                      _c("div", { staticClass: "col s7" }, [
                        _c("p", [
                          _c(
                            "a",
                            { attrs: { href: _vm.breadcrumbHomeLink() } },
                            [_vm._v(_vm._s(_vm.$t("common-home")))]
                          ),
                          _vm._v(" / "),
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.breadcrumbBaseLink() + "CAM" }
                            },
                            [_vm._v(_vm._s(_vm.$t("common-camera")))]
                          ),
                          _vm._v(" /\n                    "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.breadcrumbCAMTAGGINGProjectLink()
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.deployment &&
                                    _vm.deployment.project.fullNm
                                )
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s5 pull-right" }, [
                        _c(
                          "p",
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("common-status")) +
                                  ": " +
                                  _vm._s(
                                    _vm.deploymentDetails.status &&
                                      _vm.deploymentDetails.status.type
                                  ) +
                                  " "
                              )
                            ]),
                            _vm._v(" "),
                            _vm.deploymentDetails.canUserModify &&
                            _vm.deploymentDetails.status &&
                            (_vm.ui.activeTab == "tag" ||
                              _vm.ui.activeTab == "fov") &&
                            _vm.isFullyTagged
                              ? [
                                  _vm.deploymentDetails.status.type ==
                                  "Ready for Tagging"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "btn btn-success",
                                          on: {
                                            click: function($event) {
                                              return _vm.moveToPhase(1)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cameraTagging-moveToTaggingComplete"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.deploymentDetails.status.type ==
                                  "Tagging Complete"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "btn btn-success",
                                          on: {
                                            click: function($event) {
                                              return _vm.moveToPhase(2)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cameraTagging-moveToReadyForTagging"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "stickyWrap",
                      attrs: { id: "topStickyBar" }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "sticky",
                              rawName: "v-sticky",
                              value: {
                                zIndex: 10,
                                stickyTop: 0,
                                stickTopOnly: true,
                                positionType: "fixed",
                                stickyContainerId: "topStickyBar"
                              },
                              expression:
                                "{zIndex: 10, stickyTop: 0, stickTopOnly: true, positionType: 'fixed', stickyContainerId: 'topStickyBar'}"
                            }
                          ],
                          staticClass: "sticky",
                          staticStyle: { position: "static" },
                          attrs: { id: "topStickyBarContent" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "container large taskHeader" },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col s12 m12 l8 title" },
                                  [
                                    _c("div", [
                                      _vm.deploymentDetails != null &&
                                      _vm.deployment != null
                                        ? _c("h1", [
                                            _vm._v(
                                              _vm._s(_vm.getDeploymentName)
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "actionGroup" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "editLink",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleDialog(
                                                    _vm.ui.helpFormName,
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "ion-help-circled"
                                              }),
                                              _vm._v(
                                                _vm._s(_vm.$t("common-help"))
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.deploymentDetails.canUserModify
                                  ? _c(
                                      "div",
                                      { staticClass: "col s12 m12 l4 filters" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _vm.ui.activeTab === "fov"
                                            ? _c(
                                                "div",
                                                {
                                                  class: {
                                                    "col s6 right":
                                                      _vm.$refs.tag &&
                                                      _vm.$refs.tag
                                                        .selectedImageCount > 0,
                                                    "col s6 offset-s6":
                                                      !_vm.$refs.tag ||
                                                      _vm.$refs.tag
                                                        .selectedImageCount == 0
                                                  }
                                                },
                                                [
                                                  _vm.isFOVVerified !== true
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-border fullWidth modal-trigger projectAdd",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.markFOVAsVerified()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "ion-ios-pricetag-outline"
                                                          }),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "cameraTagging-verifyFieldOfView"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "right green-font"
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "ion-checkmark"
                                                          }),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "cameraTagging-fieldOfViewVerified"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "col s6": !_vm.isFullyTagged,
                                                "col s6 offset-s6":
                                                  _vm.isFullyTagged
                                              }
                                            },
                                            [
                                              _vm.$refs.tag &&
                                              _vm.$refs.tag.selectedImageCount >
                                                0
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btnImportant fullWidth modal-trigger projectAdd",
                                                      attrs: {
                                                        href: "#tagCameraModal"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.tagSelected()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "ion-ios-pricetag-outline"
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "common-tagSelected"
                                                            )
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                      _c(
                                                        "span",
                                                        { staticClass: "qty" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$refs.tag
                                                                .selectedImageCount
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.isFullyTagged &&
                                          _vm.ui.activeTab !== "fov"
                                            ? _c(
                                                "div",
                                                {
                                                  class: {
                                                    "col s6":
                                                      _vm.$refs.tag &&
                                                      _vm.$refs.tag
                                                        .selectedImageCount > 0,
                                                    "col s6 offset-s6":
                                                      !_vm.$refs.tag ||
                                                      _vm.$refs.tag
                                                        .selectedImageCount == 0
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-border fullWidth modal-trigger projectAdd",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.tagAllUntagged()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "ion-ios-pricetag-outline"
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "cameraTagging-updateAllUntagged"
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "container large taskheader" },
                    [
                      _vm.ui.activeTab != "map" &&
                      _vm.ui.activeTab != "photos" &&
                      _vm.deployment &&
                      _vm.$refs.tag &&
                      _vm.$refs.tag.imageList &&
                      _vm.$refs.tag.imageList.length > 0
                        ? _c("weather-segment", {
                            attrs: {
                              projectId: _vm.deployment.project.id,
                              "date-string": _vm.$refs.tag.imageList[0].date.split(
                                "."
                              )[0],
                              "location-id": _vm.deployment.location.id,
                              "site-latitude": _vm.deployment.location.latitude,
                              "site-longitude":
                                _vm.deployment.location.longitude,
                              "show-date": true
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.deploymentDetails != null
              ? _c("div", { staticClass: "container overflow" }, [
                  _c("section", { staticClass: "tabbed" }, [
                    _c("ul", { staticClass: "tabs" }, [
                      _c(
                        "li",
                        {
                          staticClass: "tab",
                          class: {
                            "!border-gray-400 !border-2 !border-b-0":
                              _vm.ui.activeTab == "tag",
                            tag: true
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchTab("tag")
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { class: { "": _vm.ui.activeTab == "tag" } },
                            [
                              _c("span", { staticClass: "ion-ios-camera" }),
                              _vm._v(
                                _vm._s(_vm.$t("cameraTagging-fullTagging")) +
                                  " "
                              ),
                              _vm.isFullyTagged === true
                                ? [_c("span", { staticClass: "ion-checkmark" })]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "tab",
                          class: {
                            "!border-gray-400 !border-2 !border-b-0":
                              _vm.ui.activeTab == "series",
                            series: true
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchTab("series")
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { class: { "": _vm.ui.activeTab == "series" } },
                            [
                              _c("span", { staticClass: "ion-ios-camera" }),
                              _vm._v(
                                _vm._s(_vm.$t("cameraTagging-seriesView")) + " "
                              ),
                              _vm.isFullyTagged === true
                                ? [_c("span", { staticClass: "ion-checkmark" })]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isFOVVisible
                        ? _c(
                            "li",
                            {
                              staticClass: "tab",
                              class: {
                                "!border-gray-400 !border-2 !border-b-0":
                                  _vm.ui.activeTab == "fov",
                                fov: true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.switchTab("fov")
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: {
                                    "!border-gray-400 border-2 border-b-0":
                                      _vm.ui.activeTab == "fov",
                                    fov: true
                                  }
                                },
                                [
                                  _c("span", { staticClass: "ion-ios-camera" }),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("cameraTagging-fieldOfView")
                                    ) + " "
                                  ),
                                  _vm.isFOVVerified === true
                                    ? [
                                        _c("span", {
                                          staticClass: "ion-checkmark"
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployment &&
                      _vm.deployment.location.latitude &&
                      _vm.deployment.location.longitude
                        ? _c(
                            "li",
                            {
                              staticClass: "tab",
                              class: {
                                "!border-gray-400 !border-2 !border-b-0":
                                  _vm.ui.activeTab == "map",
                                map: true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.switchTab("map")
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: {
                                    "!border-gray-400 border-2 border-b-0":
                                      _vm.ui.activeTab == "map",
                                    map: true
                                  }
                                },
                                [
                                  _c("span", { staticClass: "ion-map" }),
                                  _vm._v(_vm._s(_vm.$t("common-map")))
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployment && _vm.deployment.hasLocationVisitImages
                        ? _c(
                            "li",
                            {
                              staticClass: "tab",
                              class: {
                                "!border-gray-400 !border-2 !border-b-0":
                                  _vm.ui.activeTab == "photos",
                                photos: true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.switchTab("photos")
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: {
                                    "!border-gray-400 border-2 border-b-0":
                                      _vm.ui.activeTab == "photos",
                                    photos: true
                                  }
                                },
                                [
                                  _c("span", { staticClass: "ion-ios-camera" }),
                                  _vm._v(
                                    _vm._s(_vm.$t("common-locationPhotos"))
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "padded boxed dataGroup !border-gray-400 border-2 tab-content",
                        class: { [_vm.ui.activeTab]: true }
                      },
                      [
                        _vm.staticOptions != null &&
                        _vm.deploymentOptions &&
                        _vm.deployment &&
                        typeof _vm.deploymentDetails.project != "undefined"
                          ? _c("tagging-filter", {
                              ref: "filter",
                              attrs: {
                                options: _vm.deploymentOptions,
                                "static-options": _vm.staticOptions,
                                "init-species-id": _vm.initialSpeciesId,
                                "filter-mode": _vm.ui.activeTab,
                                "deployment-info": _vm.deployment,
                                project: _vm.deploymentDetails.project,
                                "use-series-view": _vm.ui.activeTab == "series",
                                "mega-detector-categories":
                                  _vm.megaDetectorCategories
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.deployment && _vm.staticOptions
                          ? _c("tagging-gallery", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.ui.activeTab == "tag" ||
                                    _vm.ui.activeTab == "fov" ||
                                    _vm.ui.activeTab == "series",
                                  expression:
                                    "ui.activeTab =='tag' || ui.activeTab =='fov' || ui.activeTab == 'series'"
                                }
                              ],
                              ref: "tag",
                              attrs: {
                                "deployment-id": _vm.userDeploymentId,
                                "static-options": _vm.staticOptions,
                                "filter-mode": _vm.ui.activeTab,
                                "is-tagger":
                                  _vm.deploymentDetails.canUserModify,
                                project: _vm.deploymentDetails.project,
                                "series-gap": _vm.seriesGap,
                                "mega-detector-categories":
                                  _vm.megaDetectorCategories
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.deployment || !_vm.staticOptions,
                                expression: "!deployment || !staticOptions"
                              }
                            ],
                            staticClass: "overaly"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "my-24",
                                staticStyle: { "text-align": "center" }
                              },
                              [
                                _c("pulse-loader", {
                                  attrs: {
                                    loading: true,
                                    color: "#C94412",
                                    size: "20px",
                                    width: 400,
                                    height: 400
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("Loading Images")) + " "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ui.activeTab == "map",
                                expression: "ui.activeTab =='map'"
                              }
                            ],
                            staticClass: "modal-content padded60"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _vm.deployment &&
                                _vm.deployment.location.latitude &&
                                _vm.deployment.location.longitude
                                  ? _c("point-map", {
                                      attrs: {
                                        "is-show": _vm.ui.activeTab == "map",
                                        latitude: parseFloat(
                                          _vm.deployment.location.latitude
                                        ),
                                        longitude: parseFloat(
                                          _vm.deployment.location.longitude
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.ui.activeTab === "photos"
                          ? _c(
                              "div",
                              {
                                staticClass: "col s12 padded60",
                                attrs: { id: "location-photos" }
                              },
                              [
                                _vm.deploymentDetails
                                  ? _c("location-photos", {
                                      attrs: {
                                        "location-id":
                                          _vm.deploymentDetails.deployment
                                            .locationId,
                                        "project-id": _vm.deployment.project.id
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.deploymentDetails == null
              ? _c("div", [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("cameraTagging-cantFindTask")))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("tagging-help", { attrs: { "form-name": _vm.ui.helpFormName } }),
            _vm._v(" "),
            _c("fov-preview", {
              attrs: {
                "fov-result": _vm.previewResult,
                "user-deployment-id": _vm.userDeploymentId
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("v-dialog")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }