var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("header", { staticClass: "mb-5 pt-10 z-20 relative" }, [
        _c("div", { staticClass: "h-24" }, [
          _c(
            "nav",
            { staticClass: "headerNav container overflow boxed" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "logIn mainNav", attrs: { id: "userInfo" } },
                [
                  _c("span", { staticClass: "logininfo user-login" }, [
                    _c(
                      "li",
                      { staticClass: "profile-area" },
                      [
                        _vm.loggedIn && _vm.userDetails
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "!text-burntOrange navigation-mainLink",
                                attrs: { to: "/home/user-settings.html" }
                              },
                              [
                                _c("i", { staticClass: "fa fa-user text-sm" }),
                                _vm._v(" " + _vm._s(_vm.userDetails.name))
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", { staticClass: "profile-area" }, [
                      _vm.loggedIn
                        ? _c(
                            "a",
                            {
                              staticClass: "!text-burntOrange",
                              on: { click: _vm.logout }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-right-from-bracket text-sm"
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("signIn-logout")) +
                                  "\n              "
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "!text-burntOrange",
                              on: { click: _vm.login }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-right-to-bracket text-sm"
                              }),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("signIn-login")) +
                                  "\n              "
                              )
                            ]
                          )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "slide-fade" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.mobileNavVisible || _vm.mobileNavOpen,
                        expression: "!mobileNavVisible || mobileNavOpen"
                      }
                    ],
                    staticClass: "mobileWrap",
                    style: _vm.mobileNavStyles
                  },
                  [
                    _c("ul", { staticClass: "mainNav" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://wildtrax.ca/resources/user-guide/",
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Guide & Resources")))]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getDataDiscoverURL(),
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("dataDiscover-exploreData")))]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.loggedIn
                        ? _c("li", { staticClass: "hasChildren abmi" }, [
                            _c("div", {
                              staticClass: "abmi sub-toggle",
                              on: { click: _vm.subMenuClick }
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                class: {
                                  "navigation-mainLink ": true,
                                  "border-burntOrange border !text-burntOrange hover:!bg-burntOrange hover:!text-white": !_vm.mobileNavVisible
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("My Data")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "sub-menu-wrap",
                                style: _vm.subMenuNavStyles,
                                attrs: { "data-v-2b6409dc": "" }
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "submenu",
                                    attrs: { "data-v-2b6409dc": "" }
                                  },
                                  [
                                    _c(
                                      "li",
                                      { on: { click: _vm.activateNav } },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/home/organization-list.html"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("org-myOrganizations")
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { on: { click: _vm.activateNav } },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: { to: "/home/projects.html" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("My Projects"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { on: { click: _vm.activateNav } },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/home/projects/data-download.html"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Data Downloads"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "nav-activator hide-on-large-only": true,
                    open: _vm.mobileNavOpen
                  },
                  on: { click: _vm.activateNav }
                },
                [
                  _c(
                    "button",
                    {
                      class: {
                        "hamburger hamburger--spin": true,
                        "is-active": _vm.mobileNavOpen
                      },
                      attrs: { type: "button" }
                    },
                    [_vm._m(1)]
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.API_URL == "https://www-api.wildtrax.ca/bis/" && _vm.isDev()
        ? _c("div", { staticClass: "container large" }, [
            _c(
              "div",
              {
                staticClass:
                  "p-5 text-5xl text-red-700 font-bold border-red-500 border bg-red-300"
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "inline w-12",
                    attrs: {
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      staticClass: "stroke-red-700",
                      attrs: {
                        d:
                          "M12 8V12M12 16H12.01M2 8.52274V15.4773C2 15.7218 2 15.8441 2.02763 15.9592C2.05213 16.0613 2.09253 16.1588 2.14736 16.2483C2.2092 16.3492 2.29568 16.4357 2.46863 16.6086L7.39137 21.5314C7.56432 21.7043 7.6508 21.7908 7.75172 21.8526C7.84119 21.9075 7.93873 21.9479 8.04077 21.9724C8.15586 22 8.27815 22 8.52274 22H15.4773C15.7218 22 15.8441 22 15.9592 21.9724C16.0613 21.9479 16.1588 21.9075 16.2483 21.8526C16.3492 21.7908 16.4357 21.7043 16.6086 21.5314L21.5314 16.6086C21.7043 16.4357 21.7908 16.3492 21.8526 16.2483C21.9075 16.1588 21.9479 16.0613 21.9724 15.9592C22 15.8441 22 15.7218 22 15.4773V8.52274C22 8.27815 22 8.15586 21.9724 8.04077C21.9479 7.93873 21.9075 7.84119 21.8526 7.75172C21.7908 7.6508 21.7043 7.56432 21.5314 7.39137L16.6086 2.46863C16.4357 2.29568 16.3492 2.2092 16.2483 2.14736C16.1588 2.09253 16.0613 2.05213 15.9592 2.02763C15.8441 2 15.7218 2 15.4773 2H8.52274C8.27815 2 8.15586 2 8.04077 2.02763C7.93873 2.05213 7.84119 2.09253 7.75172 2.14736C7.6508 2.2092 7.56432 2.29568 7.39137 2.46863L2.46863 7.39137C2.29568 7.56432 2.2092 7.6508 2.14736 7.75172C2.09253 7.84119 2.05213 7.93873 2.02763 8.04077C2 8.15586 2 8.27815 2 8.52274Z",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                ),
                _vm._v(" PRODUCTION MODE\n      "),
                _c(
                  "svg",
                  {
                    staticClass: "inline w-12",
                    attrs: {
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      staticClass: "stroke-red-700",
                      attrs: {
                        d:
                          "M12 8V12M12 16H12.01M2 8.52274V15.4773C2 15.7218 2 15.8441 2.02763 15.9592C2.05213 16.0613 2.09253 16.1588 2.14736 16.2483C2.2092 16.3492 2.29568 16.4357 2.46863 16.6086L7.39137 21.5314C7.56432 21.7043 7.6508 21.7908 7.75172 21.8526C7.84119 21.9075 7.93873 21.9479 8.04077 21.9724C8.15586 22 8.27815 22 8.52274 22H15.4773C15.7218 22 15.8441 22 15.9592 21.9724C16.0613 21.9479 16.1588 21.9075 16.2483 21.8526C16.3492 21.7908 16.4357 21.7043 16.6086 21.5314L21.5314 16.6086C21.7043 16.4357 21.7908 16.3492 21.8526 16.2483C21.9075 16.1588 21.9479 16.0613 21.9724 15.9592C22 15.8441 22 15.7218 22 15.4773V8.52274C22 8.27815 22 8.15586 21.9724 8.04077C21.9479 7.93873 21.9075 7.84119 21.8526 7.75172C21.7908 7.6508 21.7043 7.56432 21.5314 7.39137L16.6086 2.46863C16.4357 2.29568 16.3492 2.2092 16.2483 2.14736C16.1588 2.09253 16.0613 2.05213 15.9592 2.02763C15.8441 2 15.7218 2 15.4773 2H8.52274C8.27815 2 8.15586 2 8.04077 2.02763C7.93873 2.05213 7.84119 2.09253 7.75172 2.14736C7.6508 2.2092 7.56432 2.29568 7.39137 2.46863L2.46863 7.39137C2.29568 7.56432 2.2092 7.6508 2.14736 7.75172C2.09253 7.84119 2.05213 7.93873 2.02763 8.04077C2 8.15586 2 8.27815 2 8.52274Z",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _vm._m(2)
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.router ? _c("router-view") : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          staticClass: "vue-dialog",
          attrs: { name: "expired", width: "400", height: "auto", shiftY: 0.1 }
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", { staticClass: "dialog-c-title" }, [
              _vm._v(_vm._s(_vm.$t("Session Expired")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dialog-c-text" }, [
              _vm._v(
                _vm._s(_vm.$t("It looks like your user session has expired.")) +
                  " \n      "
              ),
              _vm.timeoutCounter > 0
                ? _c("div", { staticClass: "mt-5" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("Directing to sign on in")) +
                        " "
                    ),
                    _c("span", { staticClass: "font-bold text-lg" }, [
                      _vm._v(_vm._s(this.timeoutCounter))
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$tc("seconds", this.timeoutCounter)) +
                        "\n      "
                    )
                  ])
                : _c("div", { staticClass: "font-bold text-lg mt-5" }, [
                    _vm._v(_vm._s(_vm.$t("Redirecting...")))
                  ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("footer", {}, [_vm._m(3)])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "headerLogo" }, [
      _c("a", {
        staticClass: "logo",
        attrs: { href: "https://wildtrax.ca", target: "_blank" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-base font-normal" }, [
      _vm._v(
        "This means you are viewing a development copy of Wildtrax but it is making changes to production data. "
      ),
      _c("span", { staticClass: "font-bold underline" }, [
        _vm._v(" Please be very careful.")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container large" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s12 m4 l2" }, [
          _c("ul", { staticClass: "footerNav" }, [
            _c("li", { staticClass: "hasChildren" }, [
              _c("div", { staticClass: "sub-toggle" }),
              _vm._v(" "),
              _c("div", { staticClass: "sub-menu-wrap" }, [
                _c("ul", { staticClass: "sub-menu" })
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "hasChildren" }, [
              _c("div", { staticClass: "sub-toggle" }),
              _vm._v(" "),
              _c("div", { staticClass: "sub-menu-wrap" }, [
                _c("ul", { staticClass: "sub-menu" })
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col s12 m8 l6 contactInfo" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col s12 m6 l6 xl5" }, [
              _c("div", { staticClass: "contentRichText richtext" }, [
                _c("p", [
                  _vm._v("1-107 Centennial Centre for"),
                  _c("br"),
                  _vm._v(
                    "\n                  Interdisciplinary Studies (CCIS)"
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                  University of Alberta, Edmonton,"
                  ),
                  _c("br"),
                  _vm._v("\n                  AB, Canada T6G 2E9")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col s12 m6 l6 xl7" }, [
              _c("div", { staticClass: "contentRichText richtext" }, [
                _c("p", [
                  _c("strong", [_vm._v("Telephone:")]),
                  _vm._v(" 780.248.1592"),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Fax:")]),
                  _vm._v(" 780.492.7635"),
                  _c("br"),
                  _vm._v(
                    "\n                  info@wildtrax.ca\n                "
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col s12 m12 l4" }, [
          _c("p", { staticClass: "learnMoreAbmi" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://us4.campaign-archive.com/home/?u=de3236a0b48dda3b348d3943b&id=ebc7168db0",
                  target: "_blank"
                }
              },
              [_vm._v("Archive")]
            ),
            _vm._v(" | "),
            _c("a", { attrs: { href: "//abmi.ca" } }, [
              _vm._v("Learn more about ABMI »")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col s12 m12 l12 copyright1" }, [
          _c("hr"),
          _vm._v(" "),
          _c("span", [
            _c(
              "a",
              { staticClass: "hide", attrs: { href: "/home/terms-of-use" } },
              [_vm._v("\n              Privacy Policy and Terms\n            ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "hide", attrs: { href: "/home/terms-of-use" } },
              [
                _vm._v(
                  "\n              | Privacy Policy and Terms\n            "
                )
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }