<template>
    <div>
        <div @click="closeForm" class="iconButton link">
            <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle">
            <h6><template v-if="uploadingTags == null">{{$t('csvUpload-surveys', {project: projectName})}}</template><template v-if="uploadingTags != null && uploadingTags">{{$t('csvUpload-tags', {project: projectName})}}</template><template v-if="uploadingTags != null && !uploadingTags">{{$t('csvUpload-tasks', {project: projectName})}}</template></h6>
        </div>
        <div class="modal-content container csvUpload" >
          <div class="flex-row">
            <span @click="showSettings = !showSettings" class="link" :class="{'ion-arrow-expand': !showSettings, 'ion-arrow-shrink': showSettings}">
              {{showSettings ? 'Hide' : 'Show'}} {{$t('common-fileUpload')}}
            </span>
          </div>

          <div v-show="showSettings" id="upload-box">
            <div class="padded20 center">
              <template v-if="uploadingTags == null">{{$t('csvUpload-subHeaderSurveys', {projectName: projectName})}}</template>
              <template v-if="uploadingTags != null && uploadingTags">{{$t('csvUpload-subHeaderTags', {projectName: projectName})}}</template>
              <template v-if="uploadingTags != null && !uploadingTags && sensor == 'ARU'">{{$t('Upload your edited CSV file to make changes to [PROJECT] tasks. Any additions or update will be reflected in [PROJECT] once the tasks are uploaded. Omitted tasks will not be deleted. Large files may take a few minutes to complete.', {projectName: projectName})}}</template>
              <template v-else-if="uploadingTags != null && !uploadingTags">{{$t('csvUpload-subHeaderTasks', {projectName: projectName})}}</template>
              {{' ' + $t('csvUpload-largeFilesWarning')}}
            </div>
            <div class="row">
              <div class="col s1 m3 xl4"></div>
              <!-- for Better UI, hide the actual folder selection tool, and use a custom button instead -->
              <div class="col s5 m5 xl3">
                <input type="file" ref="chooseFile" id="chooseFile" name="equipCSV"
                    accept=".csv"
                    class="form-control hidden file-chooser"
                    @change="chooseFile"
                    >
                <button type="button" style="width: 100%" class="btn btn-default" id="browseButton"  @click="clickChooseFile()" >
                  <span class="glyphicon glyphicon-refresh"></span> <span class="info-icon ion-information-circled" v-tooltip="tooltips.csvUpload"></span> {{$t('common-csvUpload')}}
                </button>
              </div>
              <div class="baseline-wrapper col s3 m2 xl3">
                <span class="baseliner" v-if="selectedFile">{{selectedFile.name}}</span>
              </div>
              <div class="col s1 m2 xl2"></div>
            </div>
            <div class="row">
              <div class="col s1 m3 xl4"></div>
              <div class="col s5 m3 xl3">
                <button type="button" class="btn btn-border btn-success" id="submitButton" :disabled="selectedFile == null || previewLoading" @click="uploadFile(true)">
                  <template v-if="uploadingTags == null">{{$t('csvUpload-QAButtonSurveys')}}</template>
                  <template v-if="uploadingTags != null && uploadingTags">{{$t('csvUpload-QAButtonTags')}}</template>
                  <template v-if="uploadingTags != null && !uploadingTags">{{$t('csvUpload-QAButtonTasks')}}</template>
                </button>
              </div>
              <div class="col s6 m6 xl5"></div>
            </div>
            <div class="loader-wrapper-small">
              <pulse-loader v-if="previewLoading" :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="preview-pulse"></pulse-loader>
            </div>
          </div>

          <div v-if="message" class="error">{{message}}</div>
          <template v-if="showTable && !message">
            <!--<div v-if="tableData.length === 0 && !finishedUpload && !previewLoading && !uploading">No changes detected in this file.</div>-->
            <div class="orange-border padded40 bottom-margin" :class="{'success-box' : tableData.length === 0}">
              <v-client-table v-if="tableData.length > 0 && !uploading" :data="tableData" :columns="columns" :options="tableOptions">
                <template slot="icon" slot-scope="props">
                    <span v-if="props.row.isError" class="info-icon ion-android-warning"></span>
                    <span v-else class="info-icon ion-information-circled"></span>
                  </template>
                  <template slot="message" slot-scope="props">
                    <template v-if="props.row.isError">
                    {{props.row.error}}
                    </template>
                    <template v-else>
                    {{props.row.message}}
                    </template>
                  </template>
              </v-client-table>
              <div class="loader-wrapper-large">
                <template v-if="uploading">
                  <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="preview-pulse"></pulse-loader>
                  <div>{{$t('common-uploading')}}...</div>
                </template>
              </div>

              <div class="row no-bottom-margin">
                <div v-if="finishedUpload && !hasUploadErrors" class="finishMsg">
                  {{$t('csvUpload-successfullyUpdated')}}
                </div>
                <div v-if="finishedUpload && hasUploadErrors" class="finishMsg">
                  {{$t('csvUpload-failedToUpload')}}
                </div>
                <!--<template v-if="tableData.length > 0">-->
                  <template v-if="!finishedUpload && !uploading">
                    <div v-if="hasPreviewErrors">{{$t('csvUpload-previewErrorMsg')}}</div>
                    <div v-else>
                      <template v-if="hasChanges">
                      {{$t('csvUpload-passedQA')}}
                        <div>{{$t('csvUpload-largeFilesWarningPolite')}}</div>
                      </template>
                      <template v-else>
                        {{$t('csvUpload-noChanges')}}
                      </template>
                    </div>
                  </template>
                <div v-if="!finishedUpload" class="col btn-holder">
                  <button type="button" :disabled="hasPreviewErrors || uploading || !hasChanges" class="btn btn-border btn-success" id="uploadButton" @click="uploadFile(false)">
                    {{$t('common-uploadItem', {item: (uploadingTags == null ? $tc('common-survey', 2) : (uploadingTags ? $tc('common-tag', 2): $tc('common-task', 2))) })}}
                  </button>
                </div>
                <div :class="{'btn-holder': finishedUpload}">
                  <button v-if="!uploading && (hasPreviewErrors || hasUploadErrors || (!finishedUpload && hasChanges))" type="button" class="btn btn-border btn-success" id="cancelButton" @click="cancelUpload">
                    {{$t('common-cancel')}}
                  </button>
                  <button v-if="!uploading && !hasChanges" type="button" class="btn btn-border btn-success" id="cancelButton" @click="cancelUpload">
                    {{$t('common-back')}}
                  </button>
                  <button v-if="finishedUpload && !hasUploadErrors" type="button" class="btn btn-border btn-success" @click="closeForm">
                    {{$t('common-close')}}
                  </button>
                </div>
                <!--</template>-->
              </div>
            </div>
          </template>
        </div>
    </div>
</template>

<script>

import { eventBus } from '@/lib/eventbus';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import FormMixin from '@/components/common/FormMixin.js';
/*
This component displays a form for the user to upload their csv file for project data
*/

export default {
  props: ['projectName', 'projectId', 'uploadingTags', 'uploadUrl', 'sensor'],
  components: {'pulse-loader': PulseLoader},
  mixins: [FormMixin],
  created () {
  },
  methods: {
    closeForm () {
      this.cancelUpload();
      eventBus.$emit('reload-task-table');
      eventBus.$emit('hide-csv-upload-form', 'project-upload-form');
    },
    clickChooseFile () {
      this.$refs.chooseFile.value = null; // so the user can reupload same file without a problem
      this.$refs.chooseFile.click();
    },
    chooseFile ($event) {
      this.selectedFile = null;
      this.selectedFile = $event.target.files[0];
      this.showTable = false;
      this.message = null;
      this.hasPreviewErrors = false;
      this.hasUploadErrors = false;
      this.finishedUpload = false;
      this.tableData = [];
    },
    async uploadFile (isPreview) {
      this.hasChanges = false;
      this.tableData = [];
      if (isPreview) {
        this.previewLoading = true;
        this.hasPreviewErrors = false;
      } else {
        this.hasUploadErrors = false;
        this.uploading = true;
        this.finishedUpload = false;
      }

      let formData = new FormData();
      formData.append('file', this.selectedFile);
      // let uploadUrl = this.uploadingTags ? this.uploadTagsCsvUrl : this.uploadTasksCsvUrl;

      try {
        const response = await this.$http.post(this.uploadUrl + this.projectId + (isPreview ? '&previewOnly=true' : '&previewOnly=false'), formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response.data.hasOwnProperty('error')) {
          this.message = response.data.error;
        } else {
          if (response.body.message === 'success') {
            // no errors were found in the file, ok to upload
          } else {
            if (response.body.messages || response.body.errors) {
              // for aru & cam only
              if (response.body.errors.length > 0) {
                this.hasChanges = true;
                this.totalRows = response.body.errors.length;
                if (isPreview) {
                  this.hasPreviewErrors = true;
                } else {
                  this.hasUploadErrors = true;
                }
                this.columns = ['icon', 'row', 'message'];
                this.tableOptions.headings.message = this.$tc('common-error', 1);
                response.body.errors.forEach(row => {
                  row.isError = true;
                  this.tableData.push(row);
                });
                // sort by row number
                this.tableData.sort((a, b) => {
                  if (parseInt(a.row) > parseInt(b.row)) {
                    return 1;
                  }
                  if (parseInt(a.row) < parseInt(b.row)) {
                    return -1;
                  }
                  return 0;
                });
              } else if (response.body.messages.length > 0) {
                this.hasChanges = true;
                this.totalRows = response.body.messages.length;
                this.columns = ['icon', 'row', 'message'];
                this.tableOptions.headings.message = this.$t('common-message');
                response.body.messages.forEach(row => {
                  this.tableData.push(row);
                });
                // sort by row number
                this.tableData.sort((a, b) => {
                  if (parseInt(a.row) > parseInt(b.row)) {
                    return 1;
                  }
                  if (parseInt(a.row) < parseInt(b.row)) {
                    return -1;
                  }
                  return 0;
                });
              }
            } else {
              // for pointcount only
              if (response.body.length > 0) {
                this.hasChanges = true;
              }
              this.totalRows = response.body.length;
              this.columns = ['icon', 'row', 'error'];
              this.tableOptions.headings.error = this.$t('common-errorMessage');
              response.body.forEach(row => {
                this.tableData.push(row);
              });
              if (this.tableData.length > 0) {
                this.hasPreviewErrors = true;
              }
            }
          }
        }
      } catch (er) {
        console.log(er);
        if (isPreview) {
          this.hasPreviewErrors = true;
        } else {
          this.hasUploadErrors = true;
        }
        this.message = this.$t('audioUploader-unknownError');
      }
      if (isPreview) {
        this.previewLoading = false;
      } else {
        this.uploading = false;
        this.finishedUpload = true;
        eventBus.$emit('refresh-task-table');
      }
      this.showTable = true;
      this.showSettings = false;
    },
    cancelUpload () {
      this.showTable = false;
      this.selectedFile = null;
      this.tableData = [];
      this.message = null;
      this.showSettings = true;
      this.hasPreviewErrors = false;
      this.hasUploadErrors = false;
      this.finishedUpload = false;
      this.$refs.chooseFile.value = null; // so the user can reupload same file without a problem
    }
  },
  data () {
    return {
      showSettings: true,
      message: null,
      selectedFile: null,
      previewLoading: false,
      uploading: false,
      finishedUpload: false,
      showTable: false,
      hasPreviewErrors: false,
      hasUploadErrors: false,
      hasChanges: false,
      columns: null,
      tableData: [],
      tooltips: {
        csvUpload: this.$t('csvUpload-tooltip', {type: (this.uploadingTags == null ? this.$tc('common-survey', 2) : (this.uploadingTags ? this.$tc('common-tag', 2) : this.$tc('common-task', 2)))})
      },
      tableOptions: {
        filterable: false,
        headings: {icon: '', row: 'Row'},
        perPage: 100,
        perPageValues: [50, 100, 1000],
        pagination: {
          nav: 'fixed',
          edge: true}
      }
    }
  }
}
</script>

<style scoped>
.csvUpload{
  padding: 40px 5px 0px 5px;
}

.file-chooser{
  width:90%;
  display:none;
}

.baseline-wrapper{
  position:relative;
}

.baseliner{
  position: absolute;
  top:20px;
}

@media only screen and (min-width: 741px){  /*768px) {*/
.baseliner{
  top: 16px;
  }
}

@media only screen and (min-width: 1370px) {
  .baseliner{
    top: 20px;
  }
}

.pulse{
  position: absolute;
	left: 50%;
	right: 50%;
  top: 30px;
  z-index: 1000;
/*bottom: 60%;
	top: 40%; */
}

.preview-pulse{
  text-align: center;
}

.loader {
  position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0.8;
	filter: blur(5px);
}

.orange-border
{
  border: 1px solid #d89b9b;
}

.no-bottom-margin{
  margin-bottom: 0px;
}

.bottom-margin{
  margin-bottom: 70px;
}

#upload-box{
  border: 1px solid #d89b9b;
  margin-bottom: 20px;
  padding-top: 10px;
}

.flex-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

span.link {
  color: #C94412;
  font-size: 17px;
}

.success-box{
  padding-top: 25px;
}

.loader-wrapper-large{
  height: 65px;
  text-align: center;
}

.loader-wrapper-small{
  height: 35px;
}

.btn-holder{
  padding-left: 0px !important;
}

</style>
