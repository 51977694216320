<template>
  <div>
    <div class="col s12 panel boxed padded60 dataGroup tab-content">
      <button class="btn btn-success" v-if="isEditable" @click="toggleCreateNewVisitForm(true)"> <span style="font-size: 20px;"></span>{{$t('visitSummaryTable-createVisit')}}</button>
      <dropdown-button class="right">
        <template slot="menuTitle">{{$t('common-manage')}}</template>
        <template slot="dropdownButtons">
          <button v-close-popover class="btn btn-success popover-menu-item" v-if="isEditable" @click="showAutogenerateVisitsForm()"> <span style="font-size: 20px;"></span>{{$t('visitSummaryTable-autogenerate')}}</button>
          <a download class="btn btn-success popover-menu-item" :class="{'downloading': downloading }" @click="downloadVisits()"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('visitSummaryTable-downloadVisits')}}</a>
          <a download class="btn btn-success popover-menu-item" :class="{'downloading': downloadingCodes }" @click="downloadCodes()"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('common-downloadCodes')}}</a>
          <!-- popover + tooltip has been buggy so this v-if part is necessary to avoid bug -->
          <span v-if="!isEditable" v-tooltip="{content: tooltips.disabledUpload, show: isEditable, classes: 'front'}">
            <button :disabled="!isEditable" v-close-popover class="btn btn-success popover-menu-item" @click="uploadVisitCSV"> <span style="font-size: 20px;"></span> {{$t('common-uploadItem', {item: $tc('common-visit', 2)})}}</button>
          </span>
          <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadVisitCSV()"> <span style="font-size: 20px;"></span>{{$t('common-uploadItem', {item: $tc('common-visit', 2)})}}</button>
        </template>
      </dropdown-button>
      <!-- <button class="btn btn-success  locationAdd" v-if="isEditable" @click="createLocation"> <span style="font-size: 20px;"></span> Add Location</button>
      <template id="people" vcloak v-if="isEditable && false">
        <button class="btn locationDeleteConfirm" v-if="showCheckbox" @click="deleteLocations"><span class="ion-trash-a"></span> Delete Selected Locations</button>
        <button class="btn btn-border locationDelete" @click="showHideCheckboxColumn" v-if="!showCheckbox">Delete Locations</button>
        <button class="btn btn-border locationDelete" @click="showHideCheckboxColumn" v-if="showCheckbox">Hide Checkboxes</button>
      </template>     -->
      <v-server-table ref="table" :url="url" :columns="columns" :options="tableOptions" class="table vue-bordered org-table w-full" @row-click="onRowClick">
        <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
        <template slot="hasImage" slot-scope="props">
          <template v-if="props.row.hasImage">
            {{$t('common-yes')}}
          </template>
          <template v-else>
            {{$t('common-no')}}
          </template>
        </template>
        <template slot="id" slot-scope="props">
          <a class="editProject modal-trigger projectAdd" @click="updateLocationVisit(props.row)"><span class="link" :class="{'ion-edit': isEditable, 'ion-eye': !isEditable}"></span></a>
        </template>
      </v-server-table>
    </div>
    <modal name="location-visit"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "80%"
      height = "75%"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <visit-form ref="visitForm"  v-bind:location-id="locationId" v-bind:is-editable="isEditable"
      v-bind:options="options" v-bind:organization-id="organizationId" v-bind:location-label="locationLabel"
      />
    </modal>

    <modal name="autogenerate-visits-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      height = "85%"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <autogenerate-visits-form v-bind:organization-id="organizationId"
      />
    </modal>

      <modal name="create-new-visit-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "85%"
      height = "75%"
      :minWidth="200"
      :minHeight="200"
      :clickToClose = "false"
    >
      <create-new-visit-form v-bind:organization-id="organizationId" />

    </modal>
    <modal name="visit-upload-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
    <upload-visits-csv
      v-bind:organization-id="organizationId"
      v-bind:organization-name="organizationName"
      v-bind:visibility-options="options.visibility"
      v-bind:type="'visits'"/>
    </modal>
    <v-dialog/>
  </div>
</template>

<script>
/* this component display a summary table for locaiton visits
after click a row, a detailed visit form will be shown
*/
import LocationSingleVisitTable from './LocationSingleVisitTable';
import CreateNewVisitForm from './CreateNewVisitForm';
import AutogenerateVisitsForm from './AutogenerateVisitsForm';
import { eventBus } from '@/lib/eventbus';
import { API_URL, alertDialog, attachAuthorizaitonHeader, capitalizeString } from '@/lib/common';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import FormMixin from '@/components/common/FormMixin.js';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import UploadCSVForm from '@/components/common/UploadCSVForm';
import DropdownButtonWithOptionsVue from '../../common/DropdownButtonWithOptions.vue';

export default {
  name: 'Location-Visit-Summary-Table',
  components: {
    'visit-form': LocationSingleVisitTable,
    'multiselect': Multiselect,
    'pulse-loader': PulseLoader,
    'autogenerate-visits-form': AutogenerateVisitsForm,
    'create-new-visit-form': CreateNewVisitForm,
    'upload-visits-csv': UploadCSVForm,
    'dropdown-button': DropdownButtonWithOptionsVue
  },
  props: {
    isEditable: {
      default: true,
      type: Boolean
    },
    organizationId: {
      default: null,
      type: Number
    },
    organizationName: {
      default: null,
      type: String
    }
  },
  mixins: [FormMixin],
  created () {
    const that = this;
    //attachAuthorizaitonHeader(); // get options
    eventBus.$on('reload-summary-table', function () {
      that.$refs.table.refresh();
    });

    eventBus.$on('close-location-visit-form', function () {
      that.$modal.hide('location-visit');
    });
    eventBus.$on('close-create-new-visit-form', function () {
      that.$modal.hide('create-new-visit-form');
    });
    eventBus.$on('close-autogenerate-visits-form', function () {
      that.$modal.hide('autogenerate-visits-form');
    });
    eventBus.$on('hide-csv-upload-form', () => {
      this.$modal.hide('visit-upload-form');
    });
  },
  watch: {
    organizationId () {
      if (this.$refs.table) {
        this.url = API_URL + 'get-location-visit-summary?organizationId=' + this.organizationId;
      }
    }
  },
  methods: {
    updateLocationVisit (row) {
      this.locationId = row.locationId;
      this.locationLabel = row.locationName;
      // this.isEditable = !row.readOnly;
      this.showLocationVisitForm();
    },
    createLocationVisit () {
      if (!this.isEditable) {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.add);
        return;
      }
      this.locationId = null;
      this.showLocationVisitForm();
    },
    toggleCreateNewVisitForm (bShow) {
      if (bShow) {
        this.$modal.show('create-new-visit-form');
      } else {
        this.createVisitSuccess = false;
        //  this.createVisitLocation = null;
        this.loading = false;
        this.$modal.hide('create-new-visit-form');
      }
    },
    showLocationVisitForm () {
      //  console.log('locationDeploymentId =', this.locationDeploymentId)
      // this.$refs.organizationForm.switchLocationVisit();
      this.$modal.show('location-visit');
    },
    initTable () {
      this.tableData = [];
      // console.log(this === this.$refs['table']);
      // this.$refs['table'].refresh();
    },
    taskRowUrl (id) {
      return this.adminPageUrl + '?id=' + id;
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.locationId;
    },

    showAutogenerateVisitsForm () {
      this.$modal.show('autogenerate-visits-form');
    },
    downloadVisits () {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.$http.get(this.downloadVisitsURL + this.organizationId, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.getFileName();
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloading = false;
      },
      (err) => {
        this.downloading = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    getFileName () {
      let now = new Date();
      let filename = this.organizationName + '_' + this.$tc('common-visit', 2) + '_' + now.getFullYear();
      if ((now.getMonth() + 1) < 10) {
        filename += '0' + (now.getMonth() + 1) + now.getDate() + '.csv';
      } else {
        filename += (now.getMonth() + 1) + now.getDate() + '.csv';
      }
      return filename;
    },
    downloadCodes () {
      if (this.downloadingCodes) {
        return;
      }
      this.downloadingCodes = true;
      this.$http.get(this.downloadCodesURL, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.$tc('common-visit', 1) + '_' + capitalizeString(this.$tc('common-code', 2)) + '.zip';
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloadingCodes = false;
      },
      (err) => {
        this.downloadingCodes = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    uploadVisitCSV () {
      this.$modal.show('visit-upload-form');
    }
  },
  data () {
    const pageSize = 100;
    return {
      url: API_URL + 'get-location-visit-summary?organizationId=' + this.organizationId,
      downloadVisitsURL: API_URL + 'download-location-visits-by-org-id?orgId=',
      downloadCodesURL: API_URL + 'download-location-visit-codes',
      locationId: null,
      locationLabel: '',
      downloading: false,
      downloadingCodes: false,
      columns: ['locationName', 'firstVisit', 'lastVisit', 'hasImage', 'id'],
      tooltips: {
        disabledUpload: this.$t('common-disabledUpload')
      },
      showCheckbox: false,
      // customFilters: ['organizationType'],
      recordName: this.$tc('common-visit', 1),
      options: {},
      tableOptions: {
        activeRowId: null,
        uniqueKey: 'id',
        highlightMatches: true,
        childRowTogglerFirst: false,
        // childRow: function (h, row) {
        //   return
        // },
        filterByColumn: true,
        filterable: ['locationName', 'firstVisit', 'lastVisit'],
        sortable: ['hasImage', 'locationName', 'firstVisit', 'lastVisit'],
        listColumns: {
        },
        headings: {
          checkbox: '',
          locationName: this.$t('visitSummaryTable-headings.locationName'),
          firstVisit: this.$t('visitSummaryTable-headings.firstVisit'),
          lastVisit: this.$t('visitSummaryTable-headings.lastVisit'),
          hasImage: this.$t('visitSummaryTable-headings.hasImage'),
          id: this.$t('visitSummaryTable-headings.id')
        },
        columnsClasses: {
          locationName: 'location-visit-name',
          projects: 'tasks'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'locationName', ascending: true},
        rowClassCallback (row) {
          return (row.locationId === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.subrow-info p {
  color: #C94412;
}
.VueTables__table td,
.VueTables__table th {
  text-align: center;
}
.VueTables__table td.location-visit-name,
.VueTables__table th.location-visit-name {
  text-align: left;
}

.VueTables__child-row-toggler {
  cursor: pointer;
}
.org-table tbody td {
	cursor: pointer;
}

.front{
  z-index: 1000000;
}
</style>
