<template>
  <div id="app">
    <header class="mb-5 pt-10 z-20 relative" v-cloak>
      <div class="h-24">
        <nav class="headerNav container overflow boxed">
          <div class="headerLogo">
            <!-- <router-link to="/" class="logo"></router-link> -->
            <a class="logo" href="https://wildtrax.ca" target="_blank"></a>
          </div>
          <ul class="logIn mainNav" id="userInfo">
            <span class="logininfo user-login">
              <li class="profile-area">
                <router-link class="!text-burntOrange navigation-mainLink" v-if="loggedIn && userDetails"
                  to="/home/user-settings.html"><i class="fa fa-user text-sm"></i> {{ userDetails.name }}</router-link>
              </li>
              <li class="profile-area">
                <a v-if="loggedIn" class="!text-burntOrange " @click="logout">
                  <i class="fa fa-right-from-bracket text-sm"></i>
                  {{ $t('signIn-logout') }}
                </a>
                <a class="!text-burntOrange" v-else @click="login">
                  <i class="fa fa-right-to-bracket text-sm"></i>
                  {{ $t('signIn-login') }}
                </a>
              </li>
            </span>


          </ul>
          <transition name="slide-fade">
            <div class="mobileWrap" :style="mobileNavStyles" v-show="!mobileNavVisible || mobileNavOpen">
              <ul class="mainNav">
                <li><a href="https://wildtrax.ca/resources/user-guide/" target="_blank">{{$t('Guide & Resources')}}</a></li>
                <li><a :href="getDataDiscoverURL()" target="_blank">{{ $t('dataDiscover-exploreData') }}</a></li>
                <li class="hasChildren abmi" v-if="loggedIn">
                  <div class="abmi sub-toggle" @click="subMenuClick"></div> <a
                    :class="{ 'navigation-mainLink ': true, 'border-burntOrange border !text-burntOrange hover:!bg-burntOrange hover:!text-white': !mobileNavVisible }">{{ $t('My Data')}}</a>
                  <div data-v-2b6409dc="" class="sub-menu-wrap" :style="subMenuNavStyles">
                    <ul data-v-2b6409dc="" class="submenu">

                      <li @click="activateNav"><router-link
                          to="/home/organization-list.html">{{ $t('org-myOrganizations') }}</router-link></li>
                      <li @click="activateNav"><router-link to="/home/projects.html">{{ $t('My Projects') }}</router-link>
                      </li>
                      <li @click="activateNav"><router-link to="/home/projects/data-download.html">{{ $t('Data Downloads')}}</router-link></li>
                    </ul>
                  </div>
                </li>

              </ul>
            </div>
          </transition>



          <div :class="{ 'nav-activator hide-on-large-only': true, 'open': mobileNavOpen }" @click="activateNav">
            <button :class="{ 'hamburger hamburger--spin': true, 'is-active': mobileNavOpen }" type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </nav>
      </div>
    </header>
    <!-- Show only if URL is non prod but pointing to prod api -->
    <div class="container large" v-if="API_URL=='https://www-api.wildtrax.ca/bis/' && isDev() " >
      <div class="p-5 text-5xl text-red-700 font-bold border-red-500 border bg-red-300">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline w-12">
          <path class="stroke-red-700" d="M12 8V12M12 16H12.01M2 8.52274V15.4773C2 15.7218 2 15.8441 2.02763 15.9592C2.05213 16.0613 2.09253 16.1588 2.14736 16.2483C2.2092 16.3492 2.29568 16.4357 2.46863 16.6086L7.39137 21.5314C7.56432 21.7043 7.6508 21.7908 7.75172 21.8526C7.84119 21.9075 7.93873 21.9479 8.04077 21.9724C8.15586 22 8.27815 22 8.52274 22H15.4773C15.7218 22 15.8441 22 15.9592 21.9724C16.0613 21.9479 16.1588 21.9075 16.2483 21.8526C16.3492 21.7908 16.4357 21.7043 16.6086 21.5314L21.5314 16.6086C21.7043 16.4357 21.7908 16.3492 21.8526 16.2483C21.9075 16.1588 21.9479 16.0613 21.9724 15.9592C22 15.8441 22 15.7218 22 15.4773V8.52274C22 8.27815 22 8.15586 21.9724 8.04077C21.9479 7.93873 21.9075 7.84119 21.8526 7.75172C21.7908 7.6508 21.7043 7.56432 21.5314 7.39137L16.6086 2.46863C16.4357 2.29568 16.3492 2.2092 16.2483 2.14736C16.1588 2.09253 16.0613 2.05213 15.9592 2.02763C15.8441 2 15.7218 2 15.4773 2H8.52274C8.27815 2 8.15586 2 8.04077 2.02763C7.93873 2.05213 7.84119 2.09253 7.75172 2.14736C7.6508 2.2092 7.56432 2.29568 7.39137 2.46863L2.46863 7.39137C2.29568 7.56432 2.2092 7.6508 2.14736 7.75172C2.09253 7.84119 2.05213 7.93873 2.02763 8.04077C2 8.15586 2 8.27815 2 8.52274Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> PRODUCTION MODE
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline w-12">
          <path class="stroke-red-700" d="M12 8V12M12 16H12.01M2 8.52274V15.4773C2 15.7218 2 15.8441 2.02763 15.9592C2.05213 16.0613 2.09253 16.1588 2.14736 16.2483C2.2092 16.3492 2.29568 16.4357 2.46863 16.6086L7.39137 21.5314C7.56432 21.7043 7.6508 21.7908 7.75172 21.8526C7.84119 21.9075 7.93873 21.9479 8.04077 21.9724C8.15586 22 8.27815 22 8.52274 22H15.4773C15.7218 22 15.8441 22 15.9592 21.9724C16.0613 21.9479 16.1588 21.9075 16.2483 21.8526C16.3492 21.7908 16.4357 21.7043 16.6086 21.5314L21.5314 16.6086C21.7043 16.4357 21.7908 16.3492 21.8526 16.2483C21.9075 16.1588 21.9479 16.0613 21.9724 15.9592C22 15.8441 22 15.7218 22 15.4773V8.52274C22 8.27815 22 8.15586 21.9724 8.04077C21.9479 7.93873 21.9075 7.84119 21.8526 7.75172C21.7908 7.6508 21.7043 7.56432 21.5314 7.39137L16.6086 2.46863C16.4357 2.29568 16.3492 2.2092 16.2483 2.14736C16.1588 2.09253 16.0613 2.05213 15.9592 2.02763C15.8441 2 15.7218 2 15.4773 2H8.52274C8.27815 2 8.15586 2 8.04077 2.02763C7.93873 2.05213 7.84119 2.09253 7.75172 2.14736C7.6508 2.2092 7.56432 2.29568 7.39137 2.46863L2.46863 7.39137C2.29568 7.56432 2.2092 7.6508 2.14736 7.75172C2.09253 7.84119 2.05213 7.93873 2.02763 8.04077C2 8.15586 2 8.27815 2 8.52274Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text-base font-normal">This means you are viewing a development copy of Wildtrax but it is making changes to production data. <span class="font-bold underline"> Please be very careful.</span></p>
      </div>
    </div>

    <router-view v-if="router"></router-view>
    <modal name="expired" class="vue-dialog" width="400" height="auto" :shiftY="0.1">
      <div  class="dialog-content ">
      <div class="dialog-c-title">{{$t('Session Expired')}}</div>
      <div class="dialog-c-text">{{$t('It looks like your user session has expired.')}} 
        <div v-if="timeoutCounter>0" class="mt-5">
        {{ $t('Directing to sign on in')}} <span class="font-bold text-lg">{{this.timeoutCounter}}</span> {{ $tc('seconds', this.timeoutCounter)}}
        </div><div v-else class="font-bold text-lg mt-5">{{$t('Redirecting...')}}</div>
      
      </div>
      </div>
    </modal>
    <!-- <div class="text-burntOrange m-10" v-else> Permission denied. </div> -->
    <footer v-cloak>
      <div class="container large">
        <div class="row">
          <div class="col s12 m4 l2">
            <ul class="footerNav">
              <li class="hasChildren">
                <div class="sub-toggle"> </div>

                <div class="sub-menu-wrap">
                  <ul class="sub-menu">
                  </ul>
                </div>

              </li>
              <li class="hasChildren">
                <div class="sub-toggle"> </div>

                <div class="sub-menu-wrap">
                  <ul class="sub-menu">
                  </ul>
                </div>

              </li>
            </ul>
          </div>
          <div class="col s12 m8 l6 contactInfo">
            <div class="row">
              <div class="col s12 m6 l6 xl5">
                <div class="contentRichText richtext">
                  <p>1-107 Centennial Centre for<br>
                    Interdisciplinary Studies (CCIS)<br>
                    University of Alberta, Edmonton,<br>
                    AB, Canada T6G 2E9</p>

                </div>
              </div>
              <div class="col s12 m6 l6 xl7">
                <div class="contentRichText richtext">
                  <p><strong>Telephone:</strong>&nbsp;780.248.1592<br>
                    <strong>Fax:</strong>&nbsp;780.492.7635<br>
                    info@wildtrax.ca
                  </p>

                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m12 l4">


            <p class="learnMoreAbmi"><a
                href="https://us4.campaign-archive.com/home/?u=de3236a0b48dda3b348d3943b&amp;id=ebc7168db0"
                target="_blank">Archive</a> | <a href="//abmi.ca">Learn more about ABMI »</a></p>

          </div>
          <div class="col s12 m12 l12 copyright1">
            <hr>
            <span>


              <a href="/home/terms-of-use" class=" hide ">
                Privacy Policy and Terms
              </a>


              <a href="/home/terms-of-use" class="hide ">
                | Privacy Policy and Terms
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { isDataDiscover, getDataDiscoverURL, API_URL,isDev, getAPIUrl } from '@/lib/common';
import { isLoggedIn, getProfile, checkLanguageToken, handleAuthentication, login, logout } from '@/lib/user-auth0.js'
import { eventBus } from '@/lib/eventbus.js'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from './lib/localStorage';

export default {
  name: 'WildtraxApp',
  created() {
  },
  mounted() {
    this.profile = getLocalStorage('profile') ? JSON.parse(getLocalStorage('profile')) : null;
    this.userDetails = this.getUserDetails();
    const self = this;
    eventBus.$on('token_updated', (data) => {
      isLoggedIn()
      getProfile();
    });
    eventBus.$on('profile_updated', profile => {
      if (typeof profile == 'string') {
        this.profile = JSON.parse(profile);
      } else {
        this.profile = profile;
      }
    });
    eventBus.$on('authentication_updated', isLoggedIn => {
      this.loggedIn = isLoggedIn;
      if (isLoggedIn)
        eventBus.$emit('logged-in');
    });
    window.addEventListener('resize', e => {
      this.windowWidth = e.target.innerWidth;
    });
    this.windowWidth = window.innerWidth;





    /** This will grant access to internal workings of wildtrax.
     * 
     * KNOWN ISSUE: If you don't complete your login and try to visit the site it wont trigger the login
     * 
     * Also have to put acceptions in here to allow access without login. 
     */
    if (this.publicRoutes.includes(window.location.pathname) || isDataDiscover()) {
      this.loadRouter();
    } else {
      if (!getLocalStorage('processing-login') && !isLoggedIn()) {
        this.login();
      } else if (getLocalStorage('processing-login') && isLoggedIn()) {
        this.loadRouter();
        removeLocalStorage('processing-login');
      } else if (isLoggedIn()) {
        this.loadRouter();
      }
      else if (getLocalStorage('processing-login') && !getLocalStorage('id_token')) {
        //This is the case if the user aborts login and sets the processing-login flag without getting a valid id_token back.
        //adding timout here
        //delayed token setting. 
        eventBus.$on('token_updated', () => {

          if (!isLoggedIn()) {
            setLocalStorage('processing-login', true);
            login();
          } else {
            removeLocalStorage('processing-login');
          }
        });
       
      this.triggerExpiredModal();
        //console.log(getLocalStorage('id_token'));
        //setTimeout(login, 0);
      } else {
        this.triggerExpiredModal();
      }
    }
  },
  computed: {
    mobileNavVisible() {
      return this.windowWidth < 993;
    },
    mobileNavStyles() {
      if (this.mobileNavVisible && this.mobileNavOpen) {
        return {
          height: "auto"
        }
      }
      return {};
    },
    subMenuNavStyles() {
      if (this.subMenuToggle) {
        return {
          height: "auto"
        };
      }
      return { height: 0 };
    }
  },
  methods: {
    getUserDetails () {
      this.$http.get(this.userDetailsURL).then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
          } else {
            this.userDetails = response.data;
          }
        }
      );
    },
    getDataDiscoverURL() {
      return getDataDiscoverURL();
    },  
    triggerExpiredModal() {
      setInterval(() => {
          this.timeoutCounter-=1;
        }, 1000);

        
        this.$modal.show('expired');
    },
    login() {
      setLocalStorage('processing-login', true);
      login();
    },
    loadRouter() {
      this.router = true;
    },
    logout() {
      logout();
    },
    getAPIUrl() {
      return getAPIUrl();
    },  
    isDev() {
      return isDev();
    },
    subMenuClick(e) {
      this.subMenuToggle = !this.subMenuToggle;
      if (!this.subMenuToggle) {
        e.target.classList.remove('toggled');
      } else {
        e.target.classList.add('toggled');
      }
    },
    activateNav() {
      this.mobileNavOpen = !this.mobileNavOpen;
    }
  },
  watch: {
    timeoutCounter: function(val){
      if (val === 0) this.login();
    }
  },
  data() {
    return {
      API_URL: API_URL,
      profile: {},
      userDetails: {},
      router: false,
      DDOnly: isDataDiscover(),
      loggedIn: isLoggedIn(),
      mobileNavOpen: false,
      windowWidth: 0,
      subMenuToggle: false,
      timeoutCounter: 5,
      publicRoutes: ['/home/landowners/reports.html', '/home/landowners/report.html', '/data-discover.html', '/id-entry'],
      userDetailsURL: API_URL + 'get-user-details',

    }
  }
}


</script>

<style scoped>
.headerNav .mainNav li a {
  text-transform: uppercase;
}

.headerNav .mainNav li a:before {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}</style>
