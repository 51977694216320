<template>
    <div>
        <div v-show="!(project && tableData)" class="overaly"
            style="opacity: 0.6; width: 100%; height: 100%; z-index: 100;">
            <div style="text-align: center; margin:250px 0px;">
                <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="200"></pulse-loader>
                <div> {{ $t('common-tableTexts.loading') }} </div>
            </div>
        </div>
        <div v-if="project && tableData">
            <div class="flex gap-1">
                <div class="grow"></div>
                <span v-tooltip="project.isAdmin ? '' : $t('Upload disabled for standard users.')"
                    :class="{ '!cursor-auto': !project.isAdmin, '!cursor-pointer': project.isAdmin }">
                    <div :style="{ height: '42px' }" :disabled="!project.isAdmin" class="btn" @click="showModal"><i
                            class="fa fa-upload"></i> {{ $t('common-upload') }}</div>
                </span>
                <dropdown-button v-show="selectedIds.length > 0">
                    <template slot="menuTitle">{{ $t('common-manage') }} ({{ selectedIds.length }})</template>
                    <template slot="dropdownButtons">
                        <a><span class="btn btn-success popover-menu-item" v-if="project.isAdmin"
                                @click="includeInDownloadBatch" v-close-popover>
                                {{ $t('Include in download') }}
                            </span></a>
                        <a><span class="btn btn-success popover-menu-item" v-if="project.isAdmin" @click="hideBatch"
                                v-close-popover>
                                {{ $t('Hide files') }}
                            </span></a>

                        <!-- Upload media to project -->
                        <a><span :class="{ 'group-top': project.isAdmin, 'btn btn-success popover-menu-item': true }"
                                @click="() => { if (!downloading) downloadBatch() }" v-close-popover>
                                <i v-show="downloading" class="fa fa-spinner fa-spin"></i> {{ $t('Download files') }}
                            </span></a>
                        <a v-if="project.isAdmin"><span class="btn btn-success popover-menu-item" @click="removeBatch"
                                v-close-popover>
                                {{ $t('Delete files') }}
                            </span></a>



                    </template>
                </dropdown-button>
            </div>
            <v-client-table ref="filesTable" v-if="tableData" :columns="columns" :options="tableOptions" :data="tableData">
                <template slot="h__check">
                    <span @click="onCheckAllClick">
                        <i class="fa fa-check text-2xl cursor-pointer" v-if="allChecked"></i>
                        <i class="fa-regular fa-square-full text-xl cursor-pointer" v-else></i>
                    </span>
                </template>
                <template slot="h__hidden">
                    <div class="text-center">{{ $t('Hidden') }} <i
                            v-tooltip="$t('Your files will not be accessible to users who are not admins.')"
                            class="fa-solid fa-circle-exclamation"></i></div>
                </template>
                <template slot="h__includeInDownload">
                    <div class="text-center">{{ $t('Inc. D/l') }} <i
                            v-tooltip="$t('Your files will be included in the download of your project if the user has access to them. Only admins can download hidden files.')"
                            class="fa-solid fa-circle-exclamation"></i></div>
                </template>
                <template slot="h__actions">
                    <div class="text-center">{{ $tc('common-action', 2) }}</div>
                </template>
                <template slot="check" slot-scope="props">
                    <label>
                        <input type="checkbox" :data-id="props.row.id" @click="onCheckClick(props.row.id)" />
                        <span></span>
                    </label>
                </template>
                <template slot="uploadDate" slot-scope="props">
                    {{ date(props.row.uploadDate) }}
                </template>
                <template slot="fileName" slot-scope="props">
                    <a @click="download(props.row)" class="cursor-pointer">{{ props.row.fileName }}</a>
                </template>
                <template slot="description" slot-scope="{row,update}">
                    <input :placeholder="$t('Click to edit description')" class="!border-0 !mb-0 !h-10"
                        v-if="project.isAdmin" type="text" :value="row.description" @keypress="e => onInput(e, row)"
                        @blur="e => updateDescription(row, e.target.value)" />
                    <div v-else>{{ row.description }}</div>
                </template>
                <template slot="hidden" slot-scope="props">
                    <div class="text-center">
                        <i @click="toggle(props.row, 'hidden')"
                            :class="{ 'text-gray-300': !project.isAdmin, 'cursor-pointer': project.isAdmin, 'fa fa-check': props.row.hidden, 'fa-regular fa-square': !props.row.hidden }"></i>

                    </div>
                </template>
                <template slot="includeInDownload" slot-scope="props">
                    <div class="text-center">
                        <i @click="toggle(props.row, 'includeInDownload')"
                            :class="{ 'text-gray-300': !project.isAdmin, 'cursor-pointer': project.isAdmin, 'fa fa-check': props.row.includeInDownload, 'fa-regular fa-square': !props.row.includeInDownload }"></i>

                    </div>
                </template>
                <template slot="actions" slot-scope="props">
                    <div class="text-center">
                        <i class="fa fa-download cursor-pointer" @click="download(props.row)"></i>
                        <!-- <i v-if="project.isAdmin" class="fa !cursor-pointer" :class="{'fa-eye':!props.row.hidden, 'fa-eye-slash':props.row.hidden}" v-tooltip="!props.row.hidden?$t('Hide from users'):$t('Show to users')" @click="hideToggle(props.row)"></i> -->
                        <i class="fa fa-trash cursor-pointer" v-if="project.isAdmin" @click="removeClick(props.row)"></i>
                    </div>
                </template>
            </v-client-table>
            <file-upload @getID="onGetModalID" :multiple="true" @filesUploaded="onUploadFiles"
                :validMimeTypes="validMimeTypes" :fileNameExcludes="tableData.map(r => r.fileName)">
            </file-upload>
        </div>
    </div>
</template>

<script>
import FileUploaderComponent from './FileUploaderComponent.vue';
import { API_URL, confirmDialog, downloadStreamFile } from '@/lib/common';
import JSZip from 'jszip';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';
import { eventBus } from '@/lib/eventbus';


export default {
    'name': 'project-files',
    'components': { 'file-upload': FileUploaderComponent, 'pulse-loader': PulseLoader, 'dropdown-button': DropdownButtonWithOptionsVue },
    'props': ['projectId', 'project', 'userList'],

    created() {
        if (module.hot) {
            module.hot.dispose(this.onHotReload)
        }
    },
    destroyed() {
        if (module.hot) {
            module.hot.removeDisposeHandler(this.onHotReload)
        }

    },
    mounted() {
        window.vueContext = this;
    },
    watch: {
        project(newval) {
            this.getProjectFiles();

        },
        tableData(newval, oldval) {
            if (oldval == null && newval != null) { //initial load of table data.
                // console.log(tOhis.tableData.filter(r=>r.includeInDownload).length);
            } else if (oldval != newval) {
            }
        }

    },
    computed: {
        hasFilesInIncludeInDL() {
            return this.tableData.filter(r => r.includeInDownload).length > 0;
        }

    },
    methods: {
        allCheckedOnPage() {
            if (!this.$refs.filesTable) { this.allChecked = false; } else {
                this.allChecked = this.$refs.filesTable.$el.querySelectorAll('input[data-id]:checked').length == this.$refs.filesTable.$el.querySelectorAll('input[data-id]').length;
            }
        },
        resetCheckBoxes() {
            //this.$set(this.tableOptions, 'activeRowId', []);
            this.$refs.filesTable.$el.querySelectorAll('input[data-id]:checked').forEach((n) => {
                n.click();
                //this.onCheckClick(n.getAttribute('data-id'))          
            });
        },
        onCheckAllClick(event) {


            if (this.selectedIds.length == 0) {
                this.$refs.filesTable.$el.querySelectorAll('input[data-id]').forEach((n) => {
                    n.click();
                    // this.onCheckClick(n.getAttribute('data-id'))          
                });
            } else {
                this.$refs.filesTable.$el.querySelectorAll('input[data-id]:checked').forEach((n) => {
                    n.click();
                    // this.onCheckClick(n.getAttribute('data-id'))          
                });
            }

            this.allCheckedOnPage();
        },

        onCheckClick(rowId) {
            rowId = parseInt(rowId);
            let activeRows = [...this.selectedIds];
            let exists = activeRows.indexOf(rowId);

            if (exists > -1) { // Remove
                //this.tableOptions.activeRowId.splice(exists,1);
                activeRows.splice(exists, 1);
                this.$set(this, 'selectedIds', activeRows);
            } else { // Add
                this.$set(this, 'selectedIds', [...this.selectedIds, rowId]);
                //this.tableOptions.activeRowId.push(rowId);
            }
            this.allCheckedOnPage();
        },

        setRowData(row) {
            let i = this.tableData.findIndex(tRow => tRow.id == row.id);
            if (i > -1) this.$set(this.tableData, i, row);

        },
        onUploadFiles(fileArrayData) {
            if (Array.isArray(fileArrayData) && fileArrayData.length > 0) {
                let uploadFileJsonRequests = [];
                let uploadFileRequests = [];
                eventBus.$emit('ProjectFiles:batchUploadStarted', fileArrayData);
                fileArrayData.map(data => {
                    let request = {
                        projectId: this.projectId,
                        fileName: data.file.name,
                        hidden: data.hidden,
                        description: data.description,
                        includeInDownload: data.includeInDownload
                    };
                    eventBus.$emit('ProjectFiles:uploadFileJsonStarted', request);
                    uploadFileJsonRequests.push(this.$http.post(this.urls.addFile, request).then(resp => {

                        if (resp.body.hasOwnProperty('error')) {
                            eventBus.$emit('ProjectFiles:uploadFileJsonComplete', request.fileName, resp.body.error);
                        }
                        else if (resp.body.id) {
                            eventBus.$emit('ProjectFiles:uploadFileJsonComplete', request.fileName);
                            let formdata = new FormData();
                            formdata.append('file', data.file);
                            eventBus.$emit('ProjectFiles:uploadFileStarted', request);

                            uploadFileRequests.push(new Promise((resolve, reject) => {

                                this.$http.post(this.urls.addFileData + resp.body.id, formdata).then(resp => {
                                    if (resp.body.hasOwnProperty('error')) {
                                        eventBus.$emit('ProjectFiles:uploadFileComplete', request.fileName, resp.body.error);
                                    } else {
                                        eventBus.$emit('ProjectFiles:uploadFileComplete', request.fileName);
                                    }
                                    resolve();
                                });

                            }));

                        }
                    }));
                });

                Promise.all(uploadFileJsonRequests).then(resp => {
                    //After all is done.
                    Promise.all(uploadFileRequests).then(resp => {
                        setTimeout(this.getProjectFiles, 200);
                        eventBus.$emit('ProjectFiles:batchUploadComplete', fileArrayData);
                    });
                });

            } else {
                alert("No files were submitted to upload. Skipping");
            }
        },

        getProjectFiles() {
            const self = this;
            this.$http.get(this.urls.getFiles).then(data => {
                if (data.body.hasOwnProperty('error')) {

                } else {
                    self.tableData = data.body;
                    self.$set(self.tableData, data.body);
                    this.originalTableData = data.body;
                }
            });
        },

        onHotReload(info) {
            console.log('hot reload', info);
            window.location.reload();
        },
        onGetModalID(id) {
            this.modalID = id;
            //setTimeout(this.showModal, 100);
        },
        showModal() {
            console.log(this.modalID);
            this.$modal.show(this.modalID);
        },
        date(dateArray) {
            return `${dateArray[0]}-${String(dateArray[1]).padStart(2, 0)}-${String(dateArray[2]).padStart(2, 0)}`;
        },
        hideBatch() {
            if (this.selectedIds.length > 0) {
                this.selectedIds.forEach(id => {
                    let row = this.tableData.find(r => r.id == id);
                    if (row) this.setValue(row, 'hidden', true);
                });
            }
            this.resetCheckBoxes();
        },
        includeInDownloadBatch() {
            if (this.selectedIds.length > 0) {
                this.selectedIds.forEach(id => {
                    let row = this.tableData.find(r => r.id == id);
                    if (row) this.setValue(row, 'includeInDownload', true);
                });
            }
            this.resetCheckBoxes();
        },
        removeClick(row) {
            confirmDialog(this.$modal, this.$t('Remove File'), `${this.$t('Are you sure you want to remove this file?')} ${row.fileName}?`, this.$t('Yes'), () => { this.onRemove(row) }, this.$t('No'), () => { });
        },
        removeBatch() {
            let fileList = [];
            let rows = [];
            this.tableData.map(r => {
                if (this.selectedIds.includes(r.id)) { fileList.push(r.fileName); rows.push(r); }
            });

            confirmDialog(this.$modal, this.$t('Remove File'), `${this.$t('Are you sure you want to remove these files: ')} ${fileList.join(', ')}?`, this.$t('Yes'), () => { this.onRemoveBatch(rows) }, this.$t('No'), () => { });
        },
        onRemoveBatch(rows) {
            rows.forEach(row => this.onRemove(row));
            this.resetCheckBoxes();
        },
        onRemove(row) {
            this.$http.post(this.urls.deleteFile + row.id).then(resp => {
                this.$set(this, 'tableData', this.tableData.filter(r => r.id != row.id));
            });
        },
        toggle(row, field) {
            this.setValue(row, field, !row[field]);
        },
        setValue(row, field, value) {
            row[field] = value;
            if (this.project.isAdmin) { //only allow this action if admin. extra precaution
                this.$http.post(this.urls.updateFile, row).then(resp => {
                    this.setRowData(row);
                });
            }
        },

        updateDescription(row, value) {
            if (row.description != value) {
                row.description = value;
                this.$http.post(this.urls.updateFile, row);
                this.setRowData(row);
            }
        },
        onInput(event, row) {
            if (event.key == "Enter") {
                this.updateDescription(row, event.target.value);
            }
        },
        download(row, batch = false) {
            let fileChunks = row.fileName.toLowerCase().split('.');
            let ext = fileChunks[fileChunks.length - 1];
            let mime = this.validMimeTypes[ext];

            if (!batch) {
                this.$http.get(this.urls.getFileData + row.id, {
                    headers: { 'Content-Type': mime, 'Accept': '*/*' },
                    responseType: 'arraybuffer'
                }).then(data => {
                    if (data.body) {
                        let link = document.createElement('a');
                        link.href = URL.createObjectURL(new Blob([data.body], { type: mime }));
                        link.download = row.fileName;
                        document.body.appendChild(link); // for firefox if link not added into body, click won't work
                        link.click();
                        link.remove();
                    }
                });
            } else {
                return this.$http.get(this.urls.getFileData + row.id, {
                    headers: { 'Content-Type': mime, 'Accept': '*/*' },
                    responseType: 'arraybuffer'
                });
            }

            //downloadStreamFile(`https://${window.location.hostname}/static/dummy.pdf`, row.fileName, 'application/pdf');

        },
        downloadBatch() {
            let batchZip = new JSZip();
            this.downloading = false;
            let promises = [];
            this.tableData.forEach(row => {
                if (this.selectedIds.includes(row.id))
                    promises.push(this.download(row, true));
            });

            Promise.all(promises).then((files) => {
                files.forEach(file => {
                    let row = this.tableData.find(row => row.id == new URL(file.url).searchParams.get('id'));
                    batchZip.file(row.fileName, file.body, { binary: true });
                });
                batchZip.generateAsync({ type: "blob" }).then(content => {
                    let link = document.createElement('a');
                    link.href = URL.createObjectURL(content, { type: 'application/zip' });
                    link.download = this.project.fullNm + '.zip';
                    document.body.appendChild(link); // for firefox if link not added into body, click won't work
                    link.click();
                    link.remove();
                    this.downloading = false;
                });
                this.resetCheckBoxes();
            });
            //this.row
        }
    },
    data() {
        return {
            modalID: null,
            downloading: false,
            allChecked: false,
            urls: {
                'getFiles': API_URL + 'get-project-files?projectId=' + this.projectId,
                'getFileData': API_URL + 'get-project-file-data?id=',
                'addFile': API_URL + 'add-project-file',
                'updateFile': API_URL + 'add-project-file',
                'addFileData': API_URL + 'add-project-file-data?id=',
                'deleteFile': API_URL + 'delete-project-file?id='
            },
            columns: ['check', 'fileName', 'description', 'uploadDate', 'hidden', 'includeInDownload', 'actions'],
            tableData: null, // maniuplate this dataset.
            selectedIds: [],
            validMimeTypes: {
                'pdf': 'application/pdf',
                'doc': 'application/msword',
                'zip': 'application/zip',
                'xzip': 'application/x-zip-compressed',
                'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'csv': 'text/csv',
                'ppt': 'application/vnd.ms-powerpoint',
                'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'xls': 'application/vnd.ms-excel',
                'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'png': 'image/png',
                'jpg': 'image/jpeg',
                'jpeg': 'image/jpeg',
                'gif': 'image/gif',
                'mp4': 'video/mp4',
                'wav': 'audio/x-wav',
                'flac': 'audio/flac',
                'mp3': 'audio/mpeg',
                'tar': 'application/x-tar',
                'gz': 'application/gzip',
                'aac': 'audio/aac',
                'json': 'application/json',
                'xml': 'text/xml',
                'txt': 'text/plain'
            },
            tableOptions: {
                texts: {
                    filter: this.$t('common-filter'),
                    filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
                    count: this.$t('common-tableTexts.count'),
                    first: this.$t('common-tableTexts.first'),
                    last: this.$t('common-tableTexts.last'),
                    // filter: 'Filter:',
                    // filterPlaceholder: 'Search query',
                    limit: this.$t('common-tableTexts.limit'),
                    page: this.$t('common-tableTexts.page'),
                    noResults: this.$t('common-tableTexts.noResults'),
                    filterBy: this.$t('common-tableTexts.filterBy'),
                    loading: this.$t('common-tableTexts.loading'),
                    defaultOption: this.$t('common-tableTexts.defaultOption'),
                    columns: this.$t('common-tableTexts.columns')
                },
                filterable: ['fileName'],
                sortable: ['fileName', 'uploadDate', 'hidden', 'includeInDownload'],
                editableColumns: ['description'],
                perPage: 50,
                perPageValues: [10, 25, 50, 100, 200, 400, 800],
                headings: {
                    'check': '',
                    'fileName': this.$t('visitPhotoGallery-fileName'),
                    'description': this.$t('common-description'),
                    'uploadDate': this.$t('common-uploaded'),
                    'hidden': this.$t('Hidden'),
                    'includeInDownload': this.$t('Inc. D/L')

                }
            }
        }
    }

};
</script>

<style scoped></style>