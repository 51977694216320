var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "iconButton link", on: { click: _vm.closeForm } },
      [_c("span", { staticClass: "ion-close" })]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-header drag-handle" }, [
      _c(
        "h6",
        [
          _vm.uploadingTags == null
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("csvUpload-surveys", { project: _vm.projectName })
                  )
                )
              ]
            : _vm._e(),
          _vm.uploadingTags != null && _vm.uploadingTags
            ? [
                _vm._v(
                  _vm._s(_vm.$t("csvUpload-tags", { project: _vm.projectName }))
                )
              ]
            : _vm._e(),
          _vm.uploadingTags != null && !_vm.uploadingTags
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("csvUpload-tasks", { project: _vm.projectName })
                  )
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-content container csvUpload" },
      [
        _c("div", { staticClass: "flex-row" }, [
          _c(
            "span",
            {
              staticClass: "link",
              class: {
                "ion-arrow-expand": !_vm.showSettings,
                "ion-arrow-shrink": _vm.showSettings
              },
              on: {
                click: function($event) {
                  _vm.showSettings = !_vm.showSettings
                }
              }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.showSettings ? "Hide" : "Show") +
                  " " +
                  _vm._s(_vm.$t("common-fileUpload")) +
                  "\n        "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSettings,
                expression: "showSettings"
              }
            ],
            attrs: { id: "upload-box" }
          },
          [
            _c(
              "div",
              { staticClass: "padded20 center" },
              [
                _vm.uploadingTags == null
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t("csvUpload-subHeaderSurveys", {
                            projectName: _vm.projectName
                          })
                        )
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.uploadingTags != null && _vm.uploadingTags
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t("csvUpload-subHeaderTags", {
                            projectName: _vm.projectName
                          })
                        )
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.uploadingTags != null &&
                !_vm.uploadingTags &&
                _vm.sensor == "ARU"
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "Upload your edited CSV file to make changes to [PROJECT] tasks. Any additions or update will be reflected in [PROJECT] once the tasks are uploaded. Omitted tasks will not be deleted. Large files may take a few minutes to complete.",
                            { projectName: _vm.projectName }
                          )
                        )
                      )
                    ]
                  : _vm.uploadingTags != null && !_vm.uploadingTags
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t("csvUpload-subHeaderTasks", {
                            projectName: _vm.projectName
                          })
                        )
                      )
                    ]
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(" " + _vm.$t("csvUpload-largeFilesWarning")) +
                    "\n        "
                )
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s1 m3 xl4" }),
              _vm._v(" "),
              _c("div", { staticClass: "col s5 m5 xl3" }, [
                _c("input", {
                  ref: "chooseFile",
                  staticClass: "form-control hidden file-chooser",
                  attrs: {
                    type: "file",
                    id: "chooseFile",
                    name: "equipCSV",
                    accept: ".csv"
                  },
                  on: { change: _vm.chooseFile }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    staticStyle: { width: "100%" },
                    attrs: { type: "button", id: "browseButton" },
                    on: {
                      click: function($event) {
                        return _vm.clickChooseFile()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-refresh" }),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.csvUpload,
                          expression: "tooltips.csvUpload"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("common-csvUpload")) +
                        "\n            "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "baseline-wrapper col s3 m2 xl3" }, [
                _vm.selectedFile
                  ? _c("span", { staticClass: "baseliner" }, [
                      _vm._v(_vm._s(_vm.selectedFile.name))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s1 m2 xl2" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s1 m3 xl4" }),
              _vm._v(" "),
              _c("div", { staticClass: "col s5 m3 xl3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-border btn-success",
                    attrs: {
                      type: "button",
                      id: "submitButton",
                      disabled: _vm.selectedFile == null || _vm.previewLoading
                    },
                    on: {
                      click: function($event) {
                        return _vm.uploadFile(true)
                      }
                    }
                  },
                  [
                    _vm.uploadingTags == null
                      ? [_vm._v(_vm._s(_vm.$t("csvUpload-QAButtonSurveys")))]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uploadingTags != null && _vm.uploadingTags
                      ? [_vm._v(_vm._s(_vm.$t("csvUpload-QAButtonTags")))]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.uploadingTags != null && !_vm.uploadingTags
                      ? [_vm._v(_vm._s(_vm.$t("csvUpload-QAButtonTasks")))]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s6 m6 xl5" })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "loader-wrapper-small" },
              [
                _vm.previewLoading
                  ? _c("pulse-loader", {
                      staticClass: "preview-pulse",
                      attrs: {
                        loading: true,
                        color: "#C94412",
                        size: "20px",
                        width: 400,
                        height: 400
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _vm.message
          ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.message))])
          : _vm._e(),
        _vm._v(" "),
        _vm.showTable && !_vm.message
          ? [
              _c(
                "div",
                {
                  staticClass: "orange-border padded40 bottom-margin",
                  class: { "success-box": _vm.tableData.length === 0 }
                },
                [
                  _vm.tableData.length > 0 && !_vm.uploading
                    ? _c("v-client-table", {
                        attrs: {
                          data: _vm.tableData,
                          columns: _vm.columns,
                          options: _vm.tableOptions
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function(props) {
                                return [
                                  props.row.isError
                                    ? _c("span", {
                                        staticClass:
                                          "info-icon ion-android-warning"
                                      })
                                    : _c("span", {
                                        staticClass:
                                          "info-icon ion-information-circled"
                                      })
                                ]
                              }
                            },
                            {
                              key: "message",
                              fn: function(props) {
                                return [
                                  props.row.isError
                                    ? [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(props.row.error) +
                                            "\n                "
                                        )
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(props.row.message) +
                                            "\n                "
                                        )
                                      ]
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3429339345
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "loader-wrapper-large" },
                    [
                      _vm.uploading
                        ? [
                            _c("pulse-loader", {
                              staticClass: "preview-pulse",
                              attrs: {
                                loading: true,
                                color: "#C94412",
                                size: "20px",
                                width: 400,
                                height: 400
                              }
                            }),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("common-uploading")) + "...")
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row no-bottom-margin" },
                    [
                      _vm.finishedUpload && !_vm.hasUploadErrors
                        ? _c("div", { staticClass: "finishMsg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("csvUpload-successfullyUpdated")
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.finishedUpload && _vm.hasUploadErrors
                        ? _c("div", { staticClass: "finishMsg" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("csvUpload-failedToUpload")) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.finishedUpload && !_vm.uploading
                        ? [
                            _vm.hasPreviewErrors
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.$t("csvUpload-previewErrorMsg"))
                                  )
                                ])
                              : _c(
                                  "div",
                                  [
                                    _vm.hasChanges
                                      ? [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("csvUpload-passedQA")
                                              ) +
                                              "\n                    "
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "csvUpload-largeFilesWarningPolite"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("csvUpload-noChanges")
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                  ],
                                  2
                                )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.finishedUpload
                        ? _c("div", { staticClass: "col btn-holder" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-border btn-success",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.hasPreviewErrors ||
                                    _vm.uploading ||
                                    !_vm.hasChanges,
                                  id: "uploadButton"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.uploadFile(false)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t("common-uploadItem", {
                                        item:
                                          _vm.uploadingTags == null
                                            ? _vm.$tc("common-survey", 2)
                                            : _vm.uploadingTags
                                            ? _vm.$tc("common-tag", 2)
                                            : _vm.$tc("common-task", 2)
                                      })
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: { "btn-holder": _vm.finishedUpload } },
                        [
                          !_vm.uploading &&
                          (_vm.hasPreviewErrors ||
                            _vm.hasUploadErrors ||
                            (!_vm.finishedUpload && _vm.hasChanges))
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-border btn-success",
                                  attrs: { type: "button", id: "cancelButton" },
                                  on: { click: _vm.cancelUpload }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common-cancel")) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.uploading && !_vm.hasChanges
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-border btn-success",
                                  attrs: { type: "button", id: "cancelButton" },
                                  on: { click: _vm.cancelUpload }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common-back")) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.finishedUpload && !_vm.hasUploadErrors
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-border btn-success",
                                  attrs: { type: "button" },
                                  on: { click: _vm.closeForm }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("common-close")) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }