<template>
  <div id="PCTask-table" class="deployment-table tasks">
      <div class="row col s12 error no-bottom-margin" :class="{ 'error-hide': !message}" v-if="message!=null">{{message}}</div>
      <div v-if="isPublished != null && hasProjectWriteAccess != null" class="right row col s12">
        <dropdown-button class="float-right">
          <template slot="menuTitle">{{$t('common-manage')}}</template>
          <template slot="dropdownButtons">
            <a download class="btn btn-success popover-menu-item" :class="{'downloading': downloadingTags }" @click="downloadTags">
              <span style="font-size: 20px;"></span><span class="spin-icon"></span><template v-if="tableLoadedOnce && $refs.table.data.length === 0">{{$t('taskTable-downloadSurveyTemplate')}}</template><template v-else>{{$t('taskTable-downloadSurveys')}}</template>
            </a>

             <!-- popover + tooltip has been buggy so this v-if part is necessary to avoid bug -->
            <span v-if="!canUpload" v-tooltip="{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}">
              <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover class=" btn btn-success popover-menu-item" @click="uploadSurveysCSV"> <span style="font-size: 20px;"></span> {{$t('common-uploadItem', {item: $tc('common-survey', 2)})}}</button>
            </span>
            <button v-else v-close-popover class=" btn btn-success popover-menu-item" @click="uploadSurveysCSV"> <span style="font-size: 20px;"></span> {{$t('common-uploadItem', {item: $tc('common-survey', 2)})}}</button>
            

            <!-- Download locations -->
            <a download class="group-top btn btn-success popover-menu-item" :class="{'downloading': downloading }" @click="downloadLocationCSV"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('locationTable-downloadCSV')}}</a>
            
            <!-- Upload Locations -->
            <span v-if="!canUpload" v-tooltip="{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}">
              <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span style="font-size: 20px;"></span> {{$t('locationTable-uploadCSV')}}</button>
            </span> 
            <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span style="font-size: 20px;"></span> {{$t('locationTable-uploadCSV')}}</button>  


           
            
            <a download class="group-top btn btn-success popover-menu-item" :class="{'downloading': downloadingCodes }" @click="downloadCodes"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('common-downloadCodes')}}</a>
          </template>
        </dropdown-button>
      </div>
    <v-server-table ref="table" :url="apiUrls.list" :columns="columns" :options="tableOptions" v-if="optionsLoaded"  @loaded="tableLoaded()" class="table vue-bordered taskTable" @row-click="onRowClick">
      <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
      <template slot="locationName" slot-scope="props">
        <a :href="recordingRowUrl(props.row.id)">
          <!--<span class="indicator"
        :class="getClass(props.row.statusType)"
        ></span>-->{{props.row.locationName}}</a>
      </template>
      <template slot='distanceMethodId' slot-scope="props">
      <template>{{distanceMethods.get(props.row.distanceMethodId)}}</template>
      </template>
      <template slot='durationMethodId' slot-scope="props">
          <template>{{durationMethods.get(props.row.durationMethodId)}}</template>
      </template>
    </v-server-table>
    <modal name="delete_task"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "55%"
      height = "420"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
    <task-delete-form v-if="showDeleteForm && taskId > 0"
      v-bind:task-row="taskRow"
      v-bind:task-id="taskId"
      v-bind:headings="tableOptions.headings"
      v-bind:active-sensor="'PC'"/>
  </modal>
  <modal name="project-upload-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <upload-pc-project-csv
        v-bind:project-name='projectName' v-bind:projectId='projectId' sensor="PC" v-bind:upload-url="apiUrls.uploadTagsCSVUrl"
      />
    </modal>
    <modal name="location-upload-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
    <upload-location-csv
      v-if="project.organizationId && options"
      v-bind:organization-id="project.organizationId"
      v-bind:organization-name="project.fullNm"
      v-bind:visibility-options="options.visibility"
      v-bind:project-id="projectId"
      v-bind:project="project"
      v-bind:type="'project-location'"/>
    </modal>
    <v-dialog/>
  </div>
</template>

<script>
/* this component shows the tasks for a point count project in a table
*/
import {API_URL, POINTCOUNT_RECORDING_URL} from '@/lib/common';
import { createLink } from '@/lib/language.js';
import { eventBus } from '@/lib/eventbus';
import TaskDeleteForm from './TaskDeleteForm';
import TaskTableMixin from './TaskTableMixin';
import VueTableMixin from '@/components/common/VueTableMixin';
import TaskTableUploadCSVForm from './TaskTableUploadCSVForm';
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';

export default {
  name: 'TaskTable',
  components: {'task-delete-form': TaskDeleteForm, 'upload-pc-project-csv': TaskTableUploadCSVForm, 'dropdown-button': DropdownButtonWithOptionsVue}, // 'multiselect': Multiselect},
  mixins: [TaskTableMixin, VueTableMixin],
  props: ['isManager', 'hasProjectWriteAccess', 'projectName', 'isPublished', 'sensorId', 'project'],
  methods: {
    uploadSurveysCSV () {
      this.$modal.show('project-upload-form');
    }
  },
  created () {
    this.$set(this.tableOptions.listColumns, 'distanceMethodId', (this.filterOptions.distanceMethods || []).map(x => { return {id: x.id, text: x.type}; }));
    this.$set(this.tableOptions.listColumns, 'durationMethodId', (this.filterOptions.durationMethods || []).map(x => { return {id: x.id, text: x.type}; }));
    // putting distanceMethods and durationMethods into Maps so it's faster for the table
    this.filterOptions.distanceMethods.forEach(method => {
      this.distanceMethods.set(method.id, method.type);
    });

    this.filterOptions.durationMethods.forEach(method => {
      this.durationMethods.set(method.id, method.type);
    });

    this.optionsLoaded = true;

    eventBus.$on('refresh-task-table', () => {
      this.$refs.table.refresh();
    });
  },

  data () {
    return {
      apiUrls: {
        list: API_URL + 'get-surveys-for-project?projectId=' + this.projectId,
        idUrl: createLink(POINTCOUNT_RECORDING_URL),
        assignUser: API_URL + 'randomly-assign-users-not-assigned-pc-tasks',
        downloadTagsCSVUrl: API_URL + 'download-point-count-by-project-id?projectId=',
        downloadCodesURL: API_URL + '/download-point-count-codes',
        uploadTagsCSVUrl: API_URL + 'upload-point-count-csv-for-sync?projectId='
      },
      distanceMethods: new Map(), // (id, type)
      durationMethods: new Map(), // (id, type)
      columns: ['locationName', 'surveyDateTime', 'distanceMethodId', 'durationMethodId', 'observationCount'],
      tableOptions: {
        statusRow: 'statusType',
        showOwnOnly: false,
        filterByColumn: true,
        filterable: ['locationName', 'surveyDateTime', 'distanceMethodId', 'durationMethodId', 'observationCount'],
        sortable: ['locationName', 'surveyDateTime', 'distanceMethodId', 'durationMethodId', 'observationCount'],
        serverMultiSorting: true,
        headings: {
          locationName: this.$tc('common-location', 1),
          surveyDateTime: this.$t('taskTable-PCheadings.surveyDateTime'),
          distanceMethodId: this.$t('taskTable-PCheadings.distanceMethodId'),
          durationMethodId: this.$t('taskTable-PCheadings.durationMethodId'),
          observationCount: this.$t('taskTable-PCheadings.observationCount')
        },
        columnsClasses: {
          locationName: 'site',
          surveyDateTime: 'surveyDateTime',
          distanceMethodId: 'distanceMethod',
          durationMethodId: 'durationMethod',
          observationCount: 'observations'
        },
        listColumns: {}
      }
    }
  }
}
</script>

<style>
  @import './task-table.css';
#PCTask-table .front{
  z-index: 1000000;
}


</style>
