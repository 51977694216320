<template>
  <div class="batchUploadForm">
    <modal name="upload-camera-form"
      :scrollable="true"   :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form !overflow-y-auto"
      draggable=".drag-handle"
      :delay="100"
      width = "80%"
      height = "90%"
      :minWidth="200"
      :minHeight="200"
      :clickToClose = "false">
      <div>
        <div @click="closeForm" class="iconButton link">
          <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle">
          <h6>{{$t('cameraUploadForm-title')}}</h6>
        </div>
        <div>
          <div class="modal-content container" >
            <div class="row">
              <p v-if="! batchUpload.folderUploadSupported">{{$t('cameraUploadForm-BrowserNotSupported')}}</p>
            </div>
            <div v-show="batchUpload.folderUploadSupported" id="batchForm" class="container-fluid">
              <div>
                <template v-for="(msg, index) in message">
                  <div class="error" :key="'msg' + index"> {{msg}}</div>
                </template>
              </div>

              <div  class="margin-bottom-20">
                <div class="tabbed" style="margin-left: 0">
                  <ul class="tabs">
                    <li class="tab" @click="tab = 'upload'"><a v-bind:class="{ active: tab === 'upload'}"><span class="ion-android-upload"></span>{{$t('common-uploading')}}</a></li>
                    <!-- <li class="tab" @click="tab = 'name'"><a v-bind:class="{ active: tab === 'name'}"><span class="ion-ios-copy-outline"></span> File Naming</a></li>         -->
                  </ul>
                  <!-- first tab for more used, upload parameters -->
                  <div class="padded20 boxed dataGroup" v-show="tab === 'upload'">
                    <div class="row folderFormat"  v-show="!batchUpload.status.uploading">
                      <div class="col s12" v-if="isAbmi">
                        <p>{{$t('cameraUploadForm-formatting')}}
                          <span class="info-icon ion-information-circled"
                          v-tooltip="$t('cameraUploadForm-tooltips.formats')"/>
                          <!-- '<li>Organization and Station can not contain dash (-) or underscore (_). </li>'   -->
                          <!-- '<li>Site-Station (OG-ABMI-1600-NE, site1234-station1)</li>' <li>Organization-Site-Station (ABMI-OG-ABMI-1600-NE, CITY-site1234-station1)</li>' + -->
                        </p>
                        <div class="col s12" >
                          <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf1" value="site-only" v-on:change="changeFolderFormatting"><span><span>{{$t('cameraUploadForm-nonABMILocation')}}</span></span></label>
                          <!-- only apply for non-abmi data -->
                          <div class="sub-item-indent" :class="{'blocked': !(batchUpload.settings.folderNamingFormat === null || !batchUpload.settings.folderNamingFormat.includes('abmi'))}">
                            <span class="label col-md-2 col-sm-3" for="settings-usefoldername">{{$t('cameraUploadForm-level')}}
                              <span class="info-icon ion-information-circled" v-tooltip ="{content: (batchUpload.settings.folderNamingFormat === null || !batchUpload.settings.folderNamingFormat.includes('abmi')) ? $t('cameraUploadForm-tooltips.levels') : ''}"></span>
                            </span>
                            <span>
                              <input type="number" class="form-control folderBaseInput" id="settings-usefoldername" value="" min="1" v-model.number="batchUpload.settings.baseLevel" :disabled="batchUpload.settings.folderNamingFormat && batchUpload.settings.folderNamingFormat.includes('abmi')"/>
                            </span>
                          </div>
                        </div>
                        <!-- <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf2" value="site-station" v-on:change="changeFolderFormatting"><span><span>Site-Station</span></span></label>
                        <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf3" value="org-site-station" v-on:change="changeFolderFormatting"><span><span>Organization-Site-Station</span></span></label> -->
                        <div class="col s12 folderFormatHeight">
                          <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf4" value="abmi-station" v-on:change="changeFolderFormatting"><span>
                            <span>{{$t('cameraUploadForm-ABMIStation')}}</span></span>
                          </label>
                        </div>
                        <div class="col s12 folderFormatHeight">
                          <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf4" value="abmi-nostation" v-on:change="changeFolderFormatting"><span>
                            <span>{{$t('cameraUploadForm-ABMINoStation')}}</span></span>
                          </label>
                        </div>
                      </div>

                      <div class="col s12 grid grid-cols-3" v-else> <!-- non abmi -->

                        <div>
                        <span class="label col-md-2 col-sm-3" for="settings-usefoldername">{{$t('cameraUploadForm-level')}}
                          <span class="info-icon ion-information-circled" v-tooltip ="$t('cameraUploadForm-tooltips.levels')"></span>
                        </span>
                        <span class="flex items-center">
                          <span class="mr-2">{{batchUpload.settings.baseLevel}}</span>
                          <input type="range" class="form-control folderBaseInput !m-0" step="1" id="settings-usefoldername" value="" min="1" max="5" v-model.number="batchUpload.settings.baseLevel" :disabled="batchUpload.settings.folderNamingFormat && batchUpload.settings.folderNamingFormat.includes('abmi')"/>
                        </span>
                        </div>

                        <label>
                          <input type="checkbox" class="exclusive checkbox" id="settings-skipdeployment" v-model="batchUpload.settings.skipExisting" />
                          <span>
                            <span class="control-label col-md-2 col-sm-3 !normal-case" for="settings-skipdeployment">{{$t('cameraUploadForm-skipExisting')}}</span>
                            <span  class="info-icon ion-information-circled" v-tooltip="$t('cameraUploadForm-tooltips.skipExisting')"></span>
                          </span>
                        </label>

                      </div>
                    </div>
                    <form v-show="batchUpload.folderUploadSupported" method="GET" role="form" enctype="multipart/form-data" id="batchForm" class="container-fluid">
                      <div class="row" v-show="!batchUpload.status.uploading">
                        <!-- <h6 class="centered">Choose a folder to upload</h6> -->
                        <div class="col s4 offset-s2">

                          <!-- for Better UI, hide the actual folder selection tool, and use a custom button instead -->

                          <input type="file" ref="chooseFolder" id="chooseFolder" name="picture"
                            :value="fileInputVal" accept="image/*"
                            class="form-control hidden" webkitdirectory="true"
                            multiple="true"
                            @change="parseUploadFolders" style="display: none;" />

                          <button type="button" style="width: 90%" class="btn btn-default" id="browseButton"  @click="browseFolder()" :disabled="!batchUpload.settings.folderNamingFormat">
                            <span class="glyphicon glyphicon-refresh"></span> {{$t('common-folderBtn')}}
                          </button>
                        </div>
                        <div class="col s4">
                          <button type="button" style="width: 90%" class="btn btn-secondary" :disabled="!batchUpload.status.totalFileCount" @click="resetBatchUpload">{{$t('Clear Folder Selection')}}</button>
                        </div>
                      </div>
                    </form>



                    <div id="batchUploadPreview" v-if="batchUpload.status.filesParsed">
                      <!-- show preview data as vue components -->
                      <!-- <p> Total of <b>{{batchUpload.status.totalFileCount}}</b> files found.</p> -->
                      <p v-for="(w, index) in batchUpload.status.warnings" class="text-danger" :key="'key' + index">{{w}}</p>

                      
                      <div v-if="showCoordsForm">
                        <h5>{{ $t('Enter Missing Coordinates') }}</h5>
                        <div class="p-3 bg-gray-100">
                         <p class="pb-3"> 
                         {{ $t("Once saved, location coordinates will be saved in the organization even if you do not proceed with uploading. If you don't want to enter coordinates for locations one at a time, keep the fields blank and use the 'Manage' button > 'Upload Location CSV' option in the project instead.")}}
                         </p>
                          <p>
                          {{ $t('When coordinates are entered via image upload, Location Visibility is automatically set to Visible. If you want to adjust the Location Visibility, use the Location sync functionalities under the Manage button')}}
                          </p>
                        </div>
                        <div class="mb-5">
                          <table>
                            <tr><th>{{$tc('common-location', 1)}}</th><th>{{$t('common-latitude')}}</th><th>{{$t('common-longitude')}}</th></tr>
                            <template v-for="(location, index) in locations">
                              <tr :key="index" class="!border-b">
                                <td class="p-0">{{ location.name }}<div class="text-red-500 text-sm">&nbsp;</div></td>
                                <td><input 
                                  type="text"
                                  @keyup="latlongValidation(location,'lat')"
                                  :class="{'highlight': (location.latitude==null||location.latitude.length==0) && location.longitude }"
                                  v-model="location.latitude" min="-90" max="90"  @blur="insertUpdateLocation(location)"  >
                                
                                <div class="text-red-500 text-sm">{{location.error||"&nbsp;"}}</div>
                                </td>
                                <td><input 
                                  v-model="location.longitude"
                                  @keyup="latlongValidation(location,'long')"
                                  :class="{'highlight': (location.longitude==null||location.longitude.length==0) && location.latitude }"
                                  type="text" min="-180" max="180" @blur="insertUpdateLocation(location)" />
                                  <div class="text-red-500 text-sm">&nbsp;</div>
                                  </td>
                                <td><span 
                                  :class="{'btn': true, 'btn-default': !location.id, 'btn-success': location.id}"
                                  :disabled="(location.longitude==null||location.longitude.length==0)||(location.latitude==null||location.latitude.length==0)"
                                  @click="insertUpdateLocation(location)"><i :class="{'fas':true, 'fa-save': !location.loading, 'fa-spinner fa-spin': location.loading}"/></span><div class="text-red-500 text-sm">&nbsp;</div></td>
                                
                              </tr>
                            </template>
                          </table>
                        </div>
                        <button type="button" class="btn btn-default" @click="showCoordsForm=false">
                          {{$t('Continue to Upload')}}
                        </button>

                      </div>

                    <div v-else>
                      <h5 v-html="$tc('cameraUploadForm-foundLocations', batchUpload.status.locationCount, {numLocations: batchUpload.status.locationCount}) + ' ' + $tc('cameraUploadForm-foundFiles', batchUpload.status.imageFileCount, {numFiles: batchUpload.status.imageFileCount})"></h5>

                      <ol><!-- , numFiles: batchUpload.status.imageFileCount -->
                        <li v-for="(deployment, dname) in batchUpload.status.deploymentStatus" :id="dname" :key="'deployment' + dname">
                          {{$t('Location')}} <span v-if="deployment.site==deployment.name"></span><span v-else> <a>{{deployment.site}}</a> {{$t('Subfolder')}}:</span> <a>{{deployment.name}}</a>
                          <template v-if="deployment.station">, {{$t('cameraUploadForm-station')}} <b>{{deployment.station}}</b></template>, {{$t('cameraUploadForm-images')}} <a>{{deployment.total}}</a> 
                          <a>
                            <span class="ion ion-android-done-all" v-if="deployment.uploadedStatus" ></span>
                            <span class="ion ion-android-done" v-if="deployment.isAllSkipped">{{$t('cameraUploadForm-skipped')}}</span>
                            <span class="ion ion-android-done" v-if="deployment.readingMetadata">{{$t('cameraUploadForm-scanMetadata')}} <pulse-loader></pulse-loader></span>
                          </a>
                          <div v-if="batchUpload.status.uploading || batchUpload.status.allUploaded" v-show="!deployment.isAllSkipped">
                            <span><a>{{ deployment.completed}}</a> {{$t('cameraUploadForm-uploadSuccess')}},</span>
                            <span v-show="deployment.skipped > 0"><a>{{ deployment.skipped}}</a> {{$t('cameraUploadForm-skipped').toLowerCase()}},</span>
                            <span v-show="deployment.failed > 0 "><a>{{ deployment.failed}}</a> {{$t('cameraUploadForm-failed')}}, </span>
                            <span>{{$t('cameraUploadForm-running')}} {{ Math.floor(0.001*(deployment.endTime - deployment.startTime))}} {{$t('commonUnits-secondsFull')}} </span>
                          </div>
                          <template v-if="deployment.message.length > 0">
                            <p class="error" v-for="(message,index2) in deployment.message" :id="dname" :key="'mesage' + dname + index2">
                              {{message}}
                            </p>
                          </template>
                        </li>
                      </ol>
                      <div class="w-full mt-2.5">

                      <div v-if="batchUpload.status.ignoredFiles.length > 0">
                        <p><b>{{batchUpload.status.ignoredFiles.length}}</b> {{$t('cameraUploadForm-ignored')}}</p>
                        <ul v-for="(f, index) in batchUpload.status.ignoredFiles" :key="'file' + index">
                          <li><b>{{f.error}}</b>: {{f.path}}</li>
                        </ul>
                      </div>

                      <div id="parsingDiv" v-show="filePreChecking"> <!-- not working here, as can't easily detect upload file cancel button -->
                        <span class="warning">{{$t('cameraUploadForm-reading')}}</span>
                      </div>
                      <button type="button" v-if="!batchUpload.status.allUploaded && !batchUpload.status.uploading && this.locations.length>0" class="btn btn-default" @click="showCoordsForm=true">
                          {{$t('Go back to set coordinates')}}
                        </button>
                      <button type="button" v-if="!batchUpload.status.uploading && !batchUpload.status.allUploaded && batchUpload.status.imageFileCount > 0" class="btn btn-default" @click="processBatchUpload">
                        {{$t('common-upload')}}
                      </button>
                      <span class="error" v-if="batchUpload.status.allUploaded">{{$t('cameraUploadForm-uploadComplete')}}</span>
                      <div v-if="batchUpload.status.hasError && !batchUpload.status.uploading">
                        <button type="button" @click="showFailedItems" class="btn btn-default btn-success" >{{$t('cameraUploadForm-viewFailed')}}</button>
                        <button type="button" @click="processFailedItems" class="btn btn-default" >{{$t('cameraUploadForm-uploadFailed')}}</button>
                      </div>
                      <button type="button" v-if="batchUpload.status.uploading" class="btn btn-default">
                        {{$t('common-uploading')}}
                      </button>
                    </div>
                   </div>

                    </div>


                  </div>
                  <div class="padded20 boxed dataGroup" v-show="tab === 'setting'">
                    <div class="row padded10">
                      <div class="col s12 ">
                        <label>
                          <input type="checkbox" class="exclusive checkbox" id="settings-skipdeployment" v-model="batchUpload.settings.skipExisting" />
                          <span>
                            <span class="control-label col-md-2 col-sm-3" for="settings-skipdeployment">{{$t('cameraUploadForm-skipExisting')}}</span>
                            <span  class="info-icon ion-information-circled" v-tooltip="$t('cameraUploadForm-tooltips.skipExisting')"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="row padded10">
                      <div class="col s12">
                        <label>
                          <input type="checkbox" name="settings.overwrite" id="settings-overwrite" v-model="batchUpload.settings.overwriteExisting" />
                          <span>
                            <span>{{$t('cameraUploadForm-overwriteExisting')}}</span>
                            <span class="info-icon ion-information-circled" v-tooltip="$t('cameraUploadForm-tooltips.overwriteExisting')"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <!-- <div class="row padded10">
                      <div class="col s12">
                        <label>
                          <input type="checkbox" id="settings-nostation" v-model="batchUpload.settings.noStationinFolderName" />
                          <span>No station in folder names <span class="info-icon ion-information-circled" v-tooltip ="'whether station names are included in folder'"></span></span>
                        </label>
                      </div>
                    </div>     -->
                  </div>
                </div>
                <!-- end of tabs -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal name="view-failed-form"
      :scrollable="true"   
      :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form"
      draggable=".drag-handle"
      :delay="100"
      width = "60%"
      height = "auto"
      :minWidth="200"
      :minHeight="200"
      :clickToClose = "true">
        <div @click="closeFailedModal" class="iconButton link">
          <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle">
          <h6>{{$t('Failed Upload Log')}}</h6>
        </div>
        <ol class="p-5">
          <template v-for="(deployment, dname) in batchUpload.status.deploymentStatus" :id="dname" >
            <li :key="'deploymentfailed' + dname" v-if="deployment.failed > 0">
              {{$t('cameraUploadForm-location')}} <a>{{deployment.name}}</a>
              <template v-if="deployment.station">, {{$t('cameraUploadForm-station')}} <b>{{deployment.station}}</b></template>, {{$t('cameraUploadForm-failedImages')}} <a>{{deployment.failed}}</a>
              <p  v-for="(failedItem,index2) in deployment.failedUploadImages"  :key="'failedItem' + dname + index2">
                <span>{{displayFilePathForError(failedItem.file)}} </span> <span class="error">{{failedItem.error}}</span>
              </p>
              <p v-if="deployment.pushError">
                {{deployment.pushError}}
              </p>
            </li>
          </template>
        </ol>
    </modal>
  </div>
</template>
<script>
/* this component show camera batch upload from
* TODO: Need to add trim leading zeros *
*/
import {API_URL} from '@/lib/common';
import { s3Uploader } from './cameraUploader';
import { eventBus } from '@/lib/eventbus';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading
import { copyObject } from '../../../lib/common';

export default {
  name: 'camera-batch-upload-form',
  components: { 'pulse-loader': PulseLoader },
  props: {
    'projectId': {
      type: Number,
      required: true
    },
    'awsParam': {type: Object},
    'isAbmi': {type: Boolean},
    organizationId: {type: Number, required: true}
  },
  created () {
    this.resetBatchUpload();
    this.resetBatchUploadSetting(); // set setting params
    window.vueContext = this;
  },
  mounted () {
  // this.$modal.show('upload-form');
  },
  methods: {
    displayFilePathForError(file) {
      console.log(file);
      if (file && file.webkitRelativePath) {
        return file.webkitRelativePath;
      } else return 'Network Error';
    },
    latlongValidation(location, type) {
      if (type == 'lat') {
        if ( location.latitude ) location.latitude = location.latitude.replace(/[^0-9\.\-]+/, '');
        if (location.latitude >= 90 || location.latitude <= -90) {
          location.latitude = '';
        } 
      } else {
        if (location.longitude) location.longitude = location.longitude.replace(/[^0-9.-]+/, '');
        if (location.longitude >= 180 || location.longitude <= -180) {
          location.longitude = '';
        } 
      }
    },
    insertUpdateLocation (location) {

      let error = null;
      const isEmpty = (value) => {
        return value == null || value.length === 0;
      }

      if (isEmpty(location.latitude) && isEmpty(location.longitude)) {
        //Both empty dont display error but dont send update either
      } else if ((!isEmpty(location.latitude) && isEmpty(location.longitude))||(isEmpty(location.latitude) && !isEmpty(location.longitude))  ) {
        // dont update.
        error = this.$t('Both fields must be filled out in order to save');
      } else {
        error = null;
        this.$set(location, 'loading', true); 
        let tempLocation = location;
        delete tempLocation.error;
        delete tempLocation.loading;
        const that = this;

        this.$http.post(API_URL + 'insert-update-location', {...tempLocation, organizationId: this.organizationId}).then(resp => {
          if (!resp.body.error) {
            that.$set(location, 'loading', false); 
            that.locations.find( (e,i)=>{ 
              if (resp.body.name === e.name) { 
                that.$set(that.locations, i, resp.body);  
              } })
            //this.batchUpload.locations = this.batchUpload.locations.filter(e => resp.body.name !== e.name);
          } 
        }, err => {
                this.$set(location, 'error', this.$t('Latitude or Longitude is malformed, the data was not saved.'));
        });
        // update
      }

      this.$set(location, 'error', error);


},
    changeFolderFormatting () {
      if (this.batchUpload.settings.folderNamingFormat && this.batchUpload.settings.folderNamingFormat.includes('abmi')) {
        this.batchUpload.settings.baseLevel = null;
      } else if (this.batchUpload.settings.baseLevel === null || this.batchUpload.settings.baseLevel === '') {
        this.batchUpload.settings.baseLevel = 0;
      }
    },
    closeFailedModal() {
      this.$modal.hide('view-failed-form');
    },
    closeForm () {
      eventBus.$emit('show-upload-form', false);
    },
    deplYearRange () {
      let range = [];
      let y = new Date().getFullYear();
      const lowerBoundary = y - 10;
      while (y >= lowerBoundary) {
        range.push(y);
        y -= 1;
      }
      return range;
    },
    updateAllUploadStatus () {
      if (this.batchUpload.status.deploymentStatus) {
        let allUploaded = true;
        for (let value of Object.entries(this.batchUpload.status.deploymentStatus)) {
          if (!value[1].uploadedStatus && !value[1].isAllSkipped) {
            allUploaded = false;
          }
        }
        if (!allUploaded) {
          return;
        }
        // if all uploaded
        if (this.batchUpload.status.uploading) {
          this.batchUpload.status.uploading = false;
          this.batchUpload.status.allUploaded = true;
        }
      }
    },
    initializeBatchUpload () {
      // TODO:  WHY does document.getElementById('chooseFolder') return null?
      this.set(this.batchUpload, 'folderUploadSupported', true);
      this.batchUpload.show = true;
    },

    browseFolder ($event) {
      if (this.batchUpload.settings.folderNamingFormat === null) {
        alert(this.$t('cameraUploadForm-namingFormat'));
        return;
      }
      if ((this.batchUpload.settings.baseLevel === null || this.batchUpload.settings.baseLevel === '') && !this.batchUpload.settings.folderNamingFormat.includes('abmi')) { // when didn't choose abmi, base folder level is requiredbundleRenderer.renderToStream
        alert(this.$t('cameraUploadForm-folderLevelFormat'));
        return;
      }
      this.fileParsing = true;
      this.$refs.chooseFolder.click();
    },
    resetBatchUpload () {
      s3Uploader.reset();
      this.message = [];
      this.batchUpload.status.uploading = false;
      this.locations = [];
      this.$set(this.batchUpload, 'status', {});
      this.$set(this.batchUpload, 'allUploaded', false);
      this.$set(this, 'fileInputVal', '');
      this.$set(this.batchUpload, 'folderUploadSupported', true);
    },
    resetBatchUploadSetting () {
      const settings = {
        skipExisting: null,
        addFilesToExisting: null, // TODO: fix
        overwriteExisting: null, // TODO: fix
        uploadChunkSize: 0.01, // GB,
        folderNamingFormat: 'site-only',
        baseLevel: 1, // updated to be 1 instead of 0
        parallelUploadCount: 10000,
        numberImagesToCheck:1000, // Number of images to send to create-or-retrieve-camera-image-for-upload-batch at a time
        failedRetryCount: 10,
        imageDBRegisterSize: 100,

        joinedNaming: {
          startLevel: null,
          separator: null,
          leftPadChar: null,
          maxLen: null
        },

        trimOccurrence: {
          at: 0,
          regex: null
        },

        case: null,
        noStationinFolderName: false,
        awsParam: this.awsParam,
        projectId: this.projectId
      };
      this.$set(this.batchUpload, 'settings', settings);
    },

    processBatchUpload () {
      /* do a validation */
      this.message = [];
      const vm = this;

      s3Uploader.registerUpdateCallback(function (deploymentName, statusObject) {
        if (deploymentName) {
          const fileStatus = statusObject.fileStatus;
          const timeType = statusObject.timeType;
          const timeStamp = statusObject.timeStamp;
          const isDeploymentCompelted = statusObject.isDeploymentCompelted;
          const isAllSkipped = statusObject.isAllSkipped;
          const readingMetadata = statusObject.readingMetadata;
          const deploymentId = statusObject.deploymentId;
          const pudIds = statusObject.pudIds;
          if (deploymentId && (vm.batchUpload.status.deploymentStatus[deploymentName].id == null)) {
            vm.batchUpload.status.deploymentStatus[deploymentName].id = deploymentId;
          }

          //console.log(statusObject);

          if (isDeploymentCompelted) {
            vm.batchUpload.status.deploymentStatus[deploymentName].uploadedStatus = true;
            if (pudIds) {
              vm._updatePhase(deploymentName, pudIds);
            }
            if (deploymentId) {
              vm.$http.post(`${vm.ensureHasImagesUrl}?projectId=${vm.projectId}&deploymentId=${deploymentId}`, {}).then(
                  (response) => {
                    if (response.data.hasOwnProperty('error')) {
                    } else {
                    }
                  },
                  (error) => {
                    vm.uploadErrors.push(error);
                  }
                );
            }
          } 

          //do ensure-deployment-has-images-else-delete

          if (fileStatus === 'failed') { // error message attach to single files
            vm.batchUpload.status.deploymentStatus[deploymentName].failedUploadImages.push({file: statusObject.file, error: statusObject.message});
            vm.batchUpload.status.hasError = true;
            vm.uploadErrors.push(statusObject);
          } else { // other messages
            if (statusObject.message) {
              vm.batchUpload.status.deploymentStatus[deploymentName].message.push((statusObject.file || '') + ' ' + statusObject.message);
            }
          }

          if (statusObject.failedDBUpdates) {
            vm.batchUpload.status.deploymentStatus[deploymentName].failedDBUpdates = vm.batchUpload.status.deploymentStatus[deploymentName].failedDBUpdates.concat(statusObject.failedDBUpdates);
          }
          // status.deploymentsWithParseErrors = status.deploymentsWithParseErrors.splice(status.deploymentsWithParseErrors.indexOf(deploymentName), 1); // quick fix not sure about this line
          if (fileStatus) {
            // console.log(deploymentName, ' +1 ', fileStatus)
            vm.batchUpload.status.deploymentStatus[deploymentName][fileStatus]++;
            if (vm.batchUpload.status.deploymentStatus[deploymentName].completed +
                vm.batchUpload.status.deploymentStatus[deploymentName].skipped +
                vm.batchUpload.status.deploymentStatus[deploymentName].failed ===
                vm.batchUpload.status.deploymentStatus[deploymentName].total) {
              vm.batchUpload.status.deploymentStatus[deploymentName].uploadedStatus = true;
              vm.updateAllUploadStatus();
            }
          }
          /* track running time */
          if (timeType != null) {
            if (timeType === 'start') {
              vm.batchUpload.status.deploymentStatus[deploymentName].id = deploymentId;
              vm.batchUpload.status.deploymentStatus[deploymentName].startTime = timeStamp;
              vm.batchUpload.status.deploymentStatus[deploymentName].endTime = timeStamp;
            } else {
              if (vm.batchUpload.status.deploymentStatus[deploymentName].endTime == null || timeStamp > vm.batchUpload.status.deploymentStatus[deploymentName].endTime) {
                vm.batchUpload.status.deploymentStatus[deploymentName].endTime = timeStamp;
              }
            }
          }

          vm.batchUpload.status.deploymentStatus[deploymentName].readingMetadata = readingMetadata;

          if (isAllSkipped) {
            vm.batchUpload.status.deploymentStatus[deploymentName].isAllSkipped = true;
            vm.batchUpload.status.deploymentStatus[deploymentName].skipped = vm.batchUpload.status.deploymentStatus[deploymentName].total;
            vm.updateAllUploadStatus();
          }
        } else { // if deployment is null, for all deployments, it is done;
          if (statusObject.isAllCompleted === true) { // all completed, reload table
            eventBus.$emit('reload-task-table');
            eventBus.$emit('load-project-info');
          }

          if (statusObject.preChecking === true) {
            vm.filePreChecking = true;
          } else {
            vm.filePreChecking = false;
          }
        }
      });

      if (Object.entries(s3Uploader.deployments).length > 0) {
        this.batchUpload.status.uploading = true;
        s3Uploader.uploadV2(this.batchUpload.settings, this.$http);

      } else {
        this.message.push(this.$t('cameraUploadForm-cannotUpload'));
      }
    }, // done batch upload

  
    showFailedItems () {
      this.$modal.show('view-failed-form');
    },
    processFailedItems () {
      // const self = this;
      s3Uploader.deployments = this.batchUpload.status.deploymentStatus; // move failed items to deployments
      let deploymentCount = 0;
      let imgFileCount = 0;
      let dNameStats = {};
      for (let dname in s3Uploader.deployments) {
        /* for one deployment, no failed item, remove them */
        if (s3Uploader.deployments[dname].failedUploadImages.length === 0) {
          this.$delete(s3Uploader.deployments, dname);
          continue;
        }
        /* deployment with failed item, count the deployment in */
        deploymentCount += 1;
        /* queue failed image */
        s3Uploader.deployments[dname].images = [];
        s3Uploader.deployments[dname].failedUploadImages.forEach(x => s3Uploader.deployments[dname].images.push(x.file));

        imgFileCount += s3Uploader.deployments[dname].images.length;
        dNameStats[dname] = {
          total: s3Uploader.deployments[dname].images.length,
          startTime: null,
          endTime: null,
          uploadedStatus: false,
          isAllSkipped: false,
          readingMetadata: false,
          // status: this.uploadStatusParam.notStarted,
          failed: 0,
          completed: 0,
          skipped: 0,
          message: [],
          site: s3Uploader.deployments[dname].site,
          station: s3Uploader.deployments[dname].station,
          failedUploadImages: [],
          failedDBUpdates: []
        };
      }

      if (Object.entries(s3Uploader.deployments).length > 0) {
        // reverse the list as it is more efficient to get the element off the end of an array

        this.batchUpload.status = {
          deploymentCount: deploymentCount,
          imageFileCount: imgFileCount,
          // deployments: Object.assign({}, s3Uploader.deployments), // used for display them
          deploymentStatus: dNameStats,
          warnings: new Set(s3Uploader.warnings),
          ignoredFiles: s3Uploader.ignored.slice(0),
          filesParsed: true,
          deploymentsWithParseErrors: s3Uploader.deploymentsWithParseErrors,
          allUploaded: false,
          hasError: false,
          uploading: true
        }
        s3Uploader.uploadV2(this.batchUpload.settings, this.$http);
      } else {
        this.batchUpload.status = {
          warnings: new Set(s3Uploader.warnings),
          ignoredFiles: s3Uploader.ignored.slice(0),
          deploymentsWithParseErrors: s3Uploader.deploymentsWithParseErrors,
          allUploaded: true,
          hasError: false,
          uploading: false
        }
        this.message.push(this.$t('cameraUploadForm-noData'));
      }
    },
    /* parse selected folders */
    parseUploadFolders (e) {
      this.resetBatchUpload();
      
      const self = this;
      self.fileParsing = true;

      e.stopPropagation();
      e.preventDefault();
      // parse files
      //s3Uploader.parseFiles(e.target.files, this.batchUpload.settings);
      s3Uploader.parseFilesV2(e.target.files, this.batchUpload.settings);

      let deploymentCount = 0;
      let dNameStats = {}
      let imgFileCount = 0;
      
      // got from old code: should have been part of s3Uploader
      
      Object.entries(s3Uploader.deployments).sort((a,b)=> a[0] > b[0]?1:-1).forEach(v=> {
        let dname = v[0];
        let invalidLocationName = false;
        if (s3Uploader.deployments[dname].images.length == 0) 
          return;

        

        //Does the location name contain invalid characters???
        if (!s3Uploader.deployments[dname].site.match(/^[\w@_&#\-:\.()]+$/)) {
          invalidLocationName = true;
        }

        

        if (!invalidLocationName && !this.locations.find(loc=> loc.name == s3Uploader.deployments[dname].site)) {
          
         

          this.locations.push(
            {
              name: s3Uploader.deployments[dname].site,
              latitude: null,
              longitude: null
            }
          );
        }

        


        deploymentCount += 1;
        
        if (!invalidLocationName)
          imgFileCount += s3Uploader.deployments[dname].images.length;
        
        dNameStats[dname] = {
          total: s3Uploader.deployments[dname].images.length,
          startTime: null,
          endTime: null,
          uploadedStatus: false,
          isAllSkipped: invalidLocationName,
          readingMetadata: false,
          // status: this.uploadStatusParam.notStarted,
          failed: 0,
          completed: 0,
          skipped: 0,
          message: (()=>invalidLocationName?[this.$t('The location name contains invalid characters.')]:[])(),
          name: s3Uploader.deployments[dname].name,
          site: s3Uploader.deployments[dname].site,
          station: s3Uploader.deployments[dname].station,
          failedUploadImages: [],
          failedDBUpdates: []
        };
        s3Uploader.orderList.push({name: dname});
      });
      // reverse the list as it is more efficient to get the element off the end of an array
      // s3Uploader.orderList.reverse();
      this.batchUpload.status = {
        totalFileCount: e.target.files.length,
        deploymentCount: deploymentCount,
        locationCount: this.locations.length,
        imageFileCount: imgFileCount,
        // deployments: Object.assign({}, s3Uploader.deployments), // used for display them
        deploymentStatus: dNameStats,
        warnings: new Set(s3Uploader.warnings), // copy warnings
        ignoredFiles: s3Uploader.ignored.slice(0), // do a shallow copy of array
        filesParsed: true,
        deploymentsWithParseErrors: s3Uploader.deploymentsWithParseErrors,
        allUploaded: false,
        hasError: false,
        uploading: false

      }
      this.fileInputVal = '';
      this.fileParsing = false;
      if (this.locations.length > 0) {
        this.$http.get(`${API_URL}get-locations-from-names?projectId=${this.projectId}&${this.locations.map(loc=>`locationNames=${loc.name}`).join('&')}`).then((response)=>{
            if (response.body.error) {

            } else {
              //If it appears in this array we need to remove it from locations array.
              response.body.map(existingLocation=>{
                let foundIndex = this.locations.findIndex(newLocation=>newLocation.name==existingLocation.name);
                if (foundIndex>-1) {
                  //Lat/long is set to remove fromthe list
                  console.log(existingLocation.latitude);
                  if (existingLocation.latitude != null) {
                    this.locations.splice(foundIndex,1);
                  } else {
                    // if still needs to be updated set the id so update can run when they enter lat/long
                    this.locations[foundIndex].id = existingLocation.id;
                  }
                }
              });
              if(this.locations.length==0) {  this.showCoordsForm = false; }
              else {  this.showCoordsForm = true; }
            }
        });
      } else {
        this.showCoordsForm = false;
      }
    },
    _updatePhase (depName, pudIds) {
      pudIds.forEach(id => {
        this.$http.post(this.updateDeploymentOptionUrl, {id: id}).then(
          (response) => {
            if (response.data.hasOwnProperty('error')) {
              this.batchUpload.status.deploymentStatus[depName].pushError = response.data.error;
            } else {

            }
          },
          (error) => {
            this.batchUpload.status.deploymentStatus[depName].pushError = error;
          }
        );
      })
    }
  },
  data () {
    return {
      showCoordsForm: false,
      locations: [],
      uploadErrors: [],
      batchUpload: { status: {} },
      message: [],
      fileInputVal: '',
      fileParsing: false,
      tab: 'upload',
      updateDeploymentOptionUrl: API_URL + 'update-camera-pud-push-forward',
      ensureHasImagesUrl: API_URL + 'ensure-deployment-has-images-else-delete',
      filePreChecking: false
      // uploadStatus: {},
      // uploadStatusParam: uploadStatusParam,
    }
  }
}

</script>
<style scoped>
.info-icon {
  font-size: 18px;
  color: #FF9800;
}
.tooltip {
  max-width: 600px;
}
.modal-form {
  background-color: #fff;
}
.modal-content   .padded10 {
  padding: 10px 5px;
  margin-bottom: 10px;
}
/* alignment for naming section display */
#batchUploadModal .modal-content .naming .row .col   {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 15px;
  font-size: 1rem;
}

#batchUploadModal .modal-content .label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 1rem;
}

.verticl-align {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 10px
}
#batchUploadModal .modal-content input,
#batchUploadModal .modal-content select {
  height: 50px;
}
.modal-form {
  overflow: hidden;
  top:10%;
}
@media only screen and (min-width: 992px) {
  .modal-form {

  width: 60%;
  }

  .modal-form .modal-header {
    max-height: 100px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 991px) {

  .modal-form .modal-header {
    max-height: 50px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
}

.row .margin-bottom-20 {
  margin-bottom: 20px!important;
}

.batchUploadForm .v--modal-overlay .v--modal-box {
  overflow: auto;
}

.batchUploadForm [type=radio]:checked+span, .batchUploadForm [type=radio]:not(:checked)+span {
  font-size: 0.8rem;
  padding-left: 30px;

}
.folderFormatHeight {
  height: 2em;
}
.folderFormat input.folderBaseInput {
  height: 2em;
  margin-left: 10px;
  width: 100px;
}

.sub-item-indent{
  margin-left: 20px;
}

.blocked{
  opacity: 0.5;
}
</style>
