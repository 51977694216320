<template><div>
 
  <div class="stickyWrap" v-if="project">
    <div>
      <!-- filter popup -->
      <div id="filterDrop" class="filterDrop">
        <div class="container !mx-0 !w-full overflow !mb-5">
          <div class="filterWrap !p-0 !shadow-none">

           
            <form id="filterForm">
              <!-- /* original search */ -->
              <div :class="`flex gap-2`">

              <div class="input !pt-0 flex-grow" v-if="filterMode !=='series'">
                  <multiselect 
                  :selectAllLabel="$t('common-selectAll')" 
                  v-model="filter.triggerModeId" 
                  :options="staticOptions.triggerMode" 
                  :multiple="true" 
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :placeholder="$t('taggingFilter-triggerMode')" 
                  label="type" 
                  track-by="type" 
                  selectLabel="" 
                  deselectLabel=""
                  :limit="3"
                  :limit-text="(count)=>`+${count}`"
                  @open="()=>{ui.dropdownStates.triggerModes=true;}"
                  @close="()=>{ui.dropdownStates.triggerModes=false}"
                  :class="multiselectClasses('triggerModes')">
                      <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
                          <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{ $tc('taggingFilter-numTriggerModes', values.length, {num: values.length}) }}</span>
                          <span class="multiselect__single" v-if="values.length == 1 && !isOpen">{{ values[0].type }}</span>
                      </template> -->
                  </multiselect>
                </div>

                <div class="input !pt-0 flex-grow" v-if="speciesId ==null && options.species">
                  <multiselect 
                  :selectAllLabel="$t('common-selectAll')" 
                  v-model="filter.speciesId" 
                  :options="options.species" 
                  :multiple="true" 
                  :close-on-select="false"
                  @input="updateSpeciesFilter()" 
                  :placeholder="$tc('common-species', 2)" 
                  label="commonName" 
                  track-by="id" 
                  selectLabel="" 
                  deselectLabel=" " 
                  @open="()=>{ui.dropdownStates.species=true;}"
                  @close="()=>{ui.dropdownStates.species=false}"
                  :class="multiselectClasses('species')">
                      
                  </multiselect>
                </div>

                <div  class="input !pt-0 flex-grow" v-if="project.useSpeciesSex">
                  <multiselect 
                  :selectAllLabel="$t('common-selectAll')" 
                  v-model="filter.sexId" 
                  :options="staticOptions.sex" 
                  :multiple="true" :close-on-select="false"
                    :placeholder="$t('taggingFilter-sex')" 
                    label="type" 
                    track-by="type" 
                    selectLabel="" 
                    deselectLabel=" " 
                    @open="()=>{ui.dropdownStates.sex=true;}"
                    @close="()=>{ui.dropdownStates.sex=false}"
                    :class="multiselectClasses('sex')">
                        
                    </multiselect>
                </div>

                <div  class="input !pt-0 flex-grow" v-if="project.useSpeciesAge">
                  <multiselect 
                  :selectAllLabel="$t('common-selectAll')" 
                  v-model="filter.ageId" 
                  :options="staticOptions.age" 
                  :multiple="true" 
                  :close-on-select="false" 
                  :clear-on-select="false"
                  :placeholder="$t('taggingFilter-age')" 
                  label="type" 
                  track-by="type" 
                  selectLabel=""
                  deselectLabel="" 
                  @open="()=>{ui.dropdownStates.age=true;}"
                  @close="()=>{ui.dropdownStates.age=false}"
                  
                  :class="multiselectClasses('age')">
                    </multiselect>
                </div>

                

               <div class="!pt-0 flex flex-grow max-w-xl -mt-4 grid grid-cols-4" >
                    <label class="overflow-hidden" for="searchReview">
                      <input class="" type="checkbox" id="searchReview" v-model="filter.needsReview" />
                      <span class="!normal-case whitespace-nowrap">{{$t('common-needsReview')}}</span>
                    </label>
                    <label for="isVerified" class="block overflow-hidden flex items-center">
                      <input class="" type="checkbox" id="isVerified" v-model="tempVerified" />
                      <span class=" whitespace-nowrap normal-case">{{$t('Verified')}}</span>
                    </label>
              
                    <label for="searchNice" class="block overflow-hidden flex items-center" v-if="project.useNice">
                      <input class="" type="checkbox" id="searchNice" v-model="filter.isNice" />
                      <span class=" whitespace-nowrap normal-case">{{$t('common-nice')}}</span>
                    </label>
             
                      <label for="isUnverified" class="block overflow-hidden flex items-center ">
                        <input class="" type="checkbox" id="isUnverified" v-model="tempUnverified" />
                        <span class=" whitespace-nowrap normal-case">{{$t('taggingHelp-unverified')}}</span>
                      </label>
                      <label class="overflow-hidden " for="autoTagged">
                        <input class="" type="checkbox" id="autoTagged" v-model="filter.excludeTagged" />
                        <span class="!normal-case whitespace-nowrap">{{$t('Exclude Tagged')}}</span>
                      </label>
                      <label class="overflow-hidden " for="hideAutoNone" v-if="project.autoTaggerConfig && project.autoTaggerConfig.filter(c=>c.autoTaggerId==1).length==1">
                        <input class="" type="checkbox" id="hideAutoNone" v-model="filter.hideAuto[1].enabled" />
                        <span class="!normal-case whitespace-nowrap">{{$t('Hide Auto None')}}</span>
                      </label>
                      <label class="overflow-hidden " for="hideAutoHuman" v-if="project.autoTaggerConfig && project.autoTaggerConfig.filter(c=>c.autoTaggerId==3).length==1">
                        <input class="" type="checkbox" id="hideAutoHuman" v-model="filter.hideAuto[3].enabled" />
                        <span class="!normal-case whitespace-nowrap ">{{$t('Hide Auto Human')}}</span>
                      </label>
                      <label class="overflow-hidden " for="hideAutoVehicle" v-if="project.autoTaggerConfig && project.autoTaggerConfig.filter(c=>c.autoTaggerId==2).length==1">
                        <input class="" type="checkbox" id="hideAutoVehicle" v-model="filter.hideAuto[2].enabled" />
                        <span class="!normal-case whitespace-nowrap">{{$t('Hide Auto Vehicle')}}</span>
                      </label>
                      <label class="overflow-hidden " for="hideAutoSpeciesClassifier" v-if="project.autoTaggerConfig && project.autoTaggerConfig.filter(c=>c.autoTaggerId==4).length==1">
                        <input class="" type="checkbox" id="hideAutoSpeciesClassifier" v-model="filter.hideAuto[4].enabled" />
                        <span class="!normal-case whitespace-nowrap ">{{$t('Hide Auto MegaClassifier')}}</span>
                      </label>
                      <label class="overflow-hidden " for="hideAutoStaff"  v-if="project.autoTaggerConfig && project.autoTaggerConfig.filter(c=>c.autoTaggerId==5).length==1">
                        <input class="" type="checkbox" id="hideAutoStaff" v-model="filter.hideAuto[5].enabled" />
                        <span class="!normal-case whitespace-nowrap " style="text-size: 100%">{{$t('Hide Auto Staff')}}</span>
                      </label>
                  
                </div>
              
                
                

              </div>
            
            <div :class="`flex gap-2 items-center mt-1`" v-show="ui.showMoreFilters">

                <div class="!pt-0 overflow-hidden " v-if="project.useIndividualCounts">
                  <input type="text" step="any" class="individualCount !mt-0 !h-[40px] !text-[14px] !mb-0" :placeholder="$t('Count')" :title="$t('taggingFilter-numberOfIndividuals')"
                    v-model="filter.individualCount" />
                </div>

                <template v-for="(attributes, name) in tagOptions">
                  <div :key="name" class="flex-initial flex-grow !pt-0" v-if="project[attributes.enabledKey]">
                    <!-- <label class="overflow-hidden whitespace-nowrap block">{{attributes.label?attributes.label:$t(`taggingFilter-${name}`)}}</label> -->
                    <multiselect 
                    :selectAllLabel="$t('common-selectAll')" 
                    v-model="filter[attributes.key]" 
                    :options="localOptions[name]" 
                    :multiple="attributes.multiple" 
                    :placeholder="attributes.label?attributes.label:$t(`taggingFilter-${name}`)" 
                    :label="attributes.labelKey"
                    :customLabel="attributes.customLabel"
                    :close-on-select="attributes.closeOnSelect"
                    :track-by="attributes.trackBy" 
                    :class="multiselectClasses(name)"
                    :limit="3"
                    :limit-text="(count)=>`+${count}`"
                    @open="()=>{ui.dropdownStates[name]=true;}"
                    @close="()=>{ui.dropdownStates[name]=false}"
                    >
                      
                    </multiselect>
                  </div>
                </template>
              </div>
              <div class="flex gap-2 items-center mt-4" v-show="ui.showMoreFilters">
                <div class="!pt-0 !-mt-3 flex-initial flex-grow ">
                  <div class="grid grid-cols-3 col-span-2" >
                    <label class="overflow-hidden" for="collar" v-if="project.useSpeciesHasCollar">
                      <input class="" type="checkbox" id="collar" v-model="filter.hasCollar" />
                      <span class="!normal-case whitespace-nowrap">{{$t('Collar')}}</span>
                    </label>
                    <label class="overflow-hidden" for="tag" v-if="project.useSpeciesHasTag">
                      <input class="" type="checkbox" id="tag" v-model="filter.hasTag" />
                      <span class="!normal-case whitespace-nowrap">{{$t('Ear Tag')}}</span>
                    </label>
                    <label for="searchHasTines" class="block overflow-hidden flex items-center" v-if="project.useSpeciesTineTracking">
                      <input class="" type="checkbox" id="searchHasTines" v-model="filter.hasTines" />
                      <span class=" whitespace-nowrap normal-case">{{$t('Has Tines')}}</span>
                    </label>
              
                    <label for="searchFire" v-if="project.useFire" class="block overflow-hidden flex items-center">
                      <input class="" type="checkbox" id="searchFire" v-model="filter.isFire" />
                      <span class=" whitespace-nowrap normal-case">{{$t('cameraTagging-fire')}}</span>
                    </label>
                    <label for="interactingWithHumanFeature" class="block overflow-hidden flex items-center" v-if="project.useSpeciesInteractingWithHumanFeature">
                      <input class="" type="checkbox" id="interactingWithHumanFeature" v-model="filter.interactingWithHumanFeature" />
                      <span class=" whitespace-nowrap normal-case">{{$t('IHF')}}</span>
                    </label>
                    <label for="hasSnow" v-if="project.useSnowDepth" class="block overflow-hidden flex items-center">
                      <input class="" type="checkbox" id="hasSnow" v-model="filter.hasSnowOnGround" />
                      <span class=" whitespace-nowrap normal-case">{{$t('Snow')}}</span>
                    </label>
        
                  </div>
                </div>

               

                 <div  class=" input !pt-0 flex-initial flex-grow max-w-sm" v-if="project.useFieldOfView">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="filter.fovId" :options="staticOptions.fieldOfView" :multiple="true" :close-on-select="false" :clear-on-select="false"
                    :placeholder="$t('cameraTagging-fieldOfView')" label="type" track-by="type" selectLabel="" deselectLabel="" class=""
                    @open="()=>{ui.dropdownStates.fov=true;}"
                  @close="()=>{ui.dropdownStates.fov=false}"
                  
                  :class="multiselectClasses('fov')"
                    >
                       
                    </multiselect>
                </div>
              
              

                <div class="input !pt-0 flex-initial flex-grow ">
                  <VueCtkDateTimePicker :locale="$root.$i18n.locale" id="startTime2" :overlay="true" :class="{dtpicker: true}" v-model="startTimeStr"
                    format="YYYY-MM-DD HH:mm"  formatted="llll"  :hint="$t('taggingFilter-selectStartTime')"  :label="$t('taggingFilter-selectStartTime')"
                    :minDate="filterMode ==='tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.startDate ? deploymentInfo.imageSetSummary.startDate.substring(0,10): null"
                    :maxDate="filterMode ==='tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.endDate ? deploymentInfo.imageSetSummary.endDate.substring(0,10): null"
                    >
                  </VueCtkDateTimePicker>
                  <!--  format="YYYY-MM-DD HH:mm" :minDate="deploymentInfo ? deploymentInfo.start_date: ''"
                    :maxDate="deploymentInfo ? deploymentInfo.end_date: ''"  -->
                </div>

                <div class="input !pt-0 flex-initial flex-grow ">
                  <VueCtkDateTimePicker :locale="$root.$i18n.locale" id="endTime" :class="{dtpicker: true}" v-model="endTimeStr"
                    format="YYYY-MM-DD HH:mm" :hint="$t('taggingFilter-selectEndTime')" :label="$t('taggingFilter-selectEndTime')"
                    :minDate="filterMode ==='tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.startDate ? deploymentInfo.imageSetSummary.startDate.substring(0,10): null"
                    :maxDate="filterMode ==='tag' && deploymentInfo.imageSetSummary && deploymentInfo.imageSetSummary.endDate ? deploymentInfo.imageSetSummary.endDate.substring(0,10): null"
                    >
                  </VueCtkDateTimePicker>

                  <!-- <VueCtkDateTimePicker id="startTime4" :overlay="true" :class="{dtpicker: true}" v-model="startTimeStr"
                    format="YYYY-MM-DD HH:mm" :minute-interval="30" hint="Select Start Time 2"  label="Select Start Time">
                  </VueCtkDateTimePicker> -->

                </div>
                <div class="overflow-hidden flex-initial flex-grow ">
                        <label class="whitespace-nowrap ">{{$t('Bounding box display threashold')}}</label>
                        <div class="flex items-center">
                        <span>0</span>
                          <input class="flex-grow !m-0" type="range" min="0" max="1" step="0.01"  v-model="filter.megaDetectorThreshold" />
                        <span>1</span>
                        </div>
                        <div class="w-full text-center -mt-[5px]">{{filter.megaDetectorThreshold}}</div>
                  </div>
                <div v-if="megaDetectorCategories" class="flex-initial flex-grow max-w-sm"">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="filter.aiCategories" :options="megaDetectorCategories" :multiple="true" :close-on-select="false" :clear-on-select="false"
                    :placeholder="$t('AI Categories')" label="type" track-by="id" selectLabel="" deselectLabel="" class=""
                    @open="()=>{ui.dropdownStates.aiCategories=true;}"
                    @close="()=>{ui.dropdownStates.aiCategories=false}"
                    :class="multiselectClasses('aiCategories')"
                    >
                    </multiselect>
                </div>

        


              </div>
                 <!-- buttons! -->

              <div class="grid grid-cols-4  gap-2 w-1/2">
                  <button type="button" id="applyFilters" class="btn w-full !block !float-none !mb-0 !h-[41.6px]" @click="filterImages()">
                    <span class="ion-android-funnel"></span> {{$t('common-update')}}
                  </button>
                  <div class="btn !mb-0 !text-base !text-white !px-3 !py-2 !h-[41.6px] " @click="clearFilter()">
                      <i class="fa-solid fa-delete-left !text-sm text-white"></i> {{$t('common-clear')}}
                  </div>
                  <div class="btn !mb-0 !text-base !text-white !px-3 !py-2 !h-[41.6px]"  @click="resetFilter()">
                      <i class="fa-solid fa-arrows-rotate !text-sm text-white"></i> {{$t('common-reset')}}
                  </div>
                  <div class="btn !mb-0 !px-3 !py-2  cursor-pointer !text-base !h-[41.6px]" @click="()=>{ui.showMoreFilters=!ui.showMoreFilters}">
                    <i :class="{'fa-solid text-sm':true, 'fa-caret-down': !ui.showMoreFilters, 'fa-caret-up': ui.showMoreFilters}"></i> {{$t('More Filters')}}
                  </div>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>
 
  </div>
</div>
</template>

<script>
/* this component is the camera tagging Filter page.
it takes user into and converted them to query parameters and then pass back
*/
import { eventBus } from '@/lib/eventbus';
// import VueCtkDateTimePicker from '@/components/Test/test'; //
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
//  import VueCtkDateTimePicker from '@/components/Test//VueCtkDateTimePicker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import { copyObject } from '@/lib/common';


export default {
  name: 'tagging-filter',
  props: ['options', 'staticOptions', 'deploymentInfo', 'speciesId', 'filterMode', 'useSeriesView', 'initSpeciesId', 'project', 'megaDetectorCategories'],
  components: {'VueCtkDateTimePicker': VueCtkDateTimePicker, 'multiselect': Multiselect},
  computed: {
      filterRowSize() {
        //No two filter modes is not an error. Colspan 2 for first column
        let expressions = [this.filterMode !=='series', this.filterMode !=='series', this.speciesId ==null && this.options.species, this.project.useSpeciesAge, this.project.useSpeciesSex, true,true];
        return expressions.filter(v=>v).length;
      },
      moreFilterRowSize() {
        let expressions = [];
        Object.values(this.tagOptions).forEach(a=>expressions.push(this.project[a.enabledKey]));
        expressions.push(this.project.useSpeciesTineTracking ||  this.project.useNice || this.filter.isFire || this.project.useSpeciesInteractingWithHumanFeature);
        expressions.push(this.project.useFieldOfView);
        expressions.push(true,true,this.project.useIndividualCounts, true, true);
        return Math.ceil(expressions.filter(v=>v).length / 2);
      },
      minMDConfidence() {
      if (typeof this.project != 'undefined') {
        return this.project.defaultMinMegaDetectorConfidence? this.project.defaultMinMegaDetectorConfidence / 100 : 0;
      }
      return 0;
      
    }
  },
  watch: {
    tempUnverified: function (newval, oldval) {
      if (newval != oldval) {
        if (newval) { //checked the value
          this.tempVerified = false;
        } 
      }
    },
    tempVerified: { 
      handler(newval,oldval) {
        if (newval != oldval) {
          if (newval) { //checked the value
            this.tempUnverified = false;
          } 
        }
      }
    }
  },
  created() {
    window.taggingFilterContext = this;
    this.localOptions = copyObject(this.staticOptions);

    this.localOptions.speciesDirection.forEach((e,i)=>{
      let id = e.id;
      this.localOptions.speciesDirection[i] = id;
    });

    this.localOptions.speciesBehaviour.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.behaviourId = e.id;
      delete temp.id;
      this.localOptions.speciesBehaviour[i] = temp;
    });

    this.localOptions.coatColor.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.coatColorId = e.id;
      delete temp.id;
      this.localOptions.coatColor[i] = temp;
    });
    this.localOptions.coatAttribute.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.coatAttributeId = e.id;
      delete temp.id;
      this.localOptions.coatAttribute[i] = temp;
    });
    this.localOptions.health.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.healthId = e.id;
      delete temp.id;
      this.localOptions.health[i] = temp;
    });

    this.localOptions.countAttribute = copyObject(this.staticOptions.tineCountAttribute);
    delete this.localOptions.tineCountAttribute;

    this.localOptions.countAttribute.forEach((e,i)=> {
      let temp = copyObject(e);
      temp.countAttributeId = temp.id;
      this.localOptions.countAttribute[i] = temp;
    });

    this.localOptions.tineProperty.forEach((e,i)=> {
      let temp = copyObject(e);
      temp.tinePropertyId = temp.id;
      this.localOptions.tineProperty[i] = temp;
    });

    this.localOptions.megaDetectorCategories = this.megaDetectorCategories||[];
    this.localOptions.megaDetectorCategories.map((cat, i) => {
      this.localOptions.megaDetectorCategories[i].categoryID = cat.id;
    });

    this.staticOptions.autoTagger.map(v=> {
        this.filter.hideAuto[v.id] = {
          id: v.id,
          type: v.type,
          enabled: false
        };
      });
    

  },
  methods: {
   multiselectClasses(name) {
    return  { 'overflow-hidden':!this.ui.dropdownStates[name], 'border-b border-grey-100':!this.ui.dropdownStates[name]};
   },
   tagOptionClasses(name) {
      let attributes = this.tagOptions[name];
      let obj = {'':true, 'overflow-hidden':!this.ui.dropdownStates[attributes.key]}; 
      obj[attributes.classes] = attributes.classes; 
      return obj;
    },
    filterImages () {
      /* convert selections object into arraies of ids */
      this.queryParam = {};
      if (this.filterMode !== 'fov') {
        // dont apply the filter to fov
        this.queryParam = Object.assign(this.queryParam, this.filter);
        /* when it is at species view */
        if (this.speciesId) {
          this.queryParam.speciesId = [];
          this.queryParam.speciesId.push(this.speciesId);
        } else {
          this.queryParam.speciesId = this.filter.speciesId ? this.filter.speciesId.map(opt => opt.id) : null;
        }
        this.queryParam.sexId = this.filter.sexId ? this.filter.sexId.map(opt => opt.id) : null;

        this.queryParam.ageId = this.filter.ageId ? this.filter.ageId.map(opt => opt.id) : null;
        this.queryParam.triggerModeId = this.filter.triggerModeId ? this.filter.triggerModeId.map(opt => opt.id) : null;
        this.queryParam.fovId = this.filter.fovId ? this.filter.fovId.map(opt => opt.id) : null;

        //New fields
        if (this.queryParam.behaviours) {
          this.queryParam.behaviourId = this.filter.behaviours.map(obj=> obj.behaviourId);
          delete this.queryParam.behaviours;
        }
        if (this.queryParam.coatColors) {
          this.queryParam.coatColorId = this.filter.coatColors.map(obj=> obj.coatColorId);
          delete this.queryParam.coatColors;
        }
        if (this.queryParam.healthConditions) {
          this.queryParam.healthId = this.filter.healthConditions.map(obj=> obj.healthId);
          delete this.queryParam.healthConditions;
        }
        if (this.queryParam.coatAttributes) {
          this.queryParam.coatAttributeId = this.filter.coatAttributes.map(obj=> obj.coatAttributeId);
          delete this.queryParam.coatAttributes;
        }
        if (this.queryParam.direction) {
          this.queryParam.directionId = this.queryParam.direction
          delete this.queryParam.direction;
        }


        /* filter needs verified on UI, but filter isVerified on backend, use opposite value */
        if (this.tempVerified) {
          this.queryParam.isVerified = true;
        } else if (this.tempUnverified) {
          this.queryParam.isVerified = false;
        } else {
          this.queryParam.isVerified = null;
        }

        if (this.deploymentInfo && this.deploymentInfo.imageSetSummary) {
        // don't put start in query considtion when it is same a the time range.
          if (this.deploymentInfo.imageSetSummary.startDate && this.deploymentInfo.imageSetSummary.startDate.substring(0, this.deploymentInfo.imageSetSummary.startDate.length - 3) !== this.startTimeStr) {
            this.queryParam.startTimeStr = this.startTimeStr;
          }
          if (this.deploymentInfo.imageSetSummary.endDate && this.deploymentInfo.imageSetSummary.endDate.substring(0, this.deploymentInfo.imageSetSummary.endDate.length - 3) !== this.endTimeStr) {
            this.queryParam.endTimeStr = this.endTimeStr;
          }
        } else {
          this.queryParam.startTimeStr = this.startTimeStr;
          this.queryParam.endTimeStr = this.endTimeStr;
        }

      
      }

      if (this.useSeriesView) {
          this.queryParam.useSeriesView = true;
          this.queryParam.limit = 999;
        } else {
          this.queryParam.limit = 60;
        }
      // const self = this;

      // this.queryParam.individualCount = this.filter.individual;
      // this.queryParam.isNice = this.filter.isFeatured;
      // this.queryParam.isFire = this.filter.isFire;
      // this.queryParam.needReview = this.filter.needReview;
      // this.queryParam.startTimeStr = this.filter.startTime;
      // this.queryParam.endTimeStr = this.filter.endTime;
      this.queryParam.megaDetectorThreshold = this.filter.megaDetectorThreshold;
      
      if (this.filter.aiCategories) {
        this.queryParam.megaDetectorCategoryId = [];
        this.filter.aiCategories.map(v=> this.queryParam.megaDetectorCategoryId.push(v.id));
        delete this.queryParam.aiCategories;

      }
      if (this.filter.hideAuto) {
        Object.entries(this.filter.hideAuto).forEach(([k,v])=> {
          if(v.enabled) {
            let label = v.type;
            if (v.id == 5) label = "Staff";
            if (v.id == 4) label = "SpeciesClassifier";
            this.queryParam[`hideAuto${label}`] = v.enabled;
          }
        });
        delete this.queryParam.hideAuto;
      }
      eventBus.$emit('apply-filter', this.queryParam);
      this.closeForm();
    },
    getDefaultTriggerMode () {
      return this.staticOptions.triggerMode.filter(x => { return x.id === 2 || x.id < 0 || (this.project && this.project.showTimeLapseByDefault && x.id === 3) ; });
    },
    getDefaultFilters() {
      this.project.autoTaggerConfig
    },  
    clearFilter () {
      this.filter = {triggerModeId: [], speciesId: [], megaDetectorThreshold: 0, hideAuto: {}};

      this.staticOptions.autoTagger.map(v=> {
        this.filter.hideAuto[v.id] = {
          id: v.id,
          type:v.type,
          enabled: false
        };
      });

      // if (this.speciesId) {
      //  try {
      //     this.filter.speciesId.push(this.staticOptions.allSpecies.find(x => x.id == this.speciesId));
      //   } catch (e) { console.log(e)}
      // }
      /* no filter for FOV mode */
      if (this.filterMode === 'series') {
        /* hide auto tagger and show motion only,
        since series view don't show motion trigger input,
        so hard code it when clear filter. */
        this.filter.triggerModeId = this.getDefaultTriggerMode();
      }
      if (this.filterMode !== 'tag' && this.filterMode !== 'fov') {
        return;
      }
      if (this.deploymentInfo.imageSetSummary && this.deploymentInfo.imageSetSummary.startDate) {
        this.startTimeStr = this.deploymentInfo.imageSetSummary.startDate.substring(0, this.deploymentInfo.imageSetSummary.startDate.length - 3); // remove second info
      }
      if (this.deploymentInfo.imageSetSummary && this.deploymentInfo.imageSetSummary.endDate) {
        this.endTimeStr = this.deploymentInfo.imageSetSummary.endDate.substring(0, this.deploymentInfo.imageSetSummary.endDate.length - 3); // remove second info this.deploymentInfo.end_date;
      }
      //    console.log('species obj', this.speciesId, ' matched ' this.staticOptions.allSpecies.find(x => x.id == this.speciesId));
    },
    /*
    Views:
      Tag (Tagging): exclude auto tagged, trigger mode default to motion trigger and unknown.
      subsample: exclude auto tagged, trigger mode default to motion trigger and unknown.
      series view: exclude auto tagged, trigger mode default to motion trigger and unknown.
      field of view: exclude auto tagged.
    */
    resetFilter () {
      this.clearFilter();
      /* no filter for FOV mode */
      if (this.speciesId || this.filterMode === 'fov') { /* fov show all images */
      } else if (this.filterMode === 'tag' || this.filterMode === 'series') { /* hide auto tagger and show motion only */
        this.filter.triggerModeId = this.getDefaultTriggerMode();
      }
        for (const [key, value] of Object.entries(this.filter.hideAuto)) {
          let found = this.project && this.project.autoTaggerConfig2 && this.project.autoTaggerConfig2.filter(config=>config.autoTaggerId == value.id);
          if (found && Array.isArray(found) && found.length>0) {
            this.filter.hideAuto[key].enabled = found[0].hideByDefault;
          }
        }
    },
    closeForm () {
      eventBus.$emit('toggle-form', 'showFilter', false);
    },
    getInitSpeciesName () {
      for (let i = 0; this.options.species.length; i++) {
        if (this.options.species[i].id === this.initSpeciesId) {
          return this.options.species[i].commonName;
        }
      }
    },
    updateSpeciesFilter () {
      this.initSpeciesSelected = false;
    //  let params = new URLSearchParams(window.location.search.slice(1));
      // remove all params except the id
    //  window.history.pushState({}, document.title, window.location.href.split('?')[0] + '?id=' + params.get('id'));
    }
  },

  mounted () { // set default filter
    this.resetFilter();
    /* for initial query parameters */
    if (this.initSpeciesId) {
      this.filter.speciesId = [{id: this.initSpeciesId}];
    }
    if (this.initSpeciesId) {
      // set the name in the filter
      this.initSpeciesName = this.getInitSpeciesName();
      this.initSpeciesSelected = true;
    }
        this.filter.megaDetectorThreshold = 0;

    this.filterImages();
  },
  data () {
    return {
      localOptions: {},
      ui: {
        showMoreFilters: false,
        dropdownStates: {aiCategories: false,fov: false, age: false, sex: false, species: false, fov: false, triggerModes:false,sex: false, age: false, species: false, speciesBehaviour: false, coatAttribute: false,  coatColor: false, health: false, speciesDirection: false},

      },
      tempUnverified: false,
      tempVerified: false,
      filter: {
        triggerModeId: [], 
        speciesId: [], 
        behaviours: [], 
        healthConditions: {}, 
        direction: {}, 
        coatColors: {}, 
        coatAttributes: {}, 
        hasTines: false, 
        megaDetectorThreshold: 0,
        hasSnowOnGround: false,
        isVerified:null,
        aiCategories:[],
        hideAuto: []
     },
      queryParam: {},
      /* need this two parameters to track start end time from calendar component
        if use filter.startTime the components won't update the display. although values were
        set but on page, still shows old time. 
      */
      startTimeStr: null,
      endTimeStr: null,
      initSpeciesName: null,
      initSpeciesSelected: false,
        tagOptions:{
          'speciesBehaviour': 
            {
              labelKey: 'type',
              label: this.$t('Behaviour'),
            trackBy: 'behaviourId', 
            key: 'behaviours', 
            multiple: true, 
            closeOnSelect: false, 
            enabledKey: 'useSpeciesBehaviour', 
            onInput: (event)=>{}
          },
          'health': { 
            labelKey: 'type',
            label: this.$t('Health'), trackBy: 'healthId', key: 'healthConditions', multiple: true, closeOnSelect: false, enabledKey: 'useSpeciesHealth', onInput: ()=>{}
          },
          'speciesDirection': { 
            label: this.$t('Direction'), key: 'direction', multiple: true, closeOnSelect: false, enabledKey: 'useSpeciesDirection', onInput: ()=>{}, classes: 'border-b border-gray-200',
            customLabel: (e)=>`${e} ${this.$t('-o- Clock')}`
          },


          'coatColor': {classes: 'border-b border-gray-200',labelKey: 'type', label: this.$t('Coat Colour'), trackBy: 'coatColorId', key: 'coatColors', multiple: true, closeOnSelect: false, enabledKey: 'useSpeciesCoatColor', onInput: ()=>{}},
          'coatAttribute': {classes: 'border-b border-gray-200', labelKey: 'type',trackBy: 'coatAttributeId', key: 'coatAttributes', multiple: true, closeOnSelect: false, enabledKey: 'useSpeciesCoatAttributes', onInput: ()=>{}},
      }
    }
  }
}
</script>
<style scoped>
.small[type="checkbox"] + span:not(.lever)::before, [type="checkbox"]:not(.filled-in) + span:not(.lever)::after {
  width: 9px;
  height: 9px;
    top: 0px;
left: 4px;
}
.small[type="checkbox"]:checked + span:not(.lever)::before {
  top: -5px;
left: 2px;
width: 6px;
height: 15px;
}
.small[type="checkbox"] + span:not(.lever) {
  height:auto;
}
.remote-camera .filterDrop, .remote-camera .searchDrop {
  display: block;
  position:inherit;
  width: 100%;
  z-index: 9;
}

.dtpicker input{
  font-size: 0.9em!important;
}
.dtpicker label.field-label  {
  color: #C94412!important;
  font-size: 0.9em!important;
  top: 0px!important;
}

.input {
  height: 60px;
}

.filter-link{
  color:#C94412;
}

</style>
