import { render, staticRenderFns } from "./RecordingForms.vue?vue&type=template&id=026bd599&scoped=true"
import script from "./RecordingForms.vue?vue&type=script&lang=js"
export * from "./RecordingForms.vue?vue&type=script&lang=js"
import style0 from "./RecordingForms.vue?vue&type=style&index=0&id=026bd599&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026bd599",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/exile/Extra/Work/Repos/wildtrax/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('026bd599')) {
      api.createRecord('026bd599', component.options)
    } else {
      api.reload('026bd599', component.options)
    }
    module.hot.accept("./RecordingForms.vue?vue&type=template&id=026bd599&scoped=true", function () {
      api.rerender('026bd599', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ARU/RecordingForms.vue"
export default component.exports