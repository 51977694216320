var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading &&
      !_vm.uploading &&
      (!_vm.imageList || _vm.imageList.length === 0)
        ? _c("div", { staticClass: "col s12 no-images" }, [
            _vm._v(_vm._s(_vm.$t("visitPhotoGallery-noneYet")))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.message
        ? _c("div", { staticClass: "col s12 error" }, [
            _vm._v(_vm._s(_vm.message))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col s12" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading || _vm.uploading,
                expression: "loading || uploading"
              }
            ],
            staticClass: "visit-photos-loader-wrapper"
          },
          [
            _c("pulse-loader", {
              attrs: {
                loading: true,
                color: "#C94412",
                size: "20px",
                width: 400,
                height: 400
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "row gallery-wrapper visit-photos-overlay",
            class: { visitPhotosOverlay: _vm.uploading || _vm.loading }
          },
          [
            _vm.imageList && _vm.imageList.length > 0 && _vm.options != null
              ? [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _vm._l(_vm.imageList, function(img, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: "image" + img.id,
                              staticClass: "photo-box p-1 !w-1/5 flex-none",
                              attrs: { id: index }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: `${_vm.$t(
                                        "visitPhotoGallery-fileName"
                                      )}: ${img.fileName} <br /> 
                      ${
                        img.directionId == null
                          ? ""
                          : _vm.$t("visitPhotoGallery-direction") +
                            ": " +
                            Object.values(_vm.options.directions).find(
                              direction => direction.id == img.directionId
                            ).description +
                            " <br />"
                      }
                      ${
                        img.verticalAngle == null
                          ? ""
                          : _vm.$t("visitPhotoGallery-verticalAngle") +
                            ": " +
                            Object.values(_vm.options.verticalAngle).find(
                              angle => angle.id == img.verticalAngle
                            ).type +
                            " <br />"
                      }
                      ${_vm.$t("visitPhotoGallery-access") +
                        ": " +
                        Object.values(_vm.options.imageAccess).find(
                          access => access.id == img.imageAccessId
                        ).type +
                        " <br />"}
                      ${_vm.$t("visitPhotoGallery-comments") +
                        ": " +
                        _vm.limitedText(img.comments)}
                      `,
                                      expression:
                                        "`${$t('visitPhotoGallery-fileName')}: ${img.fileName} <br /> \n                      ${img.directionId == null?'':$t('visitPhotoGallery-direction')+': '+Object.values(options.directions).find(direction=>direction.id==img.directionId).description+' <br />'}\n                      ${img.verticalAngle == null?'':$t('visitPhotoGallery-verticalAngle')+': '+Object.values(options.verticalAngle).find(angle=>angle.id==img.verticalAngle).type+' <br />'}\n                      ${$t('visitPhotoGallery-access')+': '+Object.values(options.imageAccess).find(access=>access.id==img.imageAccessId).type+' <br />'}\n                      ${$t('visitPhotoGallery-comments')+': '+limitedText(img.comments)}\n                      `"
                                    }
                                  ],
                                  staticClass:
                                    "imgWrap h-48 overflow-hidden bg-center bg-cover",
                                  style: {
                                    backgroundImage: `url(${_vm.getImageSrc(
                                      false,
                                      img
                                    )})`
                                  },
                                  attrs: { id: index },
                                  on: {
                                    error: function($event) {
                                      return _vm.imgUrlAlt($event, img)
                                    },
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.showLightbox(
                                        img.largeStoragePath
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !(
                                            _vm.loading || _vm.uploading
                                          ),
                                          expression: "!(loading || uploading)"
                                        }
                                      ],
                                      staticClass: "imgOverlay"
                                    },
                                    [_vm._m(0, true)]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isEditable
                                ? _c(
                                    "div",
                                    {
                                      key: "btns" + img.id,
                                      staticClass: "btns-wrapper row col s12"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            disabled:
                                              _vm.loading || _vm.uploading
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editSitePhoto(
                                                img,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-pencil"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            disabled:
                                              _vm.loading || _vm.uploading
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteSitePhoto(
                                                img,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("lightbox", {
        ref: "lightbox",
        attrs: {
          id: "mylightbox",
          images: _vm.lightboxImages,
          timeoutDuration: 10000,
          "can-download": _vm.isEditable
        }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "edit-visit-photo-info",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "85%",
            height: "55%",
            minWidth: 200,
            minHeight: 200,
            clickToClose: false
          }
        },
        [
          _vm.editingImage != null
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "iconButton link", on: { click: _vm.close } },
                  [_c("span", { staticClass: "ion-close" })]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-header drag-handle" }, [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("visitPhotoGallery-editInfo")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-content padded40 main" }, [
                  _c("div", { staticClass: "col s12 m6 l5" }, [
                    _c("label", { attrs: { for: "editImageDirection" } }, [
                      _vm._v(
                        _vm._s("visitPhotoGallery-direction") + "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editingImage.directionId,
                            expression: "editingImage.directionId"
                          }
                        ],
                        staticClass: "initialized",
                        attrs: { id: "editImageDirection", type: "number" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.editingImage,
                              "directionId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.options.directions, function(option, index) {
                        return _c(
                          "option",
                          {
                            key: "direction" + index,
                            domProps: { value: option.id }
                          },
                          [_vm._v(_vm._s(option.description))]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col s12 m6 l5" }, [
                    _c("label", { attrs: { for: "editImageAngle" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("visitPhotoGallery-verticalAngle")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editingImage.verticalAngle,
                            expression: "editingImage.verticalAngle"
                          }
                        ],
                        staticClass: "initialized",
                        attrs: { id: "editImageAngle", type: "number" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.editingImage,
                              "verticalAngle",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.options.verticalAngle, function(
                        option,
                        index
                      ) {
                        return _c(
                          "option",
                          {
                            key: "vAngle" + index,
                            domProps: { value: option.id }
                          },
                          [_vm._v(_vm._s(option.type))]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col s12 m6 l5" }, [
                    _c("label", { attrs: { for: "editImageAccess" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("visitPhotoGallery-access")) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editingImage.imageAccessId,
                            expression: "editingImage.imageAccessId"
                          }
                        ],
                        staticClass: "initialized",
                        attrs: { id: "editImageAccess", type: "number" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.editingImage,
                              "imageAccessId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.options.imageAccess, function(option, index) {
                        return _c(
                          "option",
                          {
                            key: "access" + index,
                            domProps: { value: option.id }
                          },
                          [_vm._v(_vm._s(option.type))]
                        )
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col s12" }, [
                    _c("label", { attrs: { for: "editImageComments" } }, [
                      _vm._v(_vm._s("visitPhotoGallery-comments"))
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editingImage.comments,
                          expression: "editingImage.comments"
                        }
                      ],
                      staticClass: "materialize-textarea",
                      attrs: { id: "editImageComments", "data-length": "120" },
                      domProps: { value: _vm.editingImage.comments },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.editingImage,
                            "comments",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "character-counter",
                      staticStyle: {
                        float: "right",
                        "font-size": "12px",
                        height: "1px"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer clearFloats" }, [
                    _c("div", { staticClass: "warning" }, [
                      _vm._v(_vm._s(_vm.editMessage))
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-border btn-success modal-action modal-close",
                        on: { click: _vm.saveImgInfo }
                      },
                      [_vm._v(_vm._s(_vm.$t("common-save")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-border btn-success modal-action modal-close",
                        on: { click: _vm.close }
                      },
                      [_vm._v(_vm._s(_vm.$t("common-close")))]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "magnify" }, [
      _c("span", { staticClass: "ion-android-search exclude" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }