<template>
     <modal 
     :name="name" 
     :clickToClose = "false"
     :adaptive="true"
     @opened="opened"
     height="auto"
    :scrollable="true"
    :classes="`v--modal !overflow-visible`"
    overflow="visible"
     >
     <div class="p-2">
      <div @click="closeForm" class="iconButton link">
          <span class="ion-close"></span>
        </div>
        <div class="modal-header bg-gray-200 p-3 rounded" ref="modalHeader">
          <h6>{{$t('Tines')}}</h6>
        </div>
        <div class="modal-content py-3">
            <div v-if="errors" class="text-red-500 text-base" v-html="errors.join('<br />')">
            </div>
            <template  v-for="(tine, tineIndex) in localTineData">
                <div :key="tineIndex" class="flex gap-3 h-full items-center">
                    
                        <div  class="flex-initial w-[100%] h-full p-1">
                            <multiselect :selectAllLabel="$t('common-selectAll')" v-model="localTineData[tineIndex]['tineProperty']" :options="localOptions['tineProperty']" :multiple="false" :close-on-select="true"
                            :placeholder="$t(`taggingFilter-tineProperty`)" label="type" :track-by="'tinePropertyId'" selectLabel="" deselectLabel=" " class="input"
                            @input="(val) => { updateTine('tineProperty', val, tineIndex);}"
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc(`taggingFilter-num-tineProperty`, values.length, {num: values.length}) }}</span>
                                </template>
                            </multiselect>
                        </div>
                        <!-- Tine Count -->
                        <div class="flex-initial w-[100%]">
                            <input type="number" min="0" class="individual w-full" :placeholder="$t('taggingFilter-tineCount')" 
                            :class="`!mb-0`"
                            v-model.number="localTineData[tineIndex].tineCount"/>
                        </div>
                        <div class="flex-initial w-[100%] h-full p-1">
                            <multiselect :selectAllLabel="$t('common-selectAll')" v-model="localTineData[tineIndex]['countAttribute']" :options="localOptions['countAttribute']" :multiple="false" :close-on-select="true"
                            :placeholder="$t(`taggingFilter-countAttribute`)" label="type" :track-by="'countAttributeId'" selectLabel="" deselectLabel=" " class="input"
                            @input="(val) => { updateTine('countAttribute', val, tineIndex);}"
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc(`taggingFilter-num-countAttribute`, values.length, {num: values.length}) }}</span>
                                </template>
                            </multiselect>
                        </div>

                       

                    
                    <div class="flex-0 text-center !p-1">
                        <span class="cursor-pointer text-red-600" @click="removeTine(tineIndex)"><i class="fa-solid fa-xmark"></i></span>
                    </div>
                </div>
            </template>
            <div class="w-full text-center mt-3">
                <button class="btn w-full" @click="addTine">{{$t('Add Tine')}}</button>
            </div>

        </div>
        <div class="modal-footer">
            <button class="btn" :disabled="false" @click="saveClose">{{$t('Save and Close')}}</button>
            <button class="btn btn-default" @click="closeForm">{{$t('common-cancel')}}</button>
        </div>
    </div>
    </modal>
</template>

<script>
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import { copyObject } from '@/lib/common';

export default {
    name: 'tine-modal-form',
    props: {'name':{type:[String]},'tagIndex':{type:Number}, 'tines': {type:[Array]},  'options': {type: Object}},
    components: {'multiselect': Multiselect},
    created() {
        this.localOptions = copyObject(this.options);
        this.localOptions.countAttribute = copyObject(this.options.tineCountAttribute);
        delete this.localOptions.tineCountAttribute;

        this.localOptions.tineProperty.map(prop=> {
            prop.tinePropertyId = prop.id;
        });

        this.localOptions.countAttribute.map(prop=> {
            prop.countAttributeId = prop.id;
        });
    },
    methods: {
        saveClose() {
            this.errors = [];
            this.localTineData.map((tine,i)=> {
                if (!tine.tineCount || !tine.countAttribute || !tine.tineProperty)
                    this.errors.push(`Tine on line ${i+1}  is missing some fields. `);
            });

            if (this.errors.length == 0) {
                this.$emit('tine-update', { 
                    'localTineData': this.localTineData, 
                    'tagIndex': this.tagIndex 
                });
                this.closeForm();
            }
        },
        closeForm() {
            this.errors = [];
            this.$modal.hide(this.name);666666
           // this.$emit('tine-update', this.localTineData, this.tagIndex);
        },
        opened() {
            this.localTineData = copyObject(this.tines);
        },
        addTine() {
            let temp = [...this.localTineData];
                temp.push({
                    countAttribute: null, tineProperty: null, tineCount: null
                });
            this.$set(this, 'localTineData', temp);        
        },
        removeTine(tineIndex) {
            let temp = [...this.localTineData];
            temp.splice(tineIndex, 1);
            this.localTineData = temp; 
        },
        updateTine(prop, value, tineIndex) {
            let temp = [...this.localTineData];
            this.localTineData[tineIndex][prop] = value;
            this.localTineData = temp;
        },
    },
    data() {
        return {
            errors: [],
            localTineData: null,
            localOptions: null,
            tineTagOptions: {
                'tineProperty': {trackBy: 'tinePropertyId', multiple: false, closeOnSelect: true},
                'countAttribute': {multiple: false, trackBy: 'countAttributeId', closeOnSelect: true}
            },
        }
    }
}
</script>
<style scoped>
.v--modal-overlay.scrollable .v--modal-box.v--modal {
    overflow:visible !important;
}

input.individual {
    font-size: 14px;
    height: 40px;
}
</style>