var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c(
        "modal",
        {
          ref: "tagForm",
          attrs: {
            name: _vm.formName,
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form",
            delay: 100,
            resizable: false,
            width: "95%",
            height: "90%",
            minWidth: 400,
            top: 0,
            clickToClose: false
          },
          on: { resize: _vm.getModalWidth }
        },
        [
          _c(
            "div",
            { staticClass: "h-full relative", attrs: { id: "tagging-form" } },
            [
              _c(
                "div",
                { ref: "scroller", staticClass: "modal-scroller !h-[100vh]" },
                [
                  _vm.options.allSpecies != null && _vm.localTag != null
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "modal-content container image-tagging !w-full p-1 !max-w-full flex !h-max overflow-x-hidden"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "w-full max-h-fit" },
                            [
                              _vm.loading
                                ? _c(
                                    "div",
                                    [
                                      _c("pulse-loader", {
                                        attrs: {
                                          loading: true,
                                          color: "#C94412",
                                          size: "15px",
                                          width: 60,
                                          height: 30
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("cameraTagging-updating")
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.error.length > 0 ||
                                        _vm.message.length > 0 ||
                                        _vm.validationMsg.length > 0,
                                      expression:
                                        "error.length > 0 || message.length > 0 || validationMsg.length > 0"
                                    }
                                  ],
                                  staticClass: "link red-font",
                                  on: {
                                    click: function($event) {
                                      _vm.error = []
                                      _vm.validationMsg = []
                                      _vm.message = []
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("cameraTagging-clearMessages")
                                      ) +
                                      "\n              "
                                  ),
                                  _c("span", {
                                    staticClass: "ion-android-close"
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _vm._l(_vm.message, function(msg, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: "msg" + index,
                                          staticClass: "message"
                                        },
                                        [_vm._v(" " + _vm._s(msg))]
                                      )
                                    ]
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _vm._l(_vm.error, function(msg, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: "error" + index,
                                          staticClass: "error"
                                        },
                                        [_vm._v(" " + _vm._s(msg))]
                                      )
                                    ]
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.validationMsg, function(
                                    msg,
                                    index
                                  ) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: "validation" + index,
                                          staticClass: "validation"
                                        },
                                        [_vm._v(" " + _vm._s(msg))]
                                      )
                                    ]
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.localTag.speciesTags, function(
                                tag,
                                index
                              ) {
                                return [
                                  !tag.isLocked
                                    ? _c(
                                        "div",
                                        {
                                          key: "tagRow" + index,
                                          staticClass: "row",
                                          class: {
                                            "!p-1 block  border-b border-b-gray-400": true,
                                            even: index % 2 == 0,
                                            odd: index % 2 == 1,
                                            "batch-update":
                                              tag.hasOwnProperty("isLocked") &&
                                              _vm.isBatchSelected
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "flex-initial" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex grid grid-cols-5 gap-0.5"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex-initial flex-grow"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "overflow-hidden whitespace-nowrap block"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$tc(
                                                                  "common-species",
                                                                  1
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("multiselect", {
                                                          class: {
                                                            "input border-b border-gray-200": true,
                                                            "overflow-hidden": !tag
                                                              .ui.dropdownStates
                                                              .species
                                                          },
                                                          attrs: {
                                                            selectAllLabel: _vm.$t(
                                                              "common-selectAll"
                                                            ),
                                                            options:
                                                              _vm.options
                                                                .allSpecies,
                                                            multiple: false,
                                                            "close-on-select": true,
                                                            disabled:
                                                              _vm.localTag
                                                                .isLocked,
                                                            placeholder: _vm.$tc(
                                                              "common-species",
                                                              1
                                                            ),
                                                            label: "commonName",
                                                            "track-by": "id",
                                                            selectLabel: "",
                                                            deselectLabel: " ",
                                                            "get-option-class":
                                                              _vm.getSpeciesOptClass
                                                          },
                                                          on: {
                                                            open: () => {
                                                              tag.ui.dropdownStates.species = true
                                                            },
                                                            close: () => {
                                                              tag.ui.dropdownStates.species = false
                                                            },
                                                            input: e =>
                                                              _vm.speciesInput(
                                                                e,
                                                                index
                                                              )
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "selection",
                                                                fn: function({
                                                                  values,
                                                                  search,
                                                                  isOpen
                                                                }) {
                                                                  return [
                                                                    values.length &&
                                                                    !isOpen
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "multiselect__single"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .$tc(
                                                                                    "common-speciesSelected",
                                                                                    values.length,
                                                                                    {
                                                                                      num:
                                                                                        values.length
                                                                                    }
                                                                                  )
                                                                                  .toLowerCase()
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value: tag.species,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                tag,
                                                                "species",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "tag.species"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.project
                                                      .useIndividualCounts ||
                                                    _vm.project.useSpeciesAge ||
                                                    _vm.project.useSpeciesSex
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "grid grid-cols-3 gap-2 col-span-2"
                                                          },
                                                          [
                                                            _vm.project
                                                              .useIndividualCounts
                                                              ? _c("div", [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      staticClass:
                                                                        "overflow-hidden whitespace-nowrap block"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Count"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("input", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          tag.individualCount,
                                                                        expression:
                                                                          "tag.individualCount"
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "individual w-full",
                                                                    class: _vm.getClass(
                                                                      tag.species,
                                                                      "count"
                                                                    ),
                                                                    attrs: {
                                                                      placeholder: _vm.$t(
                                                                        "Count"
                                                                      ),
                                                                      readonly:
                                                                        _vm
                                                                          .localTag
                                                                          .isLocked,
                                                                      disabled:
                                                                        _vm
                                                                          .localTag
                                                                          .isLocked,
                                                                      min: "0"
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        tag.individualCount
                                                                    },
                                                                    on: {
                                                                      input: [
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            tag,
                                                                            "individualCount",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          tag.hasChanged = true
                                                                        }
                                                                      ]
                                                                    }
                                                                  })
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.attributesOptions,
                                                              function(
                                                                attributes,
                                                                name
                                                              ) {
                                                                return [
                                                                  _vm.project[
                                                                    attributes
                                                                      .enabledKey
                                                                  ]
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          key: name,
                                                                          staticClass:
                                                                            "flex-initial flex-grow"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "overflow-hidden whitespace-nowrap block"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  name
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "multiselect",
                                                                            {
                                                                              staticClass:
                                                                                "input",
                                                                              class: {
                                                                                "border-b border-gray-200": true,
                                                                                "overflow-hidden ": !tag
                                                                                  .ui
                                                                                  .dropdownStates[
                                                                                  attributes
                                                                                    .key
                                                                                ]
                                                                              },
                                                                              attrs: {
                                                                                selectAllLabel: _vm.$t(
                                                                                  "common-selectAll"
                                                                                ),
                                                                                options:
                                                                                  _vm
                                                                                    .options[
                                                                                    name
                                                                                  ],
                                                                                multiple:
                                                                                  attributes.multiple,
                                                                                "close-on-select":
                                                                                  attributes.closeOnSelect,
                                                                                placeholder: _vm.$t(
                                                                                  `taggingFilter-${name}`
                                                                                ),
                                                                                label:
                                                                                  "type",
                                                                                "track-by":
                                                                                  "id",
                                                                                selectLabel:
                                                                                  "",
                                                                                deselectLabel:
                                                                                  " ",
                                                                                disabled:
                                                                                  _vm
                                                                                    .localTag
                                                                                    .isLocked
                                                                              },
                                                                              on: {
                                                                                input: e => {
                                                                                  tag[
                                                                                    attributes.key +
                                                                                      "Id"
                                                                                  ] = e
                                                                                    ? e.id
                                                                                    : null
                                                                                  tag.hasChanged = true
                                                                                },
                                                                                open: () => {
                                                                                  tag.ui.dropdownStates[
                                                                                    attributes.key
                                                                                  ] = true
                                                                                },
                                                                                close: () => {
                                                                                  tag.ui.dropdownStates[
                                                                                    attributes.key
                                                                                  ] = false
                                                                                }
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "selection",
                                                                                    fn: function({
                                                                                      values,
                                                                                      search,
                                                                                      isOpen
                                                                                    }) {
                                                                                      return [
                                                                                        values.length &&
                                                                                        !isOpen
                                                                                          ? _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "multiselect__single"
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$tc(
                                                                                                      `taggingFilter-num-${name}`,
                                                                                                      values.length,
                                                                                                      {
                                                                                                        num:
                                                                                                          values.length
                                                                                                      }
                                                                                                    )
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                              model: {
                                                                                value:
                                                                                  tag[
                                                                                    attributes
                                                                                      .key
                                                                                  ],
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    tag,
                                                                                    attributes.key,
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "tag[attributes.key]"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.project
                                                      .useSpeciesBehaviour
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-initial flex-grow"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "overflow-hidden whitespace-nowrap block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Behaviour"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("multiselect", {
                                                              class: _vm.tagOptionClasses(
                                                                "speciesBehaviour",
                                                                tag
                                                              ),
                                                              attrs: {
                                                                selectAllLabel: _vm.$t(
                                                                  "common-selectAll"
                                                                ),
                                                                options:
                                                                  _vm
                                                                    .localOptions
                                                                    .speciesBehaviour,
                                                                multiple: true,
                                                                placeholder: _vm.$t(
                                                                  "Behaviour"
                                                                ),
                                                                label: "type",
                                                                "close-on-select": false,
                                                                "track-by":
                                                                  "behaviourId",
                                                                limit: 3,
                                                                "limit-text": count =>
                                                                  `+${count}`
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  tag.hasChanged = true
                                                                },
                                                                open: () => {
                                                                  tag.ui.dropdownStates.speciesBehaviour = true
                                                                },
                                                                close: () => {
                                                                  tag.ui.dropdownStates.speciesBehaviour = false
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  tag.behaviours,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    tag,
                                                                    "behaviours",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "tag.behaviours"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.project.useSpeciesHealth
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-initial flex-grow"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "overflow-hidden whitespace-nowrap block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Health"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("multiselect", {
                                                              class: _vm.tagOptionClasses(
                                                                "healthConditions",
                                                                tag
                                                              ),
                                                              attrs: {
                                                                selectAllLabel: _vm.$t(
                                                                  "common-selectAll"
                                                                ),
                                                                options:
                                                                  _vm
                                                                    .localOptions
                                                                    .health,
                                                                multiple: true,
                                                                placeholder: _vm.$t(
                                                                  "Health"
                                                                ),
                                                                label: "type",
                                                                "close-on-select": false,
                                                                "track-by":
                                                                  "healthId",
                                                                limit: 3,
                                                                "limit-text": count =>
                                                                  `+${count}`
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  tag.hasChanged = true
                                                                },
                                                                open: () => {
                                                                  tag.ui.dropdownStates.healthConditions = true
                                                                },
                                                                close: () => {
                                                                  tag.ui.dropdownStates.healthConditions = false
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  tag.healthConditions,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    tag,
                                                                    "healthConditions",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "tag.healthConditions"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex grid grid-cols-6 gap-0.5"
                                                  },
                                                  [
                                                    _vm.project
                                                      .useSpeciesDirection
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-initial flex-grow"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "overflow-hidden whitespace-nowrap block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Direction"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("multiselect", {
                                                              class: _vm.tagOptionClasses(
                                                                "direction",
                                                                tag
                                                              ),
                                                              attrs: {
                                                                selectAllLabel: _vm.$t(
                                                                  "common-selectAll"
                                                                ),
                                                                options:
                                                                  _vm
                                                                    .localOptions
                                                                    .speciesDirection,
                                                                multiple: false,
                                                                placeholder: _vm.$t(
                                                                  "Direction"
                                                                ),
                                                                customLabel: e =>
                                                                  `${e} ${_vm.$t(
                                                                    "-o- Clock"
                                                                  )}`,
                                                                "close-on-select": true,
                                                                limit: 3,
                                                                "limit-text": count =>
                                                                  `+${count}`
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  tag.hasChanged = true
                                                                },
                                                                open: () => {
                                                                  tag.ui.dropdownStates.direction = true
                                                                },
                                                                close: () => {
                                                                  tag.ui.dropdownStates.direction = false
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  tag.direction,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    tag,
                                                                    "direction",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "tag.direction"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.project
                                                      .useSpeciesCoatColor
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-initial flex-grow"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "overflow-hidden whitespace-nowrap block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Coat Colour"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("multiselect", {
                                                              class: _vm.tagOptionClasses(
                                                                "coatColors",
                                                                tag
                                                              ),
                                                              attrs: {
                                                                selectAllLabel: _vm.$t(
                                                                  "common-selectAll"
                                                                ),
                                                                options:
                                                                  _vm
                                                                    .localOptions
                                                                    .coatColor,
                                                                multiple: true,
                                                                placeholder: _vm.$t(
                                                                  "Coat Colour"
                                                                ),
                                                                label: "type",
                                                                "close-on-select": false,
                                                                "track-by":
                                                                  "coatColorId",
                                                                limit: 3,
                                                                "limit-text": count =>
                                                                  `+${count}`
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  tag.hasChanged = true
                                                                },
                                                                open: () => {
                                                                  tag.ui.dropdownStates.coatColors = true
                                                                },
                                                                close: () => {
                                                                  tag.ui.dropdownStates.coatColors = false
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  tag.coatColors,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    tag,
                                                                    "coatColors",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "tag.coatColors"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.project
                                                      .useSpeciesCoatAttributes
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-initial flex-grow"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "overflow-hidden whitespace-nowrap block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      `taggingFilter-coatAttribute`
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("multiselect", {
                                                              class: _vm.tagOptionClasses(
                                                                "coatAttributes",
                                                                tag
                                                              ),
                                                              attrs: {
                                                                selectAllLabel: _vm.$t(
                                                                  "common-selectAll"
                                                                ),
                                                                options:
                                                                  _vm
                                                                    .localOptions
                                                                    .coatAttribute,
                                                                multiple: true,
                                                                placeholder: _vm.$t(
                                                                  `taggingFilter-coatAttribute`
                                                                ),
                                                                label: "type",
                                                                "close-on-select": false,
                                                                "track-by":
                                                                  "coatAttributeId",
                                                                limit: 3,
                                                                "limit-text": count =>
                                                                  `+${count}`
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  tag.hasChanged = true
                                                                },
                                                                open: () => {
                                                                  tag.ui.dropdownStates.coatAttributes = true
                                                                },
                                                                close: () => {
                                                                  tag.ui.dropdownStates.coatAttributes = false
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  tag.coatAttributes,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    tag,
                                                                    "coatAttributes",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "tag.coatAttributes"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.project
                                                      .useSpeciesTineTracking
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "relative overflow-hidden"
                                                          },
                                                          [
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Tines"
                                                                  )
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                ref:
                                                                  "tineDropdown",
                                                                refInFor: true,
                                                                class: {
                                                                  "cursor-pointer": !_vm
                                                                    .localTag
                                                                    .isLocked,
                                                                  "relative bg-white border-gray-200 border rounded p-2 absolute z-20 w-full tinedropdown max-h-[39px] overflow-hidden": true
                                                                },
                                                                on: {
                                                                  click: () => {
                                                                    if (
                                                                      !_vm
                                                                        .localTag
                                                                        .isLocked
                                                                    )
                                                                      _vm.tineClick(
                                                                        index
                                                                      )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex z-20"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "flex-1"
                                                                      },
                                                                      [
                                                                        !tag.tines ||
                                                                        tag
                                                                          .tines
                                                                          .length ==
                                                                          0
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "text-sm"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "No tines. Click to add."
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._l(
                                                                                  tag.tines,
                                                                                  function(
                                                                                    tine,
                                                                                    tineIndex
                                                                                  ) {
                                                                                    return [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          key: tineIndex,
                                                                                          class: {
                                                                                            "rounded-md  text-sm text-white py-0.5 pl-2 pr-1 ml-1 font-light": true,
                                                                                            "bg-yellow-400/60":
                                                                                              _vm
                                                                                                .localTag
                                                                                                .isLocked,
                                                                                            "bg-yellow-400": !_vm
                                                                                              .localTag
                                                                                              .isLocked
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              domProps: {
                                                                                                innerHTML: _vm._s(
                                                                                                  _vm.formatTineLabel(
                                                                                                    tag
                                                                                                      .tines[
                                                                                                      tineIndex
                                                                                                    ]
                                                                                                  )
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              class: {
                                                                                                "-mr-1  text-gray-600  rounded-md py-0.5 px-2 text-center": true,
                                                                                                "cursor-pointer hover:bg-emerald-600 hover:text-white": !_vm
                                                                                                  .localTag
                                                                                                  .isLocked
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.stopPropagation()
                                                                                                  if (
                                                                                                    !_vm
                                                                                                      .localTag
                                                                                                      .isLocked
                                                                                                  )
                                                                                                    _vm.removeTine(
                                                                                                      tineIndex,
                                                                                                      index
                                                                                                    )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "i",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "fa-solid fa-xmark text-xs"
                                                                                                }
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                )
                                                                              ],
                                                                              2
                                                                            )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                !_vm.localTag
                                                                  .isLocked
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "absolute right-[15px] top-[15px] text-sm"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa-solid fa-caret-down"
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "grid grid-cols-2 gap-2 mt-6"
                                                      },
                                                      [
                                                        _vm.project
                                                          .useSpeciesHasCollar
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "flex-initial overflow-hidden text-ellipsis"
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "flex items-start"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              tag.hasCollar,
                                                                            expression:
                                                                              "tag.hasCollar"
                                                                          }
                                                                        ],
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm
                                                                              .localTag
                                                                              .isLocked,
                                                                          type:
                                                                            "checkbox"
                                                                        },
                                                                        domProps: {
                                                                          checked: Array.isArray(
                                                                            tag.hasCollar
                                                                          )
                                                                            ? _vm._i(
                                                                                tag.hasCollar,
                                                                                null
                                                                              ) >
                                                                              -1
                                                                            : tag.hasCollar
                                                                        },
                                                                        on: {
                                                                          change: [
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  tag.hasCollar,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c = $$el.checked
                                                                                  ? true
                                                                                  : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v = null,
                                                                                  $$i = _vm._i(
                                                                                    $$a,
                                                                                    $$v
                                                                                  )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      tag,
                                                                                      "hasCollar",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      tag,
                                                                                      "hasCollar",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  tag,
                                                                                  "hasCollar",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              tag.hasChanged = true
                                                                            }
                                                                          ]
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span"),
                                                                    _vm._v(" "),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Collar"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "flex items-start"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              tag.hasTag,
                                                                            expression:
                                                                              "tag.hasTag"
                                                                          }
                                                                        ],
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm
                                                                              .localTag
                                                                              .isLocked,
                                                                          type:
                                                                            "checkbox"
                                                                        },
                                                                        domProps: {
                                                                          checked: Array.isArray(
                                                                            tag.hasTag
                                                                          )
                                                                            ? _vm._i(
                                                                                tag.hasTag,
                                                                                null
                                                                              ) >
                                                                              -1
                                                                            : tag.hasTag
                                                                        },
                                                                        on: {
                                                                          change: [
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  tag.hasTag,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c = $$el.checked
                                                                                  ? true
                                                                                  : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v = null,
                                                                                  $$i = _vm._i(
                                                                                    $$a,
                                                                                    $$v
                                                                                  )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      tag,
                                                                                      "hasTag",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      tag,
                                                                                      "hasTag",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  tag,
                                                                                  "hasTag",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              tag.hasChanged = true
                                                                            }
                                                                          ]
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span"),
                                                                    _vm._v(" "),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Tag"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-initial overflow-hidden"
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "need-review flex items-start",
                                                                class: _vm.getClass(
                                                                  tag.species,
                                                                  "age"
                                                                ),
                                                                attrs: {
                                                                  for:
                                                                    "reviewed" +
                                                                    index
                                                                }
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        tag.needsReview,
                                                                      expression:
                                                                        "tag.needsReview"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm
                                                                        .localTag
                                                                        .isLocked,
                                                                    type:
                                                                      "checkbox",
                                                                    id:
                                                                      "reviewed" +
                                                                      index,
                                                                    name:
                                                                      "review"
                                                                  },
                                                                  domProps: {
                                                                    checked: Array.isArray(
                                                                      tag.needsReview
                                                                    )
                                                                      ? _vm._i(
                                                                          tag.needsReview,
                                                                          null
                                                                        ) > -1
                                                                      : tag.needsReview
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            tag.needsReview,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c = $$el.checked
                                                                            ? true
                                                                            : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v = null,
                                                                            $$i = _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                tag,
                                                                                "needsReview",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                tag,
                                                                                "needsReview",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            tag,
                                                                            "needsReview",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        tag.hasChanged = true
                                                                      }
                                                                    ]
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c("span"),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    class: {
                                                                      inactive: !_vm.isEditable
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "cameraTagging-review"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.project
                                                              .useSpeciesInteractingWithHumanFeature
                                                              ? _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "overflow-hidden block flex items-start"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              tag.interactingWithHumanFeature,
                                                                            expression:
                                                                              "tag.interactingWithHumanFeature"
                                                                          }
                                                                        ],
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm
                                                                              .localTag
                                                                              .isLocked,
                                                                          type:
                                                                            "checkbox"
                                                                        },
                                                                        domProps: {
                                                                          checked: Array.isArray(
                                                                            tag.interactingWithHumanFeature
                                                                          )
                                                                            ? _vm._i(
                                                                                tag.interactingWithHumanFeature,
                                                                                null
                                                                              ) >
                                                                              -1
                                                                            : tag.interactingWithHumanFeature
                                                                        },
                                                                        on: {
                                                                          change: [
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  tag.interactingWithHumanFeature,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c = $$el.checked
                                                                                  ? true
                                                                                  : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v = null,
                                                                                  $$i = _vm._i(
                                                                                    $$a,
                                                                                    $$v
                                                                                  )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      tag,
                                                                                      "interactingWithHumanFeature",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      tag,
                                                                                      "interactingWithHumanFeature",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  tag,
                                                                                  "interactingWithHumanFeature",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              tag.hasChanged = true
                                                                            }
                                                                          ]
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span"),
                                                                    _vm._v(" "),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "IHF"
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.isEditable
                                                      ? _c("div", {}, [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "cameraTagging-comments"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  tag.comments,
                                                                expression:
                                                                  "tag.comments"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "tag-comments mt-1",
                                                            attrs: {
                                                              disabled:
                                                                _vm.localTag
                                                                  .isLocked,
                                                              placeholder: _vm.$t(
                                                                "cameraTagging-comments"
                                                              ),
                                                              rows: "1"
                                                            },
                                                            domProps: {
                                                              value:
                                                                tag.comments
                                                            },
                                                            on: {
                                                              input: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  )
                                                                    return
                                                                  _vm.$set(
                                                                    tag,
                                                                    "comments",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  tag.hasChanged = true
                                                                }
                                                              ]
                                                            }
                                                          })
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex flex-1 flex-col ml-1"
                                              },
                                              [
                                                _c("label", [
                                                  _vm.localTag.isLocked
                                                    ? _c("i", {
                                                        staticClass:
                                                          "fa-sharp fa-solid fa-lock text-xl mx-auto"
                                                      })
                                                    : _vm._e()
                                                ]),
                                                _vm._v(" "),
                                                !_vm.localTag.isLocked
                                                  ? [
                                                      !_vm.isBatchSelected
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-error !px-2 !py-1 !text-xs",
                                                              attrs: {
                                                                title: _vm.$t(
                                                                  "cameraTagging-deleteTag"
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteTag(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa-solid fa-trash !text-xs"
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.isBatchSelected &&
                                                      tag.id
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-error !px-2 !py-1 !text-xs",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.batchDeleteTag(
                                                                    tag,
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa-solid fa-trash !text-xs"
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.isBatchSelected &&
                                                      !tag.id
                                                        ? _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-error !px-2 !py-1 !text-xs",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteTag(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa-solid fa-trash !text-xs"
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-border !px-2 !py-1 !text-xs",
                                                          class: _vm.getClass(
                                                            tag.species,
                                                            "age"
                                                          ),
                                                          attrs: {
                                                            title: _vm.$t(
                                                              "cameraTagging-duplicateTag"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.duplicateTag(
                                                                tag
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa-solid fa-copy !text-xs"
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }),
                              _vm._v(" "),
                              _vm.isEditable && !_vm.loading
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.hasTag,
                                          expression: "hasTag"
                                        }
                                      ],
                                      staticClass: "flex row even p-1"
                                    },
                                    [
                                      _c("div", { staticClass: "flex-grow" }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-success",
                                          attrs: {
                                            disabled:
                                              _vm.localTag &&
                                              _vm.localTag.isLocked
                                          },
                                          on: {
                                            click: () => {
                                              if (!_vm.localTag.isLocked)
                                                _vm.addTag()
                                            }
                                          }
                                        },
                                        [_vm._v("+ Add Tag")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex grid grid-cols-8 gap-3 bg-gray-200 p-3"
                                },
                                [
                                  _vm.project.useFieldOfView
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-initital col-span-2"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "col s12 m8":
                                                  _vm.isBatchSelected
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "overflow-hidden whitespace-nowrap block"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("Field of View")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("multiselect", {
                                                class: {
                                                  input: true,
                                                  "overflow-hidden": !_vm.ui
                                                    .task.dropdownStates
                                                    .fieldOfView
                                                },
                                                attrs: {
                                                  selectAllLabel: _vm.$t(
                                                    "common-selectAll"
                                                  ),
                                                  options:
                                                    _vm.options.fieldOfView,
                                                  multiple: false,
                                                  "close-on-select": true,
                                                  disabled:
                                                    _vm.localTag.isLocked ||
                                                    !_vm.localTag.canUpdateFOV,
                                                  placeholder: _vm.$t(
                                                    "cameraTagging-fieldOfView"
                                                  ),
                                                  label: "type",
                                                  "track-by": "id",
                                                  selectLabel: "",
                                                  deselectLabel: " "
                                                },
                                                on: {
                                                  input: function($event) {
                                                    _vm.hasChanged = true
                                                  },
                                                  open: () => {
                                                    _vm.ui.task.dropdownStates.fieldOfView = true
                                                  },
                                                  close: () => {
                                                    _vm.ui.task.dropdownStates.fieldOfView = false
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function({
                                                        values,
                                                        search,
                                                        isOpen
                                                      }) {
                                                        return [
                                                          values.length &&
                                                          !isOpen
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "multiselect__single"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$tc(
                                                                        "taggingFilter-numFieldOfViews",
                                                                        values.length,
                                                                        {
                                                                          num:
                                                                            values.length
                                                                        }
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  468701917
                                                ),
                                                model: {
                                                  value:
                                                    _vm.localTag.task.fovId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.localTag.task,
                                                      "fovId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localTag.task.fovId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.project.useSnowDepth
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "overflow-hidden whitespace-nowrap block"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Snow Depth (m)")) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.localTag.task.snowDepthM,
                                              expression:
                                                "localTag.task.snowDepthM"
                                            }
                                          ],
                                          staticClass: "individual w-full",
                                          attrs: {
                                            type: "number",
                                            placeholder: _vm.$t("Snow Depth"),
                                            disabled:
                                              _vm.localTag.isLocked ||
                                              !_vm.localTag.canUpdateSnowDepth,
                                            readonly: !_vm.isEditable,
                                            min: "0"
                                          },
                                          domProps: {
                                            value: _vm.localTag.task.snowDepthM
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.localTag.task,
                                                "snowDepthM",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.project.useWaterDepth
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "overflow-hidden whitespace-nowrap block"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("Water Depth (m)")
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.localTag.task.waterDepthM,
                                              expression:
                                                "localTag.task.waterDepthM"
                                            }
                                          ],
                                          staticClass: "individual w-full",
                                          attrs: {
                                            type: "number",
                                            placeholder: _vm.$t("Water Depth"),
                                            disabled:
                                              _vm.localTag.isLocked ||
                                              !_vm.localTag.canUpdateWaterDepth,
                                            readonly: !_vm.isEditable,
                                            min: "0"
                                          },
                                          domProps: {
                                            value: _vm.localTag.task.waterDepthM
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.localTag.task,
                                                "waterDepthM",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.project.useSnowTracking
                                    ? _c(
                                        "div",
                                        { staticClass: "flex-initial" },
                                        [
                                          _c("label", [_vm._v(" ")]),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "overflow-hidden whitespace-nowrap block flex items-start"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.localTag.task
                                                        .hasSnowOnGround,
                                                    expression:
                                                      "localTag.task.hasSnowOnGround"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  disabled:
                                                    _vm.localTag.isLocked ||
                                                    !_vm.localTag
                                                      .canUpdateHasSnowOnGround
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.localTag.task
                                                      .hasSnowOnGround
                                                  )
                                                    ? _vm._i(
                                                        _vm.localTag.task
                                                          .hasSnowOnGround,
                                                        null
                                                      ) > -1
                                                    : _vm.localTag.task
                                                        .hasSnowOnGround
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.localTag.task
                                                          .hasSnowOnGround,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.localTag.task,
                                                            "hasSnowOnGround",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.localTag.task,
                                                            "hasSnowOnGround",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.localTag.task,
                                                        "hasSnowOnGround",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span"),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(_vm._s(_vm.$t("Snow")))
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.project.useNice
                                    ? _c(
                                        "div",
                                        { staticClass: "flex-initial" },
                                        [
                                          _c("div", [
                                            _c("label", [_vm._v(" ")]),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "overflow-hidden whitespace-nowrap block flex items-start",
                                                attrs: { for: "featured" }
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.localTag.task.nice,
                                                      expression:
                                                        "localTag.task.nice"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "featured",
                                                    name: "featured",
                                                    disabled:
                                                      _vm.localTag.isLocked ||
                                                      !_vm.localTag
                                                        .canUpdateNice
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.localTag.task.nice
                                                    )
                                                      ? _vm._i(
                                                          _vm.localTag.task
                                                            .nice,
                                                          null
                                                        ) > -1
                                                      : _vm.localTag.task.nice
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          _vm.localTag.task
                                                            .nice,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.localTag.task,
                                                              "nice",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.localTag.task,
                                                              "nice",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.localTag.task,
                                                          "nice",
                                                          $$c
                                                        )
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span"),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class: {
                                                      inactive: !_vm.isEditable
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("common-nice")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.project.useFire
                                    ? _c(
                                        "div",
                                        { staticClass: "flex-initial" },
                                        [
                                          _c("div", [
                                            _c("label", [_vm._v(" ")]),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "overflow-hidden whitespace-nowrap block flex items-start",
                                                attrs: { for: "fire" }
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.localTag.task.fire,
                                                      expression:
                                                        "localTag.task.fire"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "fire",
                                                    name: "fire",
                                                    disabled:
                                                      _vm.localTag.isLocked ||
                                                      !_vm.localTag
                                                        .canUpdateFire
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.localTag.task.fire
                                                    )
                                                      ? _vm._i(
                                                          _vm.localTag.task
                                                            .fire,
                                                          null
                                                        ) > -1
                                                      : _vm.localTag.task.fire
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          _vm.localTag.task
                                                            .fire,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.localTag.task,
                                                              "fire",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.localTag.task,
                                                              "fire",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.localTag.task,
                                                          "fire",
                                                          $$c
                                                        )
                                                      }
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span"),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    class: {
                                                      inactive: !_vm.isEditable
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "cameraTagging-fire"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "flex-initial" }, [
                                    _c("div", [
                                      _c("label", [_vm._v(" ")]),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "overflow-hidden whitespace-nowrap block flex items-start",
                                          attrs: { for: "isMalfunction" }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.localTag.task.malfunction,
                                                expression:
                                                  "localTag.task.malfunction "
                                              }
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "isMalfunction",
                                              name: "isMalfunction",
                                              disabled:
                                                _vm.localTag.isLocked ||
                                                !_vm.localTag
                                                  .canUpdateMalfunction
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.localTag.task.malfunction
                                              )
                                                ? _vm._i(
                                                    _vm.localTag.task
                                                      .malfunction,
                                                    null
                                                  ) > -1
                                                : _vm.localTag.task.malfunction
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.localTag.task
                                                      .malfunction,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.localTag.task,
                                                        "malfunction",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.localTag.task,
                                                        "malfunction",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.localTag.task,
                                                    "malfunction",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span"),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                inactive: !_vm.isEditable
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "cameraTagging-malfunction"
                                                  )
                                                ) + "\n                        "
                                              ),
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value:
                                                      _vm.tooltips.malfunction,
                                                    expression:
                                                      "tooltips.malfunction"
                                                  }
                                                ],
                                                staticClass:
                                                  "info-icon ion-information-circled"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-span-7" }, [
                                    _vm.isEditable
                                      ? _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Comments")) +
                                              ":\n                    "
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.localTag.task.comments,
                                                expression:
                                                  "localTag.task.comments"
                                              }
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.localTag.isLocked ||
                                                !_vm.localTag.canUpdateComments
                                            },
                                            domProps: {
                                              value: _vm.localTag.task.comments
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.localTag.task,
                                                  "comments",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isEditable &&
                                    _vm.localTag.task.comments
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("cameraTagging-comments")
                                            ) + ": "
                                          ),
                                          !_vm.isEditable
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.localTag.task.comments
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "!min-w-[25%] relative p-1 h-[99vh] overflow-x-hidden"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "bg-white w-full h-14" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "iconButton link",
                                      class: { disabled: _vm.loading },
                                      on: { click: _vm.closeForm }
                                    },
                                    [_c("span", { staticClass: "ion-close" })]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "pt-4" }, [
                                    _vm.id.length
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.id.length))
                                        ])
                                      : _c("span", [_vm._v("1")]),
                                    _vm._v(" IMAGE"),
                                    this.id.length
                                      ? _c("span", [_vm._v("S")])
                                      : _vm._e()
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "!sticky top-0" },
                                [
                                  _vm.id.length
                                    ? [
                                        _vm._l(_vm.imageList, function(
                                          value,
                                          index
                                        ) {
                                          return [
                                            _vm.id.indexOf(value.task.id) != -1
                                              ? _c("img", {
                                                  key: index,
                                                  staticClass:
                                                    "cursor-pointer min-w-[25%] mb-2",
                                                  attrs: {
                                                    src: value.fileThumbURL
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.showLightbox(
                                                        value.fileURL
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        })
                                      ]
                                    : _c("img", {
                                        staticClass: "cursor-pointer",
                                        staticStyle: { "min-width": "25%" },
                                        attrs: {
                                          src: _vm.imageList.find(
                                            i => i.task.id == _vm.id
                                          ).fileThumbURL
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            _vm.showLightbox(
                                              _vm.imageList.find(
                                                i => i.task.id == _vm.id
                                              ).fileURL
                                            )
                                          }
                                        }
                                      })
                                ],
                                2
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex p-2 gap-1 absolute bottom-0 right-0 w-full bg-white items-center"
                },
                [
                  _c("div", { staticClass: "flex-grow" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasFormModified,
                          expression: "hasFormModified"
                        }
                      ],
                      staticClass: "text-red-500 font-bold mr-5"
                    },
                    [_vm._v("You have unsaved changes")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success !mb-0 !px-10",
                      attrs: {
                        disabled:
                          (_vm.localTag && _vm.localTag.isLocked) ||
                          !_vm.hasFormModified
                      },
                      on: { click: _vm.save }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-border modal-action modal-close !px-10 !mb-0",
                      class: { disabled: _vm.loading },
                      attrs: { id: "cancelAdd" },
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common-close")))]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("tine-modal", {
        attrs: {
          name: "tineModal",
          tines: _vm.selectedTagTines,
          tagIndex: _vm.selectedTagIndex,
          options: _vm.options
        },
        on: { "tine-update": _vm.storeTineData }
      }),
      _vm._v(" "),
      _vm.imageList
        ? _c("lightbox", {
            ref: "lightbox2",
            attrs: {
              id: "lightbox2",
              images: _vm.generateLightboxImageObjects(),
              "mega-detector-categories": _vm.megaDetectorCategories,
              timeoutDuration: 10000,
              "can-download": _vm.isEditable,
              "z-index": 1000,
              project: _vm.project,
              "disable-md-confidence": true
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }