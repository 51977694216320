<template>
  <div>
      <div class="container overflow boxed homeHead !mb-5" style="padding-top: 5%; padding-bottom: 5%;">
        <div class="headImg image-container" id="download-page">
            <span class="photo-credit white small">Nina Veselka</span>
        </div>

        <h2 class="white-font">Data Downloads    </h2>
  </div>
<div class="container overflow ">
<div class="col s12 panel boxed padded60 dataGroup">
    <div id="msform" >
    <!-- progressbar -->
    <ul id="progressbar">
      <li :class="{active: navStep===1}">{{$tc('common-sensor', 2)}}</li>
      <li :class="{active: navStep===2}">{{$tc('common-project', 2)}}</li>
      <li :class="{active: navStep===3}">{{$t('projectDownload-nav.customize')}}</li>
    </ul>
    <div class="nextPrev">
        <input type="button" name="previous" class="previous btn btn-large" :value="$t('common-prev')" @click="validSelection(-1)" v-if="navStep > 1"/>
        <input type="button" name="next" class="next btn btn-large" :value="$t('common-next')" @click="validSelection(1)" v-if="navStep < 3"/>
    </div>
    <div class="center-align warning" v-if="warning != null">
      <p class="">{{warning}}</p>
    </div>
    <!-- fieldsets -->
    <fieldset v-show="navStep === 1">
      <h2>{{$t('projectDownload-chooseSensor')}}</h2>
      <p class="lead center-align">{{$t('projectDownload-chooseSensorSub')}}</p>

      <div class="row sensorSelect">

          <div v-for="(item, index) in sensors" class="col s12 m12 l3 sensor" :key="'sensor'+index">
            <input  name="sensor" v-model="sensor" type="radio" :id="'sensor'+item.id" :value="item.id" :disabled="false && !item.isSensorAdmin && !item.isManagerOfAnyProject"/>
            <label :for="'sensor'+ item.id">{{$t(`common-${item.id.toLowerCase()}`)}}</label>
          </div>
      </div>
    </fieldset>

    <fieldset v-if="navStep === 2">
      <h2>{{$t('projectDownload-chooseProjects')}}</h2>
      <p class="lead center-align">{{$t('projectDownload-chooseProjectsSub')}}</p>
      <div class="allList" v-if="sensor === 'all'">
        <p class="center-align">{{$t('projectDownload-chooseAll')}}</p>
      </div>
      <div class="aruList">
        <project-table ref="project-table" v-bind:active-sensor="sensor" v-bind:pre-selected="projectIds" v-bind:show-admin-only-projects="true" />
      </div>

    </fieldset>

    <fieldset v-show="navStep === 3">
      <div class="col s3 offset-s4 center">
        <h3>{{$t('common-results')}}</h3>
        <!-- <div class="row col s12 m6 l4">
            <label><span>{{$t('projectDownload-split')}}</span>
              <span class="info-icon ion-information-circled" v-tooltip="$t('projectDownload-splitTooltip')"/>
              <input type="checkbox" v-model="isSplitLocation">
              <span/>
            </label>
          </div> -->
        <a @click="downloadRaw" download :class="{'downloading':isDownloading, 'disabled': projectIds.length == 0}" class="btn btn-large download"><span class="spin-icon"></span>{{$t('projectDownload-downloadRaw')}}</a>
        <!-- <a download class="btn btn-large btn-alt download disabled">{{$t('projectDownload-downloadFWMIS')}}</a> -->
        <a :href="resetUrl" class="btn btn btn-large btn-border" @click="restart"><span class="ion-loop"></span>{{$t('projectDownload-clear')}}</a>
      </div>
    </fieldset>
  </div>
</div>
</div>
</div>
</template>

<script>
import ProjectTableSelection from './ProjectTableSelection';
import Autocomplete from 'vuejs-auto-complete';
import {getAuthorizaitonHeader, API_URL, DATA_DOWNLOAD_URL, attachAuthorizaitonHeader, getParameterByName} from '@/lib/common';
import { createLink } from '@/lib/language.js';

export default {
  name: 'project-download-form',
  components: {'project-table': ProjectTableSelection, 'auto-complete': Autocomplete}, // 'camera-project-table': CameraProjectTableSelection},
  created () {
    //attachAuthorizaitonHeader();
    this.$http.get(API_URL + 'sensor-options').then(
      function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        }
        this.sensors = response.data;
        if (this.sensors != null) {
          this.activeSensor = this.sensors.find(sensor => sensor.id === getParameterByName('sensorId', 'ARU'));
          this.isManagerOfAnyProject = false;
          this.sensors.forEach(x => { this.isManagerOfAnyProject = this.isManagerOfAnyProject || x.isManagerOfAnyProject; });
        }
      }, response => {
        // error callback
        this.error = this.$tc('common-error', 1) + ': ' + (response.data ? response.data.error : '') + ' ' + response.status;
      }
    );
  },
  data () {
    return {
      getSpeciesAPIUrl: API_URL + 'get-species?filter=',
      downloadRawUrl: API_URL + 'download-report',
      sensor: '',
      navStep: 1,
      projectIds: [],
      cameraProjectIds: [],
      validMsg: '',
      speciesCodes: [],
      showLUF: false,
      showNR: false,
      showOS: false,
      isDownloading: false,
      isSensorChanged: true,
      isSplitLocation: false,
      warning: null,
      sensors: [],
      resetUrl: createLink(DATA_DOWNLOAD_URL)
    }
  },
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    }
  },
  watch: {
    sensor (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isSensorChanged = true;
      }
    }
  },
  methods: {
    validSelection (direction) {
      if (this.navStep === 1) { // select sensor
        if (this.isSensorChanged) {
          this.isSensorChanged = false;
          // this.$refs['project-table'].$refs.table.refresh();
          this.projectIds = [];
          this.speciesCodes = [];
        }
        if (direction === 1 && this.sensor === '') {
          this.warning = this.$t('projectDownload-sensorWarn');
          return;
        }
      } else if (this.navStep === 2) { // second step select projects.
        if (this.$refs['project-table']) {
          this.projectIds = this.$refs['project-table'].selected;
        }

        if (direction === 1 && this.projectIds.length === 0) {
          this.warning = this.$t('projectDownload-selectWarn');
          return;
        }
      }

      this.warning = null;
      this.navStep = this.navStep + direction;
    },
    formattedSpeciesDisplay (result) {
      return result.code + ' | ' + result.commonName + (!result.inAlberta ? ' | ' + this.$t('projectDownload-outsideAlberta') : '');
    },
    enterSpecies (result) {
      // console.log('enter a species')
      this.setSpeciesId(result);
    },
    setSpeciesId (speciesRow) {
      // console.log('selected a species')
      const speciesInfo = speciesRow.display.split('|');
      this.speciesCodes.push(speciesInfo[0].trim());
    },
    clearSpeciesInput () {

    },
    getRawDataUrl () {
      let url = '';
      //url += this.projectIds.map(x => 'projectIds=' + x).join('&') + '&';
      return this.downloadRawUrl + '?' + url + 'sensorId=' + this.sensor + '&' + 'splitLocation=' + this.isSplitLocation;
    },
    downloadRaw () {
      this.isDownloading = true;
      this.$http.post(this.getRawDataUrl(), JSON.stringify({projectIds: this.projectIds}), {headers: {'Content-Type': 'application/json', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'application/zip'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = 'SpeciesRawDownload.zip';
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.isDownloading = false;
      },
      (err) => {
        this.isDownloading = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    restart () {
      this.sensor = '';
      this.navStep = 1;
      this.projectIds = [];
      this.speciesCodes = [];
      this.isSensorChanged = true;
      this.warning = null;
    }
  }
}
</script>
<style scoped>
.error {
  color:#f40;
}
.nextPrev {
    top: -100px;
}
#msform fieldset:not(:first-of-type) {
  display: block;
}

.sensorSelect{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sensorSelect label {
    color: #fff;
    background-color: #c9441257;
    cursor:pointer;
}

.sensorSelect [type=radio][disabled]+label {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.sensorSelect .sensor {
  margin-left: 0px !important;
}

[type=checkbox]+span:not(.lever):before{
  top: 25%;
  margin-left: 5px;
}

</style>
