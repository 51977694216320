var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vue", attrs: { id: "recording-page" } }, [
      _vm.error != null
        ? _c("div", [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("common-pageError", { error: _vm.error })) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null && _vm.taskOverview.taskId > 0
        ? _c("div", [
            _c("div", { staticClass: "container large taskHeader !pb-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "w-full taskTitle" }, [
                  _c("p", [
                    _c("a", { attrs: { href: _vm.breadcrumbHomeLink() } }, [
                      _vm._v(_vm._s(_vm.$t("common-home")))
                    ]),
                    _vm._v(" / "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbBaseLink() + "ARU" } },
                      [_vm._v(_vm._s(_vm.$t("common-aru")))]
                    ),
                    _vm._v(" / "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbARUProjectLink() } },
                      [_vm._v(_vm._s(_vm.taskOverview.projectFullName) + " ")]
                    ),
                    _vm._v(" /")
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatHeading(_vm.taskOverview.recordingName)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _vm.taskOverview.meanDecibels != null
                    ? _c("p", { staticClass: "NoiseLevel" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("recordingPage-taskDecibels")) + ":"
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("recordingPage-mean")))
                        ]),
                        _vm._v(
                          " (" +
                            _vm._s(_vm.taskOverview.meanDecibels) +
                            ")\n            "
                        ),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("recordingPage-max")))
                        ]),
                        _vm._v(
                          " (" +
                            _vm._s(_vm.taskOverview.maxDecibels) +
                            ")\n            "
                        ),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("recordingPage-rms")))
                        ]),
                        _vm._v(
                          " (" +
                            _vm._s(_vm.taskOverview.rmsDecibels) +
                            ")\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex gap-5 -mx-3" }, [
                _c(
                  "div",
                  { staticClass: "!w-2/3" },
                  [
                    _vm.recordingInformation
                      ? _c("weather-segment", {
                          attrs: {
                            projectId: _vm.taskOverview.project.id,
                            "date-string": _vm.taskOverview.recordingDate,
                            "location-id": _vm.recordingInformation.location.id,
                            "site-latitude":
                              _vm.recordingInformation.location.latitude,
                            "site-longitude":
                              _vm.recordingInformation.location.longitude,
                            "show-date": false
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex mt-5 !w-1/3" }, [
                  _c("div", { staticClass: "flex-1 flex" }, [
                    _vm.taskOverview.isReadOnly
                      ? _c("i", {
                          staticClass: "col !text-6xl s2 icon ion-ios-locked"
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.taskOverview.readOnlyReason))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-initial w-2/3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        class: { "no-statusOption": !_vm.isStatusOptionVisible }
                      },
                      [
                        _c("div", { staticClass: "flex-1 pr-2" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.orgStatusId,
                                  expression: "orgStatusId",
                                  modifiers: { number: true }
                                }
                              ],
                              attrs: {
                                disabled: !_vm.canModifyStatus,
                                id: "statusSelector",
                                type: "number"
                              },
                              domProps: { value: _vm.orgStatusId },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return _vm._n(val)
                                      })
                                    _vm.orgStatusId = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.updateTaskStatus
                                ]
                              }
                            },
                            _vm._l(_vm.options.taskStatus, function(
                              option,
                              index
                            ) {
                              return _c(
                                "option",
                                {
                                  key: "status" + index,
                                  domProps: { value: option.id }
                                },
                                [_vm._v(_vm._s(option.type))]
                              )
                            }),
                            0
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1" }, [
                          _vm.taskOverview.nextTaskId <= 0
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "You have no assigned tasks to go to."
                                      ),
                                      expression:
                                        "$t('You have no assigned tasks to go to.')"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-border fullWidth",
                                      attrs: {
                                        disabled:
                                          _vm.taskOverview.nextTaskId <= 0
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("recordingPage-nextTask")
                                        ) + " "
                                      ),
                                      _c("span", {
                                        staticClass: "ion-ios-arrow-thin-right"
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "btn btn-border fullWidth",
                                  attrs: { href: _vm.getNextTaskUrl() }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("recordingPage-nextTask")) +
                                      " "
                                  ),
                                  _c("span", {
                                    staticClass: "ion-ios-arrow-thin-right"
                                  })
                                ]
                              )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("p"),
                    _vm._v(" "),
                    _vm.showIncompleteMessage()
                      ? _c("span", { staticClass: "text-yellow-600" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("recordingPage-incompleteTaskInfo")
                              ) +
                              "\n            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "invalid_msg" }, [
                      _vm._v(" " + _vm._s(_vm.localTaskStatusUpdateMessage))
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container overflow tabbed" }, [
              _c("ul", { staticClass: "tabs !ml-3" }, [
                _c("li", { staticClass: "tab left !ml-0" }, [
                  _c(
                    "a",
                    {
                      class: {
                        "!border-gray-400 border-2 border-b-0":
                          _vm.showTab === "main"
                      },
                      on: {
                        click: function($event) {
                          _vm.showTab = "main"
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "ion-volume-medium" }),
                      _vm._v(" " + _vm._s(_vm.$t("recordingPage-spectrograms")))
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.taskOverview.location && _vm.taskOverview.location.latitude
                  ? _c("li", { staticClass: "tab left" }, [
                      _c(
                        "a",
                        {
                          class: {
                            "!border-gray-400 border-2 border-b-0":
                              _vm.showTab === "map"
                          },
                          on: {
                            click: function($event) {
                              _vm.showTab = "map"
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ion-map" }),
                          _vm._v(" " + _vm._s(_vm.$t("common-map")))
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.taskOverview.taskId && _vm.hasLocationVisitImages
                  ? _c("li", { staticClass: "tab left" }, [
                      _c(
                        "a",
                        {
                          class: {
                            "!border-gray-400 border-2 border-b-0":
                              _vm.showTab === "photos" &&
                              _vm.hasLocationVisitImages
                          },
                          on: {
                            click: function($event) {
                              _vm.showTab = "photos"
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ion-ios-camera" }),
                          _vm._v(_vm._s(_vm.$t("common-locationPhotos")))
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.taskOverview.taskId && _vm.taskOverview.isUserAdmin
                  ? _c("li", { staticClass: "tab left" }, [
                      _c(
                        "a",
                        {
                          class: {
                            "!border-gray-400 border-2 border-b-0":
                              _vm.showTab === "audit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickedAuditTab()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ion-clipboard" }),
                          _vm._v(" " + _vm._s(_vm.$t("recordingPage-audit")))
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTab === "main",
                        expression: "showTab === 'main'"
                      }
                    ],
                    staticClass:
                      "col s12 panel boxed padded60 border-gray-400 border-2",
                    attrs: { id: "spectrograms" }
                  },
                  [
                    _c("div", { staticClass: "spectrogram-top row flex" }, [
                      _c(
                        "span",
                        {
                          staticClass: "mini-help-bar col s12 m8 flex-initial"
                        },
                        [
                          _c("span", { staticClass: "currentStatus" }, [
                            _vm._v(
                              _vm._s(_vm.$t("recordingPage-method")) +
                                ": " +
                                _vm._s(
                                  _vm.taskOverview && _vm.taskOverview.methodStr
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-popover",
                            {
                              attrs: {
                                id: "hotkeys",
                                placement: "top",
                                trigger: "hover",
                                popoverClass: "shortcut-popover-base"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "popover-target-button shortcuts-btn"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("recordingPage-hotkeys"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("template", { slot: "popover" }, [
                                _c("div", { staticClass: "shortcuts" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("recordingPage-startStop")
                                      ) + " ("
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "white-emphasis" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("recordingPage-space"))
                                        )
                                      ]
                                    ),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-skipAhead")
                                        ) +
                                        " ("
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "white-emphasis" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("recordingPage-m"))
                                        )
                                      ]
                                    ),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-skipBackwards")
                                        ) +
                                        " ("
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "white-emphasis" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("recordingPage-z"))
                                        )
                                      ]
                                    ),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-addMarker")
                                        ) +
                                        " ("
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "white-emphasis" },
                                      [_vm._v("1")]
                                    ),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-previousMarker")
                                        ) +
                                        " ("
                                    ),
                                    _c("span", {
                                      staticClass:
                                        "white-emphasis arrow-key ion-arrow-left-b"
                                    }),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-nextMarker")
                                        ) +
                                        " ("
                                    ),
                                    _c("span", {
                                      staticClass:
                                        "white-emphasis arrow-key ion-arrow-right-b"
                                    }),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-firstMarker")
                                        ) +
                                        " ("
                                    ),
                                    _c("span", {
                                      staticClass:
                                        "white-emphasis arrow-key ion-arrow-up-b"
                                    }),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-lastMarker")
                                        ) +
                                        " ("
                                    ),
                                    _c("span", {
                                      staticClass:
                                        "white-emphasis arrow-key ion-arrow-down-b"
                                    }),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-hideShowBoxes")
                                        ) +
                                        " ("
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "white-emphasis" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("recordingPage-b"))
                                        )
                                      ]
                                    ),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t("recordingPage-hideShowText")
                                        ) +
                                        " ("
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "white-emphasis" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("recordingPage-t"))
                                        )
                                      ]
                                    ),
                                    _vm._v(") ")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "| " +
                                        _vm._s(
                                          _vm.$t(
                                            "recordingPage-hideShowLeftRight"
                                          )
                                        ) +
                                        " ("
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "white-emphasis" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("recordingPage-lr"))
                                        )
                                      ]
                                    ),
                                    _vm._v(")")
                                  ])
                                ])
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "top-buttons-wrapper col m3 flex-initial",
                          staticStyle: { float: "none !important" }
                        },
                        [
                          _vm.taskOverview.enableShowAllRecordingTasks
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "Toggle On to see all the tags made for this recording in this project. Toggle Off to only see your tags."
                                      ),
                                      expression:
                                        "$t('Toggle On to see all the tags made for this recording in this project. Toggle Off to only see your tags.')"
                                    }
                                  ],
                                  staticClass: "text-right text-toggle"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("recordingPage-viewAll")) +
                                      " "
                                  ),
                                  _c("ToggleButton", {
                                    attrs: {
                                      value: _vm.taskOverview.isUserTranscriber,
                                      color: "#C94412"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.toggleChange()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showForm
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "btn btn-noborder ion-eye-disabled text-right",
                                  on: {
                                    click: function($event) {
                                      _vm.showForm = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("recordingPage-hideInfoPanel")
                                    )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.showForm
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "btn btn-noborder ion-eye text-right",
                                  on: {
                                    click: function($event) {
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("recordingPage-showInfoPanel")
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "col m flex-initial" },
                        [
                          _vm.$refs.recording
                            ? _c(
                                "dropdown-button",
                                [
                                  _c("template", { slot: "menuTitle" }, [
                                    _vm._v(_vm._s(_vm.$t("common-manage")))
                                  ]),
                                  _vm._v(" "),
                                  _c("template", { slot: "dropdownButtons" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-success popover-menu-item",
                                        class: {
                                          downloading:
                                            _vm.$refs.recording.downloading
                                        },
                                        attrs: { download: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.recording.downloadRecording()
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticStyle: { "font-size": "20px" }
                                        }),
                                        _c("span", {
                                          staticClass: "spin-icon"
                                        }),
                                        _vm._v(" "),
                                        _vm.$refs.recording.recording.file
                                          ? _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "recordingPlayers-downloadOriginal"
                                                  )
                                                )
                                              )
                                            ])
                                          : _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "recordingPlayers-downloadRecording"
                                                  )
                                                )
                                              )
                                            ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.$refs.recording.recording.file
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-success popover-menu-item",
                                            class: {
                                              downloading:
                                                _vm.$refs.recording.downloading
                                            },
                                            attrs: { download: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.recording.downloadGeneratedRecording()
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticStyle: {
                                                "font-size": "20px"
                                              }
                                            }),
                                            _c("span", {
                                              staticClass: "spin-icon"
                                            }),
                                            _vm._v(
                                              " \n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "recordingPlayers-downloadGenerated"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "close-popover",
                                            rawName: "v-close-popover"
                                          }
                                        ],
                                        staticClass:
                                          "btn btn-success popover-menu-item",
                                        on: {
                                          click: () => {
                                            this.$refs[
                                              "recording"
                                            ].showParameterModal()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(_vm.$t("Audio Settings")) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.taskOverview.taskId != null
                      ? _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("recording-play", {
                              ref: "recording",
                              attrs: {
                                loading: _vm.recordingPlayerLoading,
                                "task-update-message": _vm.taskUpdateMessage,
                                "show-form": _vm.showForm,
                                modify: _vm.canModifyTask,
                                "task-overview": _vm.taskOverview,
                                options: _vm.options,
                                "options-loaded": _vm.optionsLoaded,
                                task: _vm.task,
                                "show-all-tasks": _vm.showAllTasks
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.showTab === "map"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col s12 panel boxed padded60 border-gray-400 border-2",
                        attrs: { id: "mapsAndPhotos" }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col s12 m12 l8" },
                            [
                              _vm.taskOverview &&
                              _vm.taskOverview.location.latitude &&
                              _vm.taskOverview.location.longitude
                                ? _c("point-map", {
                                    attrs: {
                                      latitude: parseFloat(
                                        _vm.taskOverview.location.latitude
                                      ),
                                      longitude: parseFloat(
                                        _vm.taskOverview.location.longitude
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col s12 m12 l4" }, [
                            _c(
                              "div",
                              { staticClass: "row gallery" },
                              _vm._l(_vm.taskOverview.photos, function(
                                img,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: "photo" + index,
                                    staticClass: "col s6 m4 l6 materialboxed",
                                    attrs: { src: img },
                                    on: {
                                      click: function($event) {
                                        return _vm.openZoomImage(img.url)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "thumbnail",
                                      style: {
                                        "background-image":
                                          "url(" + img.thumbNailURL + ")"
                                      }
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.showImage == true
                          ? _c(
                              "div",
                              {
                                ref: "modalBox",
                                staticClass: "modal modal-wrapper",
                                on: { click: _vm.hideZoomImage }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal-container",
                                    on: { click: _vm.hideZoomImage }
                                  },
                                  [
                                    _c("img", {
                                      ref: "zoomedImg",
                                      attrs: { src: _vm.largeImage }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTab === "audit"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col s12 panel boxed padded60 border-gray-400 border-2",
                        attrs: { id: "auditHistory" }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading,
                                  expression: "loading"
                                }
                              ],
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "250px"
                              }
                            },
                            [
                              _c("pulse-loader", {
                                attrs: {
                                  loading: true,
                                  color: "#C94412",
                                  size: "20px",
                                  width: 400,
                                  height: 400
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col s12 m12" },
                            [
                              _vm.auditsText != null
                                ? _c("div", [_vm._v(_vm._s(_vm.auditsText))])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.audits, function(line, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "audit" + index,
                                    staticClass: "audit"
                                  },
                                  [
                                    _c("span", { staticClass: "audit-type" }, [
                                      _vm._v(_vm._s(line.auditType))
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(line.audit))])
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTab === "photos"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col s12 panel boxed padded60 border-gray-400 border-2",
                        attrs: { id: "location-photos" }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col s12 m12 l8" },
                            [
                              _vm.taskOverview.location
                                ? _c("location-photos", {
                                    attrs: {
                                      "location-id":
                                        _vm.taskOverview.location.id,
                                      "project-id": _vm.taskOverview.projectId
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }