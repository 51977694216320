import { API_URL, copyObject } from '@/lib/common';
import { readExifDetails, dateParse } from '@/components/utils/exif/exif-parser';
import i18n from '@/i18n';
import moment from 'moment/moment';
import { v4 as uuidv4 } from 'uuid';


// var rest = {
// 	ep: {
// 		batchUpload: "/api/deployment/bupload"
// 	}
// };

// const defaultStatus = DB_CONST.deploymentStatuses["No images"];
const DB_CONST = {
  // deploymentStatuses: {
  // 	"No images": 1,
  // 	"All uploaded": 2,
  // 	"Auto-ready": 3,
  // 	"Auto-done": 4,
  // 	"Tagged Pass 1": 5,
  // 	"Tagged Pass 2": 6,
  // 	"QA done": 7
  // },

  quadrantStations: ['NE', 'SE', 'SW', 'NW', 'CL', 'CCL'],
  stations: ['NE', 'SE', 'SW', 'NW', 'CL', 'CCL', 1, 2, 3, 4, 5]
};

const sep = ' _-';
const trimSeparator = new RegExp('^[' + sep + ']+|[' + sep + ']+$', 'g');
const simpleSite = '[a-z' + sep + ']*\\d+.*';
const stationSuffix = '([' + sep + '][a-z])?'; // e.g. "-b"
const quadrantStationsPattern = new RegExp('(' + simpleSite + ')[' + sep + ']([0-9]*|(' + DB_CONST.quadrantStations.join('|') + ')' + stationSuffix + ')$', 'i');
const stationsPattern = new RegExp('(' + simpleSite + ')[' + sep + ']([0-9]*|(' + DB_CONST.stations.join('|') + ')' + stationSuffix + ')$', 'i');
const siteLabelPattern = new RegExp('^([a-z' + sep + ']*?)(W?)(\\d+)([a-z]?)\\b([\\d' + sep + ']*)$', 'i');
// const folderNamePattern = new RegExp('[A-Z][a-z][0-9]@_&#-', 'i');
const nonABMISiteNamePattern = new RegExp('^[\\w\\-@#]+$', 'i');

/* non abmi site pattern */
const OrgSiteStationNamePattern = new RegExp('^([\\w]*)[' + sep + ']([\\w' + sep + ']+)[' + sep + ']([\\w]*)$', 'i');
const SiteStationNamePattern = new RegExp('^([\\w' + sep + ']+)[' + sep + ']([\\w]*)$', 'i');

const offGridLabel = /\bOGW?/i; // testing against OG or OGW in the beginning of a word
const terrestrialLabel = /\bT\b/i; // testing against a standalone "T"
const imageType = /^image\//;
// const extractErrorFromHtmlDoc = /<p>[^]*?<\/p>/; // [^] to match any character including new line, which "." does not include
const confirmImageUploadUrl = API_URL + 'finalize-image-records-uploaded';
const getDeploymentInfoUrl = API_URL + 'create-or-retreive-camera-deployment-for-upload';
const getDeploymentInfoBatchUrl = API_URL + 'create-or-retrieve-camera-image-for-upload-batch';

/* AWS amazon, API */
const getPreSignAWSUrl = process.env.PRESIGN_AWS_URL; // default settings 'https://d9co5p2kw9.execute-api.us-west-2.amazonaws.com/beta/';
const insertCheckImageUrl = API_URL + 'create-or-retreive-camera-image-for-upload';
const insertCheckImageUrlBatch = API_URL + 'create-or-retrieve-camera-image-for-upload-batch';
// const supportedReconyxModel		= [ 'HF2 PRO', 'UltraFire', 'PC', 'HC', 'Unknown' ];
// const updateDeploymentOptionUrl = API_URL + 'update-camera-pud-option';
// export const uploadStatusParam = {
// 	notStarted: 1,
// 	inProgress: 2,
// 	successful: 3,
// 	failed: 4
// };

function getNameFromParts(arr) {
  return arr.filter(function (val) {
    return !!val;
  }).join('-').replace(/ /g, '');
}

function rightTrimCustom(str, regex, trimAt) {
  if (trimAt <= 0) return str;
  let tempPos = 0;
  let offset = 0;
  let count = 0;
  while (tempPos >= 0 && count < trimAt) {
    tempPos = str.substr(offset).search(regex);
    if (tempPos > -1) {
      count++;
      offset += tempPos + 1;
    }
  }
  return count === trimAt && offset > 1 ? str.substring(0, offset - 1) : str;
}

/** change to lower/upper case */
function toCaseCustom(str, caseSetting) {
  switch (caseSetting) {
    case 1: return str.toLowerCase();
    case 2: return str.toUpperCase();
    default: return str;
  }
}

function getPaddedName(str, padChar, maxLength) {
  if (!maxLength || !padChar.length) {
    return padChar + str;
  } else if (str.length >= maxLength) {
    return str;
  }
  var paddingSequence = padChar.length === 1 ? padChar.repeat(maxLength) : padChar;

  return (paddingSequence + str).slice(-maxLength);
}

function getCustomizedName(str, settings) {
  if (settings.trimOccurrence && settings.trimOccurrence.regex && settings.trimOccurrence.at) {
    str = rightTrimCustom(str, settings.trimOccurrence.regex, settings.trimOccurrence.at);
  }

  if (settings.case) {
    str = toCaseCustom(str, settings.case);
  }

  if (settings.joinedNaming.leftPadChar && settings.joinedNaming.maxLen) {
    str = getPaddedName(str, settings.joinedNaming.leftPadChar, settings.joinedNaming.maxLen);
  }

  return str;
}

export var s3Uploader = {
  debug: false,
  deployments: {},
  deploymentsv2: {},
  fileTree: {},
  ignored: [],
  warnings: new Set(),
  orderList: [],
  deploymentsWithParseErrors: [],
  processingImageList: {},
  processedImageId: [],
  deploymentCheck: [],
  onUpdateCallback: null,
  settings: {},
  imageCheckObjArray: [], //Response from create-or-retrieve
  imageCheckProcessingIteration: 0,

  reset: function () {
    this.deployments = {}; // use file path as key
    this.ignored = [];
    this.warnings = new Set();

    this.orderList = [];
    this.deploymentsWithParseErrors = [];
  },

  fileListToTree: function (files) {
    let fileTree = {};
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let pathChunks = file.webkitRelativePath.split("/");
      let current = fileTree;

      pathChunks.map((chunk, index) => {

        if (index == pathChunks.length - 1) {
          let validFile = true;
          if (!current.__files) current.__files = [];

          if (!file.webkitRelativePath) {
            console.error(file.name, 'webkitRelativePath NOT supported!');
            validFile = false;
          }

          if (file.type.indexOf('image') === -1 ) { // don't process none image files or image too small
            console.error(file.name, 'File is not an image');
            validFile = false;
          } else if(file.size <= 4096) {
            console.error(file.name, 'File is less than 4Kb');
            validFile = false;
          } else if (file.name[0] == '.') {
            console.error(file.name, 'Hidden file (begins with .) ignoring.');
            validFile = false;
          }

          if (validFile) current.__files.push(file);
        }
        else {

          if (typeof current[chunk] == 'undefined')
            current[chunk] = { __depth: index, __files: [] };

          current = current[chunk];
        }

      });
    }
    return fileTree;
  },
  parseFilesV2: function (files, settings, iter) {
    this.settings = settings;
    files = this.fileListToTree(files);
    this.recurseFiles(files, 0);
  },

  recurseFiles: function (files, currentDepth, locationName = null, deploymentName = null) {
    let folders = Object.keys(files).filter(v => !['__files', '__depth'].includes(v));
    if (folders.length > 0) {
      folders.map(folder => {
        this.recurseFiles(
          files[folder],
          currentDepth + 1,
          currentDepth + 1 == this.settings.baseLevel ? folder : locationName, //Recurse with location name once current depth is at the settings base level.
          deploymentName !== null && currentDepth > 1 ? `${deploymentName}/${folder}` : folder
        );
      });
    }

    if (deploymentName && currentDepth >= this.settings.baseLevel) {
      this.deployments[`${deploymentName}-${uuidv4()}`] = {
        images: files.__files,
        name: deploymentName,
        site: locationName
      };
    }

    


  },


  parseABMINames: function (parts, filepath, settings) {
    var result = { deployment: '', site: '', station: '', baseLevel: 1 };

    // test whether any of the folders has the offgrid label in its name
    var offGridMatches = offGridLabel.exec(parts.join('/'));
    var isOffGrid = !!offGridMatches;

    /*
      Step 1: test whether any of the folders has the offgrid label in its name
      -------

      Step 2:
      -------
      If [ the user specified 'No Station in Folder Name'] {
        parse using [siteLabelPattern]
      }
      else {
        if [ off grid ] {
          use: [stationsPattern]
        }
        else {
          use: [quadrantStationsPattern]
        }
      }

      Step 3:
      -------
      scan the folders using the pattern from Step 2 to find the first matching folder - this is the deployment name.

      Step 4:
      -------
      If no match is found,
        The error message depends on whether the user specified 'No Station in Folder Name'.
        If 'No Station in Folder Name':
          error <- no qualifying site label
        else
          error <- no qualifying site label

      Step 5:
      -------
    */

    var pattern = (settings.folderNamingFormat === 'abmi-nostation') ? siteLabelPattern
      : (isOffGrid ? stationsPattern : quadrantStationsPattern);

    // start searching for the folder whose name is a qualified deployment name
    var j = parts.length;
    var matches = null;
    while (j >= 0 && !matches) {
      matches = pattern.exec(parts[--j]);
    }
    if (!matches) {
      var message = settings.noStationinFolderName ? i18n.t('cameraUploader-noSiteLabel') : i18n.t('cameraUploader-noStationInfo');
      this.ignored.push({ error: message, path: filepath });
      return;
    }

    // TP: seems that baseLevel is the level at which the deployment name found starting from 0 (root folder) -- update: baselevel starts at 1 now
    result.baseLevel = j;

    // TP: If there's station in folder name, it's the third element in matches found from the pattern
    result.station = settings.noStationinFolderName ? '' : matches[2];

    let siteMatches;
    if (settings.noStationinFolderName) {
      siteMatches = matches;
    } else {
      // preliminary site name is obtained as the deployment folder name without the station ending
      let siteTemp = matches[1];
      siteMatches = siteLabelPattern.exec(siteTemp);
      if (!siteMatches) {
        console.error('site label does not match after matching stationspattern or QuadrantStationsPattern');
        this.ignored.push({ error: i18n.t('cameraUploader-noSiteInfo'), path: filepath });
        return;
      }
    }

    var label = siteMatches[1];
    // parseInt will trim leading zeroes, then convert back to string so that '0' is considered a non-empty value by getNameFromParts
    var optionalW = siteMatches[2];
    var number = String(parseInt(siteMatches[3], 10));
    var siteSuffix = siteMatches[4];
    var subNumber = siteMatches[5];
    if (isOffGrid) {
      var siteParts = [
        offGridMatches[0],
        label.replace(offGridLabel, '').replace(trimSeparator, ''),
        number + siteSuffix,
        subNumber.replace(trimSeparator, '')
      ];
      result.site = getNameFromParts(siteParts);
      // include optionalW in front of site name in the deployment name
      siteParts[2] = optionalW + siteParts[2];
      result.deployment = getNameFromParts([getNameFromParts(siteParts), result.station]);
    } else {
      result.site = number + siteSuffix;
      var deploymentParts = [
        label.replace(terrestrialLabel, '').replace(trimSeparator, ''),
        optionalW + result.site,
        result.station
      ];
      result.deployment = getNameFromParts(deploymentParts);
    }
    return result;
  },

  useFolderNames: function (parts, filepath, settings) {
    // for this setting, use the folder name as is from the specified level

    if (parts.length <= settings.baseLevel - 1) {
      this.warnings.add(i18n.t('cameraUploader-insufficientDepth', { filepath: filepath }));
      return;
    }

    const siteName = parts[parts.length - 1];

    let pattern;
    switch (settings.folderNamingFormat) {
      case 'org-site-station':
        pattern = OrgSiteStationNamePattern;
        break;
      case 'site-station':
        pattern = SiteStationNamePattern;
        break;
      case 'site-only':
        pattern = nonABMISiteNamePattern;
        break;
      default:
        this.warnings.add(i18n.t('cameraUploader-formattingNotRecognized'));
        return;
    }

    const matches = pattern.exec(siteName);

    if (!pattern.test(siteName)) {
      this.warnings.add(i18n.t('cameraUploader-mismatchNameConvention', { siteName: siteName }) + ' ' + i18n.t('cameraUploader-mismatchCharacters') + ' ' + i18n.t('cameraUploader-pleaseRename'));
      return;
    }

    let site = null;
    let station = null;

    try {
      switch (settings.folderNamingFormat) {
        case 'org-site-station':
          site = matches[2];
          station = matches[3];
          break;
        case 'site-station':
          site = matches[1];
          station = matches[2];
          break;
        case 'site-only':
          site = siteName;
          station = '';
          break;
      }
    } catch (e) { }

    if (site === null || station === null) {
      this.warnings.add(i18n.t('cameraUploader-mismatchNameConvention') + ' ' + (site === null ? i18n.t('cameraUploader-missingSiteName') : '') + ' ' + (station === null ? i18n.t('cameraUploader-missingStationName') : '') + '. ' + i18n.t('cameraUploader-pleaseRename'));
      return;
    }

    return { deployment: siteName, baseLevel: settings.baseLevel, site: site, station: station };
  },

  registerUpdateCallback(next) {
    if (typeof next === 'function') {
      this.onUpdateCallback = next;
    }
  },

  /* when fail happened:
  each file has a count, so each file can be failed and reupload for the given number of times
  if a file failed, we put a 'z' as prefix, so it goes to the back of the queue for uploading

  if file failed eventually, send 'failed' message
  */
  failedCallback(deploymentName, fileObj, bDirectRemove, fileStatus, message) {
    if (!fileObj) {
      this.onUpdateCallback(deploymentName, { fileStatus: 'failed', message, timeType: 'current', timeStamp: Date.now() });
      return;
    }
    // console.log('fileObj', fileObj, 'fileStatus', fileStatus, 'this.processingImageList[index]',  this.processingImageList[fileObj.index]);
    if (bDirectRemove) {
      this.onUpdateCallback(deploymentName, { file: fileObj.file, fileStatus: (fileStatus || 'failed'), timeType: 'current', timeStamp: Date.now(), message });
      delete this.processingImageList[fileObj.index]; // remove file
    } else { // remove after several tries
      this.processingImageList[fileObj.index].uploadCount--;
      if (this.processingImageList[fileObj.index].uploadCount <= 0) {
        delete this.processingImageList[fileObj.index]; // remove file
        this.onUpdateCallback(deploymentName, { file: fileObj.file, fileStatus: (fileStatus || 'failed'), message, timeType: 'current', timeStamp: Date.now() });
      } else {
        let failedObj = this.processingImageList[fileObj.index];
        delete this.processingImageList[fileObj.index]; // remove file
        failedObj.index = 'z' + failedObj.index;
        this.processingImageList[failedObj.index] = failedObj; // add to the bottom, so will processing later
      }
    }
    return -1;
  },

  async processBatchFiles(http, settings, files, deploymentName, deploymentPath, deploymentId, deploymentKey) {
    let resultArray = [];
      if (this.debug)
      console.log('process to batch files', files);

      if (window.stopthings)
        throw new Error();

      //Loop through the files array passed
      if (Array.isArray(files)) {
        // main loop to upload each file in array.
      for (let i = 0; i < files.length; i++) {

          // Consume items in the this.imageCheckObjArray after each file iteration.
          // If there is no more check objects we need to get some more. 
          // Await for the response before proceeding.
          if (this.imageCheckObjArray.length == 0) 
            await this.createOrRetrieveImagesForProcessing(http, settings, deploymentKey, deploymentId);


          //If our check array is 0 right after trying to retrieve the data it must be empty.
          if (this.imageCheckObjArray.length == 0) {
            this.imageCheckProcessingIteration=0;
            this.processingImageList = [];
            return resultArray;
          }

          let imageCheckObj = this.imageCheckObjArray.shift();

          if (imageCheckObj.hasOwnProperty('errors') && imageCheckObj.errors.length > 0) {
            this.failedCallback(deploymentKey, files[i], true, 'failed', `${imageCheckObj.errors.length} errors: ${imageCheckObj.errors.join(' & ')}`);
            resultArray.push(-1);
          }
          else if (imageCheckObj.hasOwnProperty('error')) {
            this.failedCallback(deploymentKey, files[i], true, 'failed', imageCheckObj.error);
            resultArray.push(-1);
          }
          else if ((imageCheckObj.addedOn && !settings.overwriteExisting) ||
            !imageCheckObj.id) {
            // when don't overwrite flag is indicated and image already exists, skip
            this.failedCallback(deploymentKey, files[i], true, 'skipped');
            resultArray.push(-1);
          } else {

            const fileKey = deploymentPath + imageCheckObj.id + '.' + imageCheckObj.extension;

            /* ---------step 3.1  get presignedurl from aws -------------------
              version 1: use getPresignedUrl to upload image as standard types
              version 2: to custom createPresignedPost to set storage TYPE Header, so it can save as intelligence type
            */
            const params = { params: { fileKey: fileKey, projectId: settings.projectId, organizationId: settings.awsParam.organizationId, bucket: settings.awsParam.bucket, region: settings.awsParam.region, time: Date.now() } }
            try {
              const urlResponse = await http.get(getPreSignAWSUrl, params);
              let uploadUrl = (urlResponse && urlResponse.body && urlResponse.body.body && urlResponse.body.body.url) ? urlResponse.body.body.url : false;
        
              //s3 accelerate appends bucket name to the url this will just get the hostname w/o filepath
              if (uploadUrl.match(/s3-accelerate/)) {
                uploadUrl = (new URL(uploadUrl)).origin;
              }
              
              //if (i == 0)
              // uploadUrl = `https://r9.iklass.ca`;
        
              /* ----------------------------------------------------------------
                step 3.2  call presignedurl to do actual uploading
              --------------------------------------------------------------- */
              if (uploadUrl) {
                let data = new FormData();
                for (const field in urlResponse.body.body.fields) {
                  data.append(field, urlResponse.body.body.fields[field]);
                }
                // if (i == 2) {
                //   // Generate random bytes to simulate a corrupt JPEG file
                //   const corruptData = new Uint8Array([0xFF, 0xD8, 0xFF, 0x00, 0x00, 0x00]); // Starts with valid JPEG header bytes but is incomplete or incorrect

                //   // Create a Blob with the corrupt data
                //   const corruptBlob = new Blob([corruptData], { type: "image/jpeg" });

                //   // Create a File object from the Blob
                //   const corruptFile = new File([corruptBlob], "corrupt-image.jpg", {
                //     type: "image/jpeg",
                //   });
                //   data.append("file", corruptFile);

                // } else 
                  data.append('file', files[i].file);


                /* important: set content-type to image, otherwise, won't display automatically image on the website */
                try {
                  if (i != 2) {
                    resultArray.push(
                      this.uploadToAWS(http, deploymentKey, imageCheckObj.id, files[i], uploadUrl, data, { headers: { 'Content-Type': files[i].file.type } })
                    );
                  } else {

                  }

                } catch (e) {
                  console.error('HERE');
                  throw e;
                }
              } else {
                throw new Error(i18n.t('cameraUploader-uploadAddressFail'));
              }
            } catch (e) { // failed to get presigned url
              // console.log('can\'t get uploadUrl');
              this.failedCallback(deploymentKey, false, false, 'failed', i18n.t('cameraUploader-amazonFail', { msg: e.message }));
              resultArray.push(-1);
            }


          }
        }

      } else {
        throw "Return from " + insertCheckImageUrlBatch + " is not an array";
      }

  

    return resultArray;


  },

  uploadToAWS(http, deploymentKey, imageId, fileObj, uploadUrl, data, options, tries = 0, ogResolve = null) {
    return new Promise((resolve, reject) => {
      const self = this;

      const resolvCB = (val) => { return ogResolve?ogResolve(val):resolve(val); }

      try {

        let response = http.post(uploadUrl, data, options);
        response.then(resp => {
          if (resp.status === 204) {
            self.onUpdateCallback(deploymentKey, { fileStatus: 'completed', timeType: 'current', timeStamp: Date.now() });
            delete self.processingImageList[fileObj.index]; // remove file
            resolvCB(imageId);
          } else {
            resolvCB(-1);
            //throw new Error(i18n.t('common-failedUpload'));
          }
        }).catch(e=> {

          if (tries == 3) {
            this.failedCallback(deploymentKey, fileObj, true, 'failed', i18n.t('cameraUploader-amazonFail', { msg: e.statusText+` Attempted ${tries} times ` }));
            resolvCB(-1);
          } else {
            if (ogResolve)
              this.uploadToAWS(http, deploymentKey, imageId, fileObj, uploadUrl, data, options, ++tries, ogResolve);
            else 
              this.uploadToAWS(http, deploymentKey, imageId, fileObj, uploadUrl, data, options, ++tries, resolve);
          }
        //  delete self.processingImageList[fileObj.index]; // remove file
          
        });

      } catch (e) {
        resolvCB(-1);
      }


    });
  },

  /**
   * 
   * Wonderful start of decoupling the api call for checking or retrieving images. 
   * Refer to the numberImagesToCheck for the total each api call should retrieive. Set in CameraTaskUploadForm.vue passed 
   * into settings here. 
   * 
   * Assume parallelUploadCount < numberImagesToCheck always
   * 
   * 
   */

  async createOrRetrieveImagesForProcessing(http, settings, deploymentKey, deploymentId) {
    
    let imageProcessingQueue = [];

    let currentLoopLimit = (((this.imageCheckProcessingIteration+1)*settings.numberImagesToCheck));

    if (currentLoopLimit > this.processingImageList.length) 
      currentLoopLimit = this.processingImageList.length;

    if (this.debug) {
      console.log('Loop limit',currentLoopLimit);
      console.log('Iteration', this.imageCheckProcessingIteration);
      console.log('# images to check', settings.numberImagesToCheck);
    }

    for (let i = (this.imageCheckProcessingIteration*settings.numberImagesToCheck); i < currentLoopLimit; i++) {
      const fileObj = this.processingImageList[i];

      if (typeof fileObj == 'undefined') {
        // An unknown file not defined slipped through?
        delete this.processingImageList[i]; // remove file
        this.failedCallback(deploymentKey, fileObj, true, false, 'Could not process file. Unknown error.');
        continue;
      }

      const file = fileObj.file;
      const index = fileObj.index;

      if (!file) {
        // don't do anything when no valid file is provided.
        delete this.processingImageList[index]; // remove file
        this.failedCallback(deploymentKey, fileObj, true);
        continue;
      }
      /* ---------------------------------------------------------------------------------------
        step 1: get image exif data
      --------------------------------------------------------------------------------------- */
      /* only need to call exif once
        using exif info to rename file to YYYY_MM_DD_HH_MM_SS_Sequence1OfSequenceTotal.file_extension
       */
      let exifInfo;
      if (file.exifdata) {
        exifInfo = file.exifdata;
      } else {
        // console.log('no exif data, read it now');
        exifInfo = await readExifDetails(file);
      }
      const exif = exifInfo.ABMIParsed;
      /* ---------------------------------------------------------------------------------------
          step 2 check if image already exists
      --------------------------------------------------------------------------------------- */
      const fileFullName = file.name.toLowerCase();
      imageProcessingQueue.push({
        projectId: settings.projectId,
        storageId: settings.awsParam.storageId,
        deploymentId: deploymentId,
        extension: fileFullName.substring(fileFullName.lastIndexOf('.') + 1), // extension
        fileName: fileFullName.substring(0, fileFullName.lastIndexOf('.')), // without extension,
        fileSize: file.size,
        exif: exif,
        errors: []
      });

      if (!exif || !exif.DateTimeOriginal) {
        //this.failedCallback(deploymentKey, fileObj, true, 'failed', i18n.t('cameraUploader-imageDateFail'));
        //imageProcessingQueue[imageProcessingQueue.length-1].errors = [];
        imageProcessingQueue[imageProcessingQueue.length-1].errors.push(i18n.t('cameraUploader-imageDateFail'));
      }

    }

    if (imageProcessingQueue.length > 0) {
      try {
      
        const response = await http.post(insertCheckImageUrlBatch, imageProcessingQueue);
        if (this.debug) {
          console.log(imageProcessingQueue);
          console.log(response);
        }
        if (!response || response.hasOwnProperty('error')) { // failed call, don't upload
          throw response.error;
        } else {

          //this.imageCheckObjArray = response.data;
          
          //Purpose is to append existing error data from above to track properly during file upload.
          response.data.map((responseImageObj,index) => {
            if (responseImageObj.hasOwnProperty('error')) {
              let temp = imageProcessingQueue[index];
              temp.errors.push(responseImageObj.error);
              this.imageCheckObjArray.push(temp);
            } else 
              this.imageCheckObjArray.push(responseImageObj);
          });


          this.imageCheckProcessingIteration++;
        }
      } catch (e) {
        console.error(e);
      }
    }

  },

  /* asynchorize process a number of files iin same deployment */
  async processSingleDeploymentInGroupV2(http, deploymentName, settings, deploymentPath, deploymentId, deploymentKey) {
    if (Object.keys(this.processingImageList).length === 0) {
      return;
    }
    let currentImageList = [];
    const imageKeys = Object.keys(this.processingImageList);
    for (let i = 0; i < imageKeys.length; i++) {
      if (this.processingImageList[imageKeys[i]].uploadCount > 0) {
        currentImageList.push(this.processingImageList[imageKeys[i]]);
      }
      if (currentImageList.length >= settings.parallelUploadCount) {
        break;
      }
    }

    // console.log('ere');
    // this.createOrRetrieveImagesForProcessing(http, settings, deploymentKey, deploymentId);
    // return;

    let imageUploadTask = [];
    imageUploadTask = await this.processBatchFiles(http, settings, currentImageList, deploymentName, deploymentPath, deploymentId, deploymentKey);

    window.promises = imageUploadTask;

    /* ----------------------------------------------------------------------------------
      after ALL image uploaded, get the values to send to api to confirm updating
     ---------------------------------------------------------------------------------- */

    await Promise.all(imageUploadTask).then(async values => {
      //console.log(values);
      // put ids from promise return with previously list, and size > predefined size, send to db for finalize image call
      this.processedImageId = Array.prototype.concat(this.processedImageId, values);
      const allCompleted = (Object.keys(this.processingImageList).length === 0);
      
      if (this.processedImageId.length >= settings.imageDBRegisterSize || allCompleted) {
        const updateDBIds = [...new Set(this.processedImageId.filter(x => x > 0))];
        try {
          if (allCompleted) {
            //set processing iteration to 0 again reset for next deployment. 
            this.imageCheckProcessingIteration = 0;
            this.onUpdateCallback(deploymentKey, { isDeploymentCompelted: true, deploymentId: deploymentId });
          }
          /* 
          ----------------------------------------------
          values are arrays of ids maybe duplicated,
          so use 'set' to remove duplicates
          ------------------------------------------------------
          */
          if (updateDBIds && updateDBIds.length > 0) { // when no ids, no nothing
            /* await is required here */
            // console.log('before finalize');
            try {
              const response = await http.post(confirmImageUploadUrl, { projectId: settings.projectId, ids: updateDBIds });
              if (response.data.hasOwnProperty('error')) {
                this.onUpdateCallback(deploymentKey, { message: i18n.t('cameraUploader-updateDatabaseFail', { err: response.data.error }), failedDBUpdates: updateDBIds });
              } else {
                
                // const dbRegistryInfo = response.data;
                if (allCompleted) {
                  
                  this.onUpdateCallback(deploymentKey, { pudIds: response.data.pudIds, isDeploymentCompelted: true });
                }
                this.onUpdateCallback(deploymentKey, { pudIds: response.data.pudIds }); //, message: 'update database completed: ' + dbRegistryInfo.uploaded + ' new images added.'});
              }
            } catch (e) {
              this.onUpdateCallback(deploymentKey, { message: i18n.t('cameraUploader-updateDatabaseFail', { err: JSON.stringify(e) }), failedDBUpdates: updateDBIds });
            }
          } else if (allCompleted) { //Lets check if stuck in uploading state. Reuploaded project with all files skipped.
              let resp = await http.get(API_URL + 'get-puds-by-deployment-id', {params: {deploymentId}});
              if (resp.body.hasOwnProperty('error')) { //could not get pud

              } else {
                let puds = resp.body;
                
                if (Array.isArray(puds) && puds.length > 0) {
                  
                  puds.forEach(pudObj=> {
                   
                    if (pudObj.status.id == 4) { //PUDs status is in uploading
                      //console.warn('PUD in uploading state still. Sending push forward call');
                      http.post(API_URL + 'update-camera-pud-push-forward', {id: pudObj.id}).then(
                        (response) => {
                          if (response.data.hasOwnProperty('error')) {
                            
                          } else {
              
                          }
                        },
                        (error) => {
                        }
                      );
                    }
                  });
                }
               
              }

             

          }
          // clear this.processedImageId,
          this.processedImageId = [];
        } catch (e) {
          this.onUpdateCallback(deploymentKey, { message: i18n.t('cameraUploader-updateDatabaseFail', { err: JSON.stringify(e) }), failedDBUpdates: updateDBIds });
        }
      }
      // console.log('work on next group');
      if (!allCompleted) { // when one deployment still have images, work on them
        await this.processSingleDeploymentInGroupV2(http, deploymentName, settings, deploymentPath, deploymentId, deploymentKey);
      }
    }).catch(error=>console.log(error)); // done promise.all
  },
  /* working on single deployment,
  here we use partial asynchronization, if we upload all at once, there will be network errors from aws.
  so we process files one batch (at preset number) a time */
  async processSingleDeploymentV2(deploymentObj, http, settings) {
    const deploymentKey = deploymentObj.key;
    const deploymentPath = deploymentObj.deploymentPath;
    const deploymentId = deploymentObj.id;
    const deploymentName = deploymentObj.name;

    this.onUpdateCallback(deploymentKey, { timeType: 'start', timeStamp: Date.now(), deploymentId: deploymentId });
    /* ----------------------------------------------------------------------------------
      proccessing  image asynchronizely
      parallelUploadCount: 300,
      failedRetryCount: 5,
    ---------------------------------------------------------------------------------- */
    this.processingImageList = [];
    this.processedImageId = [];

    this.deployments[deploymentKey].images.forEach((file, index) => { this.processingImageList[index] = { file: file, index: index, uploadCount: (settings.failedRetryCount > 0 ? settings.failedRetryCount : 1) }; });

    await this.processSingleDeploymentInGroupV2(http, deploymentName, settings, deploymentPath, deploymentId, deploymentKey);
  },
  /* scan image set to get camera model, make, serial number, date, used to check if deployment previously exist or not */
  async getImageMetadata(files) {
    // const self = this;
    let cameraModel = new Set();
    let serialNumber = new Set();
    let make = new Set();
    let cameraMissingExif = false;
    let processFiles = [];
    let dateList = [];
    let file = null;

   


    for (let i = 0; i < files.length; i++) {
      file = files[i];
      console.log(file);
      let exifInfo = await readExifDetails(file);
      let imageDate = exifInfo.DateTimeOriginal || exifInfo.DateTimeDigitized;

      if (!exifInfo.DateTimeOriginal || !(new moment(imageDate, 'YYYY:MM:DD HH:mm:ss')).isValid()) {
        cameraMissingExif = true;
      } else {
        cameraMissingExif = false;
      }

      let exif = exifInfo.ABMIParsed;

      if (exif) {
        make.add(exif['Make']);
        cameraModel.add(exif['Model']);
        dateList.push(imageDate);
        serialNumber.add(exif['SerialNumber']);
      }

      if (!cameraMissingExif) break;
    }


    // Serial number may not be found at the end of the set looked at.
    // Thats ok though, if none are found then assume all are null?



    /* return promise.all the parent function can get value */
    //const dateList = await Promise.all(listDateTask);
    if (dateList.length > 1) {
      dateList.sort();
    }
    return { file, startDate: dateParse(dateList[0]), endDate: dateParse(dateList[dateList.length - 1]), cameraInfo: { cameraModel, cameraMissingExif, serialNumber, make } };
  },
  async uploadV2(settings, http) {
    // const self = this;
    let deploymentKeys = Object.keys(this.deployments).sort((a,b)=> a > b?1:-1);
    console.log(deploymentKeys);
    /* ----------------------------------------------------------------------------------
      Step 1. make sequential calls to check deployment info ( insert site station when needed )
      it can be new deployment or existing ones, for existing ones, keep their id and uuid
    ---------------------------------------------------------------------------------- */
    for (let i = 0; i < deploymentKeys.length; i++) {
      const deploymentKey = deploymentKeys[i];
      const deploymentObj = this.deployments[deploymentKey];
      const deploymentName = deploymentObj.name;
      /* scanned all image in the deployment for date range, and models */


      if (deploymentObj.images.length == 0) {
        // this.onUpdateCallback(deploymentKey, { isAllSkipped: true });
        continue;
      }

      //cant tell to skip this deployment any other way.
      if (!deploymentObj.site.match(/^[\w@_&#\-:\.()]+$/)) {
        continue;
      }

      deploymentObj.images.sort((a, b) => {
        if (a.webkitRelativePath < b.webkitRelativePath) return -1;
        else if (a.webkitRelativePath > b.webkitRelativePath) return 1;
        return 0;
      })

      this.onUpdateCallback(deploymentKey, { readingMetadata: true });


      const metadata = await this.getImageMetadata(deploymentObj.images);

      /* 3 fields: camera model, camera make, and serial number, need at least one */
      let isSupportedModels = false;
      if ((metadata.cameraInfo.cameraModel && metadata.cameraInfo.cameraModel.size) ||
        (metadata.cameraInfo.make && metadata.cameraInfo.make.size) ||
        (metadata.cameraInfo.serialNumber && metadata.cameraInfo.serialNumber.size)) {
        isSupportedModels = true;
      }

      this.onUpdateCallback(deploymentKey, { readingMetadata: false });
      /* if includes non supported reconyx models, don't upload and skip */
      if (!isSupportedModels) {
        if (metadata.cameraInfo.cameraMissingExif) {
          this.onUpdateCallback(deploymentKey, { isAllSkipped: true, message: i18n.t('cameraUploader-corruptFail') });
        } else {
          this.onUpdateCallback(deploymentKey, { isAllSkipped: true, message: i18n.t('cameraUploader-cameraNotSupported') });
        }
      } else {
        if (metadata.cameraInfo.cameraMissingExif) {
          this.onUpdateCallback(deploymentKey, { isAllSkipped: true, message: metadata.file.webkitRelativePath + ': ' + i18n.t('Failed to read image dates from EXIF data. Cannot create deployment without valid date.') });
        } else {
          const param = {
            projectId: settings.projectId,
            currentFolder: deploymentObj.name,
            siteName: deploymentObj.site,
            stationName: deploymentObj.station,
            locationName: deploymentObj.site,
            date: metadata.startDate,
            // endDate: 		metadata.endDate,
            make: metadata.cameraInfo.make.values().next().value || null,
            model: metadata.cameraInfo.cameraModel.values().next().value || null,
            serialNumber: metadata.cameraInfo.serialNumber.values().next().value || null // Array.from(metadata.cameraInfo.serialNumber)
          };

          /* first call api to check if deployment exists, when exists and skip is true, don't process it. */
          try {
            const response = await http.post(getDeploymentInfoUrl, param);
            if (response.data.hasOwnProperty('error')) {
              this.onUpdateCallback(deploymentKey, { isAllSkipped: true, message: i18n.t('cameraUploader-deploymentInfoFail', { err: response.data.error }) });
            } else if ((!response.data.hasOwnProperty('isNew') || response.data.isNew === false) && settings.skipExisting) {
              this.onUpdateCallback(deploymentKey, { isAllSkipped: true, message: i18n.t('cameraUploader-deploymentAlreadyExists', { deploymentName: deploymentName }) });
            } else if (!response.data.uuid) {
              this.onUpdateCallback(deploymentKey, { isAllSkipped: true, message: i18n.t('cameraUploader-deploymentIdFail') });
            } else {
              /* new or overwrite deployment, put deployment into a queue for futrue processing */
              const deplymentUUID = response.data.uuid;
              const deploymentPath = deplymentUUID + '/large/'; 	// large is required in path, otherwise no thumbnail will be created.
              let depObj = {};
              depObj = {};
              depObj.key = deploymentKey;
              depObj['name'] = deploymentName;
              depObj['deploymentPath'] = deploymentPath;
              depObj['id'] = response.data.id;

              //Process images as we go along instead.
              await this.processSingleDeploymentV2(depObj, http, settings);
            }
          } catch (e) {
           
            /* erorr on getting deployment info */
            this.onUpdateCallback(deploymentKey, { isAllSkipped: true, message: i18n.t('cameraUploader-deploymentInfoFail', { err: JSON.stringify(e) }) });
            throw e;
          }
        }
      }
    };
    this.onUpdateCallback(null, { preChecking: false, isAllCompleted: true });
    /* ----------------------------------------------------------------------------------
    step 2, use id/uuid info from step 1 to processing each deployment one at a time
    use recursive for deployment processing, so work on single deployment upload at one time
    ---------------------------------------------------------------------------------- */
    // Has been moved inside the deployment check loop in step 1
    // this.processSingleDeployment(http, settings);
  }
};
