import Vue from 'vue';
import axios from 'axios';
import i18n from '@/i18n';
import {isLoggedIn, login, getLanguageSession} from './user-auth0.js';
export function getParameterByName (name, defaultVal) {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(decodeURIComponent(window.location.search ? window.location.search : window.location.href));
  const value = match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  if (value) {
    return value;
  }
  if (defaultVal) {
    return defaultVal;
  }
  return null;
}

export function attachAuthorizaitonHeader () {
  const pattern = new RegExp('.execute-api.([a-zA-Z\\d-]){0,}.amazonaws.com', 'i'); // .execute-api.().amazonaws.com/
  Vue.http.interceptors.push((request, next) => {
    if ((request.url.indexOf('X-Amz-Security-Token') === -1 &&
      !(request.body && Object.prototype.toString.call(request.body).includes('FormData') && request.body.has('X-Amz-Credential')))
    ) { // when no amazon token attached, attach bear token
    
      if ( getAuthorizaitonHeader()) 
        request.headers.set('Authorization', getAuthorizaitonHeader());
      
      request.headers.set('Accept', 'application/json');
    }
    next(function (response) {
      if (response.status === 0 || response.status === 401 || response.status === 403 || response.status === 500) {
        if (!isLoggedIn() && request.url.match(pattern) == null) { // don't relogin for uploading
         // login();
        } else
          if ( [0,500,501,502,503].includes(response.status) && !!parseInt(process.env.ENABLE_MAINTENANCE_REDIRECT)) {
            
            if (process.env.NODE_ENV !== 'localhost') {
              if (window.location.pathname !== process.env.MAINTENANCE_URL) {
                let locale = getLanguageSession();
                console.error("Going into maintenance mode. Redirecting...");
                window.location.href = (locale!=='en'?'/'+locale:'') + process.env.MAINTENANCE_URL;
              }
            }
            else {
              //alert('Issue with an API call. This alert is in place of a redirect to a different page on local.');
            }
          }
      }
    })
  });

  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = getAuthorizaitonHeader();    
    return config;
  });

  axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    if (!isLoggedIn()) { // don't relogin for uploading
      //login();
    } else {
      return Promise.reject(error);
    }
  });
}

export function getAuthorizaitonHeader () {
  const token = localStorage.getItem('access_token');
  if (isLoggedIn()) {
    return 'Bearer ' + token;
  }
  return false;
  
  if (!token) return false;
  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik1rSkdOa1JDTWtNd09FTTRNa1pGTVRWRE1UaEJOVVkzUmpNd1FqWkROREZGT0RGRU16WTJOQSJ9.eyJodHRwczovL2Jpcy5hYm1pLmNhL3JvbGVzIjoiRGVsZWdhdGVkIEFkbWluIC0gQWRtaW5pc3RyYXRvciIsImlzcyI6Imh0dHBzOi8vYWJtaS5hdXRoMC5jb20vIiwic3ViIjoiZ29vZ2xlLW9hdXRoMnwxMDEyMTEyNjE4NjkyMTMyNDI3NzciLCJhdWQiOlsidGVzdDEyMyIsImh0dHBzOi8vYWJtaS5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNTI2MzE3MTA2LCJleHAiOjE1MjYzMjQzMDYsImF6cCI6IkVnMk1QVnRxa2YzU3VLUzV1WHpQOTdueFUxM1oySzFpIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCByZWFkOm1lc3NhZ2VzIGJpc19hZG1pbiJ9.BiixBchFOcs0Mt9033L0L-PutYXUG5d0ruQAe2GqnSar-stJmxZktDkC9JcG3SdUdw22p3G4CYvAGphiY4pqTFfajGmsigeHgNKoKZcz1dxbFVRDYh3rQeMjtBDkIvWqLJbh19cRU7YgXXrwr2BnR4n8-xuszDMmowiY4gLujdD5o5QFqomFHNyBo7GvPU3U12DTKEZNO44GzVqG9s7cXYjhmWBX39VZ_rkz-g3B6606sTsxVC-n1fZVPGGKL0uNf7nB3xvqDWCGOOAH0zfIYppYckR2FzTVZxVp68WALJUgQBjvuBeHXqHVEVMsVguLc1sY-T_Sq2VLwgLpdqxOLA';
  
}

export function exportToCsv (rows, header, headerObj, filename) {
  console.log('ready to export to csv ...')
  let generateSingleRow = (colVal) => {
    let innerValue = colVal == null ? '' : colVal.toString();
    if (colVal instanceof Date) {
      innerValue = colVal.toLocaleString();
    };
    let result = innerValue.replace(/"/g, '""');
    if (result.search(/("|,|\n)/g) >= 0) {
      result = '"' + result + '"';
    }
    return result + ',';
  };
  let processInput = (row) => {
    let finalVal = '';
    if (Array.isArray(row) === 'array') {
      row.forEach(j => { finalVal += generateSingleRow(j); });
    } else if (typeof row === 'object') {
      headerObj.forEach(j => { finalVal += generateSingleRow(row[j.trim()]); });
    }
    return finalVal + '\n';
  };

  let csvFile = header || '' + '\n';
  (rows || []).forEach(row => { csvFile += processInput(row); });

  downloadCsvFile(csvFile, filename);
}

function downloadCsvFile (csvFile, filename) {
  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
// use for create a temporary link on  webpage, and download file. can't use direct url as it requires authorization header.
export function downloadStreamFile (url, fileName, contentType) { // contentType 'application/zip'
  Vue.http.get(url, {headers: {'Content-Type': contentType, 'Accept': '*/*'},
    responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
    const blob = new Blob([response.body], {type: contentType});
    const objectUrl = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = objectUrl;
    link.download = fileName;
    document.body.appendChild(link); // for firefox if link not added into body, click won't work
    link.click();
    link.remove(); // remove the link after clicking. only needed for firefox
  },
  (err) => {
    alert('File Download failed. error=' + err.status);
  });
}

export function getToday () {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();

  return yyyy + '-' + String(mm).padStart(2, 0) + '-' + String(dd).padStart(2,0);
}

export function copyObject (obj) {
  return JSON.parse(JSON.stringify(obj));
}
export function compareObject (obj1, obj2, excludeFields) { // works with no DOM, no method, simple objects
  // console.log('compare', JSON.stringify(obj1), JSON.stringify(obj2));
  function replacer (key, value) {
    // Filtering out properties
    if ((excludeFields || []).includes(key)) {
      return undefined;
    }
    return value;
  }
  return JSON.stringify(obj1, replacer) === JSON.stringify(obj2, replacer);
}
export function confirmDialog (modal, title, text, buttonText1, callback1, buttonText2, callback2) {
  modal.show('dialog', {
    title: title,
    text: text,
    buttons: [
      {
        title: buttonText1 || i18n.t('common-yes'),
        default: true,
        handler: () => {
          modal.hide('dialog')
          if (callback1) {
            callback1();
          }
        }
      },
      {
        title: buttonText2 || i18n.t('common-no'),
        handler: () => {
          modal.hide('dialog')
          if (callback2) {
            callback2();
          }
        }
      }
    ]
  })
}

export function confirmDialog3Button (modal, title, text, buttonText1, callback1, buttonText2, callback2, buttonText3, callback3) {
  modal.show('dialog', {
    title: title,
    text: text,
    buttons: [
      {
        title: buttonText1 || i18n.t('common-yes'),
        default: true,
        handler: () => {
          modal.hide('dialog')
          if (callback1) {
            callback1();
          }
        }
      },
      {
        title: buttonText2 || i18n.t('common-no'),
        handler: () => {
          modal.hide('dialog');
          if (callback2) {
            callback2();
          }
        }
      },
      {
        title: buttonText3 || i18n.t('common-cancel'),
        handler: () => {
          modal.hide('dialog');
          if (callback3) {
            callback3();
          }
        }
      }
    ]
  })
}

export function alertDialog (modal, title, text, width) {
  modal.show('dialog', {
    title: title,
    text: text,
    buttons: [
      {
        title: i18n.t('common-close').toUpperCase(),
        default: true,
        handler: () => {
          modal.hide('dialog')
        }
      }
    ]
  })
}

export function validateEmail (email) {
  const re = /\S+@\S+\.\S+/; // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function capitalizeString (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const isLocal = () => {
  let parsedName = window.location.hostname.split('.');
  let nonLocalSubdomains = ['acpt', 'www', 'dev', 'prod', 'portal'];
  return !nonLocalSubdomains.includes(parsedName[0]);
};

export const isDev = () => {
  // console.log(window.location.hostname);
  let parsedName = window.location.hostname.split('.');
  let TLD = parsedName[parsedName.length-1];
  let subdomains = parsedName.slice(0, parsedName.length-2);

  if (TLD == 'localhost') return true;
  if (subdomains.length == 0) return false;

  return (TLD == 'localhost') || subdomains.filter(sub=>['acpt','www','portal'].includes(sub)).length == 0;
};

export const isProd = () => {
  let parsedName = window.location.hostname.split('.');
  let TLD = parsedName[parsedName.length-1];
  let subdomains = parsedName.slice(0, parsedName.length-2);

  return (subdomains.length == 0)  || subdomains.filter(sub=>['www'].includes(sub)).length == 1;
  
}

export const createLightboxImageObject = (img) => {
  if (!img.fileURL) return;
  
  let urlObj = new URL(img.fileURL);
  let path = urlObj.pathname
  return {
    ...img,
    'name': img.fileURL,
    'locationName': img.locationName,
    'extension': path.split('.')[path.split('.').length-1],
    'orgName': img.organizationName,
    'date': img.date.split(' ')[0] + '_' + img.date.split(' ')[1].replace(/:/g, '')
  };
};

export const isDataDiscover = () => {
  let parsedName = window.location.hostname.split('.');

//  let TLD = parsedName[parsedName.length-1];
//  let domain = parsedName[parsedName.length-2];
  let subdomains = parsedName.slice(0, parsedName.length-2);

  return subdomains.includes('discover');
};

export const getAPIUrl = () => {

    // console.log(window.location.hostname);
    let parsedName = window.location.hostname.split('.');

    let TLD = parsedName[parsedName.length-1];
    let domain = parsedName[parsedName.length-2];
    let subdomains = parsedName.slice(0, parsedName.length-2);
    

    // just pure wildtrax.ca assume prod. 
    if (subdomains.length == 0) {
     // if (TLD != 'localhost')
        return 'https://www-api.wildtrax.ca/bis/';
    //  return 'https://dev-api.wildtrax.ca/bis/';
    }

    
    switch (subdomains[0]) {
      case 'acpt': 
        return 'https://acpt-api.wildtrax.ca/bis/';
      case 'www': case 'portal': case 'chad': case 'dev':
        return 'https://www-api.wildtrax.ca/bis/';
        default: 
        return 'https://dev-api.wildtrax.ca/bis/';
    }
};

// '//api.wildtrax.ca/bis/';
export const API_URL = getAPIUrl();


export const getDataDiscoverURL = () => {
   // console.log(window.location.hostname);
   let parsedName = window.location.hostname.split('.');

   let TLD = parsedName[parsedName.length-1];
   let domain = parsedName[parsedName.length-2];
   let subdomains = parsedName.slice(0, parsedName.length-2);
   
   // just pure wildtrax.ca assume prod. 
   if (subdomains.length == 0)
     return 'https://discover.wildtrax.ca/';

   
   switch (subdomains[0]) {
     case 'acpt': 
       return 'https://discover.acpt.wildtrax.ca/';
     case 'www': case 'portal': 
       return 'https://discover.wildtrax.ca/';
     default: 
       return 'https://discover.dev.wildtrax.ca/';
   }
};

export const DHTMLX_LIB_PATH = '/static/';// process.env.DHTMLX_LIB_PATH;
export const TOM_PRJ_COLOR = {'ABMI Core Monitoring': '#a46306', 'Adopt-a-Camera': '#d2523e', 'Alberta Boreal Deer Project - Christina Lake': '#254b45', 'Algar UBC ITA': '#0dfdd5', 'Big Grid': '#37e008', 'Boutin 2014 Pilot Study': '#65d8de', 'Ecosystem Cameras': '#015799', 'Edge-Interior': '#e9c604', 'Integrated Landscape Management': '#5f390e', 'Nexen Pilot Study': '#f357db', 'Richardson UBC ITA': '#69e176', 'Township Project': '#f2a7cc', 'Wildlife Habitat Effectiveness and Connectivity': '#bcd828'};
export const prefixUrl = '';

export const ARU_RECORDING_URL = prefixUrl + '/home/aru-tasks/recording.html?taskId=';
export const CAMERA_RECORDING_URL = prefixUrl + '/home/camera-deployments/image-tagging.html?id=';
export const POINTCOUNT_RECORDING_URL = prefixUrl + '/home/pc-surveys/pc-survey.html?surveyId=';
export const CAMERA_VERIFY_PAGE_URL = prefixUrl + '/home/camera-deployments/species-verification.html?projectId=';
export const ARU_VERIFY_PAGE_URL = prefixUrl + '/home/aru-tasks/species-verification.html?projectId=';
export const ARU_PROJECT_URL = prefixUrl + '/home/aru-tasks.html?sensorId=ARU&projectId=';
export const CAM_PROJECT_URL = prefixUrl + '/home/camera-deployments.html?sensorId=CAM&projectId=';
export const PC_PROJECT_URL = prefixUrl + '/home/pc-surveys.html?sensorId=PC&projectId=';
export const POINTCOUNT_PROJECT_URL = prefixUrl + '/home/pc-surveys.html?sensorId=PC&projectId='
export const LANDOWNER_REPORTS_LIST_URL = prefixUrl + '/home/landowners/reports.html?UUID='
export const LANDOWNER_REPORT_URL = prefixUrl + '/home/landowners/report.html?UUID='
export const ORGANIZATION_ADMIN_URL = prefixUrl + '/home/organization-list/organization-admin.html?id=';
export const ORGANIZATION_LIST_URL = prefixUrl + '/home/organization-list.html';
export const PROJECTS_PAGE_WITH_SENSOR_URL = prefixUrl + '/home/projects.html?sensorId=';
export const HOME_URL = prefixUrl + '/home.html';
export const PROJECTS_URL = prefixUrl + '/home/projects.html';
export const DATA_DOWNLOAD_URL = prefixUrl + '/home/projects/data-download.html';
export const USER_SETTINGS_URL = prefixUrl + '/home/user-settings.html';
export const MAP_ACCESS_TOKEN = 'pk.eyJ1IjoicWZhbmciLCJhIjoiY2p5NjJ3OHNqMGUydDNibXpvZzdodzNsaCJ9.HAK1XLZht400oO5Q_19LsQ';
export const PROJECT_URLS = {
  'ARU': ARU_PROJECT_URL,
  'CAM': CAM_PROJECT_URL,
  'PC': PC_PROJECT_URL
}
export const TASK_URLS = {
  'ARU': ARU_RECORDING_URL,
  'CAM': CAMERA_RECORDING_URL,
  'PC': POINTCOUNT_RECORDING_URL
}

export const MY_PROJECTS_TOGGLE_SELECTION = 'myProjectsToggleSelection';
