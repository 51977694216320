var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-wrapper" }, [
    _c(
      "div",
      {
        staticClass:
          "container overflow col s12 panel boxed padded60 dataGroup tab-content"
      },
      [
        _c("h1", {}, [_vm._v("UUID Entry")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex justify-center items-center gap-1 w-1/2" },
          [
            _c("input", {
              ref: "box1",
              staticClass: "numBox !w-[110px]",
              attrs: { type: "text", id: "0", maxlength: "8" },
              on: {
                focus: function($event) {
                  return _vm.focus(0)
                },
                keyup: _vm.keyupEvent
              }
            }),
            _vm._v("-\n            "),
            _c("input", {
              ref: "box2",
              staticClass: "numBox !w-[67px]",
              attrs: { type: "text", id: "1", maxlength: "4" },
              on: {
                focus: function($event) {
                  return _vm.focus(1)
                },
                keyup: _vm.keyupEvent
              }
            }),
            _vm._v("-\n            "),
            _c("input", {
              ref: "box3",
              staticClass: "numBox !w-[67px] small",
              attrs: { type: "text", id: "2", maxlength: "4" },
              on: {
                focus: function($event) {
                  return _vm.focus(2)
                },
                keyup: _vm.keyupEvent
              }
            }),
            _vm._v("-\n            "),
            _c("input", {
              ref: "box4",
              staticClass: "numBox !w-[67px] small",
              attrs: { type: "text", id: "3", maxlength: "4" },
              on: {
                focus: function($event) {
                  return _vm.focus(3)
                },
                keyup: _vm.keyupEvent
              }
            }),
            _vm._v("-\n            "),
            _c("input", {
              ref: "box5",
              staticClass: "numBox large",
              attrs: { type: "text", id: "4", maxlength: "12" },
              on: {
                focus: function($event) {
                  return _vm.focus(4)
                },
                keyup: _vm.keyupEvent
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex justify-center items-center gap-1 w-1/2" },
          [
            _c(
              "button",
              {
                staticClass: "btn flex-1",
                attrs: { disabled: _vm.disableGo, id: "goBtn", type: "button" },
                on: { click: _vm.openUrl }
              },
              [_vm._v("Go")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn flex-1",
                attrs: { type: "button" },
                on: { click: _vm.clear }
              },
              [_vm._v("Clear")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }