<template>
    <div>
        <div class="col s12 no-images" v-if="!loading && !uploading && (!imageList || imageList.length === 0)">{{$t('visitPhotoGallery-noneYet')}}</div>
        <div class="col s12 error" v-if="message">{{message}}</div>
            <div class="col s12">
                  <div class="visit-photos-loader-wrapper" v-show="loading || uploading">
                    <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
                    <!--<div> <template v-if="uploading">uploading...</template><template v-else>loading...</template> </div>-->
                  </div>
              <div class="row gallery-wrapper visit-photos-overlay" :class="{visitPhotosOverlay: uploading || loading}">
                  <template v-if="imageList && imageList.length > 0 && options != null">
                    <div class="flex flex-wrap">
                    <template v-for="(img, index) in imageList">
                      <div :key="'image' + img.id" class="photo-box p-1 !w-1/5 flex-none" :id="index"
                        
                        >
                        <div 
                        v-tooltip="`${$t('visitPhotoGallery-fileName')}: ${img.fileName} <br /> 
                          ${img.directionId == null?'':$t('visitPhotoGallery-direction')+': '+Object.values(options.directions).find(direction=>direction.id==img.directionId).description+' <br />'}
                          ${img.verticalAngle == null?'':$t('visitPhotoGallery-verticalAngle')+': '+Object.values(options.verticalAngle).find(angle=>angle.id==img.verticalAngle).type+' <br />'}
                          ${$t('visitPhotoGallery-access')+': '+Object.values(options.imageAccess).find(access=>access.id==img.imageAccessId).type+' <br />'}
                          ${$t('visitPhotoGallery-comments')+': '+limitedText(img.comments)}
                          `"
                          class="imgWrap  h-48 overflow-hidden bg-center bg-cover"
                          :style="{backgroundImage: `url(${getImageSrc(false, img)})` }"
                          @error="imgUrlAlt($event, img)" 
                          :id="index" 
                          @click.stop.prevent="showLightbox(img.largeStoragePath)"
                          >
                          <!-- <img :src="" :id="'img' + img.id" @error="imgUrlAlt($event, img)"/> -->

                          <div v-show="!(loading || uploading)" class="imgOverlay">
                            <div class="magnify" >
                              <span class="ion-android-search exclude" ></span>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="img-info col s12 m8">
                          <div>
                            {{$t('visitPhotoGallery-fileName')}}: {{img.fileName}}
                          </div>
                          <div>
                            {{$t('visitPhotoGallery-direction')}}: 
                            <template v-if="img.directionId == null">--</template>
                              <template v-else v-for="direction in options.directions">
                                <template v-if="img.directionId === direction.id">{{direction.description}}</template>
                              </template>
                            </div>
                            <div>
                            {{$t('visitPhotoGallery-verticalAngle')}}: <template v-if="img.verticalAngle == null">--</template>
                              <template v-else v-for="verticalAngle in options.verticalAngle">
                                <template v-if="img.verticalAngle === verticalAngle.id">{{verticalAngle.type}}</template>
                              </template>
                            </div>
                          <div>
                            {{$t('visitPhotoGallery-access')}}: <template v-for="access in options.imageAccess">
                              <template v-if="img.imageAccessId === access.id">{{access.type}}</template>
                              </template>
                            </div>
                          <div class="visit-photo-comments">{{$t('visitPhotoGallery-comments')}}: {{limitedText(img.comments)}}</div>
                        </div> -->
                        <div v-if="isEditable" :key="'btns' + img.id" class="btns-wrapper row col s12">
                            <span class="cursor-pointer" @click="editSitePhoto(img, index)" :disabled="loading || uploading"><i class="fa fa-pencil"></i></span>
                            <span class="cursor-pointer"  @click="deleteSitePhoto(img, index)" :disabled="loading || uploading"><i class="fa fa-trash"></i></span>
                            <!-- <button class="btn btn-border btn-success modal-action modal-close no-bottom-margin" @click="editSitePhoto(img, index)" :disabled="loading || uploading">{{$t('common-edit')}}</button>
                            <button class="btn btn-border btn-success modal-action modal-close no-bottom-margin" @click="deleteSitePhoto(img, index)" :disabled="loading || uploading">{{$t('common-delete')}}</button> -->
                          </div>
                      </div>
                          
                    </template>
                    </div>
                  </template>
                </div>
            </div>
            <!-- -END Image PANEL- -->
      <!-- popup and modal boxes -->
      <lightbox id="mylightbox" ref="lightbox"
      v-bind:images="lightboxImages"
      :timeoutDuration="10000" v-bind:can-download="isEditable"></lightbox>

      <modal name="edit-visit-photo-info"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "85%"
      height = "55%"
      :minWidth="200"
      :minHeight="200"
      :clickToClose = "false"
    >
          <div v-if="editingImage != null">
          <div @click="close" class="iconButton link">
            <span class="ion-close"></span>
          </div>
          <div class="modal-header drag-handle">
            <h5>{{$t('visitPhotoGallery-editInfo')}}</h5>
          </div>
        <div class="modal-content padded40 main">
          <div class="col s12 m6 l5">
            <label for="editImageDirection">{{'visitPhotoGallery-direction'}}
              <!--<span class="info-icon ion-information-circled" v-tooltip="tooltips.name"></span>-->
            </label>
            <select id="editImageDirection" class="initialized" v-model="editingImage.directionId" type="number">
              <!--<option value="-100" disabled selected>Direction</option>-->
              <option v-for="(option, index) in options.directions" :key="'direction' + index" :value="option.id">{{option.description}}</option>
            </select>
          </div>
          <div class="col s12 m6 l5">
            <label for="editImageAngle">{{$t('visitPhotoGallery-verticalAngle')}}
              <!--<span class="info-icon ion-information-circled" v-tooltip="tooltips.name"></span>-->
            </label>
            <select id="editImageAngle" class="initialized" v-model="editingImage.verticalAngle" type="number">
              <!--<option value="-100" disabled selected>Direction</option>-->
              <option v-for="(option, index) in options.verticalAngle" :key="'vAngle' + index" :value="option.id">{{option.type}}</option>
            </select>
          </div>
          <div class="col s12 m6 l5">
            <label for="editImageAccess">{{$t('visitPhotoGallery-access')}}
              <!--<span class="info-icon ion-information-circled" v-tooltip="tooltips.name"></span>-->
            </label>
            <select id="editImageAccess" class="initialized" v-model="editingImage.imageAccessId" type="number">
              <!--<option value="-100" disabled selected>Access Type</option>-->
              <option v-for="(option, index) in options.imageAccess" :key="'access' + index" :value="option.id">{{option.type}}</option>
            </select>
          </div>
          <div class="col s12 ">
            <label for="editImageComments">{{'visitPhotoGallery-comments'}}</label>
            <textarea id="editImageComments" class="materialize-textarea" data-length="120" v-model="editingImage.comments"></textarea>
            <span class="character-counter" style="float: right; font-size: 12px; height: 1px;"></span>
          </div>
          <div class="modal-footer clearFloats">
            <div class="warning">{{editMessage}}</div>
            <button class="btn btn-border btn-success modal-action modal-close" @click="saveImgInfo">{{$t('common-save')}}</button>
            <button class="btn btn-border btn-success modal-action modal-close" @click="close">{{$t('common-close')}}</button>
          </div>
        </div>
    </div>

    </modal>
    <v-dialog/>

    </div>
</template>

<script>
/*
Shows a list of images of the site during the visit
 */
import {API_URL} from '@/lib/common';
import Lightbox from '@/components/utils/lightbox.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
export default {
  name: 'visit-photo-gallery',
  components: {
    'lightbox': Lightbox,
    'pulse-loader': PulseLoader
  },
  props: ['visitId', 'uploading', 'isEditable'],
  created () {
    if (this.visitId != null) {
      this.getOptions();
      this.getImages();
    }
  },
  watch: {
    visitId: function (newVal, oldVal) {
      if (newVal != null) {
        this.getOptions();
      }
    }
  },
  methods: {
     
    /* when image load error use place holder image */
    imgUrlAlt (event, img) {
      // if the thumbnail hasnt been created on s3, use original img instead
      if (this.getImageSrc(false, img) === event.target.src) {
        event.target.src = this.getImageSrc(true, img);
        return;
      }
      const placeHolderImageUrl = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      if (placeHolderImageUrl !== event.target.src) {
        event.target.src = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      }
    },
    getImageSrc (isLarge, img) {
      return 'https://' + img.storage.camBucket + '.s3-accelerate.amazonaws.com/site-photos/' + img.uuid + '/' + (isLarge ? img.storage.imagePrefix : img.storage.thumbNailPrefix) + '/' + img.id + '.' + img.extension;
    },
    showLightbox (imgUrl) {
      this.$refs.lightbox.show(imgUrl);
    },
    populateLightBoxImages (imgs) {
      let lbImages = [];
      this.lightboxImages = [];
      if (!imgs || imgs.length === 0) {
        return;
      }
      imgs.forEach((img, index) => {
        console.log('ere2');
        lbImages.push({
          ...img,
          'bucket': img.storage.camBucket,
          'name': this.getImageSrc(true, img),
          'alt': this.$t('common-imgNumber', {num: (index + 1), total: imgs.length}),
          'id': img.id,
          'extension': img.extension,
          'fileName': img.fileName
        });
      });
      this.lightboxImages = lbImages;
    },
    getImages () {
      this.loading = true;
      this.$http.get(this.getVisitPhotosUrl + this.visitId).then(response => {
        this.loading = false;
        if (response.body.hasOwnProperty('error')) {
          this.message = response.body.error;
          this.imageList = [];
        } else {
          this.imageList = response.data;
          // sort by id
          this.imageList.sort((a, b) => {
            if (a.id > b.id) {
              return 1;
            }
            if (a.id < b.id) {
              return -1;
            }
            return 0;
          });
          this.populateLightBoxImages(this.imageList);
        }
      },
      function () {
        this.loading = false;
        this.imageList = [];
        this.message = this.$t('visitPhotoGallery-loadingError');
      }
      );
    },
    getOptions () {
      this.$http.get(this.getVisitImageOptionsUrl).then(response => {
        if (response.body.hasOwnProperty('error')) {
          this.message = response.body.error;
        } else {
          this.options = response.body;
        }
      },
      function () {
        this.message = this.$t('visitPhotoGallery-optionsError');
      });
    },
    addUploadedImages (imgs) {
      this.imageList = this.imageList.concat(imgs);
      this.populateLightBoxImages(this.imageList);
      // setTimeout(() => { this.imageList = this.imageList.concat(imgs); }, this.delayNum);
    },
    editSitePhoto (img, index) {
      this.editingImage = img;
      this.editingImgIndex = index;
      this.$modal.show('edit-visit-photo-info');
    },
    saveImgInfo () {
      let {storage, ...params} = this.editingImage;

      this.$http.post(this.updateSitePhotoUrl, params).then(response => {
        if (response.body.hasOwnProperty('error')) {
          this.editMessage = response.body.error;
        } else {
          // this.imageList = response.data;
          this.imageList[this.editingImgIndex].comments = response.data.comments;
          this.imageList[this.editingImgIndex].directionId = response.data.directionId;
          this.imageList[this.editingImgIndex].imageAccessId = response.data.imageAccessId;
          this.editMessage = this.$t('visitPhotoGallery-infoSaved');
        }
      },
      function () {
        this.editMessage = this.$t('visitPhotoGallery-updateError');
      }
      );
    },
    deleteSitePhoto (img, index) {
      if (!confirm(this.deleteSitePhotoMsg)) {
        return;
      }
      let {storage, ...params} = img;
      this.$http.post(this.deleteSitePhotoUrl, params).then(response => {
        if (response.body.hasOwnProperty('error')) {
          this.message = response.body.error;
        } else {
          this.imageList.splice(index, 1);
          this.message = this.$t('visitPhotoGallery-photoDeleted');
          this.lightboxImages.splice(index, 1);
        }
      },
      function () {
        this.message = this.$t('visitPhotoGallery-deleteError');
      }
      );
    },
    close () {
      this.editingImage = null;
      this.editingImgIndex = -1;
      this.editMessage = '';
      this.$modal.hide('edit-visit-photo-info');
    },
    limitedText (text) {
      if (!text) return '';

      // only show a certain number of characters before showing '...'
      if (text == null) {
        return;
      }
      if (text.length < 200) {
        return text;
      } else {
        let tmp = text.slice(0, 199);
        tmp = tmp.concat('...');
        return tmp;
      }
    }
  },
  data () {
    return {
      getVisitPhotosUrl: API_URL + 'get-location-visit-image-by-visit?visitId=',
      updateSitePhotoUrl: API_URL + 'create-or-update-location-visit-image',
      deleteSitePhotoUrl: API_URL + 'delete-location-visit-image',
      getVisitImageOptionsUrl: API_URL + 'get-location-visit-image-options',
      initalParamNames: ['sortAsc', 'limit', 'sort', 'pudId', 'projectId'],
      currentImgId: null,
      selectedImgId: [],
      imageList: [],
      lightboxImages: [],
      options: null,
      ImagePerPageOption: [12, 24, 48, 60, 80],
      pagination: null,
      totalPageNum: 0,
      totalImageCount: 1000,
      selectedImageCount: 0,
      loading: false,
      currentInViewImageIndex: -1,
      message: '',
      deleteSitePhotoMsg: this.$t('visitPhotoGallery-deleteMsg'),
      // edit visit photo vars
      editMessage: '',
      editingImage: null,
      editingImgIndex: -1
    }
  }
}
</script>
<style scoped>

.imgWrap .imgOverlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(50, 50, 50, 0.4);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 2.3em;
    cursor: pointer;
}

.imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    transition-delay: 0.8s;

}
.imgWrap:hover .imgOverlay {
    opacity: 1;
    display:flex;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
}
.imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
}
.imgWrap .imgOverlay .magnify{
    font-size: 1px;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.5s!important;
}
.imgWrap:hover .imgOverlay span{
    font-size: 2.3rem;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.8s!important;
}

.imgOverlay .magnify {
    color: #fff;
    display: inline-block;
    padding: 4px 6px;
    cursor: pointer;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
}

.imgWrap{
  width: 100%;
  position: relative;
  display: inline-block;
  float: left;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.imgWrap img{
  width: 100%;
  display: inline-block;
  float: left;
}

.gallery-wrapper{
  margin-top: 20px;
}

.clearFloats{
  clear: both;
}

.no-images{
  text-align: center;
}

.visitPhotosOverlay{
  opacity: 0.6;
  z-index: 100;
  background: white;
}

.visit-photos-loader-wrapper{
  text-align: center;
  margin-top:30px;
  position: absolute;
  left:50%;
  transform:translateX(-50%);
  z-index: 101;
}

.no-bottom-margin{
  margin-bottom: 0px;
}

.btns-wrapper{
  padding: 0px;
}

.photo-box{
  margin-bottom: 10px;
}
/*
.visit-photo-comments{
  --lh: 1.4rem;
  line-height: var(--lh);
  --max-lines: 3;
  position:relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow:hidden;
  padding-right: 1rem;
}

.visit-photo-comments::before{
position:absolute;
content: "...";
inset-block-end:0;
inset-inline-end:0;
}

.visit-photos-comments::after{
  content:"";
  position:absolute;
  inset-inline-end: 0;
  width:1rem;
  height:1rem;
  background: white;
}
*/
</style>
