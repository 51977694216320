var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "batchUploadForm" },
    [
      _c(
        "modal",
        {
          attrs: {
            name: "upload-camera-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form !overflow-y-auto",
            draggable: ".drag-handle",
            delay: 100,
            width: "80%",
            height: "90%",
            minWidth: 200,
            minHeight: 200,
            clickToClose: false
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "iconButton link", on: { click: _vm.closeForm } },
              [_c("span", { staticClass: "ion-close" })]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-header drag-handle" }, [
              _c("h6", [_vm._v(_vm._s(_vm.$t("cameraUploadForm-title")))])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "modal-content container" }, [
                _c("div", { staticClass: "row" }, [
                  !_vm.batchUpload.folderUploadSupported
                    ? _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("cameraUploadForm-BrowserNotSupported"))
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.batchUpload.folderUploadSupported,
                        expression: "batchUpload.folderUploadSupported"
                      }
                    ],
                    staticClass: "container-fluid",
                    attrs: { id: "batchForm" }
                  },
                  [
                    _c(
                      "div",
                      [
                        _vm._l(_vm.message, function(msg, index) {
                          return [
                            _c(
                              "div",
                              { key: "msg" + index, staticClass: "error" },
                              [_vm._v(" " + _vm._s(msg))]
                            )
                          ]
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "margin-bottom-20" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tabbed",
                          staticStyle: { "margin-left": "0" }
                        },
                        [
                          _c("ul", { staticClass: "tabs" }, [
                            _c(
                              "li",
                              {
                                staticClass: "tab",
                                on: {
                                  click: function($event) {
                                    _vm.tab = "upload"
                                  }
                                }
                              },
                              [
                                _c(
                                  "a",
                                  { class: { active: _vm.tab === "upload" } },
                                  [
                                    _c("span", {
                                      staticClass: "ion-android-upload"
                                    }),
                                    _vm._v(_vm._s(_vm.$t("common-uploading")))
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tab === "upload",
                                  expression: "tab === 'upload'"
                                }
                              ],
                              staticClass: "padded20 boxed dataGroup"
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.batchUpload.status.uploading,
                                      expression:
                                        "!batchUpload.status.uploading"
                                    }
                                  ],
                                  staticClass: "row folderFormat"
                                },
                                [
                                  _vm.isAbmi
                                    ? _c("div", { staticClass: "col s12" }, [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cameraUploadForm-formatting"
                                              )
                                            ) + "\n                        "
                                          ),
                                          _c("span", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.$t(
                                                  "cameraUploadForm-tooltips.formats"
                                                ),
                                                expression:
                                                  "$t('cameraUploadForm-tooltips.formats')"
                                              }
                                            ],
                                            staticClass:
                                              "info-icon ion-information-circled"
                                          })
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col s12" }, [
                                          _c("label", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.batchUpload.settings
                                                      .folderNamingFormat,
                                                  expression:
                                                    "batchUpload.settings.folderNamingFormat"
                                                }
                                              ],
                                              attrs: {
                                                type: "radio",
                                                id: "fnf1",
                                                value: "site-only"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.batchUpload.settings
                                                    .folderNamingFormat,
                                                  "site-only"
                                                )
                                              },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    return _vm.$set(
                                                      _vm.batchUpload.settings,
                                                      "folderNamingFormat",
                                                      "site-only"
                                                    )
                                                  },
                                                  _vm.changeFolderFormatting
                                                ]
                                              }
                                            }),
                                            _c("span", [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cameraUploadForm-nonABMILocation"
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "sub-item-indent",
                                              class: {
                                                blocked: !(
                                                  _vm.batchUpload.settings
                                                    .folderNamingFormat ===
                                                    null ||
                                                  !_vm.batchUpload.settings.folderNamingFormat.includes(
                                                    "abmi"
                                                  )
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label col-md-2 col-sm-3",
                                                  attrs: {
                                                    for:
                                                      "settings-usefoldername"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "cameraUploadForm-level"
                                                      )
                                                    ) +
                                                      "\n                            "
                                                  ),
                                                  _c("span", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: {
                                                          content:
                                                            _vm.batchUpload
                                                              .settings
                                                              .folderNamingFormat ===
                                                              null ||
                                                            !_vm.batchUpload.settings.folderNamingFormat.includes(
                                                              "abmi"
                                                            )
                                                              ? _vm.$t(
                                                                  "cameraUploadForm-tooltips.levels"
                                                                )
                                                              : ""
                                                        },
                                                        expression:
                                                          "{content: (batchUpload.settings.folderNamingFormat === null || !batchUpload.settings.folderNamingFormat.includes('abmi')) ? $t('cameraUploadForm-tooltips.levels') : ''}"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "info-icon ion-information-circled"
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value:
                                                        _vm.batchUpload.settings
                                                          .baseLevel,
                                                      expression:
                                                        "batchUpload.settings.baseLevel",
                                                      modifiers: {
                                                        number: true
                                                      }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control folderBaseInput",
                                                  attrs: {
                                                    type: "number",
                                                    id:
                                                      "settings-usefoldername",
                                                    value: "",
                                                    min: "1",
                                                    disabled:
                                                      _vm.batchUpload.settings
                                                        .folderNamingFormat &&
                                                      _vm.batchUpload.settings.folderNamingFormat.includes(
                                                        "abmi"
                                                      )
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.batchUpload.settings
                                                        .baseLevel
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "baseLevel",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.$forceUpdate()
                                                    }
                                                  }
                                                })
                                              ])
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col s12 folderFormatHeight"
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.batchUpload.settings
                                                        .folderNamingFormat,
                                                    expression:
                                                      "batchUpload.settings.folderNamingFormat"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  id: "fnf4",
                                                  value: "abmi-station"
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    _vm.batchUpload.settings
                                                      .folderNamingFormat,
                                                    "abmi-station"
                                                  )
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      return _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "folderNamingFormat",
                                                        "abmi-station"
                                                      )
                                                    },
                                                    _vm.changeFolderFormatting
                                                  ]
                                                }
                                              }),
                                              _c("span", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "cameraUploadForm-ABMIStation"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ])
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col s12 folderFormatHeight"
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.batchUpload.settings
                                                        .folderNamingFormat,
                                                    expression:
                                                      "batchUpload.settings.folderNamingFormat"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  id: "fnf4",
                                                  value: "abmi-nostation"
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    _vm.batchUpload.settings
                                                      .folderNamingFormat,
                                                    "abmi-nostation"
                                                  )
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      return _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "folderNamingFormat",
                                                        "abmi-nostation"
                                                      )
                                                    },
                                                    _vm.changeFolderFormatting
                                                  ]
                                                }
                                              }),
                                              _c("span", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "cameraUploadForm-ABMINoStation"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ])
                                            ])
                                          ]
                                        )
                                      ])
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col s12 grid grid-cols-3"
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "label col-md-2 col-sm-3",
                                                attrs: {
                                                  for: "settings-usefoldername"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "cameraUploadForm-level"
                                                    )
                                                  ) +
                                                    "\n                        "
                                                ),
                                                _c("span", {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.$t(
                                                        "cameraUploadForm-tooltips.levels"
                                                      ),
                                                      expression:
                                                        "$t('cameraUploadForm-tooltips.levels')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "info-icon ion-information-circled"
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "flex items-center"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.batchUpload.settings
                                                          .baseLevel
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value:
                                                        _vm.batchUpload.settings
                                                          .baseLevel,
                                                      expression:
                                                        "batchUpload.settings.baseLevel",
                                                      modifiers: {
                                                        number: true
                                                      }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control folderBaseInput !m-0",
                                                  attrs: {
                                                    type: "range",
                                                    step: "1",
                                                    id:
                                                      "settings-usefoldername",
                                                    value: "",
                                                    min: "1",
                                                    max: "5",
                                                    disabled:
                                                      _vm.batchUpload.settings
                                                        .folderNamingFormat &&
                                                      _vm.batchUpload.settings.folderNamingFormat.includes(
                                                        "abmi"
                                                      )
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.batchUpload.settings
                                                        .baseLevel
                                                  },
                                                  on: {
                                                    __r: function($event) {
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "baseLevel",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.$forceUpdate()
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("label", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.batchUpload.settings
                                                      .skipExisting,
                                                  expression:
                                                    "batchUpload.settings.skipExisting"
                                                }
                                              ],
                                              staticClass: "exclusive checkbox",
                                              attrs: {
                                                type: "checkbox",
                                                id: "settings-skipdeployment"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.batchUpload.settings
                                                    .skipExisting
                                                )
                                                  ? _vm._i(
                                                      _vm.batchUpload.settings
                                                        .skipExisting,
                                                      null
                                                    ) > -1
                                                  : _vm.batchUpload.settings
                                                      .skipExisting
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.batchUpload.settings
                                                        .skipExisting,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.batchUpload
                                                            .settings,
                                                          "skipExisting",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.batchUpload
                                                            .settings,
                                                          "skipExisting",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.batchUpload.settings,
                                                      "skipExisting",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "control-label col-md-2 col-sm-3 !normal-case",
                                                  attrs: {
                                                    for:
                                                      "settings-skipdeployment"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "cameraUploadForm-skipExisting"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.$t(
                                                      "cameraUploadForm-tooltips.skipExisting"
                                                    ),
                                                    expression:
                                                      "$t('cameraUploadForm-tooltips.skipExisting')"
                                                  }
                                                ],
                                                staticClass:
                                                  "info-icon ion-information-circled"
                                              })
                                            ])
                                          ])
                                        ]
                                      )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "form",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.batchUpload.folderUploadSupported,
                                      expression:
                                        "batchUpload.folderUploadSupported"
                                    }
                                  ],
                                  staticClass: "container-fluid",
                                  attrs: {
                                    method: "GET",
                                    role: "form",
                                    enctype: "multipart/form-data",
                                    id: "batchForm"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.batchUpload.status
                                            .uploading,
                                          expression:
                                            "!batchUpload.status.uploading"
                                        }
                                      ],
                                      staticClass: "row"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col s4 offset-s2" },
                                        [
                                          _c("input", {
                                            ref: "chooseFolder",
                                            staticClass: "form-control hidden",
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "file",
                                              id: "chooseFolder",
                                              name: "picture",
                                              accept: "image/*",
                                              webkitdirectory: "true",
                                              multiple: "true"
                                            },
                                            domProps: {
                                              value: _vm.fileInputVal
                                            },
                                            on: {
                                              change: _vm.parseUploadFolders
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-default",
                                              staticStyle: { width: "90%" },
                                              attrs: {
                                                type: "button",
                                                id: "browseButton",
                                                disabled: !_vm.batchUpload
                                                  .settings.folderNamingFormat
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.browseFolder()
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "glyphicon glyphicon-refresh"
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("common-folderBtn")
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col s4" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-secondary",
                                            staticStyle: { width: "90%" },
                                            attrs: {
                                              type: "button",
                                              disabled: !_vm.batchUpload.status
                                                .totalFileCount
                                            },
                                            on: { click: _vm.resetBatchUpload }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("Clear Folder Selection")
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.batchUpload.status.filesParsed
                                ? _c(
                                    "div",
                                    { attrs: { id: "batchUploadPreview" } },
                                    [
                                      _vm._l(
                                        _vm.batchUpload.status.warnings,
                                        function(w, index) {
                                          return _c(
                                            "p",
                                            {
                                              key: "key" + index,
                                              staticClass: "text-danger"
                                            },
                                            [_vm._v(_vm._s(w))]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm.showCoordsForm
                                        ? _c("div", [
                                            _c("h5", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Enter Missing Coordinates"
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "p-3 bg-gray-100"
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "pb-3" },
                                                  [
                                                    _vm._v(
                                                      " \n                       " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Once saved, location coordinates will be saved in the organization even if you do not proceed with uploading. If you don't want to enter coordinates for locations one at a time, keep the fields blank and use the 'Manage' button > 'Upload Location CSV' option in the project instead."
                                                          )
                                                        ) +
                                                        "\n                       "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "When coordinates are entered via image upload, Location Visibility is automatically set to Visible. If you want to adjust the Location Visibility, use the Location sync functionalities under the Manage button"
                                                        )
                                                      ) +
                                                      "\n                        "
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "mb-5" }, [
                                              _c(
                                                "table",
                                                [
                                                  _c("tr", [
                                                    _c("th", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tc(
                                                            "common-location",
                                                            1
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _c("th", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common-latitude"
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _c("th", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common-longitude"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.locations,
                                                    function(location, index) {
                                                      return [
                                                        _c(
                                                          "tr",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "!border-b"
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "p-0"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    location.name
                                                                  )
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-red-500 text-sm"
                                                                  },
                                                                  [_vm._v(" ")]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      location.latitude,
                                                                    expression:
                                                                      "location.latitude"
                                                                  }
                                                                ],
                                                                class: {
                                                                  highlight:
                                                                    (location.latitude ==
                                                                      null ||
                                                                      location
                                                                        .latitude
                                                                        .length ==
                                                                        0) &&
                                                                    location.longitude
                                                                },
                                                                attrs: {
                                                                  type: "text",
                                                                  min: "-90",
                                                                  max: "90"
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    location.latitude
                                                                },
                                                                on: {
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.latlongValidation(
                                                                      location,
                                                                      "lat"
                                                                    )
                                                                  },
                                                                  blur: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.insertUpdateLocation(
                                                                      location
                                                                    )
                                                                  },
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      location,
                                                                      "latitude",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-red-500 text-sm"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      location.error ||
                                                                        " "
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      location.longitude,
                                                                    expression:
                                                                      "location.longitude"
                                                                  }
                                                                ],
                                                                class: {
                                                                  highlight:
                                                                    (location.longitude ==
                                                                      null ||
                                                                      location
                                                                        .longitude
                                                                        .length ==
                                                                        0) &&
                                                                    location.latitude
                                                                },
                                                                attrs: {
                                                                  type: "text",
                                                                  min: "-180",
                                                                  max: "180"
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    location.longitude
                                                                },
                                                                on: {
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.latlongValidation(
                                                                      location,
                                                                      "long"
                                                                    )
                                                                  },
                                                                  blur: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.insertUpdateLocation(
                                                                      location
                                                                    )
                                                                  },
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      location,
                                                                      "longitude",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-red-500 text-sm"
                                                                },
                                                                [_vm._v(" ")]
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  class: {
                                                                    btn: true,
                                                                    "btn-default": !location.id,
                                                                    "btn-success":
                                                                      location.id
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      location.longitude ==
                                                                        null ||
                                                                      location
                                                                        .longitude
                                                                        .length ==
                                                                        0 ||
                                                                      location.latitude ==
                                                                        null ||
                                                                        location
                                                                          .latitude
                                                                          .length ==
                                                                          0
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.insertUpdateLocation(
                                                                        location
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    class: {
                                                                      fas: true,
                                                                      "fa-save": !location.loading,
                                                                      "fa-spinner fa-spin":
                                                                        location.loading
                                                                    }
                                                                  })
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-red-500 text-sm"
                                                                },
                                                                [_vm._v(" ")]
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-default",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.showCoordsForm = false
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Continue to Upload"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        : _c("div", [
                                            _c("h5", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$tc(
                                                    "cameraUploadForm-foundLocations",
                                                    _vm.batchUpload.status
                                                      .locationCount,
                                                    {
                                                      numLocations:
                                                        _vm.batchUpload.status
                                                          .locationCount
                                                    }
                                                  ) +
                                                    " " +
                                                    _vm.$tc(
                                                      "cameraUploadForm-foundFiles",
                                                      _vm.batchUpload.status
                                                        .imageFileCount,
                                                      {
                                                        numFiles:
                                                          _vm.batchUpload.status
                                                            .imageFileCount
                                                      }
                                                    )
                                                )
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "ol",
                                              _vm._l(
                                                _vm.batchUpload.status
                                                  .deploymentStatus,
                                                function(deployment, dname) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: "deployment" + dname,
                                                      attrs: { id: dname }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("Location")
                                                          ) +
                                                          " "
                                                      ),
                                                      deployment.site ==
                                                      deployment.name
                                                        ? _c("span")
                                                        : _c("span", [
                                                            _c("a", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  deployment.site
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Subfolder"
                                                                  )
                                                                ) +
                                                                ":"
                                                            )
                                                          ]),
                                                      _vm._v(" "),
                                                      _c("a", [
                                                        _vm._v(
                                                          _vm._s(
                                                            deployment.name
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      deployment.station
                                                        ? [
                                                            _vm._v(
                                                              ", " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "cameraUploadForm-station"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  deployment.station
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        : _vm._e(),
                                                      _vm._v(
                                                        ", " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "cameraUploadForm-images"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("a", [
                                                        _vm._v(
                                                          _vm._s(
                                                            deployment.total
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("a", [
                                                        deployment.uploadedStatus
                                                          ? _c("span", {
                                                              staticClass:
                                                                "ion ion-android-done-all"
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        deployment.isAllSkipped
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ion ion-android-done"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "cameraUploadForm-skipped"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        deployment.readingMetadata
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ion ion-android-done"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "cameraUploadForm-scanMetadata"
                                                                    )
                                                                  ) + " "
                                                                ),
                                                                _c(
                                                                  "pulse-loader"
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.batchUpload.status
                                                        .uploading ||
                                                      _vm.batchUpload.status
                                                        .allUploaded
                                                        ? _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: !deployment.isAllSkipped,
                                                                  expression:
                                                                    "!deployment.isAllSkipped"
                                                                }
                                                              ]
                                                            },
                                                            [
                                                              _c("span", [
                                                                _c("a", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      deployment.completed
                                                                    )
                                                                  )
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "cameraUploadForm-uploadSuccess"
                                                                      )
                                                                    ) +
                                                                    ","
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        deployment.skipped >
                                                                        0,
                                                                      expression:
                                                                        "deployment.skipped > 0"
                                                                    }
                                                                  ]
                                                                },
                                                                [
                                                                  _c("a", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        deployment.skipped
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .$t(
                                                                            "cameraUploadForm-skipped"
                                                                          )
                                                                          .toLowerCase()
                                                                      ) +
                                                                      ","
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        deployment.failed >
                                                                        0,
                                                                      expression:
                                                                        "deployment.failed > 0 "
                                                                    }
                                                                  ]
                                                                },
                                                                [
                                                                  _c("a", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        deployment.failed
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "cameraUploadForm-failed"
                                                                        )
                                                                      ) +
                                                                      ", "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "cameraUploadForm-running"
                                                                    )
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      Math.floor(
                                                                        0.001 *
                                                                          (deployment.endTime -
                                                                            deployment.startTime)
                                                                      )
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "commonUnits-secondsFull"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      deployment.message
                                                        .length > 0
                                                        ? _vm._l(
                                                            deployment.message,
                                                            function(
                                                              message,
                                                              index2
                                                            ) {
                                                              return _c(
                                                                "p",
                                                                {
                                                                  key:
                                                                    "mesage" +
                                                                    dname +
                                                                    index2,
                                                                  staticClass:
                                                                    "error",
                                                                  attrs: {
                                                                    id: dname
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        message
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "w-full mt-2.5" },
                                              [
                                                _vm.batchUpload.status
                                                  .ignoredFiles.length > 0
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("p", [
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.batchUpload
                                                                  .status
                                                                  .ignoredFiles
                                                                  .length
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "cameraUploadForm-ignored"
                                                                )
                                                              )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.batchUpload.status
                                                            .ignoredFiles,
                                                          function(f, index) {
                                                            return _c(
                                                              "ul",
                                                              {
                                                                key:
                                                                  "file" + index
                                                              },
                                                              [
                                                                _c("li", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        f.error
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    ": " +
                                                                      _vm._s(
                                                                        f.path
                                                                      )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.filePreChecking,
                                                        expression:
                                                          "filePreChecking"
                                                      }
                                                    ],
                                                    attrs: { id: "parsingDiv" }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "warning"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "cameraUploadForm-reading"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                !_vm.batchUpload.status
                                                  .allUploaded &&
                                                !_vm.batchUpload.status
                                                  .uploading &&
                                                this.locations.length > 0
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-default",
                                                        attrs: {
                                                          type: "button"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.showCoordsForm = true
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "Go back to set coordinates"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.batchUpload.status
                                                  .uploading &&
                                                !_vm.batchUpload.status
                                                  .allUploaded &&
                                                _vm.batchUpload.status
                                                  .imageFileCount > 0
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-default",
                                                        attrs: {
                                                          type: "button"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.processBatchUpload
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common-upload"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.batchUpload.status
                                                  .allUploaded
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "error" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "cameraUploadForm-uploadComplete"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.batchUpload.status
                                                  .hasError &&
                                                !_vm.batchUpload.status
                                                  .uploading
                                                  ? _c("div", [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-default btn-success",
                                                          attrs: {
                                                            type: "button"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.showFailedItems
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "cameraUploadForm-viewFailed"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-default",
                                                          attrs: {
                                                            type: "button"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.processFailedItems
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "cameraUploadForm-uploadFailed"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.batchUpload.status.uploading
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-default",
                                                        attrs: {
                                                          type: "button"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common-uploading"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ])
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tab === "setting",
                                  expression: "tab === 'setting'"
                                }
                              ],
                              staticClass: "padded20 boxed dataGroup"
                            },
                            [
                              _c("div", { staticClass: "row padded10" }, [
                                _c("div", { staticClass: "col s12" }, [
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.batchUpload.settings
                                              .skipExisting,
                                          expression:
                                            "batchUpload.settings.skipExisting"
                                        }
                                      ],
                                      staticClass: "exclusive checkbox",
                                      attrs: {
                                        type: "checkbox",
                                        id: "settings-skipdeployment"
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.batchUpload.settings.skipExisting
                                        )
                                          ? _vm._i(
                                              _vm.batchUpload.settings
                                                .skipExisting,
                                              null
                                            ) > -1
                                          : _vm.batchUpload.settings
                                              .skipExisting
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.batchUpload.settings
                                                .skipExisting,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.batchUpload.settings,
                                                  "skipExisting",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.batchUpload.settings,
                                                  "skipExisting",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.batchUpload.settings,
                                              "skipExisting",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "control-label col-md-2 col-sm-3",
                                          attrs: {
                                            for: "settings-skipdeployment"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cameraUploadForm-skipExisting"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.$t(
                                              "cameraUploadForm-tooltips.skipExisting"
                                            ),
                                            expression:
                                              "$t('cameraUploadForm-tooltips.skipExisting')"
                                          }
                                        ],
                                        staticClass:
                                          "info-icon ion-information-circled"
                                      })
                                    ])
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row padded10" }, [
                                _c("div", { staticClass: "col s12" }, [
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.batchUpload.settings
                                              .overwriteExisting,
                                          expression:
                                            "batchUpload.settings.overwriteExisting"
                                        }
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        name: "settings.overwrite",
                                        id: "settings-overwrite"
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.batchUpload.settings
                                            .overwriteExisting
                                        )
                                          ? _vm._i(
                                              _vm.batchUpload.settings
                                                .overwriteExisting,
                                              null
                                            ) > -1
                                          : _vm.batchUpload.settings
                                              .overwriteExisting
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.batchUpload.settings
                                                .overwriteExisting,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.batchUpload.settings,
                                                  "overwriteExisting",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.batchUpload.settings,
                                                  "overwriteExisting",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.batchUpload.settings,
                                              "overwriteExisting",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "cameraUploadForm-overwriteExisting"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.$t(
                                              "cameraUploadForm-tooltips.overwriteExisting"
                                            ),
                                            expression:
                                              "$t('cameraUploadForm-tooltips.overwriteExisting')"
                                          }
                                        ],
                                        staticClass:
                                          "info-icon ion-information-circled"
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "view-failed-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form",
            draggable: ".drag-handle",
            delay: 100,
            width: "60%",
            height: "auto",
            minWidth: 200,
            minHeight: 200,
            clickToClose: true
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "iconButton link",
              on: { click: _vm.closeFailedModal }
            },
            [_c("span", { staticClass: "ion-close" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-header drag-handle" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("Failed Upload Log")))])
          ]),
          _vm._v(" "),
          _c(
            "ol",
            { staticClass: "p-5" },
            [
              _vm._l(_vm.batchUpload.status.deploymentStatus, function(
                deployment,
                dname
              ) {
                return [
                  deployment.failed > 0
                    ? _c(
                        "li",
                        { key: "deploymentfailed" + dname },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("cameraUploadForm-location")) +
                              " "
                          ),
                          _c("a", [_vm._v(_vm._s(deployment.name))]),
                          _vm._v(" "),
                          deployment.station
                            ? [
                                _vm._v(
                                  ", " +
                                    _vm._s(_vm.$t("cameraUploadForm-station")) +
                                    " "
                                ),
                                _c("b", [_vm._v(_vm._s(deployment.station))])
                              ]
                            : _vm._e(),
                          _vm._v(
                            ", " +
                              _vm._s(_vm.$t("cameraUploadForm-failedImages")) +
                              " "
                          ),
                          _c("a", [_vm._v(_vm._s(deployment.failed))]),
                          _vm._v(" "),
                          _vm._l(deployment.failedUploadImages, function(
                            failedItem,
                            index2
                          ) {
                            return _c(
                              "p",
                              { key: "failedItem" + dname + index2 },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.displayFilePathForError(
                                        failedItem.file
                                      )
                                    ) + " "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "error" }, [
                                  _vm._v(_vm._s(failedItem.error))
                                ])
                              ]
                            )
                          }),
                          _vm._v(" "),
                          deployment.pushError
                            ? _c("p", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(deployment.pushError) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }