<template>
  <div class="batchUploadForm">
    <modal 
      name="upload-audio-form"
   
      :scrollable="true" 
      :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form !overflow-y-auto relative"
      draggable="false"
      :delay="100"
      width = "90%"
      height = "auto"
      :max-width = "1500"
      :min-width="200"
      :click-to-close = "false">
      <div>
        <div 
          @click="() => {  if (!(!batchUpload.status.allUploaded && this.stepState == 5)) { resetBatchUpload(); closeForm(); stepState = 1;  } }" 
          class="iconButton link">
          <span class="ion-close"/>
        </div>
         
        <div>
          <div class="modal-content px-10 !overflow-y-auto" >
            <h4 v-if="projectId"><i class="fas fa-upload"/> {{ $t('audioUploadForm-title') }} {{ projectName }}</h4>
            <h4 v-else><i class="fas fa-upload"/> {{ $t('Upload To') }} {{organizationName}}</h4>
            <div class="margin-bottom-20">
              <!-- first tab for more used, upload parameters -->
              <div v-show="disableFirstScreen" class=" w-full bg-white/75 backdrop-opacity-10 z-50">
                <div style="text-align: center;" class="my-[150px]">
                  <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
                  <div> {{ $t('common-tableTexts.loading') }} </div>
                </div>
              </div>
              <div class=" dataGroup">

                <!--- 

                  STEP 1: INIT UPLOAD

                ---->

                <form 
                  v-if="stepState == 1" 
                  v-show="!batchUpload.status.uploading && !disableFirstScreen" 
                  method="GET" 
                  role="form" 
                  enctype="multipart/form-data" 
                  id="batchForm" 
                  class="container-fluid">
                  <div>
                    <div>
                      <template v-for="(msg, index) in message">
                        <div 
                          class="error" 
                          :key="'msg' + index"> {{ msg }}</div>
                      </template>
                    </div>
                    <div class="row no-margin-bototm">
                      <h5>{{ $t('Upload Settings') }}</h5>
                      <div class="col s12 xl12 marginTop10" >
                        <label class="block">
                          <input 
                            type="checkbox" 
                            class="exclusive checkbox" 
                            id="settings-includeSubFolder" 
                            v-model="batchUpload.settings.includeSubFolder" >
                          <span>
                            <span 
                              class="control-label col-md-2 col-sm-3" 
                              for="settings-includeSubFolder">{{ $t('audioUploadForm-fields.subfolders') }}</span>
                            <span 
                              class="info-icon ion-information-circled" 
                              v-tooltip="tooltips.includeSubFolder"/>
                          </span>
                        </label>
                        <label>
                          <input 
                            type="checkbox" 
                            class="exclusive checkbox" 
                            id="settings-removeLeadingZeros" 
                            v-model="batchUpload.settings.removeLeadingZeros" >
                          <span>
                            <span 
                              class="control-label col-md-2 col-sm-3" 
                              for="settings-removeLeadingZeros">{{ $t('audioUploadForm-fields.leadingZeros') }}</span>
                            <span 
                              class="info-icon ion-information-circled" 
                              v-tooltip="tooltips.removeLeadingZeros"/>
                          </span>
                        </label>
                        <label class="block">
                          <input 
                            type="checkbox" 
                            class="exclusive checkbox" 
                            id="settings-isTriggered" 
                            v-model="batchUpload.settings.isTriggered" >
                          <span>
                            <span 
                              class="control-label col-md-2 col-sm-3" 
                              for="settings-isTriggered">{{ $t('Mark uploads as triggered') }}</span>
                            <span 
                              class="info-icon ion-information-circled" 
                              v-tooltip="$t('Mark all uploaded files as being manually triggered rather than scheduled.')"/>
                          </span>
                        </label>
                        <label class="block">
                          <input 
                            type="checkbox" 
                            class="exclusive checkbox" 
                            id="settings-isTriggered" 
                            v-model="prescan" >
                          <span>
                            <span 
                              class="control-label col-md-2 col-sm-3" 
                              for="settings-isTriggered">{{ $t('Prescan files for Sample Rate and Duration.') }}</span>
                            <span 
                              class="info-icon ion-information-circled" 
                              v-tooltip="$t('Uncheck this box if the initial file load is taking too long. This will disable autofill of Task Length and Sample Rate.')"/>
                          </span>
                        </label>
                      </div>
                      <div class="col s6 xl6 marginTop10" />
                    </div>
                    <div class="row" v-if="projectId">
                      <h5>{{ $t('Task Settings') }} <span 
                        class="info-icon ion-information-circled" 
                        v-tooltip="$t('Choose the default settings for your recording upload. You can start over or change these settings later during upload.')"/></h5>
                      <div class="grid grid-cols-2 gap-2 items-center">
                        <label class="">
                          <span 
                            class="control-label" 
                            for="settings-defaultMethodId">{{ $t('Task Length') }}
                            <span v-tooltip="$t('WildTrax will upload and store the entire length of the recording in the organization but will only generate a task to the length you choose for this project. If left blank, the entire recording length will be used.')"><i class="fas fa-info-circle text-yellow-400"></i></span>
                            <span 
                              v-show="false" 
                              class="info-icon ion-information-circled" 
                              v-tooltip="tooltips.defaultMethodId"/>
                          </span>
                          <input 
                            :disabled="batchUpload.settings.recordingLength" 
                            type="number" 
                            min="1" 
                            max="600"
                            @keyup="(e)=>{ if (e.target.value < 1 || e.target.value > 600) e.target.value = ''; }"
                            :class="{'!bg-gray-100': batchUpload.settings.recordingLength}"
                            :placeholder="$t('If left blank, the recording length will be used')"
                            v-model="batchUpload.settings.taskLength" >
                        </label>
                          
                        <label class="">
                          <span 
                            class="control-label" 
                            for="settings-defaultMethodId">{{ $t('Method Type') }}
                            <span 
                              v-show="false" 
                              class="info-icon ion-information-circled" 
                              v-tooltip="tooltips.defaultMethodId"/>
                          </span>
                          <select 
                            class="" 
                            v-model="batchUpload.settings.defaultMethodId" 
                            style="height: 40px; ">
                            <option value="8">-- {{ $t('Select One') }} --</option>
                            <option 
                              v-for="(opt, optIndex) in methodOption" 
                              v-show="!opt.deprecated" 
                              :key="'default-method' + optIndex" 
                              :value="opt.id"> {{ opt.type }}</option>
                          </select>
                        </label>
                          
                      </div>
                    </div>

                    <div class="row">
                      <div class="grid grid-cols-2 gap-2 items-center">
                      <h5>{{ $t('audioUploadForm-titles.chooseFiles') }}</h5>
                      <h6 @click="()=>{ui.states.methodHelpDropdown.visible=!ui.states.methodHelpDropdown.visible}">{{ $t('Which method should I use?') }} <i v-if="ui.states.methodHelpDropdown.visible" class="fa-solid fa-caret-down"></i><i v-else class="fa-solid fa-caret-right"></i></h6>
                      </div>
                      <div class="grid grid-cols-2 gap-2 items-start">
                        <div class="">
                          <input 
                            type="file" 
                            ref="chooseFolder" 
                            id="chooseFolder" 
                            name="picture"
                            class="form-control hidden file-chooser" 
                            webkitdirectory="true"
                            multiple="true"
                            @change="browseFolder"
                          >
                          <button 
                            type="button" 
                            style="width: 100%" 
                            class="btn btn-default" 
                            id="browseButton"
                            :disabled="disableBrowseButton"
                            @click="clickBrowseFolder()" >
                            <span class="glyphicon glyphicon-refresh"/> <span 
                              class="info-icon ion-information-circled" 
                              v-tooltip="tooltips.audioFileFormat"/> {{ $t('common-folderBtn') }}
                          </button>

                          <div class="border h-12 relative border-gray-300" v-if="selectedFiles.length > 0 && selectedFiles.length < totalFiles">
                              <div class="bar h-full bg-blue-100" :style="{
                                width: `${(selectedFiles.length/totalFiles)*100}%`
                              }">
                              
                              </div>
                              <div class="absolute  bottom-0">{{$t('Loading')}} {{ selectedFiles.length }} {{ $tc('common-file', selectedFiles.length) }}</div>
                            </div>
                            <div v-if="selectedFiles.length === totalFiles && totalFiles > 0">
                              {{$t('Loaded')}} {{totalFiles }} {{ $tc('common-file', selectedFiles.length) }}
                            </div>
                            <div v-if="invalidFilesLog.length > 0" class="text-red-500">
                              {{$t('There were issues with {number} files.', {number: invalidFilesLog.length})}}
                              <span @click="showInvalidFilesAlert" class="cursor-pointer text-blue-500">{{$t('Click here to see the log.')}}</span>
                            </div>

                        </div>
                        <div class="">
                        
                          <div v-show="ui.states.methodHelpDropdown.visible" class="rounded bg-gray-100 border border-gray-200 p-3">
                            {{$t('Method type allows you to restrict how many tags you can make for an individual in each task. Click on a method type to find out more.')}}
                            <div class="my-2">
                              <div class="font-bold py-1">{{$t('1SPM (1 Species-Individual per minute)')}}</div>
                              <div>
                              {{$t('Create one tag for each individual in each minute of the task. Creates standardized duration limited point counts of different lengths and allows analyses that account for detection error based on the occupancy concept.')}}
                              <div class="type_1spm w-full h-24 bg-contain bg-no-repeat "></div>
                              </div>
                            </div>
                             <div class="my-2">
                              <div class="font-bold py-1">{{$t('1SPT (1 species-individual tag allowed per task)')}}</div>
                              <div>
                              {{$t('Create one tag for each individual sound at the time of first detection in the task. Creates a standardized duration limited point count to census wildlife or other sounds, and allows analyses that account for detection error based on time to first detection concept.')}}
                              <div class="type_1spt w-full h-24 bg-contain bg-no-repeat "></div>
                              </div>
                            </div>
                             <div class="my-2">
                              <div class="font-bold py-1">{{$t('None (Unlimited species-individual tags per task)')}}</div>
                              <div>
                              {{$t('Create an unlimited amount of tags. Upload recognizer hits or tagging each time an individual sound is heard.')}}
                              <div class="type_none w-full h-24 bg-contain bg-no-repeat "></div>
                              </div>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                    <div class="row flex justify-end m-0 gap-2 mt-3">
                      <button 
                        type="button" 
                        class="btn btn-success flex-initial" 
                        id="browseButton" 
                        :disabled="selectedFiles.length === 0 || selectedFiles.length !== totalFiles || buttonLoading.step1.next" 
                        @click="() => {checkFileNames(); buttonLoading.step1.next = true; }">
                          <span class="!text-sm" v-if="!buttonLoading.step1.next"> {{ $t('Next') }} </span>
                          <pulse-loader v-else />
                      </button>
                    </div>
                  </div>
                </form>

                <!--- 

                  STEP 2: USER NOTIFICATION OF ISSUES WITH FILENAMES

                ---->

                <div v-if="stepState == 2">
                 
                  <div>
                    {{ $t("There are a few files that don't have valid names. You may continue to proceed, the uploader will disregard these files.") }}
                    <p 
                      class="bg-red-100 border border-red-200 p-2 text-red-500 text-base my-5" 
                      v-show="batchUpload.fileNameErrors && selectedFiles.length == batchUpload.fileNameErrors.length">
                      <i class="fas fa-exclamation-triangle"/>
                      {{ $t('Problems were identified with certain files, and as a result, they will not be uploaded.') }}
                    </p>
                  </div>
                  <table>
                    <tr><th>Recording</th><th>Issue</th></tr>
                    <template v-for="(error,index) in batchUpload.fileNameErrors">
                      <tr :key="index" >
                        <td>{{ error.recordingName }}</td>
                        <td>{{ error.errorMessage }}</td>
                      </tr>
                    </template>
                  </table>
            
                  <div class="row flex justify-end gap-2 mt-3 ">
                    <button 
                      type="button" 
                      class="btn btn-default flex-initial"
                      :disabled="buttonLoading.step2.prev"
                      @click="() => {stepState = 1; buttonLoading.step2.prev = true; }">
                        <span class="!text-sm" v-if="!buttonLoading.step2.prev"> {{ $t('common-previous') }} </span>
                        <pulse-loader v-else />
                    </button>
                    <button 
                      type="button" 
                      :disabled="batchUpload.fileNameErrors && selectedFiles.length == batchUpload.fileNameErrors.length || buttonLoading.step2.next"
                      class="btn btn-success flex-initial"
                       @click="() => {checkFileNamesForLocation(); buttonLoading.step2.next = true; }">
                        <span class="!text-sm" v-if="!buttonLoading.step2.next"> {{ $t('Next') }} </span>
                        <pulse-loader v-else />
                    </button>
                  </div>
                </div>

                <!--- 

                  STEP 3: LOCATION COORDINATES SCREEN

                ---->

                <div 
                  v-if="stepState == 3" 
                  class="">

                  <h5>{{ $t('Enter Missing Coordinates') }}</h5>
                  <div class="p-3 bg-gray-100">
                    {{ $t('Once saved the location will be saved in the organization even if you do not proceed with uploading your recordings')}}
                  </div>
                  <div class="">
                    <table>
                      <tr><th>{{$tc('common-location', 1)}}</th><th>{{$t('common-latitude')}}</th><th>{{$t('common-longitude')}}</th></tr>
                      <template v-for="(location, index) in batchUpload.locations">
                        <tr :key="index" class="!border-b">
                          <td class="p-0">{{ location.name }}<div class="text-red-500 text-sm">&nbsp;</div></td>
                          <td><input 
                            type="text"
                            @keyup="latlongValidation(location,'lat')"
                            :class="{'highlight': (location.latitude==null||location.latitude.length==0) && location.longitude }"
                            v-model="location.latitude" min="-90" max="90"  @blur="insertUpdateLocation(index)"  >
                          
                          <div class="text-red-500 text-sm">{{location.error||"&nbsp;"}}</div>
                          </td>
                          <td><input 
                            v-model="location.longitude"
                            @keyup="latlongValidation(location,'long')"
                            :class="{'highlight': (location.longitude==null||location.longitude.length==0) && location.latitude }"
                            type="text" min="-180" max="180" @blur="insertUpdateLocation(index)" />
                            <div class="text-red-500 text-sm">&nbsp;</div>
                            </td>
                          <td><span 
                            :class="{'btn': true, 'btn-default': !location.id, 'btn-success': location.id}"
                            :disabled="(location.longitude==null||location.longitude.length==0)||(location.latitude==null||location.latitude.length==0)"
                            @click="insertUpdateLocation(index)"><i :class="{'fas':true, 'fa-save': !location.loading, 'fa-spinner fa-spin': location.loading}"/></span><div class="text-red-500 text-sm">&nbsp;</div></td>
                          
                        </tr>
                      </template>
                    </table>
                  </div>

                  <div class="row flex justify-end gap-2 mt-3 ">
                    <button 
                      type="button" 
                      class="btn btn-default flex-initial"
                      :disabled="buttonLoading.step3.prev"
                         @click="() => {checkFileNames(true); buttonLoading.step3.prev = true; }">
                        <span class="!text-sm" v-if="!buttonLoading.step3.prev"> {{ $t('common-previous') }} </span>
                        <pulse-loader v-else />
                    </button>
                    <button 
                      type="button" 
                      class="btn btn-success flex-initial"
                      :disabled="buttonLoading.step3.next"
                      @click="() => {parseUploadFolders(); buttonLoading.step3.next = true; }">
                        <span class="!text-sm" v-if="!buttonLoading.step3.next"> {{ $t('Next') }} </span>
                        <pulse-loader v-else />
                    </button>
                  </div>
                </div>

                <!--- 

                  STEP 4: PARSE FILES

                ---->

                <div 
                  id="batchUploadPreview" 
                  class="tabbed" 
                  v-if="stepState == 4" 
                  style="margin: 20px 0;">
            
                  <h5>{{ $t('Confirm Files to Upload') }}</h5>

                  <div class="padded20 overflow-auto boxed dataGroup tab-content tag file-table">
  
                    <template v-if="error">
                      <div 
                        class="warning" 
                        colspan="10">{{ error }}</div>
                    </template>

                    <div v-if="batchUpload.counts[filterTable] === 0">
                      <h6>{{ $t('audioUploadForm-noFilesTab', {tab: filterTable}) }}</h6>
                          </div>
                              
                    <batch-table-upload-preview v-else 
                      :files="batchUpload.files" 
                      :status="batchUpload.status" 
                      :counts="batchUpload.counts"
                      :locations="batchUpload.locations"
                      :methodOption="methodOption"
                      :hiddenColumns="!projectId?['methodId', 'taskLength']:[]"
                    />
                   
                  </div>

                  <div class="row flex justify-end gap-2  mt-3 ">
                    <button 
                      type="button" 
                      class="btn btn-default flex-initial"
                      :disabled="buttonLoading.step4.prev"
                         @click="() => {checkFileNamesForLocation(true); buttonLoading.step4.prev = true; }">
                        <span class="!text-sm" v-if="!buttonLoading.step4.prev"> {{ $t('common-previous') }} </span>
                        <pulse-loader v-else />
                    </button>
                    <button 
                      type="button" 
                      class="btn btn-success flex-initial"
                      :disabled="numFilestoUpload==0 || buttonLoading.step4.next"
                        @click="() => {processBatchUpload(); buttonLoading.step4.next = true; }">
                        <span class="!text-sm" v-if="!buttonLoading.step4.next"> {{ $t('Begin Upload') }} </span>
                        <pulse-loader v-else />
                    </button>
                  </div>
                </div>
                <!--
                    STEP 5: UPLOAD SCREEN
              --->

                <div 
                  v-if="stepState == 5" 
                  >

                  
                  <div class="padded20 dataGroup tab-content tag file-table">
                 
                    <p v-if="batchUpload.status.allUploaded">
                      <span 
                        class="text-green-500" 
                        v-if="batchUpload.counts['Uploaded'] > 0">
                          <span >{{ batchUpload.counts['Uploaded'] }} </span>
                          {{ $tc('audioUploadForm-finishUploadMsg2', batchUpload.counts['Uploaded']) }}
                      </span>
                      <span 
                        class="text-red-500" 
                        v-if="batchUpload.counts['Failed'] > 0">
                          <span class="error" >{{ batchUpload.counts['Failed'] }} </span>
                          {{ $tc('audioUploadForm-finishUploadMsg3', batchUpload.counts['Failed']) }}
                      </span>
                    </p>
                    <p v-else>
                      <i class="fas fa-spinner fa-spin"></i> {{$t('Upload in progress...')}}
                    </p>
                    <template v-if="error">
                      <div 
                        class="warning" 
                        colspan="10">{{ error }}</div>
                    </template>

                    <table>
                      <tr>
                        <th><span 
                          title="" 
                          class="VueTables__heading">{{ $t('Status') }}</span></th>
                        <th><span 
                          title="" 
                          class="VueTables__heading">{{ $t('audioUploadForm-headings.fileName') }}</span></th>
                        <th><span 
                          title="" 
                          class="VueTables__heading">{{ $t('File Type') }}</span></th>
                        <th><span 
                          title="" 
                          class="VueTables__heading">{{ $t('Triggered') }}</span></th>
                        <th><span 
                          title="" 
                          class="VueTables__heading">{{ $t('Length(s)') }}</span></th>
                        <th><span 
                          title="" 
                          class="VueTables__heading">{{ $t('audioUploadForm-headings.size') }}</span></th>
                      </tr>
                      <template v-for="(row, index) in audioUploader.audioList">
                        <tr 
                          v-if="row.fileName" 
                          :key="'file-id' + filterTable + index">
                          <td>
                              <i v-if="row.status===statusOption.SKIP" v-tooltip="row.message" class="fas fa-forward"></i>
                              <i v-if="row.status===statusOption.QUEUE" class="fas fa-hourglass-end"></i>
                              <i v-if="row.status===statusOption.UPLOADING" class="fas fa-spinner fa-pulse text-blue-500"></i>
                              <i v-if="row.status===statusOption.UPLOADED" class="fas fa-check-circle text-green-500"></i>
                              <i v-if="row.status===statusOption.FAILED" v-tooltip="row.message" class="fas fa-times-circle text-red-500"></i>
                          </td>
                          <td >{{ row.recordingName || row.fileName }}</td>
                          <td >{{ row.fileType }}</td>
                          <td ><i v-if="row.isTriggered" class="fas fa-square-check"></i><i v-else class="fa-regular fa-square"></i></td>
                          <td>{{ row.taskLength||$t("Default") }}</td>
                          <td>{{ row.fileSize }} {{ $t('commonUnits-megabyte') }} </td>
                        </tr>
                      </template>
                    </table>
                    <div class="btn btn-default mt-3" 
                    :disabled="!batchUpload.status.allUploaded && !batchUpload.status.hasError"
                    @click="downloadLog">
                    <span class="!text-sm" v-if="batchUpload.status.allUploaded || batchUpload.status.hasError">{{ $t('audioUploadForm-downloadLog') }}</span>
                      <pulse-loader v-else />
                    </div>

                  </div>
                  <div class="row flex justify-end gap-2 mt-3 ">
                    <button 
                      type="button" 
                      class="btn btn-default flex-initial"
                      :disabled="!batchUpload.status.allUploaded && !batchUpload.status.hasError"
                      @click="() => { stepState = 1; resetBatchUpload(); closeForm();  }">
                      <span class="!text-sm" v-if="batchUpload.status.allUploaded || batchUpload.status.hasError">{{ $t('Close') }}</span>
                      <pulse-loader v-else />
                    </button>
                    <button 
                      type="button" 
                      class="btn btn-success flex-initial"
                      :disabled="!batchUpload.status.allUploaded && !batchUpload.status.hasError"
                        @click="() => { stepState=1; resetBatchUpload(); buttonLoading.step5.next = true; }">
                        <span class="!text-sm" v-if="!buttonLoading.step5.next && (batchUpload.status.allUploaded || batchUpload.status.hasError)"> {{ $t('Start Over') }} </span>
                        <pulse-loader v-else />
                    </button>
                  </div>
                </div>
              <!-- end of tabs -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
/* this component show batch upload from
*/
import {API_URL, alertDialog, confirmDialog, exportToCsv} from '@/lib/common';
import { audioUploader, statusOption, ALLCOMPLETED } from './AudioUploader';
import { eventBus } from '@/lib/eventbus';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading
import BatchTableUploadPreview from './BatchTableUploadPreview.vue';
import { v4 as uuidv4 } from 'uuid';

import * as mmb from 'music-metadata-browser';

export default {
  name: 'AruBatchUploadForm',
  components: { 'pulse-loader': PulseLoader, 'batch-table-upload-preview': BatchTableUploadPreview },
  props: ['projectId', 'options', 'projectName', 'methodOption', 'organizationId', 'organizationName'],
  created () {
    this.resetBatchUploadSetting(); // set setting params
    this.resetBatchUpload();
    
    window.addEventListener('unhandledrejection', event => {
      if (event.reason && event.reason.ignoreUnhandledRejection) {
        event.preventDefault();
      }
    });

    window.onfocus = ()=>{
      if (this.disableFirstScreen && this.selectedFiles.length === 0) {
          this.disableFirstScreen = false;
      }
    };

  },
  mounted () {
    const vm = this;
    this.audioUploader.registerUpdateCallback((statusObject) => {
      if (statusObject.fileName)		 {
        let matchedRecording = this.audioUploader.audioList.find(l => l.fileName === statusObject.fileName);

        if (matchedRecording != null) {
          if (statusObject.startTime) {
            vm.$set(matchedRecording, 'startTime', statusObject.startTime);
            vm.$set(matchedRecording, 'status', statusOption.UPLOADING);
            vm.batchUpload.counts[statusOption.UPLOADING]++;
            vm.batchUpload.counts[statusOption.QUEUE]--;
          }
          if (statusObject.endTime) {
            if (statusObject.audioLength != null) {
              vm.$set(matchedRecording, 'audioLength', statusObject.audioLength);
            }
            vm.$set(matchedRecording, 'endTime', statusObject.endTime);
            vm.$set(matchedRecording, 'totalTime', (0.001 * (matchedRecording.endTime - matchedRecording.startTime)).toFixed(1));
            vm.batchUpload.counts[statusOption.UPLOADING]--;
          }
          if (statusObject.status) {
            vm.batchUpload.counts[statusObject.status]++;
            vm.$set(matchedRecording, 'status', statusObject.status);
          }
          vm.$set(matchedRecording, 'message', statusObject.message);
        } else {
          console.log('error: can not mtach ', statusObject);
        }
      } else { // for all
        if (statusObject.startTime) {
          vm.$set(vm.batchUpload.status, 'startTime', statusObject.startTime);
          vm.batchUpload.status.runningThread = Math.min(statusObject.totalFile, vm.batchUpload.settings.parallelUploadCount);
          // console.log('running thread', statusObject.totalFile, vm.batchUpload.status.runningThread);
          vm.runningTimer = setInterval(() => {
            vm.$set(vm.batchUpload.status, 'runningTime', (0.001 * (Date.now() - vm.batchUpload.status.startTime)).toFixed());
          }, 1000);
        }

        if (statusObject.status === ALLCOMPLETED) {
          // console.log('running thread finished');
          vm.batchUpload.status.runningThread--;
        }
      }
      if (statusObject.totalFile === 0) {
        this.error = this.$t('audioUploadForm-noneSelected');
      }
      if (vm.batchUpload.status.runningThread === 0) {
        vm.batchUpload.status.allUploaded = true;
        vm.batchUpload.status.uploading = false;
        clearInterval(vm.runningTimer); // remove time out
        if (statusObject.endTime) {
          vm.$set(vm.batchUpload.status, 'endTime', statusObject.endTime);
          vm.$set(vm.batchUpload.status, 'totalTime', (0.001 * (vm.batchUpload.status.endTime - vm.batchUpload.status.startTime)).toFixed(1));
        }
        if (vm.batchUpload.counts[statusOption.UPLOADED] > 0) {
          eventBus.$emit('reload-task-table');
        }
      }
    });

  
  },
  
  watch: {
 
    stepState(newVal, oldVal) { //Here lets do things when we change steps. newVal is detected once the page is actually loaded.
      let step = newVal;
      this.resetButtonStates(step);
       document.querySelector('.modal-form').scrollTop = 0;

      //If going backwards to first step from 3 or 4
      if (newVal === 1 && (oldVal === 3 || oldVal == 4)) {
        this.batchUpload.locations = {};
      }
    },
    recordingLengthChanged (newVal, oldVal) {
      this.$set(this.batchUpload.settings, 'taskLength', null);
    },
    locationsChanged (newVal, oldVal) {
      if (newVal === 0) { this.parseUploadFolders(); }
    },
    prescan(newVal,oldVal){
      if (newVal !== oldVal && newVal === true) {
        this.$refs.chooseFolder.value = null;
        this.selectedFiles= [];
      }
        
    }
  },
  computed: {
    recordingLengthChanged () {
      return this.batchUpload.settings.recordingLength;
    },
    locationsChanged () {
      return this.batchUpload.locations.length;
    },
    numFilestoUpload () {
      return this.batchUpload.counts.Queue;
    }
  },
  methods: {
      showInvalidFilesAlert() {
        if (this.invalidFilesLog.length) {
          //alert(this.invalidFilesLog.join("\n"));
          alertDialog(this.$modal, this.$t('There were issues with {number} files.', {number: this.invalidFilesLog.length}), `
            <div class="w-full overflow-auto whitespace-nowrap py-5">
              ${this.invalidFilesLog.join("<br />")}
            </div>
            `
          );

        }
      },
       mapBatchFilesArrayForPreview() {
        return this.batchUpload.files.map(f => {
          console.log(f);
          let temp = {...f};
          delete temp.file;
          return temp;
      });

    },
    latlongValidation(location, type) {
      if (type == 'lat') {
        if ( location.latitude ) location.latitude = location.latitude.replace(/[^0-9\.\-]+/, '');
        if (location.latitude >= 90 || location.latitude <= -90) {
          location.latitude = '';
        } 
      } else {
        if (location.longitude) location.longitude = location.longitude.replace(/[^0-9.-]+/, '');
        if (location.longitude >= 180 || location.longitude <= -180) {
          location.longitude = '';
        } 
      }
    },
    fileStatusIcon (row) {
      switch (row.status) {
        case statusOption.SKIP: return '<i class="fas fa-forward"></i>';
        case statusOption.QUEUE: return '<i class="fas fa-hourglass-end"></i>';
        case statusOption.UPLOADING: return '<i class="fas fa-spinner fa-pulse text-blue-500"></i>';
        case statusOption.UPLOADED: return '<i class="fas fa-check-circle text-green-500"></i>';
        case statusOption.FAILED: return '<i class="fas fa-times-circle text-red-500"></i>';
      }
    },
    alertMsg (row) {
      alertDialog(this.$modal, this.$t('common-message'), row.recordingName + '<br>' + row.message);
    },
    closeForm () {
      this.disableFirstScreen = false;
      this.$modal.hide('upload-audio-form');
      //eventBus.$emit('show-upload-form', false);
    },
    updateRowStatus (row) {
      if (row) {
        if (row.status === statusOption.FAILED) { // not allow to change existing recording status
          return;
        }
        if (row.includeInUpload) {
          row.status = statusOption.QUEUE;
        } else {
          row.status = statusOption.SKIP;
        }
      }
      let counts = {};
      Object.keys(statusOption).forEach(x => {
        counts[statusOption[x]] = 0;
      });
      this.batchUpload.files.forEach(x => {
        counts[x.status]++
      });
      this.$set(this.batchUpload, 'counts', counts);
    },
    updateMethodText (row) {
      if (row.methodId) {
        row.methodText = (this.methodOption.find(m => m.id === row.methodId) || {}).type; // could use ?? for new browser
      }
    },
    addToQueueList (row, orginalStatus) {
      this.batchUpload.status.allUploaded = false; // start new round of update, clear error message.
      row.status = statusOption.QUEUE;
      row.message += this.$t('audioUploadForm-tryReupload');
      row.totalTime = null;
      this.batchUpload.counts[statusOption.QUEUE]++;
      this.batchUpload.counts[orginalStatus]--;
    },
    clickBrowseFolder () {
      this.disableFirstScreen = true;
      this.$refs.chooseFolder.value = '';
      this.selectedFiles = [];
      this.invalidFilesLog = [];
      this.$refs.chooseFolder.click();
    },
    async browseFolder ($event) {
     
      this.totalFiles = 0;
      let prefilteredFiles = [];
      let fileNamesValid = await this.$http.post(API_URL+'confirm-aru-naming-standards', 
        Array.from($event.target.files).map(file=>file.name)
      );
      fileNamesValid = fileNamesValid.body;
     
      for (var i = 0; i < $event.target.files.length; i++) {
        let file = $event.target.files[i];

        if (!this.allowedExtensions.includes(file.name.split('.')[file.name.split('.').length-1].toLowerCase())) {
          this.invalidFilesLog.push(this.$t('{filename} does not contain valid extention ({valid_extensions})',{filename:file.name,valid_extensions:this.allowedExtensions.join(', ')}));
          continue;
        }
        if (file.size < 1) {
          this.invalidFilesLog.push(this.$t('{filename} empty file', {filename: file.name}));
          continue;
        }
        
        if (!fileNamesValid[i]) {
          this.invalidFilesLog.push(this.$t('The location name contains invalid characters. ')+` [${file.name}]`);
          continue;
        }
        

        //valid extension
        if (file.size > 0 && this.allowedExtensions.includes($event.target.files[i].name.split('.')[$event.target.files[i].name.split('.').length-1].toLowerCase())) {
         if (!this.batchUpload.settings.includeSubFolder && ($event.target.files[i].webkitRelativePath.split("/").length <= 2)) {
            prefilteredFiles.push($event.target.files[i]);
         } else if (this.batchUpload.settings.includeSubFolder) {
           prefilteredFiles.push($event.target.files[i]);
         }
        }
      }

      this.totalFiles = prefilteredFiles.length;
      this.disableBrowseButton = true;
      for (var i = 0; i < this.totalFiles; i++) {
        let file = prefilteredFiles[i];
        let sampleRate, duration = null;
        let skip = false;

          if(!['wac'].includes(file.name.split('.')[1].toLowerCase()) && this.prescan){
            try {
          
              //Might be invalid audio file if cannot parse metadata. 
              let meta = (await mmb.parseBlob(file));
              sampleRate = meta.format.sampleRate;
              duration = meta.format.duration;
            } catch (e) {
              skip = true;
              this.totalFiles--;
              console.log(e);
              this.invalidFilesLog.push(this.$t('{filename} could not extract sampleRate/duration from file', {filename:file.name}));
            }
          }
          if (!skip) {
            this.selectedFiles.push({
              file,
              name: file.name,
              sampleRate,
              duration,
              uuid: uuidv4()
            });
          }
      }
      this.disableBrowseButton = false;
      this.disableFirstScreen = false;



    },
    initializeBatchUpload () {
    },
    resetBatchUpload () {
      this.audioUploader.reset();
      this.message = [];
      this.error = null;
      this.filterTable = '';
      this.selectedFiles = [];

      this.$set(this.batchUpload, 'status', {});
      this.$set(this.batchUpload, 'files', []);
      this.$set(this.batchUpload, 'allUploaded', false);
      this.$refs.chooseFolder && (this.$refs.chooseFolder.value = '');
      this.$set(this.batchUpload, 'counts', {});

      this.batchUpload.status.uploading = false;
      this.batchUpload.fileNameErrors = null;
    },
    resetBatchUploadSetting () {
      let settings = {
        parallelUploadCount: 4, // make this and running thread same, upload audio one at a time.
        // failedRetryCount: 10,
        projectId: this.projectId,
        organizationId: this.organizationId,
        includeSubFolder: true,
        defaultMethodId: 1,
        defaultMethodText: (this.methodOption.find(m => m.id === 8) || {}).type, // set default method to id 8
        autoTrim: true,
        removeLeadingZeros: true,
        createTask: true,
        taskLength: null,
        recordingLength: false,
        isTriggered: false,
        uploadUrl: 'upload-recording-create-task'

      };

      this.$set(this.batchUpload, 'settings', settings);
    },

    processBatchUpload (e) {
      /* do a validation */
      this.message = [];
      this.error = null;
      this.batchUpload.settings.stopUpload = false; // put in setting, so audio upload file can accss it.
      this.batchUpload.status.allUploaded = false;
      this.stepState = 5;

      if (this.batchUpload.files.length > 0) {
        this.batchUpload.status.uploading = true;
        this.audioUploader.upload(this.batchUpload.settings, this.$http, statusOption.QUEUE);
      } else {
        this.message.push(this.$t('audioUploadForm-noFiles'));
      }
    }, // done batch upload
    checkFileNames (back = false) {
      let recordingNames = this.selectedFiles.map(obj=> ({ sampleRate: obj.sampleRate, name: obj.name }));

      this.$http.post(API_URL + 'check-recording-file-names-for-errors', { recordingNames }, {
        params: { projectId: this.projectId, removeLeadingZeros: this.batchUpload.settings.removeLeadingZeros, organizationId: this.organizationId }
      }).then(resp => {
        if (resp.body) {
          if (resp.body.find(e => e.errorMessage)) {
            this.batchUpload.fileNameErrors = resp.body;
            this.stepState = 2;
          } else {
            this.batchUpload.fileNameErrors = null;
            if (back) {
              this.stepState = 1;
            } else {
              this.checkFileNamesForLocation();
            }
          }
        }
      });
    },
    checkFileNamesForLocation (back = false) {
      let recordingNames = this.selectedFiles.map(f => f.name);

      this.$http.post(API_URL + 'check-recording-file-names-for-location-no-long-lat', { recordingNames }, {
        params: { projectId: this.projectId, removeLeadingZeros: this.batchUpload.settings.removeLeadingZeros, organizationId: this.organizationId }
      }).then(resp => {
        if (resp.body) {
          if (resp.body.length === 0) {
            if (back) this.stepState = 1;
            else this.parseUploadFolders();
          } else {
            this.batchUpload.locations = resp.body.map(loc => {
              if (loc.location == null) return { name: loc.locationName, latitude: null, longitude: null, organizationId: this.organizationId };
              return loc.location;
            });
            this.stepState = 3;
          }
        }
      });
    },
    insertUpdateLocation (index) {

      let location = this.batchUpload.locations[index];
      let error = null;
      const isEmpty = (value) => {
        return value == null || value.length === 0;
      }

      if (isEmpty(location.latitude) && isEmpty(location.longitude)) {
        //Both empty dont display error but dont send update either
      } else if ((!isEmpty(location.latitude) && isEmpty(location.longitude))||(isEmpty(location.latitude) && !isEmpty(location.longitude))  ) {
        // dont update.
        error = this.$t('Both fields must be filled out in order to save');
      } else {
        error = null;
        this.$set(this.batchUpload.locations[index], 'loading', true); 
        let tempLocation = location;
        delete tempLocation.error;
        delete tempLocation.loading;
        const that = this;

        this.$http.post(API_URL + 'insert-update-location', {...tempLocation}).then(resp => {
          if (!resp.body.error) {
            that.$set(this.batchUpload.locations[index], 'loading', false); 
            that.batchUpload.locations.find( (e,i)=>{ 
              if (resp.body.name === e.name) { 
                that.$set(this.batchUpload.locations, index, resp.body);  
              } })
            //this.batchUpload.locations = this.batchUpload.locations.filter(e => resp.body.name !== e.name);
          } 
        }, err => {
                this.$set(this.batchUpload.locations[index], 'error', this.$t('Latitude or Longitude is malformed, the data was not saved.'));
        });
        // update
      }

      this.$set(this.batchUpload.locations[index], 'error', error);

 
    },
    /* parse selected folders */
    async parseUploadFolders () {
      const self = this;
      self.fileParsing = true;
      // parse files
      try {
        this.batchUpload.settings.defaultMethodText = (this.methodOption.find(m => m.id === self.batchUpload.settings.defaultMethodId) || {}).type; // set default method text
        const response = await this.audioUploader.parseFolder(
          this.batchUpload.fileNameErrors ? this.selectedFiles.filter(
            sFile => !this.batchUpload.fileNameErrors.find(
              eFile => eFile.recordingName === sFile.name
            )
          ) : this.selectedFiles, this.batchUpload.settings, this.$http);

        if (response.hasOwnProperty('error')) {
          this.error = this.$t('audioUploadForm-filesWarning') + response.error;
          // TO DO: to remove later.
          this.batchUpload.files = this.audioUploader.audioList;
        } else {
          this.batchUpload.files = this.audioUploader.audioList; // reference to audioList
          this.updateRowStatus();
          this.stepState = 4;
        }
      } catch (e) {
        this.error = this.$t('audioUploadForm-filesWarning') + e.message;
        console.log(this.error);
      } finally {
        this.batchUpload.status = {
          filesParsed: true,
          allUploaded: false,
          hasError: false,
          uploading: false
        }
        this.fileParsing = false;
      }
    },
    cancelUpload () {
      const self = this;
      confirmDialog(this.$modal, this.$t('common-unsavedChanges'), this.$t('audioUploadForm-cancelUploadMsg'),
        this.$t('common-stop'), function () { self.batchUpload.settings.stopUpload = true; },
        this.$t('common-cancel'));
    },
    downloadLog () {
      const header = this.$t('audioUploadForm-logHeadings');
      const headerObj = 'fileName, fileSize, location, isNewLocation, methodText, audioLength, status, totalTime, message ';

      exportToCsv(this.batchUpload.files, header, headerObj.split(','), this.$t('audioUploadForm-logFileName') + '.csv');
    },
    resetButtonStates(stepNum) {
      Object.entries(this.buttonLoading).forEach(
        ([name, step]) => {
          step = this.buttonLoading[name];
          if (name != `step${stepNum}`) {
            if (step.next) step.next = false;
            if (step.prev) step.prev = false;
          }
        });
    }
  },
  data () {
    return {
      invalidFilesLog: [], // Store reasons for not including files in upload
      prescan: false,
      disableBrowseButton: false,
      statusOption: statusOption,
      disableFirstScreen: false,
      buttonLoading: {
        step1: { next: false },
        step2: {next: false, prev: false},
        step3: {next: false, prev: false},
        step4: {next: false, prev: false},
        step5: {next: false, prev: false},
      },
      ui: {
        states: {
          methodHelpDropdown: {
            visible: false
          }
        }
      },
      audioUploader,
      batchUpload: { settings: { recordingLength: false, isTriggered: false }, fileNameErrors: null, locations: {} },
      message: [],
      error: null,
      fileParsing: false,
      tab: 'upload',
      updateDeploymentOptionUrl: API_URL + 'update-camera-pud-push-forward',
      filePreChecking: false,
      filterTable: '',
      runningTimer: null,
      showFolderBox: true,
      stepState: 1, // used to control the wizard state step
      selectedFiles: [],
      totalFiles: 0, //Store length of files array to verify loading.
      allowedExtensions: ['flac','mp3','wav','wac', 'w4v'],
      tooltips: {
        includeSubFolder: this.$t('audioUploadForm-tooltips.includeSubFolder'),
        defaultMethodId: this.$t('audioUploadForm-tooltips.defaultMethodId'),
        audioFileFormat: this.$t('audioUploadForm-tooltips.audioFileFormat'),
        submitForUpload: this.$t('audioUploadForm-tooltips.submitForUpload'),
        fileFormat: this.$t('audioUploadForm-tooltips.fileFormat'),
        isLocationExist: this.$t('audioUploadForm-tooltips.isLocationExist'),
        removeLeadingZeros: this.$t('audioUploadForm-tooltips.removeLeadingZeros'),
        createTask: this.$t('audioUploadForm-tooltips.createTask')
      }
    }
  }
}

</script>
<style scoped>
/* .info-icon {
  font-size: 18px;
  color: #FF9800;
}
.tooltip {
  max-width: 600px;
}
.modal-form {
  background-color: #fff;
  max-height: 800px;
  overflow: auto!important;
}
.modal-content   .padded10 {
  padding: 10px 5px;
  margin-bottom: 10px;
} */

.type_1spt {
  background-image: url('../../../assets/1spt_type.png');
}
.type_1spm {
  background-image: url('../../../assets/1spm_type.png');
}
.type_none {
  background-image: url('../../../assets/none_type.png');
}

input.highlight, input.highlight:focus {
  box-shadow: 0px 0px 5px rgb(212, 59, 59) !important;
}

.tableBodyScroll  {
  display: block;
  overflow-y: auto;
  font-size: 0.8em;
  position: relative; /* very important, otherwise, the modal height is based on full list not scroll height;  */
}
@media only screen and (max-width: 1366px) {
  .tableBodyScroll  {
    font-size: 0.7em;
    line-height: 1rem;
  }
  .batchUploadPreview {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  #batchForm, #batchForm span.control-label {
    font-size: 0.9rem;
    line-height: 1rem;
  }

  .scrollbarWidth {
    font-size: 0.7em;
    line-height: 1rem;
    margin-bottom: 5px;
  }
 /* .dataGroup {
    padding-top: 20px;
  }*/
}
.tableBodyScroll select {
  margin-bottom: 4px;
  height: 30px;
}
#batchUploadPreview .file-table .row {
  margin: 0;
  text-align: center;
}

#batchUploadPreview .tabs li.tab a span.top {
  vertical-align: top;
}
#batchUploadPreview .tabs li.tab a span.middle {
  vertical-align: middle;
}
span.link {
  color: #C94412;
}
#batchUploadPreview .tabs li.tab a span {
  font-size: 1em;
}
#batchUploadPreview .tabs li.tab a.active,
#batchUploadPreview .tab-content{
  border: 1px solid #d89b9b;
}
.no-margin-bototm {
  margin-bottom: 0px;
}
.scrollbarWidth{
  padding-right:17px;
}
.marginTop10 {
  margin-top: 10px;
}
.control-label {
  font-size: 0.8rem;
}

.wide-btn{
  width:100%;
}

.file-chooser{
  width:90%;
  display:none;
}

.flex-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#browseButton{
  float:none;
}

.baseline-wrapper{
  position:relative;
}

.baseliner{
  position: absolute;
  top:20px;
}

@media only screen and (min-width: 741px){  /*768px) {*/
.baseliner{
  top: 16px;
  }
}

@media only screen and (min-width: 1370px) {
  .baseliner{
    top: 20px;
  }
}

</style>
