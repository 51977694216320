<!--
This form allows the user to merge 2 locations into one
-->
<template>
  <div >
    <div @click="closeForm" class="iconButton link">
      <span class="ion-close"></span>
    </div>
    <div class="modal-header drag-handle" ref="modalHeader">
      <h6>{{$t('mergeForm-merge', {type: translatedMergeType})}}</h6>
    </div>
      <div class="modal-content padded40">
        <template v-if="success === false">
        <div class="row">
          <div class="col s12">
            {{$t('mergeForm-mainMsg', {type: translatedMergeType})}} <strong>{{$t('mergeForm-strongMsg')}}</strong>
            <template v-if="mergeType === 'project'">{{$t('mergeForm-projectMsg')}}</template>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m6">
                <label>{{$t('mergeForm-source', {type: translatedMergeType})}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.source"></span></label>
                <div>
                    <template v-if="mergeSourceInfo.locationName">{{mergeSourceInfo.locationName}}</template><template v-else>{{mergeSourceInfo.fullNm}}</template>
                </div>
            </div>
            <div class="col s12 m6">
                <label>{{$t('mergeForm-target', {type: translatedMergeType})}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.target"></span></label>
                <select v-if="mergeType === 'location'" v-model="target" @change="getTargetDetails">
                <template v-for="(item, index) in targetList">
                        <option :key="index" :value="item" v-if="item.id !== mergeSourceInfo.id">
                        {{item.locationName}}
                        </option>
                    </template>
                </select>
                <select v-else v-model="target" @change="getTargetDetails">
                <template v-for="(item, index) in targetList">
                        <option :key="index" :value="item" v-if="item.id !== mergeSourceInfo.id && item.organizationId === mergeSourceInfo.organizationId && item.status === 'Active'">
                        {{item.fullNm}}
                        </option>
                    </template>
                </select>
            </div>
        </div>
        <div class="row">
          <div v-if="mergeSourceInfo.subDetails" class="col s12 m6">
            <label>{{$t('mergeForm-summary', {name: mergeSourceInfo.locationName})}}</label>
            <template v-if="mergeSourceInfo.subDetails.length > 0">
              <div v-for="(project,index) in mergeSourceInfo.subDetails" v-bind:key="`srcproject`+index">
                <div>{{project.projectName}} - {{project.sensor}}</div>
              </div>
            </template>
          <div v-else>{{$t('mergeForm-noProjects')}}</div>
          </div>
          <div class="col s12 m6">
            <div v-if="target && targetDetails">
              <label>{{$t('mergeForm-summary', {name: target.locationName})}}</label>
              <template v-if="targetDetails.length > 0">
                <div v-for="(project, index) in targetDetails" v-bind:key="`trgtproject`+index">
                  <div >{{project.projectName}} - {{project.sensor}}</div>
                </div>
              </template>
              <div v-else>{{$t('mergeForm-noProjects')}}</div>
            </div>
          </div>
          </div>
          <div class="row">
              <div class="status" :class="{'error':error,'message':!error}">{{message}}</div>
            </div>
          </template>
          <template v-else>
            <div>
              {{$t('mergeForm-successfullyMerged', {source: (mergeSourceInfo.locationName ? mergeSourceInfo.locationName : mergeSourceInfo.fullNm), target: (target.locationName ? target.locationName : target.fullNm)})}}
              </div>
            </template>
        <div class="modal-footer">
          <button v-if="success=== false" class="btn btn-success modal-trigger modal-action modal-close" id="sendRequest" @click="merge">
            <template>{{$t('mergeForm-mergeBtn')}}</template>
          </button>
          <button class="btn btn-border modal-action modal-close" id="cancelAdd" @click="closeForm">{{$t('common-close')}}</button>
        </div>
      </div>
    <!--<div v-if="loading" class="center" style="padding:40px;">
        <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
        <div>{{commonMsg.loading}}</div>
    </div> -->
  </div>
</template>

<script>
import {eventBus} from '@/lib/eventbus';
import {API_URL, attachAuthorizaitonHeader} from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import FormMixin from '@/components/common/FormMixin.js';

export default {
  name: 'merge-location-form',
  props: ['mergeSourceInfo', 'organizationId', 'mergeType'],
  components: {'pulse-loader': PulseLoader},
  mixins: [FormMixin],
  data () {
    return {
      translatedMergeType: this.$tc(`common-${this.mergeType}`,1),
      mergeLocationsUrl: API_URL + 'merge-locations',
      getLocationsUrl: API_URL + 'get-location-summary',
      getTargetDetailsUrl: API_URL + 'get-location-details-view?organizationId=',
      getProjectsUrl: API_URL + 'get-project-summary?sensorId=',
      mergeProjectsUrl: API_URL + 'merge-project',
      target: null,
      message: null,
      error: false,
      targetList: [],
      targetDetails: null,
      success: false,
      tooltips: {
        source: this.$t('mergeForm-tooltips.source', {type: this.translatedMergeType}),
        target: this.$t('mergeForm-tooltips.target', {type: this.translatedMergeType})
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  },
  async created () {
    //attachAuthorizaitonHeader();
    if (this.mergeType === 'location') {
      this.getLocationList();
    } else {
      this.getProjectList();
    }
  },
  methods: {
    merge () {
      this.error = false;
      this.message = '';
      let params = {};
      if (this.mergeType === 'project') {
        params = {targetProjectId: this.target.id, sourceProjectId: this.mergeSourceInfo.id};
      } else {
        params = {targetId: this.target.id, sourceId: this.mergeSourceInfo.id};
      }

      this.$http.post(this.mergeType === 'project' ? this.mergeProjectsUrl : this.mergeLocationsUrl, null, {params, headers: this.headers}).then(function (response) {
        if (response.body.error) {
          this.error = true;
          this.message = response.body.error;
        } else {
          this.success = true;
        }
      }, () => {
        // error callback
        this.error = true;
        this.message = this.$t('mergeForm-mergeError');
      });
    },
    getLocationList () {
      this.$http.get(this.getLocationsUrl, {params: {organizationId: this.organizationId}}).then(function (response) {
        if (response.body.error) {
          this.error = true;
          this.message = this.$t('mergeForm-retrieveLocationError');
        } else {
          this.targetList = response.body.results;
          // sort objects by locationName
          this.targetList.sort((a, b) => {
            if (a.locationName > b.locationName) {
              return 1;
            }
            if (a.locationName < b.locationName) {
              return -1;
            }
            return 0;
          });
        }
      });
    },
    getProjectList () {
      // if from project page
      this.$http.get(this.getProjectsUrl + this.mergeSourceInfo.sensorId + '&filters%5B0%5D%5Bcolumn%5D=isAdmin&filters%5B0%5D%5Bvalue%5D=true').then((response) => {
        if (response.body.hasOwnProperty('error')) {
          this.error = true;
          this.message = this.$t('mergeForm-retrieveProjectError');
        } else {
          this.targetList = response.body.results;
          this.targetList.sort((a, b) => {
            if (a.fullNm > b.fullNm) {
              return 1;
            }
            if (a.fullNm < b.fullNm) {
              return -1;
            }
            return 0;
          });
          // need to set source org id as a number instead of a string
          this.mergeSourceInfo.organizationId = this.targetList.find((item) => {
            if (item.id === this.mergeSourceInfo.id) {
              return item;
            }
          }).organizationId;
        }
      }, () => {
        // error callback
        this.error = true;
        this.message = this.$t('mergeForm-retrieveProjectError');
      });
    },
    getTargetDetails () {
      if (this.mergeType === 'project') {
        return;
      }
      this.error = false;
      this.message = '';
      this.$http.get(this.getTargetDetailsUrl, {params: {organizationId: this.organizationId, locationId: this.target.id}}).then(function (response) {
        if (response.body.error) {
          this.error = true;
          this.message = this.$t('mergeForm-retrieveLocationError');
        } else {
          this.targetDetails = response.body;
        }
      });
    },
    closeForm () {
      this.message = '';
      this.error = false;
      eventBus.$emit('close-merge-form');
      this.$modal.hide('merge-form');
      this.success = false;
      eventBus.$emit('reload-table');
    }
  }
}
</script>
<style scoped>
.message {
  color: rgb(255, 123, 0);
}

.status{
  height: 25px;
}

.no-bottom-margin{
  margin-bottom: 0;
}

strong{
  font-weight: 550;
}

</style>
