<template>
    <modal :name="name" class="overflow-scroll" :adaptive="true" width="95%" height="auto" @opened="opened" @before-open="beforeOpen" @before-close="beforeClose">
         <div>
         <div v-show="loading" class="h-96 items-center flex">
          <div class="text-center flex-1 grow">
            <pulse-loader class="text-center" :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            <p>{{ $t('aruVerificationModal-loadingAudio') }}</p>
          </div>
        </div>
       
        <div v-if="!loading" class="overflow-scroll p-3" :id="name">
          <!-- Have to hide this section but not load the title until the taskoverview has been loaded. -->
            <header class="mb-0 flex ">
              <span class="flex-1 grow">
                <div class="text-4xl block w-full">{{$t('aruVerificationModal-tagDetails')}}</div>
                <a class="!cursor-pointer text-2xl py-1" :href="taskUrl(tag)" target="_blank">
                  {{$tc('common-task',1)}}: {{details.recordingName.split('@')[0]+' @ '+details.recordingName.split('@')[1]}}
                </a>
              </span>
              <span @click="close()" class="btn !bg-white !border-white !text-gray-300 !px-2 !py-1 flex-initial"><i class="fas fa-times"></i></span>
            </header>
            <div class="toolbar flex w-full mb-5">
              <span @click="deleteModal(tag)" :disabled="!userCanModify" class="btn !px-3 !py-1 flex-initial mr-1"><i class="fas fa-trash !text-sm"></i></span>
              <span @click="markVerified(tag)" :disabled="!userCanModify" class="btn !px-2 !py-1 flex-initial mr-1  " :class="{'!bg-green-500 !border-green-600':tag.isVerified&&userCanModify}"><i class="fas fa-check !text-sm"></i></span>
              <span @click="ratingModal()" :disabled="!userCanModify" class="btn !px-2 !py-1 flex-initial" :class="{'!bg-yellow-400 !bg-yellow-500': tag.rating}">
                {{tag.rating}}
                <i class="fas fa-star !text-sm"></i>
              </span>
            </div>
            <div class="flex">
              <div  class="w-1/4 text-base flex-initial">
              <img class="rounded-full" 
              :src="`https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${details.species.scientificName}`"
             
               /><br />
                  {{$t('Vocalization Type')}}: {{staticOptions.vocalization.find(voc=>voc.id==details.summary.vocalizationId).type||$t('Unknown')}} <br />
                  {{$t('recordingForms-abundance')}}: {{details.summary.abundanceType != "TMTT"?details.summary.abundanceType:$t('aruVerificationModal-TMTT')}} <br />
                  {{ $t('aruVerificationModal-minFreq') }}: {{details.summary.minFreq}}<br />
                  {{ $t('aruVerificationModal-maxFreq') }}: {{details.summary.maxFreq}}<br />
                  {{ $t('aruVerificationModal-length') }}: {{details.recording.audioLength}}s<br />
                  {{ $t('aruVerificationModal-channel') }}: {{ findChannel(details.summary.channel).description }}<br />
                  {{ $t('aruSpeciesVerification-tooltips.birdnet') }}: {{ details.summary.birdNetConfidence }}<br />
                  {{$t('aruSpeciesVerification-tooltips.peakDBFS')}}: {{ details.summary.peakDBFS }}
                  <div class="mt-5">
                    <b class="font-bold">{{$t('common-equipment')}}</b><br />
                    {{ $t('common-make') }}: {{details.locationEquipment.equipmentMake || $t('recordingPage-unknown') }} <br />
                    {{$t('common-model')}}: {{details.locationEquipment.equipmentModel || $t('recordingPage-unknown')}}
                  </div>
              </div>
              <div class="items-center w-3/4 mt-3 flex-1 grow">
                  <div class="flex overflow-x-auto">
                      <div class="flex-shrink w-0 relative">
                          
                      </div>
                      <div class="flex-initial flex flex-col">
                        <div class="flex-initial grow flex flex-col">
                          <div class="flex-initial text-xs">{{details.summary.maxFreq}}</div>
                          <div class="grow flex-1 relative"><span class="transform absolute -left-10 top-1/2 -translate-y-1/2 -rotate-90 block text-xs ">{{ $t('aruVerificationModal-frequency') }}</span></div>
                          <div class="flex-initial text-xs invisible">{{tag.minFrequency}}</div>
                        </div>
                        <div class="flex-initial">
                          <span class="text-xs block h-10 -mt-2">{{details.summary.minFreq}}</span>
                        </div>
                      </div>
                      <div class="flex-initial flex flex-col">
                          <div ref="spectrogram" class="ml-1 border-gray-500 border-solid border-4 relative float-left spectrogram overflow-hidden">
                              <div ref="line" class="absolute spectro-line bg-red-500" style="left: 0px"></div>
                              <!-- <div class="absolute bg-gray-500 h-full w-1 " :style="{left:(image.width/2)+'px'}"></div> -->
                              <div  class="absolute w-px h-full bg-gray-600 top-0" :style="{left: ((details.summary.clipStartTime/details.summary.clipLength)*details.summary.clipSpectrogramWidth)+'px'}"></div>
                              <div  class="absolute w-px h-full bg-gray-600 top-0" :style="{left: ((details.summary.clipEndTime/details.summary.clipLength)*details.summary.clipSpectrogramWidth)+'px'}"></div>
                            
                              <img @load="() => {imageLoaded=true;}" ref="spectrogram-image" class="spectro-image w-full" :style="{'min-width': details.summary.clipSpectrogramWidth+'px'}" :src="image.src" />
                          </div>
                          <div class="flex">
                            <div class="flex-initial bg-gray-500 cursor-pointer text-white ml-1 text-center py-1 px-2 clear-left float-left"
                                  
                                 @click="toggleButton(tag.tagId)"
                                 :disabled="true"
                            >
                                <i :class="{'fas fa-play': !playing, 'fas fa-pause': playing}"></i>
                            </div>
                            <div class="flex-initial bg-gray-400 cursor-pointer text-white text-center py-1 px-2 clear-left float-left" @click="resetbutton(tag)">
                                <i class="fas fa-redo"></i>
                            </div>
                            <div class="flex-1 grow text-center flex">
                              <span class="text-xs flex-1 grow">{{getAudioRef(tag.tagId).currentTime}}</span>
                              <span class="text-xs -mr-2 flex-initial">{{tag.audioLength}}</span>
                            </div>
                          </div>
                          
                      </div>
                      <div class="flex-1 grow">
                      </div>
                  </div>
                  <!-- Filter modification form -->
                <div class="mt-10 rounded border p-5">
                  <div class="grid grid-cols-4 gap-3">
                     <div class="input">
                      <label :for="`${details.summary.tagId}-filter`">{{$t('aruVerificationModal-filter.freqFilter')}}  <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.frequencyfilter')"></span>
                        <input type="checkbox" :id="`${details.summary.tagId}-filter`" v-model="audioControls.freqRangeFilter" @change="audioControlsChanged=true" />
                        <i v-if="audioControls.freqRangeFilter" class="fas fa-toggle-on !block text-4xl text-green-400 "></i>  
                        <i v-if="!audioControls.freqRangeFilter" class="fas fa-toggle-off !block text-4xl text-red-400 "></i>  
                      </label>
                    </div>
                    <label :for="`${details.summary.tagId}-amplify`">{{$t('aruVerificationModal-filter.amplify')}}  <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.amplify')"></span>
                      <select :id="`${details.summary.tagId}-amplify`" v-model="audioControls.amplify" @change="audioControlsChanged=true">
                        <option v-for="(option, key) in details.options.amplify" :key="key" :value="option.value" :selected="option.isDefault">{{option.description}}</option>
                      </select>
                    </label>
                    <label :for="`${details.summary.tagId}-noise`">{{$t('aruVerificationModal-filter.noiseFilter')}}  <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.noisefilter')"></span>
                      <select :id="`${details.summary.tagId}-noise`" v-model="audioControls.noiseFilter" @change="audioControlsChanged=true">
                        <option v-for="(option, key) in details.options.noiseFilter" :key="key" :value="option.value" :selected="option.isDefault">{{option.description}}</option>
                      </select>
                    </label>
                    <label :for="`${details.summary.tagId}-channel`">{{$t('aruVerificationModal-filter.channel')}}  <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.channelfilter')"></span>
                      <select :id="`${details.summary.tagId}-channel`" v-model="audioControls.channel" @change="audioControlsChanged=true">
                        <option v-for="(option, key) in details.options.channel" :key="key" :value="option.value" :selected="option.isDefault">{{option.description}}</option>
                      </select>
                    </label>
                    <label :for="`${details.summary.tagId}-channel`">{{$t('aruVerificationModal-filter.zContrast')}}  <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.Z-contrast')"></span>
                      <select :id="`${details.summary.tagId}-channel`" v-model="audioControls.spectrogramZContrast" @change="audioControlsChanged=true">
                        <option v-for="(option, key) in details.options.spectrogramZContrast" :key="key" :value="option.value" :selected="option.isDefault">{{option.description}}</option>
                      </select>
                    </label>
                    <!-- <label :for="`${details.summary.tagId}-channel`">{{$t('aruVerificationModal-filter.yScale')}}  <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.Y-scale')"></span>
                      <select :id="`${details.summary.tagId}-channel`" v-model="audioControls.spectrogramYScale" @change="audioControlsChanged=true">
                        <option v-for="(option, key) in details.options.spectrogramYScale" :key="key" :value="option.value" :selected="option.isDefault">{{option.description}}</option>
                      </select>
                    </label> -->
                    <label :for="`${details.summary.tagId}-channel`">{{$t('aruVerificationModal-filter.zBrightness')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.Z-brightness')"></span>
                      <select :id="`${details.summary.tagId}-channel`" v-model="audioControls.spectrogramZBrightness" @change="audioControlsChanged=true">
                        <option v-for="(option, key) in details.options.spectrogramZBrightness" :key="key" :value="option.value" :selected="option.isDefault">{{option.description}}</option>
                      </select>
                    </label>
                   
                  </div>
                  <div>
                    <button class="btn" :disabled="!audioControlsChanged" @click="getAudioFile">{{$t('common-applyFilters')}}</button>
                    <button class="btn" @click="resetFilters">{{$t('common-resetFilters')}}</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Audio DOM -->
            <audio v-if="details.tagClip.clipURL" :id="details.summary.tagId" :ref="details.summary.tagId">
                <source v-if="!audioFile" :src="details.tagClip.clipURL" type="audio/mpeg">
                <source v-if="audioFile" :src="`data:audio/mpeg;base64,${audioFile}`" type="audio/mpeg">
            </audio> 
        </div>
         <!-- 
           Dynamic data loading here.
           <audio :id="tag.tagId" :ref="tag.tagId">
                <source v-if="audioFile" :src="`data:audio/mpeg;base64,${audioFile}`" type="audio/mpeg">
        </audio> -->
        
      </div>
    </modal>
</template>

<script>
import anime from 'animejs/lib/anime.es';
import { API_URL, ARU_RECORDING_URL } from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

export default {
  name: 'tagging-main-page',
  components: {'pulse-loader': PulseLoader},
  props: ['name', 'tag', 'ratingModal', 'markVerified', 'deleteModal', 'userCanModify', 'opened','staticOptions'],
  /**************************************************************************************
   *                                CREATED
   **************************************************************************************/
  created () {
   
  },
  /**************************************************************************************
   *                                MOUNTED
   **************************************************************************************/
  mounted () {
    this.$on('modal-fully-loaded', () => {
    
    });
  },

  /**************************************************************************************
   *                                UPDATED
   **************************************************************************************/
  updated () {
  
  },
  /**************************************************************************************
   *                                WATCH
   **************************************************************************************/
  watch: {
   
  },
  /**************************************************************************************
   *                                COMPUTED
   **************************************************************************************/
  computed: {
    loading () {
      let loading = !this.details || this.loadingModification;
      if (!loading) { 
        this.$emit('modal-fully-loaded');
      }

      return loading;
    }
  },
  /**************************************************************************************
   *                                METHODS
   **************************************************************************************/
  methods: {

    /*****************
     * Modal event callbacks
     *****************/ 
  
    beforeOpen () {
      this.getTaskDetails();

      // Comment in to dynamic load binary data.
      // this.getAudioFile();
    },
    beforeClose () {
      if (this.playing) {
        this.pause();
      }
    },
    close () {
      this.$modal.hide(this.name);
    },
    /*****************
     * AUdio Player methods.
     *****************/ 
    resetAudioStart () {
      let audio = this.getAudioRef(this.details.tagClip.tagId);
      if (audio) { audio.currentTime = 0; }
    },
    resetbutton (tag) {
      if (this.playing) { this.pause(); }
      this.resetAudioStart();
      this.animator.restart();
      this.animator.pause();
    },
    toggleButton (tagId) {
      if (this.audioControlsChanged) {
        this.getAudioFile().then(() => {
          this.audioControlsChanged = false;
          this.playing ? this.pause() : this.play();
        });
      } else {
        this.playing ? this.pause() : this.play();
      }
    },
    play () {
      this.animator.play();
      this.playing = true;
      this.getAudioRef(this.tag.tagId).play();
    },
    pause () {
      this.animator.pause();
      this.playing = false;
      this.getAudioRef(this.tag.tagId).pause();
    },
    getAudioRef (tagId) {
      let audio = this.$refs[tagId];
      if (audio) {
        return audio;
      }
      return false;
    },
    /*****************
     * General drawing methods.
     *****************/ 
    drawAICheck () {
      let arr = [];
      arr[0] = `${this.tag.speciesCode}: ${this.tag.birdNetConfidence || '0.00'}`;
      if (this.tag.birdNetResults && this.tag.birdNetResults.length > 0) {
        this.tag.birdNetResults.forEach((v, i) => {
          arr[i + 1] = `${v.speciesCode}: ${v.birdNetConfidence}`;
        });
      }
      return arr.join('<br />');
    },
    findChannel (channelId) {
      return this.details.options.channel.find(x => channelId == x.value) || {description: this.$t('aruSpeciesVerificationModal-unknown')}
    },

    // Called after details have been loaded.
    detailsLoaded () {
      this.$emit('data-loaded');
      this.imageURL = this.details.tagClip.spectrogramClipURL;
      Object.entries(this.audioControls).forEach((v, i) => {
        let options = Object.entries(this.details.options).find((v2, i2) => {
          return v[0] === v2[0]; 
        });
        if (options) {
          let foundOption = options[1].find(x => x.isDefault);
          this.audioControls[v[0]] = foundOption.value;
        } 
      });
      this.initAnimator();
    },

    resetFilters() {
      this.audioControlsChanged = true;
      const reset = {
        amplify: 0,
        noiseFilter: 0,
        channel: 0,
        freqRangeFilter: false,
        spectrogramZContrast: 90,
        spectrogramZBrightness: 0,
        spectrogramYScale: 1

      };

     for (const [key, value] of Object.entries(reset)) {
       this.audioControls[key] = value;
     }
    },

    /*****************
     * AJAX calls
     *****************/ 
    getTaskDetails () {
      this.$http.get(this.getTaskDetailsUrl, { params: { tagId: this.tag.tagId } }).then(
        (response) => {
        // get body data
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.details = response.data;
            this.detailsLoaded();
          }
        }, (err) => {
          // error callback
          this.error = err;
        });
    },
    getAudioFile () {
      let that = this;
      this.loadingModification = true;

      let tempImgSrc = this.imageURL;
      this.imageURL = null;

      return new Promise((resolve, reject) => {
        this.$http.get(this.tagFileUrl, { params: { 
          tagId: this.details.summary.tagId,
          ...Object.keys(this.audioControls).filter(k => this.audioControls[k] != null).reduce((a, k) => ({...a, [k]: this.audioControls[k]}), {})
        } }).then(
          (response) => {
            if (response.body.mp3) {
              this.audioFile = response.body.mp3;
              this.imageURL = `data:image/jpg;base64,${response.body.jpg}`
              this.loadingModification = false;
              this.initAnimator();
              resolve();
            }
            this.audioControlsChanged = false;
            this.$emit('audio-loaded-' + that.tag.tagId);
          }, (err) => {
          // error callback
            this.error = err;
            this.imageURL = tempImgSrc;
            reject(err);
          });
      });
    },
    
    taskUrl: function (tag) {
      return ARU_RECORDING_URL + tag.taskId + '&tagId=' + tag.tagId;
    },
    initAnimator () {
      this.animator = null;
      this.image = new Image();
      this.image.src = this.imageURL;
      this.$nextTick(() => {
        this.animator = new anime({
          targets: document.querySelector('#' + this.name + ' .spectro-line'),
          left: this.details.tagClip.spectrogramWidth + 'px',
          autoplay: false,
          duration: (this.details.tagClip.paddedLength * 1000),
          easing: 'linear',
          update: ani => {
            this.$forceUpdate();
          },
          complete: (ani) => {
            ani.restart();
            this.pause();
            this.resetAudioStart();
          }
        });
        this.resetAudioStart();
      });
    }
  },
  /**************************************************************************************
   *                                DATA
   **************************************************************************************/
  data () {
    return {
      getTaskDetailsUrl: API_URL + 'get-aru-species-verification-details?',
      tagFileUrl: API_URL + 'aru-get-tag-file-with-modifications?',
      animator: null,
      image: new Image(),
      imageLoaded: false,
      playing: false,
      audio: null,
      details: null,
      audioControls: {
        amplify: null,
        noiseFilter: null,
        channel: null,
        freqRangeFilter: false,
        spectrogramZContrast: null,
        spectrogramZBrightness: null,
        spectrogramYScale: null

      },
      imageURL: null,
      imageWidth: 0, // Spectrogram image width. Store original so when filters are applied it will use the same.
      loadingModification: false,
      audioFile: null,
      audioControlsChanged: false, // Flag to mark if audio controls changed and new audio is required.
      filterPanelVisible: false // Set to null to load dynamic audio. Set to true to skip 
    };
  }
}
</script>

<style scoped>

.spectro-line {
    width: 2px;
    height: 100%;
}

</style>
