/* vue component to create an independ function component on magnolia page.
  Magnolia Page will created same dom id specified here xx-page through component creation,
  and read libary from the same folder name at webresources\bundle folder.
  After build the vue app, upload the js/css folder to the magnolia bundle folders.
*/
import '../../static/style/fontawesome.min.css';
import '../../static/style/solid.css';
import '../../static/style/regular.css';
import '../assets/tailwind.css';
import '../../static/style/core.css';
import '../../static/style/camera-core.css';
import '../../static/style/custom.css';

import Vue from 'vue'
import VueResource from 'vue-resource';


//import maintenanceModeHTML from '../../assets/maintenance_mode.html';

// import router from '../router';
import VueJsModal from 'vue-js-modal'
import VueTables from 'vue-tables-2';
import { isLoggedIn, getProfile, checkLanguageToken, handleAuthentication, login, logout } from '@/lib/user-auth0.js'
import { attachAuthorizaitonHeader } from '@/lib/common';
// import Autocomplete from 'vuejs-auto-complete';
import { eventBus } from '@/lib/eventbus.js'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';
import VueSticky from '@/components/utils/vue-sticky/src/index.js';
import VueCookies from 'vue-cookies'
import VueAgile from 'vue-agile';
import i18n from '../i18n'
import store from '@/store';
import {attachLanguageHeader} from '@/lib/language.js';
import VueLazyload from 'vue-lazyload';
import router from '../router';



Vue.config.productionTip = false
Vue.prototype.window = window;
Vue.directive('tooltip', VTooltip)
Vue.use(VueResource);
Vue.config.productionTip = false
Vue.use(VueTables.ServerTable);
Vue.use(VueTables.ClientTable);
Vue.directive('close-popover', VClosePopover);
Vue.component('v-popover', VPopover);
Vue.directive('sticky', VueSticky);
Vue.use(VueCookies);
Vue.use(VueAgile);
Vue.use(VueLazyload);

Vue.use(VueJsModal, {
  dialog: true,
  dynamic: true,
  dynamicDefaults: {
    foo: 'foo'
  }
});
handleAuthentication();
attachAuthorizaitonHeader();
attachLanguageHeader();
checkLanguageToken();


import App from '../App.vue';

new Vue({
  i18n,
  store,
  router,
  el: '#app',
  render: (h) => h(App)
});




document.addEventListener('DOMContentLoaded', function () {
 // handleAuthentication();

  var authenticated = isLoggedIn(); // this.handleAuthentication();
  eventBus.$emit('authentication_updated', authenticated);
  if (authenticated) {
    getProfile();
    
  } 
});
