import Vue from 'vue'
import i18n from '@/i18n';
import Router from 'vue-router'
import { checkLanguageToken } from '@/lib/user-auth0.js';
import ProjectPage from '@/components/project/ProjectPage'
import RecordingPage from '@/components/ARU/RecordingPage'
import AruTaskPage from '@/components/task/ARUTaskPage'
import CAMTaskPage from '@/components/task/CameraTaskPage'
import PCTaskPage from '@/components/task/PCTaskPage'
import SpeciesVerifyPage from '@/components/camera/SpeciesVerifyPage';
import ARUSpeciesVerifyPage from '@/components/ARU/SpeciesVerifyPage';
import TaggingPage from '@/components/camera/TaggingPage';
import OrganizationListPage from '@/components/organization/OrganizationListPage';
import OrganizationAdminPage from '@/components/organization/OrganizationAdminPage';
import DataDiscoverPage from '@/components/data-discover/DataDiscoverPage';
import ProjectDownloadForm from '@/components/project/ProjectDownloadForm';
import GrantAccessPage from '@/components/grant-access/GrantAccessPage';
import LandOwnerHomePage from '@/components/landowner-reports/LandOwnerHomePage';
import ReportPage from '@/components/landowner-reports/ReportPage';
import PointCountSurveyPage from '@/components/point-count/PointCountSurveyPage';
import UserDetailsPage from '@/components/user/UserDetailsPage';
import Logout from '@/components/common/Logout';
import IdEntry from '@/components/common/IdEntry';
// import {languageFromUrl} from '@/lib/language.js'
import { isDataDiscover, getDataDiscoverURL } from '@/lib/common';

Vue.use(Router);

let routes = [
  {
    path: '/home/projects.html',
    alias: '/accueil/projets.html', // can make alias into an array later if theres more languages
    // name: 'ProjectPage',
    component: ProjectPage
  },
  {
    path: '/home.html',
    alias: '/accuiel.html',
    component: ProjectPage
  },
  {
    path: '/',
    component: ProjectPage
  },
  {
    path: '/null',
    component: ProjectPage
  },
  {
    path: '/home/camera-deployments/image-tagging.html',
    alias: '/accueil/camera-deployments/image-tagging.html', // TODO replace w french urls once provided
    // name: 'TaggingPage',
    component: TaggingPage
  },
  {
    path: '/home/camera-deployments/species-verification.html',
    alias: '/accueil/camera-deployments/species-verification.html', // TODO replace w french urls once provided
    name: 'SpeciesVerifyPage',
    component: SpeciesVerifyPage
  },
  {
    path: '/home/aru-tasks/species-verification.html',
    alias: '/accueil//aru-tasks/species-verification.html', // TODO replace w french urls once provided
    // name: 'SpeciesVerifyPage',
    component: ARUSpeciesVerifyPage
  },
  {
    path: '/home/camera-deployments.html',
    alias: '/accueil/camera-deployments.html', // TODO replace w french urls once provided
    // name: 'CAMTaskPage',
    component: CAMTaskPage
  },
  {
    path: '/home/aru-tasks.html',
    alias: '/accueil/aru-tasks.html', // TODO replace w french urls once provided
    // name: 'ARUTaskPage',
    component: AruTaskPage
  },
  {
    path: '/home/pc-surveys.html',
    alias: '/accueil/pc-surveys.html', // TODO replace w french urls once provided
    // name: 'PCTaskPage',
    component: PCTaskPage
  },
  {
    path: '/home/aru-tasks/recording.html',
    alias: '/accueil/aru-tasks/recording.html', // TODO replace w french urls once provided
    // name: 'RecordingPage',
    component: RecordingPage
  },
  {
    path: '/home/organization-list.html',
    alias: '/accueil/liste-organisations.html',
    // name: 'OrganizationList',
    component: OrganizationListPage
  },
  {
    path: '/home/organization-list/organization-admin.html',
    alias: '/accueil/liste-organisations/organization-admin.html', // TODO replace w french urls once provided
    component: OrganizationAdminPage
  },
  {
    path: '/data-discover.html',
    alias: '/accueil/projets/découvrir-données.html',
    beforeEnter(to,from,next) {
      window.location.href = getDataDiscoverURL();
    }
  },
  {
    path: '/home/projects/data-download.html',
    alias: '/accueil/projets/télécharger-données',
    component: ProjectDownloadForm
  },
  {
    path: '/home/admin/grant-access',
    alias: '/accueil/admin/grant-access.html', // TODO replace w french urls once provided
    component: GrantAccessPage
  },
  {
    path: '/home/landowners/reports.html',
    alias: '/accueil/landowners/reports.html', // TODO replace w french urls once provided
    component: LandOwnerHomePage
  },
  {
    path: '/home/landowners/report.html',
    alias: '/accueil/landowners/report.html', // TODO replace w french urls once provided
    component: ReportPage
  },
  {
    path: '/home/pc-surveys/pc-survey.html',
    alias: '/accueil/pc-surveys/pc-survey.html', // TODO replace w french urls once provided
    component: PointCountSurveyPage
  },
  {
    path: '/home/user-settings.html',
    alias: '/accueil/user-settings.html', // TODO replace w french urls once provided
    component: UserDetailsPage
  },
  {
    path: '/home/logout.html',
    alias: '',
        component: Logout
  },
  {
    path: '/id-entry',
    component: IdEntry
  }
];


if (isDataDiscover()) {
  routes.forEach((route,index)=> {
    if (route.path == '/') {
      routes[index].component = DataDiscoverPage;
    }
  });
}

export default new Router({
  mode: 'history', //To change to history mode visit https://v3.router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
  routes
})
