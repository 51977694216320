var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "audio",
        {
          ref: "audio-player",
          attrs: { preload: "auto" },
          on: { loadeddata: _vm.audioLoaded }
        },
        [
          _vm.recording.filePath
            ? _c("source", {
                attrs: {
                  src: _vm.recording.filePath,
                  type: `audio/${_vm.recording.fileType}`
                }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "audio",
        {
          ref: "audio-player-dynamic",
          attrs: { preload: "auto" },
          on: { loadeddata: _vm.audioLoaded }
        },
        [
          _vm.recording.file
            ? _c("source", {
                attrs: {
                  src: `data:audio/${_vm.recording.fileType};base64,${_vm.recording.file}`
                }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col s12 m12",
          class: { l8: _vm.showForm, xl9: _vm.showForm, l12: !_vm.showForm },
          attrs: { id: "listeningPanel" }
        },
        [
          _vm.task.length > 600
            ? _c(
                "div",
                {
                  staticClass:
                    "bg-blue-100 text-blue-400 border border-blue-400 rounded mb-10 p-3 text-base"
                },
                [
                  _c("i", { staticClass: "fa-solid fa-circle-info" }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Task length is larger than 600 seconds. It may take a few moments for the spectrogram to load completely."
                        )
                      ) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !Object.keys(_vm.recording).length ||
                    !Object.entries(this.options).length,
                  expression:
                    "!Object.keys(recording).length || !Object.entries(this.options).length"
                }
              ],
              staticClass: "overaly"
            },
            [
              _c(
                "div",
                {
                  staticClass: "my-64",
                  staticStyle: { "text-align": "center" }
                },
                [
                  _c("pulse-loader", {
                    attrs: {
                      loading: true,
                      color: "#C94412",
                      size: "20px",
                      width: 400,
                      height: 400
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("common-tableTexts.loading")) + " "
                    )
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: Object.keys(_vm.recording).length,
                  expression: "Object.keys(recording).length"
                }
              ]
            },
            [
              _vm.options.spectrogramOptions
                ? _c(
                    "modal",
                    { attrs: { name: "parameterModal", height: "auto" } },
                    [
                      _c("div", { staticClass: "p-5 relative" }, [
                        _c("i", {
                          staticClass:
                            "fas fa-times absolute right-3 top-3 cursor-pointer",
                          on: { click: _vm.hideParameterModal }
                        }),
                        _vm._v(" "),
                        _c("h5", [_vm._v(_vm._s(_vm.$t("Audio Settings")))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid grid-cols-2 gap-2" }, [
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Channel")) + " "),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.channelfilter"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.channelfilter')"
                                  }
                                ],
                                staticClass:
                                  "ion-information-circled text-yellow-500"
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.tempAudioSettings.channel,
                                      expression: "tempAudioSettings.channel"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.tempAudioSettings,
                                        "channel",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.options.spectrogramOptions.channel,
                                  function(channel, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: channel.value }
                                      },
                                      [_vm._v(_vm._s(channel.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Amplify")) + " "),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.amplify"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.amplify')"
                                  }
                                ],
                                staticClass:
                                  "ion-information-circled text-yellow-500"
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.tempAudioSettings.amplifyId,
                                      expression: "tempAudioSettings.amplifyId"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.tempAudioSettings,
                                        "amplifyId",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.options.spectrogramOptions.amplify,
                                  function(value, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: value.value }
                                      },
                                      [_vm._v(_vm._s(value.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid grid-cols-2 gap-2" }, [
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Minimum Frequency (Hz)")))
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.tempAudioSettings.minFreqHz,
                                    expression: "tempAudioSettings.minFreqHz"
                                  }
                                ],
                                attrs: { type: "number" },
                                domProps: {
                                  value: _vm.tempAudioSettings.minFreqHz
                                },
                                on: {
                                  keyup: _vm.validateMinFreq,
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.tempAudioSettings,
                                      "minFreqHz",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Maximum Frequency (Hz)")))
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.tempAudioSettings.maxFreqHz,
                                    expression: "tempAudioSettings.maxFreqHz"
                                  }
                                ],
                                attrs: { type: "number" },
                                domProps: {
                                  value: _vm.tempAudioSettings.maxFreqHz
                                },
                                on: {
                                  keyup: _vm.validateMaxFreq,
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.tempAudioSettings,
                                      "maxFreqHz",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("X Scale")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time."
                                ),
                                expression:
                                  "$t('Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time.')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "bg-yellow-100 text-yellow-500 p-1 text-sm":
                                _vm.tempAudioSettings.xScale >= 5 &&
                                !_vm.isUltraSonic()
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.tempAudioSettings.xScale >= 5 &&
                                      !_vm.isUltraSonic(),
                                    expression:
                                      "tempAudioSettings.xScale>=5&&!isUltraSonic()"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "High X-scale spectrograms are only recommended for ultra sonic recordings"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "flex-initial w-10 text-center"
                                },
                                [_vm._v(_vm._s(_vm.tempAudioSettings.xScale))]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.tempAudioSettings.xScale,
                                    expression: "tempAudioSettings.xScale"
                                  }
                                ],
                                staticClass: "flex-initial",
                                attrs: {
                                  type: "range",
                                  min: "0.25",
                                  max: "10",
                                  step: "0.25"
                                },
                                domProps: {
                                  value: _vm.tempAudioSettings.xScale
                                },
                                on: {
                                  __r: function($event) {
                                    return _vm.$set(
                                      _vm.tempAudioSettings,
                                      "xScale",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("Y Pixels")) + " "),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.$t(
                                    "aruVerificationModal-tooltips.Y-scale"
                                  ),
                                  expression:
                                    "$t('aruVerificationModal-tooltips.Y-scale')"
                                }
                              ],
                              staticClass:
                                "ion-information-circled text-yellow-500"
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.tempAudioSettings.yPixels,
                                    expression: "tempAudioSettings.yPixels"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.tempAudioSettings,
                                      "yPixels",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(
                                _vm.options.spectrogramOptions
                                  .spectrogramYPixels,
                                function(value, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: value.id }
                                    },
                                    [_vm._v(_vm._s(value.value))]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: { "grid gap-2 grid-cols-3": true } },
                          [
                            _c("div", [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("Use Monochrome")) + " "),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms."
                                      ),
                                      expression:
                                        "$t('When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms.')"
                                    }
                                  ],
                                  staticClass:
                                    "ion-information-circled text-yellow-500"
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm.tempAudioSettings.useMonochrome
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-on !block text-4xl text-green-400",
                                      on: {
                                        click: function($event) {
                                          _vm.tempAudioSettings.useMonochrome = false
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.tempAudioSettings.useMonochrome
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-off !block text-4xl text-red-400",
                                      on: {
                                        click: function($event) {
                                          _vm.tempAudioSettings.useMonochrome = true
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("Use Lightmode")) + " "),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms."
                                      ),
                                      expression:
                                        "$t('When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms.')"
                                    }
                                  ],
                                  staticClass:
                                    "ion-information-circled text-yellow-500"
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm.tempLightMode
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-on !block text-4xl text-green-400",
                                      on: {
                                        click: function($event) {
                                          _vm.tempLightMode = false
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.tempLightMode
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-off !block text-4xl text-red-400",
                                      on: {
                                        click: function($event) {
                                          _vm.tempLightMode = true
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("Generate Audio")) + " "),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "When toggled on, only the audio between the selected frequency ranges will be played."
                                      ),
                                      expression:
                                        "$t('When toggled on, only the audio between the selected frequency ranges will be played.')"
                                    }
                                  ],
                                  staticClass:
                                    "ion-information-circled text-yellow-500"
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm.tempAudioSettings.generateAudio
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-on !block text-4xl text-green-400",
                                      on: {
                                        click: function($event) {
                                          _vm.tempAudioSettings.generateAudio = false
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.tempAudioSettings.generateAudio
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-off !block text-4xl text-red-400",
                                      on: {
                                        click: function($event) {
                                          _vm.tempAudioSettings.generateAudio = true
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid grid-cols-3 gap-2" }, [
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Noise Filter")) + " "),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.noisefilter"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.noisefilter')"
                                  }
                                ],
                                staticClass:
                                  "ion-information-circled text-yellow-500"
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.tempAudioSettings.noiseFilterId,
                                      expression:
                                        "tempAudioSettings.noiseFilterId"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.tempAudioSettings,
                                        "noiseFilterId",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.options.spectrogramOptions.noiseFilter,
                                  function(value, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: value.value }
                                      },
                                      [_vm._v(_vm._s(value.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Contrast")) + " "),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.Z-contrast"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.Z-contrast')"
                                  }
                                ],
                                staticClass:
                                  "ion-information-circled text-yellow-500"
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.tempAudioSettings.contrastId,
                                      expression: "tempAudioSettings.contrastId"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.tempAudioSettings,
                                        "contrastId",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.options.spectrogramOptions
                                    .spectrogramZContrast,
                                  function(value, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: value.value }
                                      },
                                      [_vm._v(_vm._s(value.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Brightness")) + " "),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.Z-brightness"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.Z-brightness')"
                                  }
                                ],
                                staticClass:
                                  "ion-information-circled text-yellow-500"
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.tempAudioSettings.brightnessId,
                                      expression:
                                        "tempAudioSettings.brightnessId"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.tempAudioSettings,
                                        "brightnessId",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.options.spectrogramOptions
                                    .spectrogramZBrightness,
                                  function(value, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: value.value }
                                      },
                                      [_vm._v(_vm._s(value.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.dynParamModalError,
                                expression: "dynParamModalError"
                              }
                            ],
                            staticClass: "text-red-700"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.dynParamModalError) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid grid-cols-2 gap-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success block w-full",
                              attrs: {
                                disabled: _vm.dynParamApplyButtonDisabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submitParameters()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Apply")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success block w-full",
                              on: {
                                click: function($event) {
                                  return _vm.resetParameters()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Reset")) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "spectrogramWrap" }, [
                _vm.numOutOfBounds > 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-blue-100 border border-blue-400 rounded mb-10 p-3 text-base"
                      },
                      [
                        _c("i", { staticClass: "fas fa-info-circle" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$tc(
                                "recordingPlayer-OOB-message",
                                _vm.numOutOfBounds,
                                { num: _vm.numOutOfBounds }
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _vm.$store.state.Species.species
                          ? _c(
                              "ul",
                              { staticClass: "!list-decimal list-inside" },
                              _vm._l(_vm.outOfBoundsList(), function(
                                tag,
                                index
                              ) {
                                return _c(
                                  "li",
                                  { staticClass: "!list-decimal" },
                                  [
                                    _vm._v(
                                      "\n            \n              " +
                                        _vm._s(
                                          (
                                            _vm.$store.state.Species.species.find(
                                              s =>
                                                s.id ===
                                                tag.speciesIndividual.speciesId
                                            ) || { code: tag }
                                          ).code
                                        ) +
                                        " from " +
                                        _vm._s(Math.ceil(tag.minFreq / 1000)) +
                                        "\n              kHz to " +
                                        _vm._s(Math.ceil(tag.maxFreq / 1000)) +
                                        " kHz"
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isUltraSonic() && _vm.isFirefox()
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-red-100 text-red-400 border border-red-400 rounded mb-10 p-3 text-base"
                      },
                      [
                        _c("i", {
                          staticClass: "fa-solid fa-triangle-exclamation"
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "Playback may not work due to a Firefox issue. We recommend using Chrome to playback audio with high frequencies."
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.recording.showLeftChannel,
                        expression: "recording.showLeftChannel"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm.$t("common-left")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.recording.showLeftChannel,
                        expression: "recording.showLeftChannel"
                      }
                    ],
                    staticClass: "singleWrap",
                    attrs: { id: "spectrogramWrap-left" }
                  },
                  [
                    _c("div", {
                      staticClass: "spectrogram-index-marker",
                      style:
                        "left:" +
                        _vm.leftOffset +
                        "px; height: " +
                        (_vm.recording.totalHeight + 40) +
                        "px;"
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "yPixels border-t border-[#d9d9d9]",
                      style: {
                        position: "absolute",
                        top: "0px",
                        left: "1px",
                        "z-index": _vm.getTagCount
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "spectrogram-display-left",
                        staticClass: "spectrogram-display",
                        class: { noscroll: !_vm.paused },
                        style:
                          "height: " +
                          (this.recording.totalHeight + 40) +
                          "px;",
                        attrs: { id: "spectrogram-display-left" }
                      },
                      [
                        _vm.recording.spectrograms
                          ? _c(
                              "div",
                              {
                                ref: "spectrogram-images-left",
                                class: { forbidden: !_vm.isEditable },
                                style: {
                                  left: _vm.leftOffset + "px",
                                  width:
                                    _vm.recording.totalLengthPixel +
                                    _vm.displayWidth +
                                    _vm.leftOffset +
                                    "px",
                                  height: _vm.recording.totalHeight + "px"
                                },
                                attrs: { id: "spectrogram-images-left" },
                                on: {
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_start($event, "left")
                                  },
                                  mousemove: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_drawing($event, "left")
                                  },
                                  mouseup: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_complete($event, "left")
                                  },
                                  mouseleave: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_complete($event, "left")
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _vm._l(
                                      _vm.recording.spectrograms.filter(
                                        i =>
                                          i && i.channel === _vm.channelIDs.left
                                      ),
                                      function(image, index) {
                                        return _c("div", { key: index }, [
                                          _c("img", {
                                            staticClass: "float-left",
                                            attrs: {
                                              src: `data:image/${image.fileType};base64, ${image.imageData}`
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.task.methodId === 2
                                            ? _c("div", {
                                                style: {
                                                  left:
                                                    index *
                                                      ((_vm.recording
                                                        .totalLengthPixel /
                                                        _vm.recording
                                                          .totalLengthSec) *
                                                        60) +
                                                    "px",
                                                  top: "0px",
                                                  width: "1px",
                                                  height:
                                                    _vm.recording.totalHeight +
                                                    "px",
                                                  "border-left":
                                                    "thin #CC0000 solid",
                                                  position: "absolute"
                                                }
                                              })
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "xscale",
                                      style: {
                                        width:
                                          _vm.recording.totalLengthPixel + "px",
                                        float: "left",
                                        "max-width": "100%",
                                        "vertical-align": "middle",
                                        position: "absolute",
                                        left: 0,
                                        top: _vm.recording.totalHeight + "px"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.showLeftTagBoxes
                                      ? _c(
                                          "div",
                                          _vm._l(_vm.visibleTags(), function(
                                            tags,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: "leftTagUser" + index,
                                                style: {
                                                  width:
                                                    _vm.recording
                                                      .totalLengthPixel + "px",
                                                  height:
                                                    _vm.recording.totalHeight +
                                                    "px"
                                                }
                                              },
                                              [
                                                _vm._l(tags.tag, function(
                                                  tagRow,
                                                  tagIndex
                                                ) {
                                                  return tagRow.maxFreq <=
                                                    _vm.dynParams.maxFreqHz &&
                                                    tagRow.minFreq >=
                                                      _vm.dynParams.minFreqHz
                                                    ? _c(
                                                        "vue-draggable-resizable",
                                                        _vm._b(
                                                          {
                                                            key:
                                                              "leftTag" +
                                                              tagIndex,
                                                            class: {
                                                              shadow: true,
                                                              "shadow-black":
                                                                _vm.dynParams
                                                                  .lightMode,
                                                              "shadow-white": !_vm
                                                                .dynParams
                                                                .lightMode,
                                                              "hideBorder !shadow-none ":
                                                                _vm.hideBoxes
                                                            },
                                                            style: {
                                                              color:
                                                                tagRow.id ==
                                                                _vm.getParam(
                                                                  "tagId"
                                                                )
                                                                  ? _vm.colors(
                                                                      "readonly"
                                                                    )
                                                                  : _vm.getColor(
                                                                      tags.taskId
                                                                    ),
                                                              "background-color":
                                                                tagRow.id ==
                                                                _vm.getParam(
                                                                  "tagId"
                                                                )
                                                                  ? _vm.colors(
                                                                      "readonly",
                                                                      20
                                                                    )
                                                                  : _vm.getColor(
                                                                      tags.taskId,
                                                                      false,
                                                                      20
                                                                    )
                                                            },
                                                            attrs: {
                                                              active:
                                                                tagRow.id ===
                                                                _vm.tagId,
                                                              tag: tagRow,
                                                              minw:
                                                                _vm.minBoxWidth,
                                                              minh:
                                                                _vm.minBoxWidth,
                                                              resizable:
                                                                _vm.isEditable,
                                                              draggable:
                                                                _vm.isDraggable &&
                                                                _vm.isEditable
                                                            },
                                                            on: {
                                                              resizestop:
                                                                _vm.resize_box_complete,
                                                              dragging:
                                                                _vm.dragging_box,
                                                              dragstop:
                                                                _vm.drag_box_complete,
                                                              dblclick:
                                                                _vm.editTag,
                                                              activated: () => {
                                                                _vm.selectedTag =
                                                                  tagRow.id
                                                              }
                                                            }
                                                          },
                                                          "vue-draggable-resizable",
                                                          _vm.calculateTagCoordinates(
                                                            tagRow
                                                          ),
                                                          false
                                                        ),
                                                        [
                                                          tagRow.speciesIndividual !=
                                                            null &&
                                                          _vm.showTagText
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tag-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.label(
                                                                          tagRow
                                                                        )
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }),
                                                _vm._v(" "),
                                                _vm._l(tags.marker, function(
                                                  markerRow,
                                                  markIndex
                                                ) {
                                                  return _c("div", {
                                                    key:
                                                      "leftMarker" + markIndex,
                                                    staticClass: "mark_box",
                                                    class: {
                                                      highlighted_mark_box:
                                                        _vm.selectedMarkerIndex ===
                                                        markIndex
                                                    },
                                                    style: {
                                                      left:
                                                        markerRow.startTime *
                                                          _vm.recording
                                                            .pixelsPerSecond +
                                                        "px",
                                                      top: "0px",
                                                      width: "10px",
                                                      height:
                                                        _vm.recording
                                                          .totalHeight + "px",
                                                      "border-color": _vm.getColor(
                                                        tags.taskId
                                                      )
                                                    },
                                                    attrs: {
                                                      id: "l" + markerRow.id,
                                                      title: _vm.$t(
                                                        "recordingPlayers-clickToDelete"
                                                      )
                                                    },
                                                    on: {
                                                      mousedown: function(
                                                        $event
                                                      ) {
                                                        return _vm.deleteMarker(
                                                          markerRow
                                                        )
                                                      }
                                                    }
                                                  })
                                                })
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "sel_box new_sel_box",
                                      style: {
                                        left: _vm.drawBox.boxStartX + "px",
                                        top: _vm.drawBox.boxStartY + "px",
                                        width: _vm.drawBox.boxWidth + "px",
                                        height: _vm.drawBox.boxHeight + "px"
                                      }
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _vm._v(
                  "\n        x " + _vm._s(_vm.visibleTags()) + "x\n        "
                ),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.recording.showRightChannel,
                        expression: "recording.showRightChannel"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm.$t("common-right")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.recording.showRightChannel,
                        expression: "recording.showRightChannel"
                      }
                    ],
                    staticClass: "singleWrap",
                    attrs: { id: "spectrogramWrap-right" }
                  },
                  [
                    _c("div", {
                      staticClass: "spectrogram-index-marker",
                      style:
                        "left:" +
                        _vm.leftOffset +
                        "px; height: " +
                        (_vm.recording.totalHeight + 40) +
                        "px;"
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "yPixels border-t border-[#d9d9d9]",
                      style: {
                        position: "absolute",
                        top: "0px",
                        left: "1px",
                        "z-index": _vm.getTagCount
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "spectrogram-display-right",
                        staticClass: "spectrogram-display",
                        style:
                          "height: " + (_vm.recording.totalHeight + 40) + "px;",
                        attrs: { id: "spectrogram-display-right" }
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _vm.recording.showRightChannel &&
                        _vm.recording.spectrograms
                          ? _c(
                              "div",
                              {
                                ref: "spectrogram-images-right",
                                class: { forbidden: !_vm.isEditable },
                                style: {
                                  left: _vm.leftOffset + "px",
                                  width:
                                    _vm.recording.totalLengthPixel +
                                    _vm.displayWidth +
                                    _vm.leftOffset +
                                    "px",
                                  height: _vm.recording.totalHeight + 40 + "px"
                                },
                                attrs: { id: "spectrogram-images-right" },
                                on: {
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_start($event, "right")
                                  },
                                  mousemove: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_drawing($event, "right")
                                  },
                                  mouseup: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_complete(
                                      $event,
                                      "right"
                                    )
                                  },
                                  mouseleave: function($event) {
                                    $event.preventDefault()
                                    return _vm.draw_box_drawing($event, "right")
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _vm._l(
                                      _vm.recording.spectrograms.filter(
                                        i =>
                                          i &&
                                          i.channel === _vm.channelIDs.right
                                      ),
                                      function(image, index) {
                                        return _c("div", { key: index }, [
                                          _c("img", {
                                            staticClass: "float-left",
                                            attrs: {
                                              src: `data:image/${image.fileType};base64, ${image.imageData}`
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.task.methodId === 2
                                            ? _c("div", {
                                                style: {
                                                  left:
                                                    index *
                                                      ((_vm.recording
                                                        .totalLengthPixel /
                                                        _vm.recording
                                                          .totalLengthSec) *
                                                        60) +
                                                    "px",
                                                  top: "0px",
                                                  width: "1px",
                                                  height:
                                                    _vm.recording.totalHeight +
                                                    "px",
                                                  "border-left":
                                                    "thin #CC0000 solid",
                                                  position: "absolute"
                                                }
                                              })
                                            : _vm._e()
                                        ])
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "xscale",
                                      style: {
                                        width:
                                          _vm.recording.totalLengthPixel + "px",
                                        float: "left",
                                        "max-width": "100%",
                                        "vertical-align": "middle",
                                        position: "absolute",
                                        left: 0,
                                        top: _vm.recording.totalHeight + "px"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.showRightTagBoxes
                                      ? _c(
                                          "div",
                                          _vm._l(_vm.visibleTags(), function(
                                            tags,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: "rightTagUser" + index,
                                                style: {
                                                  width:
                                                    _vm.recording
                                                      .totalLengthPixel + "px",
                                                  height:
                                                    _vm.recording.heightHz +
                                                    40 +
                                                    "px"
                                                }
                                              },
                                              [
                                                _vm._l(tags.tag, function(
                                                  tagRow,
                                                  tagIndex
                                                ) {
                                                  return tagRow.maxFreq <=
                                                    _vm.dynParams.maxFreqHz &&
                                                    tagRow.minFreq >=
                                                      _vm.dynParams.minFreqHz
                                                    ? _c(
                                                        "vue-draggable-resizable",
                                                        _vm._b(
                                                          {
                                                            key:
                                                              "rightTag" +
                                                              tagIndex,
                                                            class: {
                                                              shadow: true,
                                                              "shadow-black":
                                                                _vm.dynParams
                                                                  .lightMode,
                                                              "shadow-white": !_vm
                                                                .dynParams
                                                                .lightMode,
                                                              "hideBorder !shadow-none ":
                                                                _vm.hideBoxes
                                                            },
                                                            style: {
                                                              color:
                                                                tagRow.id ==
                                                                _vm.getParam(
                                                                  "tagId"
                                                                )
                                                                  ? _vm.colors(
                                                                      "readonly"
                                                                    )
                                                                  : _vm.getColor(
                                                                      tags.taskId
                                                                    ),
                                                              "background-color":
                                                                tagRow.id ==
                                                                _vm.getParam(
                                                                  "tagId"
                                                                )
                                                                  ? _vm.colors(
                                                                      "readonly",
                                                                      20
                                                                    )
                                                                  : _vm.getColor(
                                                                      tags.taskId,
                                                                      false,
                                                                      20
                                                                    )
                                                            },
                                                            attrs: {
                                                              active:
                                                                tagRow.id ===
                                                                _vm.tagId,
                                                              tag: tagRow,
                                                              minw:
                                                                _vm.minBoxWidth,
                                                              minh:
                                                                _vm.minBoxWidth,
                                                              resizable:
                                                                _vm.isEditable,
                                                              draggable:
                                                                _vm.isDraggable &&
                                                                _vm.isEditable
                                                            },
                                                            on: {
                                                              resizestop:
                                                                _vm.resize_box_complete,
                                                              dragging:
                                                                _vm.dragging_box,
                                                              dragstop:
                                                                _vm.drag_box_complete,
                                                              dblclick:
                                                                _vm.editTag,
                                                              click: () => {
                                                                this.selectedTag =
                                                                  tagRow.id
                                                              },
                                                              activated: () => {
                                                                _vm.selectedTag =
                                                                  tagRow.id
                                                              }
                                                            }
                                                          },
                                                          "vue-draggable-resizable",
                                                          _vm.calculateTagCoordinates(
                                                            tagRow
                                                          ),
                                                          false
                                                        ),
                                                        [
                                                          tagRow.speciesIndividual !=
                                                            null &&
                                                          _vm.showTagText
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tag-label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.label(
                                                                        tagRow
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }),
                                                _vm._v(" "),
                                                _vm._l(tags.marker, function(
                                                  markerRow,
                                                  markerIndex
                                                ) {
                                                  return _c("div", {
                                                    key:
                                                      "leftMarker" +
                                                      markerIndex,
                                                    staticClass: "mark_box",
                                                    class: {
                                                      highlighted_mark_box:
                                                        _vm.selectedMarkerIndex ===
                                                        markerIndex
                                                    },
                                                    style: {
                                                      left:
                                                        markerRow.startTime *
                                                          _vm.recording
                                                            .pixelsPerSecond +
                                                        "px",
                                                      top: "0px",
                                                      width: "10px",
                                                      height:
                                                        _vm.recording
                                                          .totalHeight + "px",
                                                      "border-color": _vm.getColor(
                                                        tags.taskId
                                                      )
                                                    },
                                                    attrs: {
                                                      id: "l" + markerRow.id,
                                                      title: _vm.$t(
                                                        "recordingPlayers-clickToDelete"
                                                      )
                                                    },
                                                    on: {
                                                      mousedown: function(
                                                        $event
                                                      ) {
                                                        return _vm.deleteMarker(
                                                          markerRow
                                                        )
                                                      }
                                                    }
                                                  })
                                                })
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "sel_box new_sel_box",
                                      style: {
                                        left: _vm.drawBox.boxStartX + "px",
                                        top: _vm.drawBox.boxStartY + "px",
                                        width: _vm.drawBox.boxWidth + "px",
                                        height: _vm.drawBox.boxHeight + "px"
                                      }
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.audioTag
                ? _c("div", { staticClass: "controlWrap" }, [
                    _c("div", { staticClass: "tool-items" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            id: "btnPlay",
                            "data-toggle": "tooltip",
                            "data-placement": "bottom",
                            title: _vm.$t("recordingPlayers-play")
                          },
                          on: {
                            click: () => {
                              _vm.toggle()
                            }
                          }
                        },
                        [
                          _c("span", {
                            class: {
                              "ion-play": _vm.paused,
                              "ion-pause": !_vm.paused
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "cursor-pointer",
                          attrs: {
                            id: "btnBookmark",
                            "data-toggle": "tooltip",
                            "data-placement": "bottom",
                            title: "Set parameters"
                          },
                          on: { click: _vm.showParameterModal }
                        },
                        [_c("i", { staticClass: "fas fa-cog" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            id: "btnReplay",
                            "data-toggle": "tooltip",
                            "data-placement": "bottom",
                            title: _vm.$t("recordingPlayers-replay")
                          },
                          on: { click: _vm.restart }
                        },
                        [_c("span", { class: { "ion-refresh": _vm.paused } })]
                      ),
                      _vm._v(" "),
                      _c("div", { attrs: { id: "media_position" } }, [
                        _c("span", { attrs: { id: "currentTime" } }, [
                          _vm._v("0")
                        ]),
                        _vm._v(
                          "s " +
                            _vm._s(
                              _vm.$t("common-audioProgress", {
                                position: "",
                                length: _vm.seconds_to_minutes_seconds(
                                  _vm.recording.totalLengthSec
                                )
                              })
                            ) +
                            "\n            "
                        ),
                        !_vm.canPlayAudio
                          ? _c(
                              "span",
                              {
                                staticClass: "error link",
                                on: { click: _vm.playAudioIssue }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("common-audioProblems")) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAllTasks,
                            expression: "showAllTasks"
                          }
                        ],
                        staticClass: "legend grid grid-cols-3 gap-2"
                      },
                      _vm._l(_vm.legendTaskUsers, function(user) {
                        return _c(
                          "div",
                          {
                            key: "withData" + user.taskId,
                            staticClass: "transcriber"
                          },
                          [
                            [
                              _c("div", { staticClass: "legend_name" }, [
                                _vm._v(_vm._s(user.userName))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.legendList,
                                    expression: "legendList"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: user.taskId,
                                  checked: Array.isArray(_vm.legendList)
                                    ? _vm._i(_vm.legendList, user.taskId) > -1
                                    : _vm.legendList
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.legendList,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = user.taskId,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.legendList = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.legendList = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.legendList = $$c
                                    }
                                  }
                                }
                              })
                            ]
                          ],
                          2
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAllTasks,
                            expression: "showAllTasks"
                          }
                        ],
                        staticClass: "p-3"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "btn btn-default float-right",
                            on: { click: _vm.toggleAllCheckmarks }
                          },
                          [_vm._v(_vm._s(_vm.$t("Turn off all users")))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.showAllTasks && _vm.myMarkers.length > 0,
                            expression: "!showAllTasks && myMarkers.length > 0"
                          }
                        ],
                        staticClass: "legend"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$tc(
                                "recordingPlayers-numMarkers",
                                _vm.myMarkers.length,
                                { num: _vm.myMarkers.length }
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.task.id === 370154
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-success",
                      on: {
                        click: function($event) {
                          return _vm.seek(11.74)
                        }
                      }
                    },
                    [_vm._v("Seek to 11.74s")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.options && _vm.options.spectrogramOptions
                ? _c("div", { staticClass: "params" }, [
                    _c("h5", [_vm._v(_vm._s(_vm.$t("Current settings")))]),
                    _vm._v(" "),
                    _c("table", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Channel")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "aruVerificationModal-tooltips.channelfilter"
                                ),
                                expression:
                                  "$t('aruVerificationModal-tooltips.channelfilter')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              (
                                _vm.options.spectrogramOptions.channel.find(
                                  v => v.value === _vm.dynParams.channel
                                ) || {}
                              ).description
                            ) + "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Amplify")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "aruVerificationModal-tooltips.amplify"
                                ),
                                expression:
                                  "$t('aruVerificationModal-tooltips.amplify')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              (
                                _vm.options.spectrogramOptions.amplify.find(
                                  v => v.value === _vm.dynParams.amplifyId
                                ) || {}
                              ).description
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Noise Filter")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "aruVerificationModal-tooltips.noisefilter"
                                ),
                                expression:
                                  "$t('aruVerificationModal-tooltips.noisefilter')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              (
                                _vm.options.spectrogramOptions.noiseFilter.find(
                                  v => v.value === _vm.dynParams.noiseFilterId
                                ) || {}
                              ).description
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Contrast")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "aruVerificationModal-tooltips.Z-contrast"
                                ),
                                expression:
                                  "$t('aruVerificationModal-tooltips.Z-contrast')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              (
                                _vm.options.spectrogramOptions.spectrogramZContrast.find(
                                  v => v.value === _vm.dynParams.contrastId
                                ) || {}
                              ).description
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Brightness"))),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "aruVerificationModal-tooltips.Z-brightness"
                                ),
                                expression:
                                  "$t('aruVerificationModal-tooltips.Z-brightness')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              (
                                _vm.options.spectrogramOptions.spectrogramZBrightness.find(
                                  v => v.value === _vm.dynParams.brightnessId
                                ) || {}
                              ).description
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("X Scale")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time."
                                ),
                                expression:
                                  "$t('Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time.')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.dynParams.xScale) + "x")]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Y Height")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "aruVerificationModal-tooltips.Y-scale"
                                ),
                                expression:
                                  "$t('aruVerificationModal-tooltips.Y-scale')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.dynParams.yPixels) + "px")
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Minimum Frequency (Hz)")))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.dynParams.minFreqHz))]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Maximum Frequency (Hz)")))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.dynParams.maxFreqHz))]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Monochrome")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms."
                                ),
                                expression:
                                  "$t('When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms.')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.dynParams.useMonochrome,
                                expression: "dynParams.useMonochrome"
                              }
                            ],
                            staticClass: "fas fa-check-circle text-green-500"
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.dynParams.useMonochrome,
                                expression: "!dynParams.useMonochrome"
                              }
                            ],
                            staticClass: "fas fa-times-circle text-red-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("Lightmode")) + " "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms."
                                ),
                                expression:
                                  "$t('When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms.')"
                              }
                            ],
                            staticClass:
                              "ion-information-circled text-yellow-500"
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.dynParams.lightMode,
                                expression: "dynParams.lightMode"
                              }
                            ],
                            staticClass: "fas fa-check-circle text-green-500"
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.dynParams.lightMode,
                                expression: "!dynParams.lightMode"
                              }
                            ],
                            staticClass: "fas fa-times-circle text-red-500"
                          })
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("recordingPlayers-loggedSounds")))
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "mode-heading" }, [
                  _vm._v(_vm._s(_vm.$t("recordingPlayers-playMode")) + ":")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "playMode" }, [
                  _c(
                    "span",
                    {
                      staticClass: "mode",
                      class: {
                        active: _vm.logTableAutoPlay,
                        "ion-checkmark": _vm.logTableAutoPlay
                      },
                      on: {
                        click: function($event) {
                          _vm.logTableAutoPlay = true
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("recordingPlayers-auto")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "mode",
                      class: {
                        active: !_vm.logTableAutoPlay,
                        "ion-checkmark": !_vm.logTableAutoPlay
                      },
                      on: {
                        click: function($event) {
                          _vm.logTableAutoPlay = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("recordingPlayers-manual")))]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.tagList.length > 0
                ? _c("log-table", {
                    attrs: {
                      "task-id": _vm.task.id,
                      "vocalization-opt": _vm.options.vocalization,
                      taggers: _vm.taskUsers,
                      "is-admin-view": _vm.showAllTasks,
                      species: _vm.species
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showForm
        ? _c("recording-form", {
            ref: "form",
            attrs: {
              "show-tag-form": _vm.showTagForm,
              "tag-permitted": _vm.isEditable,
              "task-update-message": _vm.taskUpdateMessage,
              task: _vm.task,
              options: _vm.options,
              "task-overview": _vm.taskOverview,
              "tag-id": _vm.tagId,
              "draw-box": _vm.drawBox,
              "is-editable": _vm.isEditable,
              "to-scroll": _vm.toScroll,
              "edit-parent-only": _vm.editParentOnly,
              modify: _vm.modify,
              species: _vm.species
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          width: "100px",
          height: "50px",
          "text-align": "left",
          "z-index": "0",
          position: "absolute",
          left: "400px"
        }
      },
      [_c("div", { attrs: { id: "pl-twrap-right" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }