import i18n from '@/i18n';
import axios from 'axios';
import Vue from 'vue';
import VueResource from 'vue-resource';
import { API_URL, attachAuthorizaitonHeader, isLocal } from '@/lib/common';
import {eventBus} from '@/lib/eventbus';

Vue.use(VueResource); // for http.get

export function getUserLanguagePreference() {
  //attachAuthorizaitonHeader();
  return new Promise((resolve, reject) => {
    Vue.http.get(API_URL + 'get-user-language').then(response => {
      if (response.body.hasOwnProperty('error')) {
        // if problem, default to english
        //  changeLanguage('en');
        resolve('en');
      } else {
        //  changeLanguage(response.body.language); //TODO: dont do this here bc refreshing the page stops functions from completing
        resolve(response.body.language);
      }
    }
    )
  })
}

export function attachLanguageHeader() {
  // add interceptor so the language setting is sent in all calls
  Vue.http.interceptors.push((request) => {
    //  if (request.url.indexOf('api.wildtrax.ca/bis/') !== -1) {
    request.headers.set('Accept-Language', i18n.locale);
    //  }
  });

  axios.interceptors.request.use(function (config) {
    config.headers['Accept-Language'] = i18n.locale;
    return config;
  });
}

/**
 * Determine which locale is specified in the URL.
 */
export function localeFromURL() {
  let locales = ['fr'];

  let pagePieces = getPagePieces();

  if (locales.includes(pagePieces[0])) {
    return pagePieces[0];
  }
  return 'en';
}

function getPagePieces() {

  let pagePieces = null;
  //For local
  try {
    pagePieces = window.location.href.split('wildtrax.ca')[1].split('/').filter(str => str.length > 0 && str !== '#');
  } catch (err) {
    pagePieces = window.location.href.split('localhost')[1].split('/').filter(str => !str.startsWith(":") && str.length > 0 && str !== '#');
  }

  return pagePieces;
}

export function redirectToLocale(locale) {

}

export const SUPPORTED_LANGUAGES = [
  {
    language: i18n.t('common-languages.english'),
    locale: 'en'
  },
  {
    language: i18n.t('common-languages.french'),
    locale: 'fr'
  }
]

export function getLanguageText(locale) {
  return i18n.t(`common-languages.${locale}`);
}

export function changeLanguage(newLocale) {
  i18n.locale = newLocale;
  return;
}

export function createLink(url, leadingSlash = true) {
    url = url.replace(/^(\/|#)*/g,"");
    url = '/'+url;
  return url;
}

