var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full", attrs: { id: "addProjectModal" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "iconButton link",
          on: { click: _vm.close }
        },
        [_c("span", { staticClass: "ion-close" })]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "center modal-content padded40",
              staticStyle: { padding: "40px" }
            },
            [
              _c("pulse-loader", {
                attrs: {
                  loading: true,
                  color: "#C94412",
                  size: "15px",
                  width: 60,
                  height: 30
                }
              }),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.commonMsg.loading))])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.project != null
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              staticClass: "h-full relative"
            },
            [
              !_vm.newProject
                ? _c(
                    "ul",
                    {
                      staticClass: "tabs drag-handle !m-0 !w-full !max-w-full"
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass: "tab",
                          on: {
                            click: function($event) {
                              _vm.showTab = "project"
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { class: { active: _vm.showTab == "project" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.projectId == null
                                    ? _vm.$t("projectForm-create", {
                                        label:
                                          _vm.project && _vm.project.sensorId
                                      })
                                    : _vm.$t("projectForm-update", {
                                        label:
                                          _vm.project && _vm.project.sensorId
                                      })
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.project && _vm.project.id
                        ? _c(
                            "li",
                            {
                              staticClass: "tab",
                              on: {
                                click: function($event) {
                                  _vm.showTab = "species"
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  class: { active: _vm.showTab === "species" }
                                },
                                [
                                  _vm._v(
                                    "\n        " +
                                      _vm._s(_vm.$t("projectForm-species")) +
                                      "\n      "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.project && _vm.project.id != null
                        ? _c(
                            "li",
                            {
                              staticClass: "tab",
                              on: {
                                click: function($event) {
                                  _vm.showTab = "user"
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                { class: { active: _vm.showTab == "user" } },
                                [_vm._v(_vm._s(_vm.$t("projectForm-users")))]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTab === "species" && _vm.speciesLoading,
                      expression: "showTab === 'species' && speciesLoading"
                    }
                  ],
                  staticClass: "center modal-content padded40",
                  staticStyle: { padding: "40px" }
                },
                [
                  _c("pulse-loader", {
                    attrs: {
                      loading: true,
                      color: "#C94412",
                      size: "15px",
                      width: 60,
                      height: 30
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("Loading Species")))])
                ],
                1
              ),
              _vm._v(" "),
              _vm.species !== null
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.showTab === "species" && !_vm.speciesLoading,
                          expression: "showTab === 'species' && !speciesLoading"
                        }
                      ],
                      staticClass: "modal-content !p-10 !static"
                    },
                    [
                      _c("h4", { staticClass: "mt-0" }, [
                        _vm._v(" " + _vm._s(_vm.$t("projectForm-species")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex relative",
                          attrs: { id: "species-selection-widget" }
                        },
                        [
                          _vm.presetLoading
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "center modal-content absolute bg-white/75 w-full h-full z-10",
                                  staticStyle: { padding: "40px" }
                                },
                                [
                                  _c("pulse-loader", {
                                    attrs: {
                                      loading: true,
                                      color: "#C94412",
                                      size: "15px",
                                      width: 60,
                                      height: 30
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.commonMsg.loading))
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-1/2 flex-1" }, [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("projectForm-displaySpeciesNamesAs")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.showScientificName,
                                    expression: "showScientificName"
                                  }
                                ],
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.showScientificName = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { domProps: { value: false } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("projectForm-common-name"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: true } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("projectForm-scientific-name")
                                    )
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("common-notIncluded")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "overflow-scroll h-96 !border !border-black !border-solid block !mb-5"
                              },
                              [
                                _c("species-sort-table", {
                                  ref: _vm.EXCLUDED_LIST,
                                  attrs: {
                                    species: _vm.species.Excluded,
                                    showScientificName: _vm.showScientificName,
                                    selectable: true
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.selectAll(_vm.EXCLUDED_LIST)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Select All")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.unselectAll(_vm.EXCLUDED_LIST)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Unselect All")))]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-init block p-1 flex-col flex pt-48"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    disabled:
                                      !_vm.project.hasProjectWriteAccess &&
                                      !_vm.newProject
                                  },
                                  on: { click: _vm.includeSpecies }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-chevron-right align-middle"
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    disabled:
                                      !_vm.project.hasProjectWriteAccess &&
                                      !_vm.newProject
                                  },
                                  on: { click: _vm.excludeSpecies }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-chevron-left align-middle"
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-1/2 flex-1" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("common-presets")) + "  "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "Preset groups allow you to choose from different lists of species from various geographic regions, e.g. Bats of Alberta, Birds of Canada. Lists are maintained by various authorities and taxa specialists. If you need a custom preset group added, email info@wildtrax.ca"
                                      ),
                                      expression:
                                        "$t('Preset groups allow you to choose from different lists of species from various geographic regions, e.g. Bats of Alberta, Birds of Canada. Lists are maintained by various authorities and taxa specialists. If you need a custom preset group added, email info@wildtrax.ca')"
                                    }
                                  ]
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-info-circle text-yellow-400"
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "flex-1 btn",
                                  attrs: {
                                    disabled:
                                      !_vm.project.hasProjectWriteAccess &&
                                      !_vm.newProject
                                  },
                                  on: { click: _vm.showPresetModal }
                                },
                                [
                                  _c("span", { staticClass: "!text-sm" }, [
                                    _vm._v(_vm._s(_vm.$t("Apply Preset")))
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("common-included")) + " "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "Species included in the project are the only ones that can be tagged. If you need to tag another species, return to the project settings and add it from the Not Included column on the left"
                                      ),
                                      expression:
                                        "$t('Species included in the project are the only ones that can be tagged. If you need to tag another species, return to the project settings and add it from the Not Included column on the left')"
                                    }
                                  ]
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-info-circle text-yellow-400"
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "overflow-scroll h-96 !border !border-black !border-solid block !mb-5"
                              },
                              [
                                _c("species-sort-table", {
                                  ref: _vm.INCLUDED_LIST,
                                  attrs: {
                                    species: _vm.species.Included,
                                    showScientificName: _vm.showScientificName,
                                    selectable: true
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn btn-primary flex-initial mr-2",
                                  on: {
                                    click: function($event) {
                                      return _vm.selectAll(_vm.INCLUDED_LIST)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("common-selectAll")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn btn-primary flex-initial mr-2",
                                  on: {
                                    click: function($event) {
                                      return _vm.unselectAll(_vm.INCLUDED_LIST)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "equipmentGridComboBox.unselectAll"
                                      )
                                    )
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-footer flex justify-end absolute bottom-5 w-full -ml-20"
                        },
                        [
                          _vm.newProject
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn mr-3 flex-initial",
                                  attrs: {
                                    disabled: !_vm.buttonStates.species.Prev
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.showTab = "project"
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Previous")))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.newProject
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-success flex-initial",
                                  attrs: {
                                    disabled: !_vm.buttonStates.species.Next
                                  },
                                  on: {
                                    click: () => {
                                      if (_vm.newProject) _vm.showTab = "user"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.newProject ? "Next" : "Update")
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTab == "project" && !_vm.loading,
                      expression: "showTab =='project' && !loading"
                    }
                  ],
                  staticClass: "modal-content padded40 !static"
                },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(
                        _vm.projectId == null
                          ? _vm.$t("projectForm-create", {
                              label: _vm.project && _vm.project.sensorId
                            })
                          : _vm.$t("projectForm-update", {
                              label: _vm.project && _vm.project.sensorId
                            })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s12 text-sm" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("Fields marked * are required")) +
                          "\n        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s12 m6 l8" }, [
                      _c("label", { attrs: { for: "project_title" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("projectForm-fields.title")) + " "
                        ),
                        _c("span", { staticClass: "font-bold text-base" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.$t(
                                "Unique short-hand name that describes the project"
                              ),
                              expression:
                                "$t('Unique short-hand name that describes the project')"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.project.fullNm,
                            expression: "project.fullNm"
                          }
                        ],
                        staticClass: "validate",
                        attrs: {
                          disabled:
                            !_vm.project.hasProjectWriteAccess &&
                            !_vm.newProject &&
                            !_vm.newProject,
                          id: "project_title",
                          type: "text",
                          required: "",
                          placeholder: _vm.$t("projectForm-fields.title")
                        },
                        domProps: { value: _vm.project.fullNm },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.project, "fullNm", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l4" }, [
                      _c("label", { attrs: { for: "project_title" } }, [
                        _vm._v(_vm._s(_vm.$tc("common-organization", 1)) + " "),
                        _c("span", { staticClass: "font-bold text-base" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.$t(
                                "Dropdown menu of the list of organizations the project can belong to (depending on user membership)"
                              ),
                              expression:
                                "$t('Dropdown menu of the list of organizations the project can belong to (depending on user membership)')"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.organizationId,
                              expression: "project.organizationId"
                            }
                          ],
                          attrs: { disabled: _vm.project.id > 0 },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.project,
                                "organizationId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _vm._l(_vm.organizationList, function(option, index) {
                            return [
                              _vm.project.id > 0
                                ? _c(
                                    "option",
                                    {
                                      key: "org" + index,
                                      domProps: { value: option.id }
                                    },
                                    [_vm._v(_vm._s(option.organization))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.project.id == null &&
                              option.canEditOrganization
                                ? _c(
                                    "option",
                                    {
                                      key: "org" + index,
                                      domProps: { value: option.id }
                                    },
                                    [_vm._v(_vm._s(option.organization))]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm.project.sensorId !== "PC"
                      ? _c("div", { staticClass: "col s12 m4 l4" }, [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("projectForm-fields.dueDate")) +
                                "\n            "
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.$t(
                                    "Due date of the project for processors/validators"
                                  ),
                                  expression:
                                    "$t('Due date of the project for processors/validators')"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            })
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vue-select-wrapper" },
                            [
                              _c("date-picker", {
                                staticClass: "!w-full",
                                attrs: {
                                  disabled:
                                    !_vm.project.hasProjectWriteAccess &&
                                    !_vm.newProject &&
                                    !_vm.newProject,
                                  lang: _vm.locales[_vm.$root.$i18n.locale],
                                  valueType: "format",
                                  format: "YYYY-MM-DD",
                                  "use-utc": true,
                                  "disabled-dates": _vm.state.disabledDates
                                },
                                model: {
                                  value: _vm.project.dueDateStr,
                                  callback: function($$v) {
                                    _vm.$set(_vm.project, "dueDateStr", $$v)
                                  },
                                  expression: "project.dueDateStr"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: {
                          "col s12": true,
                          "m8 l8": _vm.project.sensorId !== "PC",
                          m12: _vm.project.sensorId === "PC"
                        }
                      },
                      [
                        _c("label", { attrs: { for: "project_dataSource" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("projectForm-fields.dataSource")) +
                              "\n            "
                          ),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "Does the data derive from an external source?"
                                ),
                                expression:
                                  "$t('Does the data derive from an external source?')"
                              }
                            ],
                            staticClass: "info-icon ion-information-circled"
                          })
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.project.dataSource,
                              expression: "project.dataSource",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "validate",
                          attrs: {
                            disabled:
                              !_vm.project.hasProjectWriteAccess &&
                              !_vm.newProject &&
                              !_vm.newProject,
                            id: "project_dataSource",
                            type: "text"
                          },
                          domProps: { value: _vm.project.dataSource },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.project,
                                "dataSource",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s6" }, [
                      _c("label", { attrs: { for: "project_description" } }, [
                        _vm._v(_vm._s(_vm.$t("Purpose and Methods"))),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.$t(
                                "An outline of the purposes (objectives) and methods of the project."
                              ),
                              expression:
                                "$t('An outline of the purposes (objectives) and methods of the project.')"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.project.description,
                            expression: "project.description"
                          }
                        ],
                        staticClass: "materialize-textarea !overflow-auto",
                        attrs: {
                          disabled:
                            !_vm.project.hasProjectWriteAccess &&
                            !_vm.newProject &&
                            !_vm.newProject,
                          id: "project_description",
                          "data-length": "120"
                        },
                        domProps: { value: _vm.project.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.project,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "character-counter",
                        staticStyle: {
                          float: "right",
                          "font-size": "12px",
                          height: "1px"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s6" }, [
                      _c("label", { attrs: { for: "project_results" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("Results Summary")) + "\n            "
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.$t(
                                "Summary of the results and data found"
                              ),
                              expression:
                                "$t('Summary of the results and data found')"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.project.results,
                            expression: "project.results"
                          }
                        ],
                        staticClass: "!overflow-auto materialize-textarea",
                        attrs: {
                          disabled:
                            !_vm.project.hasProjectWriteAccess &&
                            !_vm.newProject &&
                            !_vm.newProject,
                          id: "project_results",
                          "data-length": "120"
                        },
                        domProps: { value: _vm.project.results },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.project,
                              "results",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "character-counter",
                        staticStyle: {
                          float: "right",
                          "font-size": "12px",
                          height: "1px"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.project.sensorId === "ARU" &&
                    _vm.options.spectrogramOptions
                      ? _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "bg-gray-100 p-6" }, [
                            _c(
                              "label",
                              { staticClass: "text-base mb-3 block" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("projectForm-ARUProjectOptions")
                                  ) + " "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "Select from the options below to choose the default spectrogram options for processing tasks."
                                      ),
                                      expression:
                                        "$t('Select from the options below to choose the default spectrogram options for processing tasks.')"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "grid grid-cols-3 gap-2" },
                              [
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Default Min Freq(Hz)")) +
                                        " "
                                    ),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.$t(
                                            "Set the default minimum frequency of the spectrogram in hertz."
                                          ),
                                          expression:
                                            "$t('Set the default minimum frequency of the spectrogram in hertz.')"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.project.defaultMinFreqHz,
                                        expression: "project.defaultMinFreqHz"
                                      }
                                    ],
                                    staticClass:
                                      "h-12 w-full border-gray-200 border px-3",
                                    attrs: {
                                      disabled:
                                        !_vm.project.hasProjectWriteAccess &&
                                        !_vm.newProject,
                                      type: "num"
                                    },
                                    domProps: {
                                      value: _vm.project.defaultMinFreqHz
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.project,
                                          "defaultMinFreqHz",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Default Max Freq(Hz)")) +
                                        " "
                                    ),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.$t(
                                            "Set the default maximum frequency of the spectrogram in hertz."
                                          ),
                                          expression:
                                            "$t('Set the default maximum frequency of the spectrogram in hertz.')"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.project.defaultMaxFreqHz,
                                        expression: "project.defaultMaxFreqHz"
                                      }
                                    ],
                                    staticClass:
                                      "h-12 w-full border-gray-200 border px-3",
                                    attrs: {
                                      disabled:
                                        !_vm.project.hasProjectWriteAccess &&
                                        !_vm.newProject,
                                      type: "num"
                                    },
                                    domProps: {
                                      value: _vm.project.defaultMaxFreqHz
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.project,
                                          "defaultMaxFreqHz",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Default X Scale")) + " "
                                    ),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.$t(
                                            "Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time."
                                          ),
                                          expression:
                                            "$t('Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time.')"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex-initial w-12" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.project.defaultXScale) +
                                              "x "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.project.defaultXScale,
                                            expression: "project.defaultXScale"
                                          }
                                        ],
                                        staticClass: "flex-1",
                                        attrs: {
                                          disabled:
                                            !_vm.project
                                              .hasProjectWriteAccess &&
                                            !_vm.newProject,
                                          type: "range",
                                          min: "0.25",
                                          max: "10",
                                          step: "0.25"
                                        },
                                        domProps: {
                                          value: _vm.project.defaultXScale
                                        },
                                        on: {
                                          __r: function($event) {
                                            return _vm.$set(
                                              _vm.project,
                                              "defaultXScale",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Default Y Pixels")) + " "
                                    ),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.$t(
                                            "aruVerificationModal-tooltips.Y-scale"
                                          ),
                                          expression:
                                            "$t('aruVerificationModal-tooltips.Y-scale')"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project.defaultYPixels,
                                          expression: "project.defaultYPixels"
                                        }
                                      ],
                                      attrs: {
                                        disabled:
                                          !_vm.project.hasProjectWriteAccess &&
                                          !_vm.newProject
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.project,
                                            "defaultYPixels",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(
                                      _vm.options.spectrogramOptions
                                        .spectrogramYPixels,
                                      function(option, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: option.id }
                                          },
                                          [_vm._v(_vm._s(option.value))]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Default Monochrome")) + " "
                                    ),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.$t(
                                            "When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms."
                                          ),
                                          expression:
                                            "$t('When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms.')"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm.project.useMonochrome
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-toggle-on !block text-4xl text-green-400",
                                          attrs: {
                                            disabled: !_vm.project
                                              .hasProjectWriteAccess
                                          },
                                          on: {
                                            click: () => {
                                              _vm.project.useMonochrome = false
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.project.useMonochrome
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-toggle-off !block text-4xl text-red-400",
                                          attrs: {
                                            disabled: !_vm.project
                                              .hasProjectWriteAccess
                                          },
                                          on: {
                                            click: () => {
                                              _vm.project.useMonochrome = true
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Default Lightmode")) + " "
                                    ),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.$t(
                                            "When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms."
                                          ),
                                          expression:
                                            "$t('When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms.')"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm.project.useLightMode
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-toggle-on !block text-4xl text-green-400",
                                          attrs: {
                                            disabled: !_vm.project
                                              .hasProjectWriteAccess
                                          },
                                          on: {
                                            click: () => {
                                              _vm.project.useLightMode = false
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.project.useLightMode
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-toggle-off !block text-4xl text-red-400",
                                          attrs: {
                                            disabled: !_vm.project
                                              .hasProjectWriteAccess
                                          },
                                          on: {
                                            click: () => {
                                              _vm.project.useLightMode = true
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                ])
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.project.sensorId === "CAM"
                      ? _c("div", { staticClass: "col s12" }, [
                          _c("div", { staticClass: "bg-gray-100 p-6 mb-3" }, [
                            _c(
                              "label",
                              { staticClass: "text-base mb-3 block" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Individual-level Tag Options"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "grid grid-cols-6 gap-3" },
                              [
                                _vm._l(_vm.individualLevelTagOptions, function(
                                  item,
                                  index
                                ) {
                                  return [
                                    _c("div", { key: index }, [
                                      _c(
                                        "label",
                                        { staticClass: "flex items-start" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.project[item.name],
                                                expression: "project[item.name]"
                                              }
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.project[item.name]
                                              )
                                                ? _vm._i(
                                                    _vm.project[item.name],
                                                    null
                                                  ) > -1
                                                : _vm.project[item.name]
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.project[item.name],
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.project,
                                                        item.name,
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.project,
                                                        item.name,
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.project,
                                                    item.name,
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span"),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(item.label) +
                                                "\n                        "
                                            ),
                                            _c("span", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: item.tooltip,
                                                  expression: "item.tooltip"
                                                }
                                              ],
                                              staticClass:
                                                "info-icon ion-information-circled"
                                            })
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "label",
                              { staticClass: "text-base mb-3 block" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Image-level Tag Options"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "grid grid-cols-6 gap-3" },
                              [
                                _vm._l(_vm.imageLevelTagOptions, function(
                                  item,
                                  index
                                ) {
                                  return [
                                    _c("div", { key: index }, [
                                      _c(
                                        "label",
                                        { staticClass: "flex items-start" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.project[item.name],
                                                expression: "project[item.name]"
                                              }
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.project[item.name]
                                              )
                                                ? _vm._i(
                                                    _vm.project[item.name],
                                                    null
                                                  ) > -1
                                                : _vm.project[item.name]
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.project[item.name],
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.project,
                                                        item.name,
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.project,
                                                        item.name,
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.project,
                                                    item.name,
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span"),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(item.label) +
                                                "\n                        "
                                            ),
                                            _c("span", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: item.tooltip,
                                                  expression: "item.tooltip"
                                                }
                                              ],
                                              staticClass:
                                                "info-icon ion-information-circled"
                                            })
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                })
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "bg-gray-100 p-6" }, [
                            _c(
                              "label",
                              { staticClass: "text-base mb-3 block" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("projectForm-fields.tools"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "grid grid-cols-5 gap-3" },
                              [
                                _c("div", {}, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "flex items-start",
                                      attrs: { for: "auto-tagger" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "w-full" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("Auto-tagger")) +
                                              " "
                                          ),
                                          _c("span", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.$t(
                                                  "The Auto-tagger selection indicates whether image uploads will be run through the auto-tagger automatically. The auto-tagger is a computer-based tool that uses machine-learning techniques to detect images of humans, vehicles, animals, domestic cows, and NONE (i.e., false fires). Once applied, images of humans are blurred, and those classified as human, vehicle, domestic cow, and NONE are tagged as such and hidden from further assessment (can be viewed via unfiltering “exclude auto-tagged” through the FILTER button). Importantly, the images classified as animals are not identified to species; thus, no species tag is applied; these images are automatically available for viewing and subsequent tagging. Note: some species identification may be available through the auto-tagger in the future."
                                                ),
                                                expression:
                                                  "$t('The Auto-tagger selection indicates whether image uploads will be run through the auto-tagger automatically. The auto-tagger is a computer-based tool that uses machine-learning techniques to detect images of humans, vehicles, animals, domestic cows, and NONE (i.e., false fires). Once applied, images of humans are blurred, and those classified as human, vehicle, domestic cow, and NONE are tagged as such and hidden from further assessment (can be viewed via unfiltering “exclude auto-tagged” through the FILTER button). Importantly, the images classified as animals are not identified to species; thus, no species tag is applied; these images are automatically available for viewing and subsequent tagging. Note: some species identification may be available through the auto-tagger in the future.')"
                                              }
                                            ],
                                            staticClass:
                                              "info-icon ion-information-circled"
                                          }),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.localOptions.autoTagger,
                                            function(tagger, i) {
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    key: i,
                                                    staticClass:
                                                      "flex items-start"
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "checkbox"
                                                      },
                                                      domProps: {
                                                        checked: _vm.autoTaggerIsChecked(
                                                          tagger.id
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.autoTaggerCheck(
                                                            tagger.id
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "!normal-case"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(tagger.type)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.autoTaggerIsChecked(
                                                          tagger.id
                                                        ),
                                                        expression:
                                                          "autoTaggerIsChecked(tagger.id)"
                                                      }
                                                    ],
                                                    key: `${i}_2`,
                                                    staticClass:
                                                      "flex items-start ml-10"
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "checkbox"
                                                      },
                                                      domProps: {
                                                        checked: _vm.autoTaggerIsCheckedHiddenByDefault(
                                                          tagger.id
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.autoTaggerCheckHiddenByDefault(
                                                            tagger.id
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "!text-sm !normal-case"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "Hidden by default"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", {}, [
                                  _c(
                                    "label",
                                    { attrs: { for: "second-image-gap" } },
                                    [
                                      _vm._v(
                                        " \n                  " +
                                          _vm._s(
                                            _vm.$t("projectForm-fields.gap")
                                          ) +
                                          "\n                  "
                                      ),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.$t(
                                              "The default time (in seconds) between images or sequences that defines an independent detection event. For example, suppose the inter-detection interval is 120 seconds and a coyote is detected in an image. In that case, all images of coyotes that occur within 120 seconds of the last image of a coyote are considered the same detection event (this continues until >120 seconds pass without a coyote present). Users can modify the inter-detection interval at any time. Note: some definitions include that detections must be consecutive (i.e., not interrupted by another species); document your definition in the Purpose and Methods."
                                            ),
                                            expression:
                                              "$t('The default time (in seconds) between images or sequences that defines an independent detection event. For example, suppose the inter-detection interval is 120 seconds and a coyote is detected in an image. In that case, all images of coyotes that occur within 120 seconds of the last image of a coyote are considered the same detection event (this continues until >120 seconds pass without a coyote present). Users can modify the inter-detection interval at any time. Note: some definitions include that detections must be consecutive (i.e., not interrupted by another species); document your definition in the Purpose and Methods.')"
                                          }
                                        ],
                                        staticClass:
                                          "info-icon ion-information-circled"
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.project.seriesGap,
                                            expression: "project.seriesGap"
                                          }
                                        ],
                                        attrs: {
                                          disabled:
                                            !_vm.project
                                              .hasProjectWriteAccess &&
                                            !_vm.newProject,
                                          id: "second-image-gap"
                                        },
                                        domProps: {
                                          value: _vm.project.seriesGap
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.project,
                                              "seriesGap",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("Bounding box display threshold")
                                      ) + "  "
                                    ),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.$t(
                                            "The minimum confidence of a mega detector bounding box to display it. Recommended 30-50%."
                                          ),
                                          expression:
                                            "$t('The minimum confidence of a mega detector bounding box to display it. Recommended 30-50%.')"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project
                                                  .defaultMinMegaDetectorConfidence ||
                                                0.5,
                                              expression:
                                                "project.defaultMinMegaDetectorConfidence||0.5"
                                            }
                                          ],
                                          attrs: {
                                            type: "range",
                                            min: "0",
                                            max: "1",
                                            step: "0.01"
                                          },
                                          domProps: {
                                            value:
                                              _vm.project
                                                .defaultMinMegaDetectorConfidence ||
                                              0.5
                                          },
                                          on: {
                                            __r: function($event) {
                                              return _vm.$set(
                                                _vm.project
                                                  .defaultMinMegaDetectorConfidence ||
                                                  0,
                                                "5",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "text-center -mt-5" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.project
                                              .defaultMinMegaDetectorConfidence
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "flex items-start",
                                      attrs: { for: "timelapse" }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.project
                                                .showTimeLapseByDefault,
                                            expression:
                                              "project.showTimeLapseByDefault"
                                          }
                                        ],
                                        attrs: {
                                          disabled:
                                            !_vm.project
                                              .hasProjectWriteAccess &&
                                            !_vm.newProject,
                                          id: "timelapse",
                                          type: "checkbox"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.project.showTimeLapseByDefault
                                          )
                                            ? _vm._i(
                                                _vm.project
                                                  .showTimeLapseByDefault,
                                                null
                                              ) > -1
                                            : _vm.project.showTimeLapseByDefault
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.project
                                                  .showTimeLapseByDefault,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.project,
                                                    "showTimeLapseByDefault",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.project,
                                                    "showTimeLapseByDefault",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.project,
                                                "showTimeLapseByDefault",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span"),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("Show timelapse images")
                                          ) +
                                          "\n                "
                                      ),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.$t(
                                              "Determines if time-lapse images are shown by default not this can be toggled on and off inside each set of images while tagging."
                                            ),
                                            expression:
                                              "$t('Determines if time-lapse images are shown by default not this can be toggled on and off inside each set of images while tagging.')"
                                          }
                                        ],
                                        staticClass:
                                          "info-icon ion-information-circled"
                                      })
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: {
                          "col s12": true,
                          "m4 l4": _vm.project.sensorId != "PC",
                          "m12 l12": _vm.project.sensorId == "OC"
                        }
                      },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("common-status")))]),
                        _vm._v(" "),
                        _c("div", { staticClass: "vue-select-wrapper" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.project.status,
                                  expression: "project.status"
                                }
                              ],
                              staticClass: "initialized",
                              attrs: { type: "number" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.project,
                                    "status",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _vm._l(_vm.options.status, function(
                                option,
                                index
                              ) {
                                return [
                                  !(
                                    _vm.project.sensorId === "PC" &&
                                    option.id === 4
                                  )
                                    ? _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: { value: option.id }
                                        },
                                        [_vm._v(_vm._s(option.type))]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("div", { key: index, staticClass: "error" }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-footer flex justify-end row w-full -ml-20"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success flex-initial",
                          attrs: { disabled: !_vm.buttonStates.project.Next },
                          on: { click: _vm.saveProject }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t(_vm.newProject ? "Next" : "Update"))
                          )
                        ]
                      )
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.project.id != null &&
                        _vm.showTab == "user" &&
                        !_vm.loading,
                      expression:
                        "project.id != null && showTab =='user' && !loading"
                    }
                  ],
                  staticClass: "modal-content padded40 !static"
                },
                [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("projectForm-users")))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row relative" },
                    [
                      _vm.project.id
                        ? _c("project-user-assignment-table", {
                            attrs: {
                              users: _vm.usersInProject.concat(
                                _vm.usersNotInProject
                              ),
                              parentId: _vm.project.id,
                              parentIdLabel: "projectId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "message" }, [
                    _vm._v(_vm._s(_vm.message))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-footer flex justify-end bottom-5 w-full"
                    },
                    [
                      _vm.newProject
                        ? _c(
                            "button",
                            {
                              staticClass: "btn flex-initial mr-3",
                              attrs: { disabled: !_vm.buttonStates.user.Prev },
                              on: {
                                click: function($event) {
                                  _vm.showTab = "species"
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Prev")))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success flex-initial",
                          attrs: { disabled: !_vm.buttonStates.user.Next },
                          on: { click: _vm.close }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t(_vm.newProject ? "Create" : "Update"))
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "preset-modal",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            delay: 100,
            width: "50%",
            clickToClose: false,
            shiftY: 0.25,
            height: "500px"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "iconButton link",
              on: { click: _vm.closePresetModal }
            },
            [_c("span", { staticClass: "ion-close" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "p-5 modal-content" }, [
            _c("h5", [_vm._v(_vm._s(_vm.$t("Apply Preset")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex gap-3 items-center" },
              [
                _vm.presets.list
                  ? _c(
                      "treeselect",
                      {
                        staticClass: "flex-1",
                        attrs: {
                          multiple: true,
                          options: _vm.presets.list,
                          "value-consists-of": "ALL",
                          "max-height": 250,
                          placeholder: _vm.$t("Begin typing to find presets.")
                        },
                        model: {
                          value: _vm.presets.selected.list,
                          callback: function($$v) {
                            _vm.$set(_vm.presets.selected, "list", $$v)
                          },
                          expression: "presets.selected.list"
                        }
                      },
                      [_vm._v("\n        >")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.presets.selected.species &&
            _vm.presets.selected.species.length > 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "overflow-scroll h-80 !border !border-gray-300 rounded !border-solid block !my-5"
                    },
                    [
                      _c("species-sort-table", {
                        attrs: {
                          species: _vm.presets.selected.species,
                          selectable: false
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "modal-footer flex justify-end px-5 w-full"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn flex-initial mr-3",
                          on: { click: _vm.closePresetModal }
                        },
                        [_vm._v(_vm._s(_vm.$t("common-cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success flex-initial",
                          attrs: { disabled: !_vm.presets.selected.list },
                          on: { click: _vm.applyPreset }
                        },
                        [_vm._v(_vm._s(_vm.$t("Confirm")))]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !Array.isArray(_vm.presets.selected.species) &&
            _vm.presets.selected.species === null
              ? _c("div", { staticClass: "py-5" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "No species were found in this preset group. Please try again."
                        )
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }