<template>
  <div>
    <div @click="closeForm" class="iconButton link">
      <span class="ion-close"></span>
    </div>
    <div class="modal-header">{{$t('Delete Selected Tasks')}}</div>
    <div class="modal-content padded40">
       <p class="orange-font">{{headers[activeSensor + '_preview']}}</p>
       <div class="row">

          <table>
              <tr>
                <template v-for="(heading,i) in allHeadings">
                  <th :key="i" v-if="!['id','subDetails', 'childRowLoading'].includes(i) && !i.endsWith('Id')" >{{heading}} </th>
                </template>
              </tr>
              <template  v-for="(taskRow, taskRowIndex) in taskRows">
                <tr :key="'taskRow-'+taskRowIndex">
                  <template v-for="(heading,i) in allHeadings" >
                          <td v-if="!['id','subDetails', 'childRowLoading'].includes(i) && !i.endsWith('Id')" :key="'taskRowCell-'+taskRowIndex+'-'+i">
                            <span v-if="i === 'deploymentName' && taskRow['subDetails']">
                                {{taskRow[i]}} <span  class="ion-information-circled text-yellow-500" v-tooltip="taskRow['subDetails']"></span>
                            </span>
                            <span v-else>
                              <span v-if="taskRow[i]">{{taskRow[i]}}</span><span v-else>Unknown</span>
                            </span>
                          </td>
                  </template>
                </tr>
              </template>
          </table>
        </div>
         <p class="orange-font">{{headers.delete_media}}</p>
        <div class="modal-footer">
          <!-- <div class="error"> {{deleteResult}} </div> -->
         
          <div class="row">
            <small>{{$t('Please type "delete" into the textbox below (without quotes) to confirm deleting these task(s)')}}</small>
            <input type="text" :placeholder="$t('Confirmation Box')" v-model="deleteConfirmation" />

          </div>
          <div class="row col s12">
            <pulse-loader v-if="loading" :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="preview-pulse"></pulse-loader>
            <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
          </div>
          <div class="row">
          
            <template v-if="true">
              <button v-if="!bDeleted" class="btn modal-trigger modal-action modal-close" @click="deleteTask(true)">
                <template>{{$t('taskDeleteForm-deleteBtn')}}</template>
              </button>
            </template>
            <template v-else>
              <button v-if="!bDeleted" class="btn btn-success modal-trigger modal-action modal-close" @click="deleteTask(false)">
                <template>{{$t('taskDeleteForm-userRow')}}</template>
              </button>
            </template>
          <button class="btn btn-border modal-action modal-close" id="cancelAdd" @click="closeForm">{{$t('common-close')}}</button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import {eventBus} from '@/lib/eventbus';
import {API_URL} from '@/lib/common';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import FormMixin from '@/components/common/FormMixin.js';

/*  function: delete deployment row
    steps:
      do a check to see if there are mulitple users using the medias
        if yes, delete the user data and user assignment to the media
        if no, in addtion to the delete user data, give user options to remove media or not
        (for current situation, to make things easier, remove media always)
        if user choose to delte media delete media from AWS.
*/
export default {
  name: 'task-delete-form',
  props: ['taskIds', 'taskRows', 'activeSensor', 'headings'],
  components: {'pulse-loader': PulseLoader},
  mixins: [FormMixin],
  data () {
    return {
      deleteConfirmation: "",
      deleteConfirmationCompare: this.$t("common-delete").toLowerCase(),
      previewResult: null,
      deleteResult: '',
      bDeleted: false,
      loading: false,
      recordName: this.$tc('common-task', 1).toLowerCase(),
      errors: [],
      headers: {
        'CAM_preview': this.$t('taskDeleteForm-headers.cam'),
        'ARU_preview': this.$t('taskDeleteForm-headers.aru'),
        'delete_media': this.$t('taskDeleteForm-headers.media')
      },
      urls: {
        'CAM': {preview: 'delete-camera-pud-by-id-preview?pudId=', action: 'delete-camera-pud-by-id'},
        'ARU': {preview: 'delete-aru-task-by-id-preview?taskId=', action: 'delete-aru-task-by-id'}
      }
    }
  },
  created () {
  },
  computed: {
    allHeadings() {
      let temp = Object.fromEntries(Object.entries(this.headings).filter(([v,i])=>v!=='id'&&!v.endsWith('Id')&&v!='check'));
      
      Object.entries(this.taskRows[0]).map((v,i)=> {
        if (v[0]!=='id' && !v[0].endsWith('Id') && !temp[v[0]]) {
          temp[v[0]] = v[0].replace(/([A-Z])/g, ' $1').trim()
        }
      });

      return temp;
    }
  },
  methods: {

    formatKey (key) {
      return this.headings[key] ? this.headings[key] : key.replace(/([A-Z])/g, ' $1').trim();
    },
    deleteTask (bDeleteMedia) {

      if (this.deleteConfirmation == this.deleteConfirmationCompare) {
        this.loading = true;
        const isCamera = (this.activeSensor === 'CAM');
        const url = API_URL + this.urls[this.activeSensor].action;
        const params = {};
        params[isCamera ? 'cascadeDeleteDeployment' : 'cascadeDeleteRecording'] = true; // hardcoded to delete media TODO: change to non-hardcode
        const queryString = '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&');
        this.commonPost(url + queryString, this.taskIds, true,
          (res) => {
            // this.deleteResult = `${this.rowName[this.activeSensor]} Deleted Successfully!`;
            this.loading = false;
            this.bDeleted = true;
            eventBus.$emit('reload-task-table');
            this.closeForm();
          }, (errorResp) => {
            this.loading = false;
            // this.deleteResult = `Error occurred during ${this.rowName[this.activeSensor].toLowerCase()} Deletion!`;
          }, 'delete');
      } else {
        console.log(this.deleteConfirmationCompare);
        this.errors.push(this.$t('You did not confirm to deletion of these task(s) using the confirmation box.'));
      }
    },
    closeForm () {
      eventBus.$emit('close-delete-task-form');
    }
  }
}
</script>
<style scoped>
.no-margin {
  margin: 0;
}

.preview-pulse{
  text-align: center;
}
</style>
